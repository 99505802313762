export enum AssetHotspotGlyph {
  /// <summary>
  ///     The hotspot glyph is undefined.
  /// </summary>
  Undefined = 'Undefined',

  /// <summary>
  ///     An arrow hotspot.
  /// </summary>
  Arrow = 'Arrow',

  /// <summary>
  ///     A spot hotspot.
  /// </summary>
  Spot = 'Spot',

  /// <summary>
  ///     A point hotspot.
  /// </summary>
  Pointer = 'Pointer',
}
