import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

import {
  IAppUser,
  IValSitesUser,
  LicenseCategory,
  TableType,
} from '@backend/interfaces';
import { SpinnerService } from '../spinner/spinner.service';
import { NotificationService } from '../shared/notification/notification.service';
import { ConfirmationService } from '../shared/confirmation/confirmation.service';
import { SitesService } from '../siteplanner/sites.service';
import { CommonModules } from '../../../../../libs/common/commonModules';

@Injectable({
  providedIn: 'root',
})
export class AppUsersService {
  public tableTypeEnum = TableType;
  public users: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private valSiteusers: BehaviorSubject<IValSitesUser[]> = new BehaviorSubject<
    IValSitesUser[]
  >([]);
  public get $users(): Observable<IAppUser[]> {
    return this.users.asObservable();
  }
  public get $valSiteusers(): Observable<IValSitesUser[]> {
    return this.valSiteusers.asObservable();
  }

  public organizations: string[] = [];
  public businessLines: string[] = [];
  public roles: string[] = [];
  public locations: any[] = [];
  public regions: any[] = [];

  public constructor(
    private http: HttpClient,
    private spinner: SpinnerService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private sitesService: SitesService
  ) {}

  public async setFeedBack(user: string): Promise<void> {
    this.spinner.showSpinner();
    try {
      await lastValueFrom(this.http.post('/api/appusers/feedback', { user }));
      this.notificationService.confirmation(
        'general.success',
        'Feeback requst Send'
      );
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'Fail',
        null,
        error.error.message
      );
    }
    this.spinner.hideSpinner();
  }

  public async addUser(body: any): Promise<void> {
    this.spinner.showSpinner();
    try {
      await lastValueFrom(this.http.post('/api/appusers', { body }));
      this.notificationService.confirmation(
        'general.success',
        'appusers.userAddSuccess'
      );
      body.sites.forEach(async (site) => {
        await this.sitesService.assignSiteToUser(body.email, site);
      });

      // Trigger email in case site is assigned to user
      if (body.sites.length > 0) await this.sendEmailNotification(body.email);
      
      await this.loadUsers();
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.userAddError',
        null,
        error.error.message
      );
    } finally {
      this.spinner.hideSpinner();
    }
  }

  public async sendEmailNotification(email: string): Promise<void> {
    await lastValueFrom(
      this.http.post('/api/val/sendSitePlannerEmail', { user: email })
    );
  }

  public async getUserLicense(user: string, category: string, item: string) {
    const appuser = this.users.value.find((u) => u.userId === user);
    if (!appuser.licenseInformation) {
      return null;
    }
    return appuser.licenseInformation.find(
      (info) => info.category === category && info.item === item
    );
  }

  public async addDeviceToUser(
    user: string,
    device: string,
    tableType: string,
    licenseStart: string,
    licenseDuration: number,
    saveToBeta: boolean,
    isDraftAssinged: boolean
  ): Promise<boolean> {
    this.spinner.showSpinner();
    try {
      if (licenseStart !== null && licenseDuration !== null) {
        await lastValueFrom(
          this.http.put('/api/appusers/devices', {
            user,
            device,
            tableType,
            licenseStart: licenseStart,
            licenseDuration: licenseDuration,
            saveToBeta,
            isDraftAssinged,
          })
        );
      } else {
        const licenseInfo = await this.getUserLicense(
          user,
          LicenseCategory.DEVICE_LICENSE,
          device
        );
        if (licenseInfo) {
          await lastValueFrom(
            this.http.put('/api/appusers/devices', {
              user,
              device,
              tableType,
              licenseStart: licenseInfo.licenseStart,
              licenseDuration: licenseInfo.licenseDuration,
              saveToBeta,
              isDraftAssinged,
            })
          );
        }
      }
      this.notificationService.confirmation(
        'general.success',
        'appusers.deviceAssignSuccess'
      );
      this.spinner.hideSpinner();
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.deviceAssignError',
        null,
        error.error.message
      );
      this.spinner.hideSpinner();
      return false;
    }
  }

  public async removeDeviceFromUser(
    user: string,
    device: string,
    tableType: string
  ): Promise<void> {
    this.spinner.showSpinner();
    try {
      await lastValueFrom(
        this.http.put('/api/appusers/devices/del', { user, device, tableType })
      );
      let appUser = await this.users.value.find((appuser) => appuser.userId === user);
      await this.updateFullDataForUser(appUser);
      this.notificationService.confirmation(
        'general.success',
        'appusers.deviceRemoveSuccess'
      );
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.deviceRemoveError',
        null,
        error.error.message
      );
    }
    this.spinner.hideSpinner();
  }

  public async addWorkflowToUser(
    user: string,
    workflow: any,
    tableType: string,
    licenseStart: string,
    licenseDuration,
    saveToBeta: boolean,
    isDraftAssinged: boolean
  ): Promise<boolean> {
    this.spinner.showSpinner();
    try {
      if (licenseStart !== null && licenseDuration !== null) {
        await lastValueFrom(
          this.http.put('/api/appusers/workflows', {
            user,
            workflow,
            tableType,
            licenseStart: licenseStart,
            licenseDuration: licenseDuration,
            saveToBeta,
            isDraftAssinged,
          })
        );
      } else {
        const licenseInfo = await this.getUserLicense(
          user,
          LicenseCategory.WORKFLOW_LICENSE,
          workflow.key
        );
        if (licenseInfo) {
          await lastValueFrom(
            this.http.put('/api/appusers/workflows', {
              user,
              workflow,
              tableType,
              licenseStart: licenseInfo.licenseStart,
              licenseDuration: licenseInfo.licenseDuration,
              saveToBeta,
              isDraftAssinged
            })
          );
        }
      }
      this.notificationService.confirmation(
        'general.success',
        'appusers.workflowAssignSuccess'
      );
      this.spinner.hideSpinner();
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.workflowAssignError',
        null,
        error.error.message
      );
      this.spinner.hideSpinner();
      return false;
    }
  }

  public async addDeviceConfigToUser(
    email: string,
    config: any[]
  ): Promise<void> {
    this.spinner.showSpinner();
    try {
      await lastValueFrom(
        this.http.put('/api/appusers/devices/config', {
          user: email,
          config: config,
        })
      );
      let appUser = await this.users.value.find((appuser) => appuser.email === email);
      await this.updateFullDataForUser(appUser);
      this.notificationService.confirmation(
        'general.success',
        'appusers.deviceConfigSuccess'
      );
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.deviceConfigError',
        null,
        error.error.message
      );
    }
    this.spinner.hideSpinner();
  }

  public async addWorkflowConfigToUser(
    email: string,
    config: any[]
  ): Promise<void> {
    this.spinner.showSpinner();
    try {
      await lastValueFrom(
        this.http.put('/api/appusers/workflows/config', {
          user: email,
          config: config,
        })
      );
      let appUser = await this.users.value.find((appuser) => appuser.email === email);
      await this.updateFullDataForUser(appUser);
      this.notificationService.confirmation(
        'general.success',
        'appusers.workflowConfigSuccess'
      );
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.workflowConfigError',
        null,
        error.error.message
      );
    }
    this.spinner.hideSpinner();
  }

  public async removeWorkflowFromUser(
    user: string,
    workflow: any,
    tableType: string
  ): Promise<void> {
    this.spinner.showSpinner();
    try {
      await lastValueFrom(
        this.http.put('/api/appusers/workflows/del', {
          user,
          workflow,
          tableType,
        })
      );
      let appUser = await this.users.value.find((appuser) => appuser.userId === user);
      await this.updateFullDataForUser(appUser);
      this.notificationService.confirmation(
        'general.success',
        'appusers.workflowRemoveSuccess'
      );
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.workflowRemoveError',
        null,
        error.error.message
      );
    }
    this.spinner.hideSpinner();
  }

  public async addOrRemoveTechnicianTraining(
    user: string,
    selected: boolean
  ): Promise<void> {
    this.spinner.showSpinner();
    try {
      await lastValueFrom(
        this.http.put('/api/appusers/technicianTraining', { user, selected })
      );
      await this.loadUsers();
      await this.updateFullDataForAllUsers();
      this.notificationService.confirmation(
        'general.success',
        selected === true
          ? 'appusers.technicianTrainingAssignSuccess'
          : 'appusers.technicianTrainingRemoveSuccess'
      );
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.technicianTrainingError',
        null,
        error.error.message
      );
    }
    this.spinner.hideSpinner();
  }

  public async removeUser(user: string): Promise<void> {
    if (
      await this.confirmationService.confirmDelete(
        'appusers.deleteUser',
        'appusers.deleteUserConfirmation'
      )
    ) {
      this.spinner.showSpinner();
      try {
        await lastValueFrom(this.http.delete(`/api/appusers/${user}`));

        let removedUserIndex: number = this.users.value.findIndex((appuser) => appuser.userId === user);
        this.users.value.splice(removedUserIndex, 1);
        this.users.next(this.users.value);

        this.notificationService.confirmation(
          'general.success',
          'appusers.userDeleteSuccess'
        );
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'appusers.userDeleteError',
          null,
          error.error.message
        );
      } finally {
        this.spinner.hideSpinner();
      }

    } 
  }

  public async loadUsers(): Promise<void> {
    this.spinner.showSpinner();
    try {
      this.users.next(
        await lastValueFrom(this.http.get<any[]>('/api/appusers'))
      );
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.getError',
        null
      );
    } finally {
      this.spinner.hideSpinner();
    }
  }

  public async valSiteUsers(): Promise<void> {
    this.spinner.showSpinner();
    try {
      this.valSiteusers.next(
        await lastValueFrom(
          this.http.get<IValSitesUser[]>('/api/val/getValSiteIdData')
        )
      );
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.sitesFetchError',
        null
      );
    } finally {
      this.spinner.hideSpinner();
    }
  }

  public hasPheno(name: string): boolean {
    return CommonModules.isPhenoModule(name);
  }

  public userHasDevice(userId: string, deviceName: string): boolean {
    const user = this.users.value.find((u) => u.userId === userId);
    if (user) {
      return user.devices.some((d) => d === deviceName);
    }
    return false;
  }

  public userHasBetaDevice(userId: string, deviceName: string): boolean {
    const user = this.users.value.find((u) => u.userId === userId);
    let isAssigned = false;
    if(user) {
      user.licenseInformation.forEach(element => {
        if(element.item === deviceName) {
          isAssigned = element.isDraftAssigned;
        }
      });
    }

    return isAssigned;
  }

  public userHasTableForDevice(
    userId: string,
    deviceName: string,
    tableType: string
  ): boolean {
    const user = this.users.value.find((u) => u.userId === userId);
    if (user) {
      return user.deviceConfig.some(
        (d) =>
          d.deviceName === deviceName &&
          d.tableType &&
          d.tableType.includes(tableType)
      );
    }
    return false;
  }

  public userHasPcmForDevice(
    userId: string,
    deviceName: string,
    pcm: string
  ): boolean {
    const user = this.users.value.find((u) => u.userId === userId);
    if (user) {
      return user.deviceConfig.some(
        (d) => d.deviceName === deviceName && d.pcm && d.pcm.includes(pcm)
      );
    }
    return false;
  }

  public userHasWorkflow(userId: string, workflowKey: string): boolean {
    const user = this.users.value.find((u) => u.userId === userId);
    if (user) {
      return user.workflows.some((w) => w === workflowKey);
    }
    return false;
  }

  public userHasBetaWorkflow(userId: string, workflowKey: string): boolean {
    const user = this.users.value.find((u) => u.userId === userId);
    let isAssigned = false;
    if(user) {
      user.licenseInformation.forEach(element => {
        if(element.item === workflowKey) {
          isAssigned = element.isDraftAssigned;
        }
      });
    }

    return isAssigned;
  }

  public userHasTableForWorkflow(
    userId: string,
    workflowKey: string,
    tableType: string
  ): boolean {
    const user = this.users.value.find((u) => u.userId === userId);
    if (user) {
      return user.workflowConfig.some(
        (w) =>
          w.workflowKey === workflowKey &&
          w.tableType &&
          w.tableType.includes(tableType)
      );
    }
    return false;
  }

  public userHasPcmForWorkflow(
    userId: string,
    workflowKey: string,
    pcm: string
  ): boolean {
    const user = this.users.value.find((u) => u.userId === userId);
    if (user) {
      return user.workflowConfig.some(
        (w) => w.workflowKey === workflowKey && w.pcm && w.pcm.includes(pcm)
      );
    }
    return false;
  }

  public isTechnicianTrainingUser(userId: string): boolean {
    const user = this.users.value.find((u) => u.userId === userId);
    if (user) {
      return user.technicianTraining;
    }
    return false;
  }

  public async resetUserPassword(
    user: string,
    email: string
  ): Promise<boolean> {
    if (
      await this.confirmationService.confirm(
        'appusers.resetPassword',
        'appusers.resetUserPasswordConfirmation',
        { email: email }
      )
    ) {
      this.spinner.showSpinner();
      try {
        await lastValueFrom(
          this.http.put('/api/appusers/resetpassword', { user, email })
        );
        let appUser = await this.users.value.find((appuser) => appuser.userId === user);
        await this.updateFullDataForUser(appUser);  
        this.notificationService.confirmation(
          'general.success',
          'appusers.resetPasswordSuccess'
        );
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'appusers.resetPasswordError',
          null,
          error.error.message
        );
      }    
      this.spinner.hideSpinner();
      return true;
    } else {
      return false;
    }
  }

  public async export(filterOptions: any) {
    return await lastValueFrom(
      this.http.post<any>(`/api/appusers/export`, { filterOptions })
    );
  }

  public async getLocations() {
    try {
      this.locations = await lastValueFrom(
        this.http.get<any>(`/api/appusers/locations`, {})
      );
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.getLocError',
        null
      );
    }
    return this.locations;
  }

  public async pulishedExpiredLicenses() {
    await lastValueFrom(
      this.http.get(`/api/license/publishExpiredLicenses`, {
        responseType: 'text',
      })
    );
  }

  public async setAvailableMetrics() {
    (await this.getLocations()).forEach((location) => {
      const index = this.regions.findIndex(
        (val) => val.region === location.region
      );
      if (index === -1 && location.region.trim() !== '') {
        this.regions.push({
          region: location.region,
          countries: [location.country],
        });
      } else if (index && this.regions[index]) {
        if (
          !this.regions[index].countries.includes(location.country) &&
          location.country.trim() !== ''
        ) {
          this.regions[index].countries.push(location.country);
        }
      }
    });
    this.$users.subscribe((appusers: any[]) => {
      appusers.forEach((user) => {
        if (
          !this.organizations.includes(user.organization) &&
          user.organization.trim() !== ''
        ) {
          this.organizations.push(user.organization);
        }
        if (
          !this.businessLines.includes(user.businessLine) &&
          user.businessLine.trim() !== ''
        ) {
          this.businessLines.push(user.businessLine);
        }
        if (!this.roles.includes(user.role) && user.role.trim() !== '') {
          this.roles.push(user.role);
        }
      });
    });
  }

  public async updateFullDataForUser(user: any): Promise<any> {
    if (!user) {
      return;
    }
    
    this.spinner.showSpinner();  
    if(!this.users.value.length) {
      await this.loadUsers();
    }
      
    let updatedUsers = await this.getFullDataForUsers([user]);
      if(updatedUsers){
        let idx = this.users.value.findIndex((appuser) => appuser.userId === updatedUsers[0].userId);
        //If appuser not found then findIndex returns -1
        if (idx >= 0) {
          this.users.value[idx] = updatedUsers[0];
          this.users.next(this.users.value);
        }
      }
    this.spinner.hideSpinner();
  }
  
  public async updateFullDataForAllUsers(): Promise<any> {
    this.spinner.showSpinner();
    if(!this.users.value.length) {
      await this.loadUsers();
    }
    
    let updatedUsers = await this.getFullDataForUsers(this.users.value);		
    this.users.next(updatedUsers);
    this.spinner.hideSpinner();
  }

  private async getFullDataForUsers(users: any[]): Promise<any[]> {
    try {
      return await lastValueFrom(this.http.post<any>(`/api/appusers/getUsersCompleteInfo`, { users }));
    } catch (error) {
      this.notificationService.error(
      'general.error',
      'Error while fetching full user information.',
      null,
      error.error.message
      );
    }}
}
