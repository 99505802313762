import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ElementRef,
  Renderer2,
  OnDestroy,
} from '@angular/core';
import { LanguageService } from '../../../../../services/language.service';
import { HotspotsService } from './hotspots.service';
import { ScreensService } from './screens.service';
import { DetailsService } from './details.service';
import { PopoverService } from '../../../../../services/popover.service';
import { DetailType } from './detail-type.enum';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { Constants } from '@backend/interfaces';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'backend-hotspots',
  templateUrl: './hotspots.component.html',
  styleUrls: ['./hotspots.component.scss'],
})
export class HotspotsComponent implements OnInit, OnDestroy {
  @ViewChildren('tableRow')
  public tableRows: QueryList<ElementRef<HTMLElement>>;

  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  public selectedHotspotId = null;
  public selectedScreenId = null;

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;

  languageSubscription: Subscription;

  public constructor(
    public popoverService: PopoverService,
    public hotspotsService: HotspotsService,
    public detailsService: DetailsService,
    public screensService: ScreensService,
    public languageService: LanguageService,
    public renderer: Renderer2,
    public localization: LocalizationService,
    public router: Router
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.scrollandUpdateHotspot();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          await this.scrollandUpdateHotspot();
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  async scrollandUpdateHotspot() {
    await this.hotspotsService.updateHotspots(true);
    if (this.hotspotsService.editedHotspot) {
      const eh = this.hotspotsService.editedHotspot.toString();
      if (this.tableRows) {
        const tableRows = this.tableRows.toArray();
        for (const tableRow of tableRows) {
          const elemId = tableRow.nativeElement.getAttribute('id');
          if (elemId === eh) {
            const row = document.getElementById(elemId) as HTMLElement;
            this.selectedHotspotId = this.hotspotsService.editedHotspot;
            row.setAttribute('expanded', 'true');
            tableRow.nativeElement.scrollIntoView();
            break;
          }
        }
      }
    }
    this.scrollToScreen();
  }

  scrollToScreen() {
    const screen = document.getElementById(
      this.screensService.lastModifiedScreenId
    );
    if (
      screen == null ||
      this.hotspotsService.editedHotspot !== this.selectedHotspotId
    )
      return;
    if (this.selectedScreenId === this.screensService.lastModifiedScreenId)
      return;
    this.selectedScreenId = this.screensService.lastModifiedScreenId;
    screen.setAttribute('expanded', 'true');
    screen.scrollIntoView();
  }

  public onDragStart(event: DragEvent): void {
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/plain', 'dragHotspot');
  }

  public onDragOver(event: DragEvent): void {
    if (event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'move';
    }
  }

  public async onDragEnd(
    event: DragEvent,
    detailType: DetailType,
    hotspotId: number,
    screenId: number,
    detailId: number,
    targetId?: number
  ): Promise<void> {
    if (event) {
      const hotspotElement = event.target as HTMLElement;
      const img = hotspotElement.parentElement as HTMLImageElement;
      const imgBounds = img.getBoundingClientRect();
      let oldX, oldY;

      if (hotspotElement) {
        oldX = hotspotElement.style.left;
        oldY = hotspotElement.style.top;
      }

      let newX = (event.clientX - imgBounds.left) / imgBounds.width;
      newX = Math.max(0, Math.min(1, newX));
      let newY = (imgBounds.bottom - event.clientY) / imgBounds.height;
      newY = Math.max(0, Math.min(1, newY));

      if (event.target) {
        this.renderer.setStyle(event.target, 'left', `${newX * 100}%`);
        this.renderer.setStyle(event.target, 'bottom', `${newY * 100}%`);
      }

      let success = false;
      switch (detailType) {
        case DetailType.Information:
          success = await this.detailsService.modifyInformationDetailPosition(
            hotspotId,
            screenId,
            detailId,
            newX,
            newY
          );
          break;
        case DetailType.SwitchScreen:
          success = await this.detailsService.modifySwitchScreenDetailPosition(
            hotspotId,
            screenId,
            detailId,
            newX,
            newY,
            targetId
          );
          break;
        case DetailType.Back:
          success = await this.detailsService.modifyBackDetailPosition(
            hotspotId,
            screenId,
            detailId,
            newX,
            newY,
            targetId
          );
          break;
      }

      if (!success && event.target) {
        this.renderer.setStyle(event.target, 'left', oldX);
        this.renderer.setStyle(event.target, 'bottom', oldY);
      }
    }
  }

  public imageLoaded(containerElement: HTMLElement): void {
    containerElement.classList.add('loaded');
  }

  public async deleteHotspot(id: number): Promise<void> {
    await this.hotspotsService.deleteHotspot(id);
  }

  public async deleteScreen(
    hotspotId: number,
    screenId: number,
    key
  ): Promise<void> {
    await this.screensService.deleteScreen(hotspotId, screenId, key);
    await this.updateAll();
  }

  public async deleteInformationDetail(
    hotspotId,
    screenId,
    detailId,
    detail
  ): Promise<void> {
    await this.detailsService.deleteInformationDetail(
      hotspotId,
      screenId,
      detailId,
      detail
    );
    await this.updateAll();
  }

  public async deleteSwitchScreenDetail(
    hotspotId,
    screenId,
    detailId,
    detail
  ): Promise<void> {
    await this.detailsService.deleteSwitchScreenDetail(
      hotspotId,
      screenId,
      detailId,
      detail
    );
    await this.updateAll();
  }

  public async deleteBackDetail(
    hotspotId,
    screenId,
    detailId,
    detail
  ): Promise<void> {
    await this.detailsService.deleteBackDetail(
      hotspotId,
      screenId,
      detailId,
      detail
    );
    await this.updateAll();
  }

  private async updateAll(): Promise<void> {
    await this.hotspotsService.updateHotspots();
    await this.hotspotsService.updateHotspot();
    await this.screensService.updateScreens();
    await this.screensService.updateScreen();
    await this.detailsService.updateDetails();
    await this.detailsService.updateDetail();
  }
}
