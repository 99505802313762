import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { BlocksService } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/blocks/blocks.service';
import { ParamsService } from '@backend/webapp/services/params.service';
import { LanguageService } from '@backend/webapp/services/language.service';
import { Router } from '@angular/router';
import { Author } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/blocks/author';
import { ImgConvertorComponent } from '@backend/webapp/img-convertor/img-convertor.component';
import { Constants } from '@backend/interfaces';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { pairwise } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'backend-edit-author',
  templateUrl: './edit-author.component.html',
  styleUrls: ['./edit-author.component.scss'],
})
export class EditAuthorComponent implements OnInit, OnDestroy {
  @ViewChild('previewImage')
  public previewImage: ElementRef<HTMLImageElement>;

  public isEditMode: boolean;
  public name: string;
  public description: string;

  public portrait: string;
  public portraitBaseName: string;
  public portraitFile: File;
  public portraitChanged: boolean = false;
  private imgConvertor: ImgConvertorComponent;

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  nameTrans: any[];
  descriptionTrans: any[];

  paramSubscription: Subscription;
  languageSubscription: Subscription;

  constructor(
    private readonly blocksService: BlocksService,
    private readonly languageService: LanguageService,
    private readonly paramsService: ParamsService,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    public readonly localization: LocalizationService
  ) {
    this.imgConvertor = new ImgConvertorComponent();
  }

  async ngOnInit(): Promise<void> {
    this.description = this.portrait = this.name = '';
    this.isEditMode = false;
    if (!this.paramsService.authorId) {
      return;
    }
    this.isEditMode = true;
    await this.updateAuthor();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive && this.paramsService.authorId) {
          this.globalLanguage = data;
          await this.updateAuthor();
        }
      }
    );
    this.paramSubscription = this.paramsService.authorId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          await this.updateAuthor();
        }
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  async updateAuthor() {
    const author: Author = await this.blocksService.getAuthor(
      this.paramsService.authorId
    );
    this.name = this.languageService.getTranslationByKey(
      author.name,
      this.globalLanguage.key
    );
    this.nameTrans = new GetLocaleTextPipe(this.localization).transform(
      author.name,
      this.globalLanguage.key
    );
    this.description = this.languageService.getTranslationByKey(
      author.description,
      this.globalLanguage.key
    );
    this.descriptionTrans = new GetLocaleTextPipe(this.localization).transform(
      author.description,
      this.globalLanguage.key
    );
    this.portrait = author.portrait;
    if (this.portrait) {
      this.portraitBaseName = this.portrait.replace(/.*[\/\\]/, '');
    } else {
      this.portraitBaseName = '';
    }
  }

  public fileChange(file: File): void {
    if (file) {
      this.portrait = file.name;
      this.portraitFile = file;
      this.portraitBaseName = file.name.replace(/.*[\/\\]/, '');
      this.portraitChanged = true;

      const reader = new FileReader();
      reader.onloadend = (e) => {
        let image = new Image();
        this.renderer.setAttribute(
          this.previewImage.nativeElement,
          'src',
          e.target.result.toString()
        );
        image.src = e.target.result.toString();
        image.onload = () => {
          this.imgConvertor.drawImageOnCanvas(image, this.portraitBaseName);
        };
      };
      reader.readAsDataURL(file);
    } else {
      this.portrait = '';
      this.portraitBaseName = '';
    }
  }

  public async save(): Promise<void> {
    const file = this.portraitChanged ? this.imgConvertor.getImageFile() : null;
    if (this.isEditMode) {
      await this.blocksService.saveAuthor({
        id: this.paramsService.authorId,
        name: this.name,
        description: this.description,
        portrait: file,
      });
    } else {
      await this.blocksService.createAuthor(this.paramsService.blockId, {
        id: undefined,
        name: this.name,
        description: this.description,
        portrait: file,
      });
    }

    this.goBack();
  }

  canSave(): boolean {
    return this.name != null;
  }

  cancel() {
    this.goBack();
  }

  private goBack() {
    this.router
      .navigate([
        'workflowguides',
        'workflows',
        this.paramsService.workflowId,
        'versions',
        this.paramsService.workflowVersionId,
        'overviews',
        this.paramsService.overviewId,
        'blocks',
        this.paramsService.blockId,
        'author',
      ])
      .then();
  }
}
