import { Component, Input } from '@angular/core';
import { AdminUsersService } from '../../adminusers.service';
import { AdminRole } from '@backend/interfaces';
import { SpinnerService } from '../../../spinner/spinner.service';

@Component({
  selector: 'backend-add-admin-user',
  templateUrl: './add-admin-user.component.html',
  styleUrls: ['./add-admin-user.component.scss'],
})
export class AddAdminUserComponent {
  private _visible = false;
  @Input()
  public set visible(value: boolean) {
    this._visible = value;
  }
  public get visible(): boolean {
    return this._visible;
  }

  public siemensId = '';
  public firstName = '';
  public lastName = '';
  public role = '';

  public roles = this.adminUsersService.getAdminRoleEnumKeys();

  public isEditMode = false;

  private id = null;

  public constructor(
    private adminUsersService: AdminUsersService,
    private spinnerService: SpinnerService
  ) {}

  public reset(): void {
    this.id = null;
    this.siemensId = '';
    this.firstName = '';
    this.lastName = '';
    this.role = null;
  }

  public async open(userId?: number): Promise<void> {
    this.reset();

    if (userId) {
      const version = await this.adminUsersService.getAdminUser(userId);
      this.id = userId;
      this.siemensId = version.name;
      this.firstName = version.firstName;
      this.lastName = version.lastName;
      this.role = version.role;
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }

    this.visible = true;
  }

  public async close(): Promise<void> {
    this.spinnerService.showSpinner();
    let result: boolean;
    let adminRole = 0;
    const adminRoleNum = parseInt(this.role, 10);
    if (isNaN(adminRoleNum)) {
      adminRole = AdminRole[this.role];
    } else {
      adminRole = adminRoleNum;
    }

    if (!this.isEditMode) {
      result = await this.adminUsersService.createAdminUser(
        this.siemensId,
        this.firstName,
        this.lastName,
        adminRole
      );
    } else {
      result = await this.adminUsersService.editAdminUser(
        this.id,
        this.siemensId,
        this.firstName,
        this.lastName,
        adminRole
      );
    }

    if (result) {
      this.visible = false;
    }

    this.spinnerService.hideSpinner();
  }

  public cancel(): void {
    this.visible = false;
  }
}
