import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { tap, concatMap, map } from 'rxjs/operators';
import { AdminRole } from '@backend/interfaces';

@Injectable({
  providedIn: 'root',
})
export class SuperAuthGuard implements CanActivate, CanActivateChild {
  public constructor(private authService: AuthService) {}

  public canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.isAuthenticated$().pipe(
      tap((isLoggetIn) => {
        if (!isLoggetIn) {
          this.authService.login();
        }
      }),
      concatMap(() => this.authService.$role),
      map((user) => user.role === AdminRole.SuperAdmin)
    );
  }

  public canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }
}
