import {
  Component,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  AfterViewInit,
  OnInit,
} from '@angular/core';

import { Observable } from 'rxjs';
import {
  LocalizationChange,
  LocalizationCreateChange,
  LocalizationModifyChange,
  LocalizationDeleteChange,
  LocalizationChangeType,
} from './localization-change.class';
import { CreateLocalizationEntryComponent } from './modals/create-localization-entry/create-localization-entry.component';
import { SpinnerService } from '../../spinner/spinner.service';
import { NotificationService } from '../notification/notification.service';
import { LanguageService } from '../../services/language.service';
import { ConfirmationService } from '../confirmation/confirmation.service';
import { DevicesService } from '../../devices/devices.service';
import { DeviceVersionsService } from '../../devices/device-versions/device-versions.service';
import { PopoverService } from '../../services/popover.service';
import { areObjectsIdentical } from '../../tools';
import { LocalizationService } from './localization.service';
import { CreateLanguageComponent } from './modals/create-language/create-language.component';
import { Constants } from '@backend/interfaces';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ParamsService } from '@backend/webapp/services/params.service';

@Component({
  selector: 'backend-localization',
  templateUrl: './localization.component.html',
  styleUrls: ['./localization.component.scss'],
})
export class LocalizationComponent implements OnInit, AfterViewInit {
  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  @ViewChild('createEntryModal')
  public createEntryModal: CreateLocalizationEntryComponent;

  @ViewChild('createLanguageModal')
  public createLanguageModal: CreateLanguageComponent;

  @ViewChild('inputSearch')
  public inputSearch: ElementRef<any>;

  @ViewChild('filey')
  public file: ElementRef<HTMLInputElement>;

  public _entriesValue: any[];
  private _filteredEntries: any[];
  public get filteredEntries(): any[] {
    return this._filteredEntries;
  }

  private _hasChanges = false;
  public get hasChanges(): boolean {
    return this._hasChanges;
  }
  public langData = this.localizationService.globalLanguage
    ? this.localizationService.globalLanguage.key
    : Constants.DEFAULT_GLOBAL_LANGUAGE.key;
  public lang = this.localizationService.globalLanguage
    ? this.localizationService.globalLanguage.key
    : Constants.DEFAULT_GLOBAL_LANGUAGE.name;
  public componententries: Observable<any[]>;
  private changeList: LocalizationChange[] = new Array<LocalizationChange>();

  public translations: any[];
  public currPage = 1;
  public pageLen = 20;
  public totalPages = 10;
  searchString = '';

  public constructor(
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    public localizationService: LocalizationService,
    public devicesService: DevicesService,
    private deviceVersionsService: DeviceVersionsService,
    public languageService: LanguageService,
    public popoverService: PopoverService,
    public router: Router,
    public paramsService: ParamsService
  ) {}

  public async ngOnInit() {
    this.localizationService.globalLanguage$.subscribe(async (data) => {
      if (data && data.key && this.router.isActive) {
        this.langData = data.key;
        this.lang = data.name;
        await this.selectLanguage(this.langData);
      }
    });
    this.localizationService.entriesSubject$.subscribe(() => {
      this.resetChanges();
    });
  }

  public async selectLanguage(langKey) {
    this.spinnerService.showSpinner();
    this.langData = langKey;
    try {
      const newEntries =
        (await this.localizationService.getLocalizationentriesForLanguage(
          langKey
        )) as any;
      this._filteredEntries = newEntries;
      this.paginate(1, this.pageLen);
      this.notificationService.confirmation(
        'general.success',
        'localization.successLoad'
      );
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'localization.failedLoad',
        null,
        error.error.message
      );
    }
    this.spinnerService.hideSpinner();
  }

  public async import() {
    const csv = this.file.nativeElement.files[0];
    if (csv && csv.type === 'text/csv') {
      try {
        this.spinnerService.showSpinner();

        const reader = new FileReader();

        const data = await new Promise((resolve, reject) => {
          reader.onload = (event) => {
            resolve(event.target.result);
          };
          reader.onerror = reject;

          reader.readAsText(csv, 'utf-8');
        });
        await this.localizationService.postImport(data);
        await this.selectLanguage(this.langData);
        this.spinnerService.hideSpinner();
      } catch (error) {
        this.spinnerService.hideSpinner();
      }
    }
  }

  public async export() {
    try {
      this.spinnerService.showSpinner();
      const csv = await this.localizationService.getExport();
      if (-1 !== window.location.href.indexOf(environment.domain)) {
        const windowUrl = window.URL || window.webkitURL;
        const blob = new Blob(['\uFEFF' + csv.text], {
          type: 'text/csv; charset=utf-8',
        });
        const url = windowUrl.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'export.csv';
        anchor.click();
      }
      this.spinnerService.hideSpinner();
    } catch (error) {
      console.log(error);
      this.spinnerService.hideSpinner();
    }
  }

  public async ngAfterViewInit(): Promise<void> {
    this.createEntryModal.closed.subscribe(async (key) => {
      if (key) {
        await this.createItem(key, this.createEntryModal.valueData);
      }
    });
    this.createLanguageModal.closed.subscribe(async (key) => {
      if (key) {
        await this.createLanguage(key, this.createLanguageModal.valueData);
      }
    });
    this.devicesService.device$.subscribe(async () => {
      await this.updateItems();
    });
    this.deviceVersionsService.deviceVersion$.subscribe(async () => {
      await this.updateItems();
    });
  }

  public async createLanguage(key, name) {
    await this.localizationService.createLanguage(key, name);
  }

  public onSearchString(searchedString: string): void {
    this.searchString = searchedString;
    this.updateFilteredEntries();
  }

  public async updateItems(): Promise<void> {
    this.spinnerService.showSpinner();
    const newEntries =
      await this.localizationService.getLocalizationentriesForLanguage(
        this.langData
      );
    this._filteredEntries = newEntries;
    if (!areObjectsIdentical(this.localizationService.entries, newEntries)) {
      await this.localizationService.getLocalizationSubjectForLanguage(
        this.langData
      );
      this.updateFilteredEntries();
    }
    this.paginate(1, this.pageLen);
    this.spinnerService.hideSpinner();
  }

  private updateFilteredEntries(): void {
    this.componententries = this.localizationService.entries$;
    this.componententries.subscribe((users: any[]) => {
      this._entriesValue = users;
      this._entriesValue = JSON.parse(JSON.stringify(this._entriesValue));
    });
    if (this._entriesValue != null) {
      const allEntries = this._entriesValue;
      if (this.searchString) {
        const lcSearchTerm = this.searchString.toLowerCase();
        this._filteredEntries = allEntries.filter(
          (entry) => entry.key.toLowerCase().indexOf(lcSearchTerm) !== -1
        );
      } else {
        this._filteredEntries = allEntries;
      }
    }
    this.paginate(1, this.pageLen);
  }

  public openCreateEntryModal(): void {
    this.createEntryModal.open();
  }

  public openCreateLangModal(): void {
    this.createLanguageModal.open();
  }

  public async createItem(key: string, valueData: string): Promise<void> {
    const createChange = <LocalizationCreateChange>{
      type: LocalizationChangeType.Create,
      key,
      language: this.localizationService.languages.find(
        (t) => t.key == this.langData
      ).key,
      value: valueData,
    };
    try {
      await this.localizationService.saveEntriescreateChange(createChange);
    } catch (e) {
      console.log(e);
    }
    this.selectLanguage(createChange.language);
  }

  public async modifyItem(entry: any, value: string): Promise<void> {
    if (!entry) {
      return;
    }

    if (entry.value !== value) {
      entry.value = value;
    } else {
      return;
    }

    entry = {
      id: this.generateRandomId(),
      value: value,
      key: entry.key,
      languageKey: this.localizationService.languages.find(
        (t) => t.key == this.langData
      ).key,
      languageName: this.localizationService.languages.find(
        (t) => t.key == this.langData
      ).Value,
    };

    for (let i = 0; i < this.changeList.length; i++) {
      const change = this.changeList[i];
      if (change.type === LocalizationChangeType.Modify) {
        const existingChange = <LocalizationModifyChange>change;
        if (existingChange.key === entry.key) {
          this.changeList.splice(i, 1);
          i--;
        }
      } else if (change.type === LocalizationChangeType.Create) {
        const existingChange = <LocalizationCreateChange>change;
        if (existingChange.key === entry.key) {
          this.changeList.splice(i, 1);
          i--;
        }
      }
    }

    this.changeList.push(<LocalizationModifyChange>{
      type: LocalizationChangeType.Modify,
      key: entry.key,
      language: this.localizationService.languages.find(
        (t) => t.key == this.langData
      ).key,
      value: value,
    });
    this._hasChanges = true;
  }

  public async deleteItem(item: any): Promise<void> {
    if (
      await this.confirmationService.confirmDelete(
        'localization.deleteLocalization',
        'localization.deleteLocalizationConfirmation',
        { localizationName: item.key }
      )
    ) {
      const index = this._filteredEntries.findIndex(
        (entry) => entry.id === item.id
      );
      if (index === -1) {
        throw new Error(
          `Cannot delete entry because id '${item.id}' was not found.`
        );
      }

      this._filteredEntries?.splice(index, 1);
      for (let i = 0; i < this.changeList.length; i++) {
        const change = this.changeList[i];
        if (
          change.type === LocalizationChangeType.Create ||
          change.type === LocalizationChangeType.Modify
        ) {
          const existingChange = <LocalizationModifyChange>change;
          const existingId = this.getIdFromKey(existingChange.key);
          if (existingId === item.id) {
            this.changeList.splice(i, 1);
            i--;
          }
        }
      }

      this.changeList.push(<LocalizationDeleteChange>{
        type: LocalizationChangeType.Delete,
        id: item.id,
      });
      this._hasChanges = true;
      this.save();
      this.updateFilteredEntries();
    }
  }

  public async save(): Promise<boolean> {
    let error = null;
    this.spinnerService.showSpinner();
    while (this.changeList.length > 0) {
      const change = this.changeList[0];
      switch (change.type) {
        case LocalizationChangeType.Create:
          const createChange = <LocalizationCreateChange>change;
          try {
            await this.localizationService.saveEntriescreateChange(
              createChange
            );
          } catch (e) {
            error = e;
            break;
          }
          break;
        case LocalizationChangeType.Modify:
          const modifyChange = <LocalizationModifyChange>change;
          try {
            await this.localizationService.saveEntriesmodifyChange(
              modifyChange
            );
          } catch (e) {
            error = e;
          }
          break;
        case LocalizationChangeType.Delete:
          const deleteChange = <LocalizationDeleteChange>change;
          try {
            await this.localizationService.saveEntriesDeleteChange(
              deleteChange
            );
          } catch (e) {
            error = e;
          }
          break;
      }

      if (error) {
        break;
      }

      this.changeList.splice(0, 1);
    }

    this.spinnerService.hideSpinner();
    this.selectLanguage(this.langData);
    if (error) {
      this.notificationService.error(
        'general.error',
        'localization.saveError',
        null,
        error.message
      );
      return false;
    } else {
      this.notificationService.confirmation(
        'general.success',
        'localization.saveSuccess'
      );
      return true;
    }
  }

  public async discard(): Promise<void> {
    if (
      await this.confirmationService.confirm(
        'localization.discardChanges',
        'localization.discardChangesConfirmation'
      )
    ) {
      await this.selectLanguage(this.langData);
    }
  }

  private resetChanges(): void {
    this.changeList = [];
    this._hasChanges = false;
  }

  private generateRandomId(): string {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  private getIdFromKey(key: string): string {
    const entry = this.localizationService.entries.find((e) => e.key === key);
    if (entry) {
      return entry.key;
    }

    return null;
  }

  public paginate(currentPage: any, page_len) {
    this.currPage = currentPage;
    const page = this.currPage || 1;
    const per_page = page_len || 20;
    const offset = (page - 1) * per_page;

    this.translations = this._filteredEntries
      ?.slice(offset)
      ?.slice(0, page_len);
    this.totalPages = Math.ceil(this._filteredEntries.length / per_page);
  }
}
