import { Component } from '@angular/core';

import { PageService } from '../page.service';

@Component({
  selector: 'backend-add-page',
  templateUrl: './add-page.component.html',
  styleUrls: ['./add-page.component.scss'],
})
export class AddPageComponent {
  public isOpen = false;

  public nameInternal = '';
  public name = '';
  public pageId: number;

  public constructor(private readonly pageService: PageService) {}

  public async save() {
    if (this.pageId) {
      await this.pageService.editPage(
        this.pageId,
        this.nameInternal,
        this.name
      );
    } else {
      await this.pageService.createPage(this.nameInternal, this.name);
    }
    this.pageId = undefined;
    this.nameInternal = '';
    this.name = '';
    this.isOpen = false;
  }
}
