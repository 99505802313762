import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParamsService } from '@backend/webapp/services/params.service';
import { LanguageService } from '@backend/webapp/services/language.service';
import { ConfirmationService } from '@backend/webapp/shared/confirmation/confirmation.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private get apiRoute(): string {
    return (
      `/api/workflowguides/phases/${this.paramsService.phaseWrapperId}/versions/${this.paramsService.phaseVersionId}/keyactivities/${this.paramsService.keyActivityId}/` +
      `steps/${this.paramsService.stepId}/assets/`
    );
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly paramsService: ParamsService,
    private readonly languageService: LanguageService,
    private confirmationService: ConfirmationService
  ) {}

  public async getAll(): Promise<any[]> {
    this.paramsService.isLoading = true;
    const result = await lastValueFrom(
      this.httpClient.get<any[]>(this.apiRoute)
    );
    this.paramsService.isLoading = false;
    return result;
  }

  public async get(assetId: number = this.paramsService.assetId): Promise<any> {
    if (!assetId) {
      return null;
    }
    this.paramsService.isLoading = true;
    const result = lastValueFrom(
      this.httpClient.get<any>(this.apiRoute + assetId)
    );
    this.paramsService.isLoading = false;
    return result;
  }

  public async editVideo(id: number, settings: any, assetFile: File) {
    return await lastValueFrom(
      this.httpClient.put<any>(
        this.apiRoute + id + '/video',
        this.getFormData(settings, assetFile)
      )
    );
  }

  public async createVideo(settings: any, assetFile: File) {
    return await lastValueFrom(
      this.httpClient.post<any>(
        this.apiRoute + 'video',
        this.getFormData(settings, assetFile)
      )
    );
  }

  public async editImage(id: number, settings: any, assetFile: File) {
    return await lastValueFrom(
      this.httpClient.put<any>(
        this.apiRoute + id + '/image',
        this.getFormData(settings, assetFile)
      )
    );
  }

  public async createImage(settings: any, assetFile: File) {
    return await lastValueFrom(
      this.httpClient.post<any>(
        this.apiRoute + 'image',
        this.getFormData(settings, assetFile)
      )
    );
  }

  public async createSimulation(settings: any): Promise<any> {
    return await lastValueFrom(
      this.httpClient.post<any>(this.apiRoute + 'simulation', settings)
    );
  }

  public async editSimulation(id: number, settings: any): Promise<any> {
    return await lastValueFrom(
      this.httpClient.put<any>(this.apiRoute + id + '/simulation', settings)
    );
  }

  public async createInstructions(settings: any): Promise<any> {
    return await lastValueFrom(
      this.httpClient.post<any>(this.apiRoute + 'instructions', settings)
    );
  }

  public async editInstructions(id: number, settings: any): Promise<any> {
    return await lastValueFrom(
      this.httpClient.put<any>(this.apiRoute + id + '/instructions', settings)
    );
  }

  private getFormData(settings: any, file: File): FormData {
    const formData = new FormData();
    Object.keys(settings).forEach((k) => {
      formData.append(k, settings[k].toString());
    });

    if (file) formData.append('asset', file, file.name);

    return formData;
  }

  async removeInstruction(assetId: number, translation: any) {
    return await lastValueFrom(
      this.httpClient.delete(
        this.apiRoute + assetId + '/instruction/' + translation.id
      )
    );
  }

  async addInstruction(assetId: number, text: string, translationId: number) {
    return await lastValueFrom(
      this.httpClient.post(
        this.apiRoute + assetId + '/instruction/' + translationId,
        {
          value: text,
          langKey: this.languageService.guiLanguageCode,
        }
      )
    );
  }

  async deleteAsset(id: number) {
    if (
      await this.confirmationService.confirm(
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.delete',
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.deleteConfirmation',
        { assetId: id }
      )
    ) {
      this.paramsService.isLoading = true;
      try {
        return await lastValueFrom(this.httpClient.delete(this.apiRoute + id));
      } catch (e) {
        console.log(e);
        return null;
      } finally {
        this.paramsService.isLoading = false;
      }
    }
  }
}
