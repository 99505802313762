import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

import { AdminRole } from '@backend/interfaces';
import { SpinnerService } from '../spinner/spinner.service';
import { NotificationService } from '../shared/notification/notification.service';
import { ConfirmationService } from '../shared/confirmation/confirmation.service';
import { areObjectsIdentical } from '../tools';

@Injectable({
  providedIn: 'root',
})
export class AdminUsersService {
  private adminUsersSubject$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
  
  public get adminUsers$(): Observable<any[]> {
    return this.adminUsersSubject$.asObservable();
  }
  
  public get adminUsers(): any[] {
    return this.adminUsersSubject$.value;
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly spinnerService: SpinnerService,
    private readonly notificationService: NotificationService,
    private readonly confirmationService: ConfirmationService
  ) {}

  public async getAdminUser(adminUserId: number): Promise<any> {
    if (!this.adminUsersSubject$.value) {
      const updateSuccess = await this.loadAdminUsers();
      if (!updateSuccess) {
        return null;
      }
    }

    return this.adminUsersSubject$.value.find((a) => a.id === adminUserId);
  }

  public async loadAdminUsers(): Promise<boolean> {
    try {
      let adminUsers = await lastValueFrom(this.http.get<any[]>('/api/adminusers'));
      if(!adminUsers){
        //when adminusers recieved as undefined / false / null
        this.notificationService.error(
          'general.error',
          'adminusers.getError',
          null
        );
        return false;
      }
      if (!areObjectsIdentical(this.adminUsers, adminUsers)) {
        this.adminUsersSubject$.next(adminUsers);
      }
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'adminusers.getError',
        null,
        error.error.message
      );
      return false;
    }
  }

  public async createAdminUser(
    siemensId: string,
    firstName: string,
    lastName: string,
    role: number
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.post('/api/adminusers', {
          name: siemensId,
          firstName,
          lastName,
          role,
        })
      );
      await this.loadAdminUsers();
      this.notificationService.confirmation(
        'general.success',
        'adminusers.createSuccess'
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'adminusers.createError',
        null,
        error.error.message
      );
      return false;
    } finally {
      this.spinnerService.hideSpinner();
    }
  }

  public async editAdminUser(
    id: number,
    siemensId: string,
    firstName: string,
    lastName: string,
    role: number
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.put('/api/adminusers/' + id, {
          name: siemensId,
          firstName,
          lastName,
          role,
        })
      );
      await this.loadAdminUsers();
      this.notificationService.confirmation(
        'general.success',
        'adminusers.editSuccess'
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'adminusers.editError',
        null,
        error.error.message
      );
      return false;
    } finally {
      this.spinnerService.hideSpinner();
    }
  }

  public async deleteAdminUser(id: number): Promise<boolean> {
    if (
      await this.confirmationService.confirmDelete(
        'adminusers.deleteUser',
        'adminusers.deleteUserConfirmation'
      )
    ) {
      this.spinnerService.showSpinner();
      try {
        await lastValueFrom(this.http.delete('/api/adminusers/' + id));
        await this.loadAdminUsers();
        this.notificationService.confirmation(
          'general.success',
          'adminusers.deleteSuccess'
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'adminusers.deleteError',
          null,
          error.error.message
        );
        return false;
      } finally {
        this.spinnerService.hideSpinner();
      }
    }
  }

  public getAdminRoleEnumKeys(): Array<string> {
    const keys = Object.keys(AdminRole);
    return keys.slice(keys.length / 2, keys.length);
  }
}
