export enum BusinessLine
{
  None = 'none',
  AT = 'at',
  CT = 'ct',
  XP = 'xp',
  MR = 'mr',
  LD = 'ld',
  MI = 'mi',
}
