import { Component, OnInit } from '@angular/core';

import { LanguageService } from '../services/language.service';
import { SpinnerService } from '../spinner/spinner.service';
import { SitesService } from './sites.service';
import { ValImagePipe } from './val-image.pipe';
import { ValTokenService } from './valToken.service';

@Component({
  selector: 'backend-siteplanner',
  templateUrl: './siteplanner.component.html',
  styleUrls: ['./siteplanner.component.scss'],
})
export class SiteplannerComponent implements OnInit {
  public sites: any[];
  public allSites: any[];
  public currPage = 1;
  public pageLen = 10;
  public totalPages = 10;
  private searchTerm = '';

  private _filteredEntries: any[];
  public get filteredEntries(): any[] {
    return this._filteredEntries;
  }

  public constructor(
    public languageService: LanguageService,
    public sitesService: SitesService,
    public valImgPipe: ValImagePipe,
    private valtokenService: ValTokenService,
    private spinner: SpinnerService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.valtokenService.getAccessToken();
    this.spinner.showSpinner();
    await this.sitesService.getAllSites();
    this.sitesService.$sites.subscribe(
      (sites: any[]) => (this.allSites = JSON.parse(JSON.stringify(sites)))
    );
    this.allSites.sort((a, b) =>
      a.siteName.toLowerCase() < b.siteName.toLowerCase() ? -1 : 1
    );
    this._filteredEntries = this.allSites;
    this.paginate(1, this.pageLen);
    this.spinner.hideSpinner();
  }

  getImageURL(image) {
    if (image) {
      let binary = '';
      const bytes = new Uint8Array(image);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    }
  }

  public paginate(currentPage: any, page_len) {
    this.currPage = currentPage;
    const page = this.currPage || 1;
    const per_page = page_len || 10;
    const offset = (page - 1) * per_page;

    this.sites = this._filteredEntries.slice(offset).slice(0, page_len);
    this.totalPages = Math.ceil(this._filteredEntries.length / per_page);
  }

  public onSearch(searchTerm: string = ''): void {
    this.sitesService.$sites.subscribe(
      (sites: any[]) => (this.allSites = JSON.parse(JSON.stringify(sites)))
    );
    this.allSites.sort((a, b) =>
      a.siteName.toLowerCase() < b.siteName.toLowerCase() ? -1 : 1
    );
    this.searchTerm = searchTerm;
    if (searchTerm) {
      const lcSearchTerm = this.searchTerm.toLowerCase();
      this._filteredEntries = this.allSites.filter(
        (site) => site.siteName.toLowerCase().indexOf(lcSearchTerm) !== -1
      );
    } else {
      this._filteredEntries = this.allSites;
    }
    this.paginate(1, this.pageLen);
  }
}
