import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  Constants,
  LicenseCategory,
  PCM,
  TableType,
} from '@backend/interfaces';
import { LanguageService } from '@backend/webapp/services/language.service';
import { SitesService } from '@backend/webapp/siteplanner/sites.service';
import { Observable, Subscription } from 'rxjs';
import { DevicesService } from '../../../devices/devices.service';
import { WorkflowService } from '../../../workflowguides/workflows/workflow.service';
import { AppUsersService } from '../../appuser.service';
import * as moment from 'moment';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { Router } from '@angular/router';
import {CommonModules} from "../../../../../../../libs/common/commonModules";

@Component({
  selector: 'backend-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent implements OnInit, OnDestroy {
  @Output() highlightUser = new EventEmitter<string>();

  public open = false;
  public email = '';
  public org = '';
  public businessLine = '';
  public role = '';
  public country = '';
  public region = '';
  public $devices: Observable<any>;
  public $workflows: Observable<any>;
  public $sites: Observable<any>;
  public assignDevices = [];
  public assignWorkflows = [];
  public assignSites = [];
  public assignDeviceConf = [];
  public assignWorkflowConf = [];
  public technicianTraining = false;
  public currentTab = 'userinfo';
  public subTab = 'betaDevices';
  public licenseInformation = [];
  public durationValues = [30, 60, 90, 120, 365];
  public durationDvc: number[] = [];
  public durationWf: number[] = [];
  public startDtDvc: string[] = [];
  public startDtWf: string[] = [];
  public dateFormat = 'YYYY/MM/DD';
  public magnusChecked: boolean[] = [];
  public siemensChecked: boolean[] = [];
  public tmpdurationDvc: number[] = [];
  public tmpdurationWf: number[] = [];
  public tmpstartDtDvc: string[] = [];
  public tmpstartDtWf: string[] = [];
  public dvcOffset = 0;
  public wfOffset = 0;
  public tableTypeEnum = TableType;
  public pcmEnum = PCM;
  private searchTerm = '';
  private _filteredSites: any[];

  public get filteredSites(): any[] {
    return this._filteredSites;
  }

  allUsers: any[];

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  languageSubscription: Subscription;

  public constructor(
    private appUsersService: AppUsersService,
    private devicesService: DevicesService,
    private workflowService: WorkflowService,
    private sitesService: SitesService,
    public languageService: LanguageService,
    public router: Router,
    private localization: LocalizationService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        this.globalLanguage = data;
      }
    );
    this.$devices = this.devicesService.devices$;
    this.$workflows = this.workflowService.workflows;
    this.$sites = this.sitesService.$sites;
    this.$sites.subscribe((sites: any[]) => (this._filteredSites = sites));
    this.currentTab = 'userinfo';
    this.subTab = 'betaDevices';
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  public onSearchSite(searchTerm: string = ''): void {
    this.$sites.subscribe((sites: any[]) => (this._filteredSites = sites));
    this.searchTerm = searchTerm;
    if (searchTerm) {
      const lcSearchTerm = this.searchTerm.toLowerCase();
      this._filteredSites = this._filteredSites.filter(
        (site) => site.siteName.toLowerCase().indexOf(lcSearchTerm) !== -1
      );
    }
  }

  public sortSites(sites: any[]) {
    return sites.sort((a, b) =>
      a.siteName.toLowerCase() < b.siteName.toLowerCase() ? -1 : 1
    );
  }

  public async add(): Promise<void> {
    const body = {
      email: this.email,
      org: this.org,
      businessLine: this.businessLine,
      role: this.role,
      country: this.country,
      region: this.region,
      devices: this.assignDevices.join(','),
      workflows: this.assignWorkflows.join(','),
      deviceConfig: this.assignDeviceConf,
      workflowConfig: this.assignWorkflowConf,
      technicianTraining: this.technicianTraining,
      licenseInformation: this.licenseInformation,
      sites: this.assignSites,
    };

    await this.appUsersService.addUser(body);
    this.resetData();
  }

  public closeModal(): void {
    this.email = '';
    this.org = '';
    this.businessLine = '';
    this.role = '';
    this.country = '';
    this.region = '';
    this.searchTerm = '';
    this.assignDevices = [];
    this.assignWorkflows = [];
    this.assignSites = [];
    this.assignDeviceConf = [];
    this.assignWorkflowConf = [];
    this.technicianTraining = false;
    this.licenseInformation = [];
    this.startDtDvc = [];
    this.durationDvc = [];
    this.startDtWf = [];
    this.durationWf = [];
    this.tmpstartDtDvc = [];
    this.tmpdurationDvc = [];
    this.tmpstartDtWf = [];
    this.tmpdurationWf = [];
    this.dvcOffset = 0;
    this.wfOffset = 0;
    this.currentTab = 'userinfo';
    this.subTab = 'betaDevices';
    this.open = false;
  }

  public resetData() {
    this.highlightUser.emit(this.email);
    this.closeModal();
    this.appUsersService.valSiteUsers();
    this.appUsersService.$users.subscribe(
      (users: any[]) => (this.allUsers = users)
    );
  }

  public searchOrgData(): void {
    const orgList = JSON.stringify(this.appUsersService.organizations);
    document.getElementById('orgText').setAttribute('search-array', orgList);
  }

  public searchBLData(): void {
    const blList = JSON.stringify(this.appUsersService.businessLines);
    document.getElementById('bLText').setAttribute('search-array', blList);
  }

  public searchRoleData(): void {
    const roleList = JSON.stringify(this.appUsersService.roles);
    document.getElementById('roleText').setAttribute('search-array', roleList);
  }

  public searchCountryData(): void {
    const countriesList = JSON.stringify(
      this.appUsersService.locations.map((loc) => {
        return loc.country;
      })
    );
    document
      .getElementById('countryText')
      .setAttribute('search-array', countriesList);
  }

  changeRegion(country) {
    const loc = this.appUsersService.locations.find((loc) => loc.country === country);
    if (loc) {
      this.region = this.appUsersService.locations.find(
        (loc) => loc.country === country
      ).region;
    }
  }

  public onSearchOrg(): void {
    this.org = '';
  }

  public onSearchBL(): void {
    this.businessLine = '';
  }

  public onSearchRole(): void {
    this.role = '';
  }

  public onSearchCountry(): void {
    this.country = '';
  }

  public filterBetaOrRelease(deviceOrWorkflowList: any[], condition: any) {
    return deviceOrWorkflowList.filter(
      (deviceOrWorkflow) => deviceOrWorkflow.status == condition
    );
  }

  isDeviceActive(deviceName: string) {
    if (
      this.assignDevices.includes(deviceName) &&
      (this.userHasTableForDevice(
        this.email,
        deviceName,
        this.tableTypeEnum.Siemens
      ) ||
        this.userHasTableForDevice(
          this.email,
          deviceName,
          this.tableTypeEnum.Magnus
        ))
    ) {
      return true;
    }
    return false;
  }

  isWorkflowActive(workflowKey: string) {
    if (
      this.assignWorkflows.includes(workflowKey) &&
      (this.userHasTableForWorkflow(
        this.email,
        workflowKey,
        this.tableTypeEnum.Siemens
      ) ||
        this.userHasTableForWorkflow(
          this.email,
          workflowKey,
          this.tableTypeEnum.Magnus
        ))
    ) {
      return true;
    }
    return false;
  }

  assignDeviceAndTable(device: string, index: number) {
    this.assignDevices.push(device);
    this.addToDevice(this.email, device, this.tableTypeEnum.Siemens);
    this.addPcmToDevice(this.email, device, this.pcmEnum.PCM_1);
    if (this.hasPheno(device)) {
      this.addToDevice(this.email, device, this.tableTypeEnum.Magnus);
      this.addPcmToDevice(this.email, device, this.pcmEnum.PCM_2);
    }
    this.addLicenseDvc(this.email, device, index);
  }

  assignWorkflowAndTable(workflow: string, index: number) {
    this.assignWorkflows.push(workflow);
    this.addToWorkflow(this.email, workflow, this.tableTypeEnum.Siemens);
    this.addPcmToWorkflow(this.email, workflow, this.pcmEnum.PCM_1);
    if (this.hasPheno(workflow)) {
      this.addToWorkflow(this.email, workflow, this.tableTypeEnum.Magnus);
      this.addPcmToWorkflow(this.email, workflow, this.pcmEnum.PCM_2);
    }
    this.addLicenseWf(this.email, workflow, index);
  }

  removeDeviceAndTable(device: string) {
    this.assignDevices.splice(this.assignDevices.indexOf(device, 1));
    const idx = this.assignDeviceConf.findIndex(
      (conf) => conf.deviceName === device && conf.user === this.email
    );
    this.assignDeviceConf.splice(idx, 1);
    this.removeLicense(this.email, device, LicenseCategory.DEVICE_LICENSE);
  }

  removeWorkflowAndTable(workflow: string) {
    this.assignWorkflows.splice(this.assignWorkflows.indexOf(workflow, 1));
    const idx = this.assignWorkflowConf.findIndex(
      (conf) => conf.workflowKey === workflow && conf.user === this.email
    );
    this.assignWorkflowConf.splice(idx, 1);
    this.removeLicense(this.email, workflow, LicenseCategory.WORKFLOW_LICENSE);
  }

  userHasTableForDevice(user: string, deviceName: string, tableType: string) {
    if (user) {
      return this.assignDeviceConf.some(
        (d) =>
          d.deviceName === deviceName &&
          d.tableType &&
          d.tableType.includes(tableType)
      );
    }
    return false;
  }

  userHasPcmForDevice(user: string, deviceName: string, pcm: string) {
    if (user) {
      return this.assignDeviceConf.some(
        (d) => d.deviceName === deviceName && d.pcm && d.pcm.includes(pcm)
      );
    }
    return false;
  }

  addToDevice(user: string, deviceName: string, tableType: string) {
    const idx = this.assignDeviceConf.findIndex(
      (conf) => conf.deviceName === deviceName && conf.user === user
    );
    const item = this.assignDeviceConf[idx];
    if (idx >= 0 && item) {
      if (
        item.tableType &&
        item.tableType.length > 0 &&
        !item.tableType.includes(tableType)
      ) {
        item.tableType.push(tableType);
      } else {
        item.tableType = [tableType];
      }
    } else {
      this.assignDeviceConf.push({
        deviceName: deviceName,
        user: user,
        tableType: [tableType],
        pcm: item ? item.pcm : [],
      });
    }
  }

  addPcmToDevice(user: string, deviceName: string, pcm: string) {
    const idx = this.assignDeviceConf.findIndex(
      (conf) => conf.deviceName === deviceName && conf.user === user
    );
    const item = this.assignDeviceConf[idx];
    if (idx >= 0 && item) {
      if (item.pcm && item.pcm.length > 0 && !item.pcm.includes(pcm)) {
        item.pcm.push(pcm);
      } else {
        item.pcm = [pcm];
      }
    } else {
      this.assignDeviceConf.push({
        deviceName: deviceName,
        user: user,
        tableType: item.tableType,
        pcm: [pcm],
      });
    }
  }

  removeFromDevice(user: string, deviceName: string, tableType: string) {
    const idx = this.assignDeviceConf.findIndex(
      (conf) => conf.deviceName === deviceName && conf.user === user
    );
    const item = this.assignDeviceConf[idx];
    if (idx >= 0 && item) {
      if (
        item.tableType &&
        item.tableType.length > 1 &&
        item.tableType.includes(tableType)
      ) {
        item.tableType.splice(item.tableType.indexOf(tableType), 1);
      } else if (
        item.tableType &&
        item.tableType.length === 1 &&
        item.tableType.includes(tableType)
      ) {
        this.assignDeviceConf.splice(this.assignDeviceConf.indexOf(item), 1);
        this.assignDevices.splice(this.assignDevices.indexOf(deviceName), 1);
      }
    }
  }

  removePcmFromDevice(user: string, deviceName: string, pcm: string) {
    const idx = this.assignDeviceConf.findIndex(
      (conf) => conf.deviceName === deviceName && conf.user === user
    );
    const item = this.assignDeviceConf[idx];
    if (idx >= 0 && item) {
      if (item.pcm && item.pcm.length > 1 && item.pcm.includes(pcm)) {
        item.pcm.splice(item.pcm.indexOf(pcm), 1);
      } else if (item.pcm && item.pcm.length === 1 && item.pcm.includes(pcm)) {
        this.assignDeviceConf.splice(this.assignDeviceConf.indexOf(item), 1);
        this.assignDevices.splice(this.assignDevices.indexOf(deviceName), 1);
      }
    }
  }

  addLicenseDvc(user: string, item: string, index: number) {
    const idx = this.licenseInformation.findIndex(
      (license) =>
        license.category === LicenseCategory.DEVICE_LICENSE &&
        license.user === user &&
        license.item === item
    );
    const l = this.licenseInformation[idx];
    if (idx >= 0 && l) {
      l.licenseStart = this.tmpstartDtDvc[index];
      l.licenseDuration = this.tmpdurationDvc[index];
    } else {
      this.licenseInformation.push({
        user: user,
        category: LicenseCategory.DEVICE_LICENSE,
        item: item,
        licenseStart: this.tmpstartDtDvc[index],
        licenseDuration: this.tmpdurationDvc[index],
      });
    }
  }

  addLicenseWf(user: string, item: string, index: number) {
    const idx = this.licenseInformation.findIndex(
      (license) =>
        license.category === LicenseCategory.WORKFLOW_LICENSE &&
        license.user === user &&
        license.item === item
    );
    const l = this.licenseInformation[idx];
    if (idx >= 0 && l) {
      l.licenseStart = this.tmpstartDtWf[index];
      l.licenseDuration = this.tmpstartDtWf[index];
    } else {
      this.licenseInformation.push({
        user: user,
        category: LicenseCategory.WORKFLOW_LICENSE,
        item: item,
        licenseStart: this.tmpstartDtWf[index],
        licenseDuration: this.tmpdurationWf[index],
      });
    }
  }

  removeLicense(user: string, item: string, category: LicenseCategory) {
    const idx = this.licenseInformation.findIndex(
      (license) =>
        license.category === category &&
        license.user === user &&
        license.item === item
    );
    const l = this.licenseInformation[idx];
    if (idx >= 0 && l) {
      this.licenseInformation.splice(idx, 1);
    }
  }

  userHasTableForWorkflow(
    user: string,
    workflowKey: string,
    tableType: string
  ) {
    if (user) {
      return this.assignWorkflowConf.some(
        (w) =>
          w.workflowKey === workflowKey &&
          w.tableType &&
          w.tableType.includes(tableType)
      );
    }
    return false;
  }

  userHasPcmForWorkflow(user: string, workflowKey: string, pcm: string) {
    if (user) {
      return this.assignWorkflowConf.some(
        (w) => w.workflowKey === workflowKey && w.pcm && w.pcm.includes(pcm)
      );
    }
    return false;
  }

  addToWorkflow(user: string, workflowKey: string, tableType: string) {
    const idx = this.assignWorkflowConf.findIndex(
      (conf) => conf.workflowKey === workflowKey && conf.user === user
    );
    const item = this.assignWorkflowConf[idx];
    if (idx >= 0 && item) {
      if (
        item.tableType &&
        item.tableType.length > 0 &&
        !item.tableType.includes(tableType)
      ) {
        item.tableType.push(tableType);
      } else {
        item.tableType = [tableType];
      }
    } else {
      this.assignWorkflowConf.push({
        workflowKey: workflowKey,
        user: user,
        tableType: [tableType],
        pcm: item ? item.pcm : [],
      });
    }
  }

  addPcmToWorkflow(user: string, workflowKey: string, pcm: string) {
    const idx = this.assignWorkflowConf.findIndex(
      (conf) => conf.workflowKey === workflowKey && conf.user === user
    );
    const item = this.assignWorkflowConf[idx];
    if (idx >= 0 && item) {
      if (item.pcm && item.pcm.length > 0 && !item.pcm.includes(pcm)) {
        item.pcm.push(pcm);
      } else {
        item.pcm = [pcm];
      }
    } else {
      this.assignWorkflowConf.push({
        workflowKey: workflowKey,
        user: user,
        tableType: item.tableType,
        pcm: [pcm],
      });
    }
  }

  removeFromWorkflow(user: string, workflowKey: string, tableType: string) {
    const idx = this.assignWorkflowConf.findIndex(
      (conf) => conf.workflowKey === workflowKey && conf.user === user
    );
    const item = this.assignWorkflowConf[idx];
    if (idx >= 0 && item) {
      if (
        item.tableType &&
        item.tableType.length > 1 &&
        item.tableType.includes(tableType)
      ) {
        item.tableType.splice(item.tableType.indexOf(tableType), 1);
      } else if (
        item.tableType &&
        item.tableType.length === 1 &&
        item.tableType.includes(tableType)
      ) {
        this.assignWorkflowConf.splice(
          this.assignWorkflowConf.indexOf(item),
          1
        );
        this.assignWorkflowConf.splice(
          this.assignWorkflows.indexOf(workflowKey),
          1
        );
      }
    }
  }

  removePcmFromWorkflow(user: string, workflowKey: string, pcm: string) {
    const idx = this.assignWorkflowConf.findIndex(
      (conf) => conf.workflowKey === workflowKey && conf.user === user
    );
    const item = this.assignWorkflowConf[idx];
    if (idx >= 0 && item) {
      if (item.pcm && item.pcm.length > 1 && item.pcm.includes(pcm)) {
        item.pcm.splice(item.pcm.indexOf(pcm), 1);
      } else if (item.pcm && item.pcm.length === 1 && item.pcm.includes(pcm)) {
        this.assignWorkflowConf.splice(
          this.assignWorkflowConf.indexOf(item),
          1
        );
        this.assignWorkflowConf.splice(
          this.assignWorkflows.indexOf(workflowKey),
          1
        );
      }
    }
  }

  hasSite(site: any): boolean {
    let isPresent = false;
    this.assignSites.forEach((ele) => {
      if (ele.siteId === site.siteId && ele.siteName === site.siteName) {
        isPresent = true;
      }
    });
    return isPresent;
  }

  hasPheno(name: string): boolean {
    return CommonModules.isPhenoModule(name);
  }

  public filterWorkflows(workflowList: any[], workflowStatus: any) {
    let list: any[];
    if (workflowStatus === 0) {
      list = workflowList.filter((workflow) => workflow.workflowDraftVersion != null);
    } else if (workflowStatus === 2) {
      list = workflowList.filter(
        (workflow) => workflow.workflowReleasedVersion != null
      );
    }

    let i = 0;
    if (workflowStatus === 0) {
      this.wfOffset = list.length;
      i = i + this.wfOffset;
    }
    list.forEach(() => {
      this.durationWf[i] = 0;
      this.startDtWf[i] = moment(new Date()).format(this.dateFormat);
      i = i + 1;
    });
    return list;
  }

  public filterDevices(deviceList: any[], deviceStatus: any) {
    let list: any[];
    if (deviceStatus === 0) {
      list = deviceList.filter((device) => device.deviceDraftVersion != null);
    } else if (deviceStatus === 2) {
      list = deviceList.filter(
        (device) => device.deviceReleasedVersion != null
      );
    }

    let i = 0;
    if (deviceStatus === 0) {
      this.dvcOffset = list.length;
      i = i + this.dvcOffset;
    }
    list.forEach(() => {
      this.durationDvc[i] = 0;
      this.startDtDvc[i] = moment(new Date()).format(this.dateFormat);
      i = i + 1;
    });
    return list;
  }

  getFromDate(start) {
    return moment(start).format(this.dateFormat);
  }

  getMinDate(start) {
    return moment(start).format('YYYY-MM-DD');
  }

  getToDate(start, span: number) {
    const futureDate = new Date(start);
    futureDate.setDate(futureDate.getDate() + Number(span));
    return moment(futureDate).format(this.dateFormat);
  }

  public changeDate(date, i, isDevice, item) {
    if (isDevice) {
      this.startDtDvc[i] = date;
      this.tmpstartDtDvc[i] = date;
      const idx = this.licenseInformation.findIndex(
        (l) => l.category === LicenseCategory.DEVICE_LICENSE && l.item === item
      );
      if (this.licenseInformation[idx]) {
        this.licenseInformation[idx].licenseStart = date;
      }
      const dateEle = document.getElementById('dvc' + i);
      if (dateEle) {
        dateEle.setAttribute(
          'value',
          this.getToDate(this.startDtDvc[i], this.tmpdurationDvc[i])
        );
      }
    } else {
      this.startDtWf[i] = date;
      this.tmpstartDtWf[i] = date;
      const idx = this.licenseInformation.findIndex(
        (l) =>
          l.category === LicenseCategory.WORKFLOW_LICENSE && l.item === item
      );
      if (this.licenseInformation[idx]) {
        this.licenseInformation[idx].licenseStart = date;
      }
      const dateEle = document.getElementById('wf' + i);
      if (dateEle) {
        dateEle.setAttribute(
          'value',
          this.getToDate(this.startDtWf[i], this.tmpdurationWf[i])
        );
      }
    }
  }

  public changeDuration(duration, i, isDevice, item) {
    if (isDevice) {
      this.durationDvc[i] = duration;
      this.tmpdurationDvc[i] = duration;
      const idx = this.licenseInformation.findIndex(
        (l) => l.category === LicenseCategory.DEVICE_LICENSE && l.item === item
      );
      if (this.licenseInformation[idx]) {
        this.licenseInformation[idx].licenseDuration = duration;
      }
      const dateEle = document.getElementById('dvc' + i);
      if (dateEle) {
        dateEle.setAttribute(
          'value',
          this.getToDate(this.tmpstartDtDvc[i], this.durationDvc[i])
        );
      }
    } else {
      this.durationWf[i] = duration;
      this.tmpdurationWf[i] = duration;
      const idx = this.licenseInformation.findIndex(
        (l) =>
          l.category === LicenseCategory.WORKFLOW_LICENSE && l.item === item
      );
      if (this.licenseInformation[idx]) {
        this.licenseInformation[idx].licenseDuration = duration;
      }
      const dateEle = document.getElementById('wf' + i);
      if (dateEle) {
        dateEle.setAttribute(
          'value',
          this.getToDate(this.tmpstartDtWf[i], this.durationWf[i])
        );
      }
    }
  }

  public isInvalid() {
    const emailEle = document.getElementById('emailText');
    const orgEle = document.getElementById('orgText');
    const bLEle = document.getElementById('bLText');
    const roleEle = document.getElementById('roleText');
    const countryEle = document.getElementById('countryText');
    const regionEle = document.getElementById('regionText');

    return (
      !emailEle.getAttribute('value') ||
      !orgEle.getAttribute('value') ||
      !bLEle.getAttribute('value') ||
      !roleEle.getAttribute('value') ||
      !countryEle.getAttribute('value') ||
      !regionEle.getAttribute('value') ||
      !this.isCountryAvailable(countryEle.getAttribute('value'))
    );
  }

  isCountryAvailable(country) {
    return this.appUsersService.locations
      .map((loc) => {
        return loc.country;
      })
      .includes(country);
  }
}
