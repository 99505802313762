import { Component, OnInit } from '@angular/core';
import { HotspotsService } from '../hotspots.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Constants } from '@backend/interfaces';
import { ScreensService } from '../screens.service';
import { ParamsService } from '@backend/webapp/services/params.service';
import { DevicesService } from '@backend/webapp/devices/devices.service';

@Component({
  selector: 'backend-hotspot-screens',
  templateUrl: './hotspot-screens.component.html',
  styleUrls: ['./hotspot-screens.component.scss'],
})
export class HotspotScreensComponent implements OnInit {
  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  languageSubscription: Subscription;

  searchScreenString = '';
  hotspot: any;
  displayedScreens: any[] = [];
  allScreens: any[] = [];
  selectedScreen: any;

  constructor(
    private hotspotsService: HotspotsService,
    private router: Router,
    private screensService: ScreensService,
    private paramsService: ParamsService,
    public devicesService: DevicesService
  ) {}

  async ngOnInit(): Promise<void> {
    this.hotspot = await this.hotspotsService.getHotspot();
    this.allScreens = this.hotspot.screens;
    this.displayedScreens = this.hotspot.screens;
  }

  public async deleteScreen(
    hotspotId: number,
    screenId: number,
    key
  ): Promise<void> {
    await this.screensService.deleteScreen(hotspotId, screenId, key);
    await this.updateAll();
  }

  private async updateAll(): Promise<void> {
    await this.hotspotsService.updateHotspots();
    await this.hotspotsService.updateHotspot();
    await this.screensService.updateScreens();
    await this.screensService.updateScreen();

    this.hotspot = await this.hotspotsService.getHotspot();
    this.allScreens = this.hotspot.screens;
    this.searchScreensByKey();
  }

  public onSearchScreenString(searchedString: string): void {
    this.searchScreenString = searchedString;
    this.searchScreensByKey();
  }

  public gotoScreenHotspots(screenId): void {
    this.router.navigateByUrl(
      '/devices/' +
        this.paramsService.deviceId +
        '/versions/' +
        this.paramsService.deviceVersionId +
        '/hotspots/' +
        this.paramsService.hotspotId +
        '/screens/' +
        screenId +
        '/details'
    );
  }

  onScreenSelection(screenId: number): void {
    this.selectedScreen = this.allScreens.find(
      (screen) => screen.id === screenId
    );
  }

  private searchScreensByKey(): void {
    if (!this.searchScreenString) {
      this.displayedScreens = this.allScreens;
    } else {
      const searchText = this.searchScreenString.toLocaleLowerCase();
      this.displayedScreens = this.allScreens.filter((screen) =>
        screen.key.toLocaleLowerCase().includes(searchText)
      );
    }
  }
}
