import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from '../spinner/spinner.service';
import { ParamsService } from '../services/params.service';
import { NotificationService } from '../shared/notification/notification.service';
import { Injectable } from '@angular/core';
import { Package } from '../license-package/models/package';
import { UserAssignedPackages } from '../license-package/models/userAssignedPackages';
import { UpdateUserPackage } from '../license-package/models/updateUserPackage';

@Injectable({
  providedIn: 'root',
})
export class LicensePackageService {
  // Packages Subject
  private packagesSubject$: BehaviorSubject<Package[]> = new BehaviorSubject<
    Package[]
  >(null);
  public get licensePackages$(): Observable<Package[]> {
    return this.packagesSubject$.asObservable();
  }
  public get licensePackages(): Package[] {
    return this.packagesSubject$.value;
  }

  //Package Subject

  private assignedPackagesSubject$: BehaviorSubject<UserAssignedPackages[]> =
    new BehaviorSubject<UserAssignedPackages[]>(null);

  public get assignedLicensePackages$(): Observable<UserAssignedPackages[]> {
    return this.assignedPackagesSubject$.asObservable();
  }

  public get assignedLicensePackages(): UserAssignedPackages[] {
    return this.assignedPackagesSubject$.value;
  }

  constructor(
    private httpClient: HttpClient,
    private spinner: SpinnerService,
    private paramsService: ParamsService,
    private notificationService: NotificationService
  ) {}

  public async getPackages(): Promise<void> {
    try {
      this.spinner.showSpinner();
      const licensePackages = await lastValueFrom(
        this.httpClient.get<Package[]>('/api/package')
      );
      this.packagesSubject$.next(licensePackages);
      this.spinner.hideSpinner();
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'licensePackage.getError',
        null,
        error.error.message
      );
    } finally {
      this.spinner.hideSpinner();
    }
  }

  public async updatePackageForUser(updateLicensePackage: UpdateUserPackage) {
    try {
      this.spinner.showSpinner();
      await lastValueFrom(
        this.httpClient.post('/api/package', updateLicensePackage)
      );
      this.notificationService.confirmation(
        'general.success',
        'package.updatePackageSuccess'
      );
      this.spinner.hideSpinner();
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'licensePackage.getError',
        null,
        error.error.message
      );
    } finally {
      this.spinner.hideSpinner();
    }
  }

  public async removePackageFromUser(user: string, packageId: number) {
    try {
      this.spinner.showSpinner();
      await lastValueFrom(
        this.httpClient.delete(`/api/package/${user}/${packageId}`)
      );

      this.notificationService.confirmation(
        'general.success',
        'package.removePackageSuccess'
      );

      this.spinner.hideSpinner();
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'package.getRemoveError',
        null,
        error.error.message
      );
    }
    this.spinner.hideSpinner();
  }
  public async getAssignedPackagesToUser(userId: string) {
    try {
      this.spinner.showSpinner();
      const assignedPackages = await lastValueFrom(
        await this.httpClient.get<UserAssignedPackages[]>(
          `/api/package/assigned`,
          {
            params: {
              userId: userId,
            },
          }
        )
      );
      this.assignedPackagesSubject$.next(assignedPackages);
      this.spinner.hideSpinner();
    } catch (error) {
      this.spinner.hideSpinner();
    }
  }
}
