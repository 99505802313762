import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ValTokenService } from './valToken.service';
import { environment } from '../../environments/environment';
import { lastValueFrom } from 'rxjs';

const valUsrEmail = environment.valUsrEmail;

@Pipe({
  name: 'valImage',
})
export class ValImagePipe implements PipeTransform {
  public constructor(
    private http: HttpClient,
    private tokenService: ValTokenService
  ) {}

  public async transform(url: string): Promise<any> {
    if (!url) return null;
    url = url.toString().split(',')[0];
    let token;

    if (this.tokenService.isTokenAvailable()) {
      token = this.tokenService.getToken().token;
    } else {
      await this.tokenService.getAccessToken().then(function (result) {
        token = result.token;
      });
    }

    const requestHeaders = {
      Authorization: token,
      userEmailId: valUsrEmail,
      valAPI: 'true',
    };

    return lastValueFrom(
      this.http.get(url, {
        headers: requestHeaders,
        responseType: 'arraybuffer',
      })
    );
  }
}
