import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LicensePackageComponent } from './license-package.component';
import {TranslateModule} from "@ngx-translate/core";



@NgModule({
  declarations: [
    LicensePackageComponent
  ],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [LicensePackageComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LicensePackageModule { }
