import { Injectable } from '@angular/core';

import { Notification } from './notification.class';
import { NotificationType } from './notification-type.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public notifications: Notification[] = [];

  public notify(
    type: NotificationType,
    title: string,
    message: string,
    messageParams?: any,
    details?: string
  ): void {
    this.notifications.push(
      new Notification(type, title, message, messageParams, details)
    );
  }

  public confirmation(
    title: string,
    message: string,
    messageParams?: any,
    details?: string
  ): void {
    this.notify(
      NotificationType.Confirmation,
      title,
      message,
      messageParams,
      details
    );
  }

  public alert(
    title: string,
    message: string,
    messageParams?: any,
    details?: string
  ): void {
    this.notify(NotificationType.Alert, title, message, messageParams, details);
  }

  public error(
    title: string,
    message: string,
    messageParams?: any,
    details?: string
  ): void {
    this.notify(NotificationType.Error, title, message, messageParams, details);
  }

  public remove(notification: Notification): void {
    this.notifications = this.notifications.filter(
      (n: Notification) => n !== notification
    );
  }
}
