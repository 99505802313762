export class CommonModules
{
  public static isPhenoModule(name: string): boolean
  {
    if (name) {
      return (
        name.toLowerCase().includes('pheno') ||
        name.toLowerCase().includes('needle')
      );
    }
    return false;
  }
}
