<div class="search-header">
  <sh-search
    size="body-2"
    class="search-box"
    [label]="'general.search' | translate"
    [value]="searchString"
    (value-changed)="onSearchString($event.target.value)"
  ></sh-search>
  <sh-button
    *ngIf="!devicesService.isReleaseVersionActive()"
    size="max-content"
    [label]="'general.add' | translate"
    [routerLink]="'add'"
  ></sh-button>
</div>
<sh-table>
  <sh-table-row slot="header">
    <sh-table-head
      unsortable
      columns="5"
      [innerHTML]="'devices.versions.hotspots.name' | translate"
    ></sh-table-head>

    <sh-table-head
      unsortable
      columns="8"
      [innerHTML]="'devices.versions.hotspots.description' | translate"
    ></sh-table-head>

    <sh-table-head
      unsortable
      columns="5"
      [innerHTML]="'devices.versions.hotspots.file' | translate"
    ></sh-table-head>

    <sh-table-head unsortable icon columns="1"></sh-table-head>
    <sh-table-head unsortable icon columns="1"></sh-table-head>
  </sh-table-row>

  <sh-table-row
    #tableRow
    *ngFor="let hotspot of displayedHotspotsCollection"
    id="{{ hotspot?.id }}"
    [routerLink]="[hotspot?.id, 'screens']"
  >
    <sh-table-cell
      columns="5"
      [innerText]="hotspot?.title | getLocaleText : globalLanguage?.key : true"
    ></sh-table-cell>

    <sh-table-cell
      columns="8"
      [innerText]="
        hotspot?.description | getLocaleText : globalLanguage?.key : true
      "
    ></sh-table-cell>

    <sh-table-cell columns="5">
      <img
        class="hotspots-image"
        *ngIf="hotspot?.thumbnail"
        [src]="hotspot?.thumbnail | s3Image | async"
      />
    </sh-table-cell>

    <sh-table-cell columns="4"> </sh-table-cell>
    <sh-table-cell icon columns="1">
      <sh-icon
        *ngIf="!devicesService.isReleaseVersionActive()"
        button
        size="s"
        icon="delete"
        [label]="'devices.versions.hotspots.deleteHotspot' | translate"
        (click)="deleteHotspot(hotspot?.id); $event.stopPropagation()"
      >
      </sh-icon>
    </sh-table-cell>
    <sh-table-cell icon columns="1">
      <sh-icon
        *ngIf="!devicesService.isReleaseVersionActive()"
        button
        size="s"
        icon="edit"
        [label]="'devices.versions.hotspots.editHotspot' | translate"
        [routerLink]="[hotspot?.id, 'edit']"
      >
      </sh-icon>
    </sh-table-cell>
  </sh-table-row>
</sh-table>

<!-- Footer -->
<div
  class="buttons"
  [ngClass]="
    displayedHotspotsCollection?.length
      ? 'position-relative'
      : 'position-absolute'
  "
>
  <div style="flex: 1"></div>
  <sh-button
    color="tertiary"
    slot="footer"
    size="max-content"
    [label]="'general.close' | translate"
    (click)="
      router.navigate([
        'devices',
        this.paramsService.deviceId,
        'versions',
        this.paramsService.deviceVersionId,
        'features'
      ])
    "
    style="margin: 0px 8px"
  ></sh-button>
  <sh-button
    size="max-content"
    slot="footer"
    [label]="'general.next' | translate"
    (click)="
      router.navigate([
        'devices',
        this.paramsService.deviceId,
        'versions',
        this.paramsService.deviceVersionId,
        'localization'
      ])
    "
    style="margin: 0px 8px"
  ></sh-button>
</div>
