import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { AuditService } from './audit.service';
import { Constants, IAdminUser, IAudit } from '@backend/interfaces';
import { Observable } from 'rxjs';
import { SpinnerService } from '../spinner/spinner.service';
import { AppUsersService } from '../appuser/appuser.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'backend-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css'],
})
export class AuditComponent implements OnInit {
  public userEmailId = '';
  public auditDetails: any = [];
  public $auditDetails: Observable<any>;
  public _auditValues: IAudit[];
  public get auditValues(): IAudit[] {
    return this._auditValues;
  }

  $auth: Observable<boolean>;
  $profile: Observable<any>;
  $role: Observable<IAdminUser>;
  users: any[];
  appusers: any[];

  public currPage = 1;
  public pageLen = 20;
  public totalPages = 20;
  public totalRecords = 0;

  public actions = Constants.ACTIONS;
  public choices = Constants.PERIOD;
  public dateRangeLabel = Constants.DATE_RANGE_LABEL;
  action = 'All';
  period = 'All';
  noToggle = false;
  from = '';
  to = '';

  constructor(
    private auditService: AuditService,
    public authService: AuthService,
    private spinner: SpinnerService,
    private appusersService: AppUsersService
  ) {}

  async ngOnInit() {
    this.$auth = this.authService.loggedIn$;
    this.$profile = this.authService.userProfile$;
    this.$role = this.authService.$role;
    this.authService.userProfile$.subscribe((data) => {
      this.userEmailId = data.idTokenClaims?.email;
    });
    await this.appusersService.loadUsers();
    this.appusersService.$users.subscribe((data) => {
      this.appusers = data;
    });
    await this.auditService.getAuditUsers();
    this.auditService.$auditUsers.subscribe((data) => (this.users = data));
    this.users.sort((a, b) => a.userName - b.userName);
    await this.auditService.getFilteredAuditDetails(
      this.userEmailId,
      this.action,
      this.period,
      this.from,
      this.to,
      1,
      20
    );
    this.getAuditDetailsData();
  }

  isEmail(name) {
    return name.toLowerCase().match(/^\S+@\S+\.\S+$/);
  }

  getName(user) {
    if (!this.isEmail(user)) {
      const idx = this.appusers.findIndex((appuser) => appuser.userId === user);
      if (idx && this.appusers[idx]) {
        return 'APP_USER: ' + this.appusers[idx].email;
      }
    }
    return user;
  }

  private async getAuditDetailsData(): Promise<void> {
    this.$auditDetails = this.auditService.$auditLogs;
    this.$auditDetails.subscribe((details: any) => {
      if (details) {
        this._auditValues = JSON.parse(JSON.stringify(details.records));
        this.auditDetails = details.records;
        this.totalRecords = details.totalRecords;
        this.totalPages = Math.ceil(details.totalRecords / this.pageLen);
      }
    });
  }

  resetDate() {
    this.from = '';
    this.to = '';
    document
      .getElementById('datePicker')
      .setAttribute('value', this.dateRangeLabel);
  }

  changeDate(event) {
    if (event.target.value && event.target.value.indexOf('From') === -1) {
      const dates = event.target.value.split('-');
      this.from = dates[0];
      this.to = dates[1];
    }
  }

  public applyFilter() {
    this.paginate(1, 20);
  }

  public isInvalid() {
    return (
      this.period === this.choices[3] &&
      document
        .getElementById('datePicker')
        .getAttribute('value')
        .indexOf('From') !== -1
    );
  }

  public paginate(currentPage: any, page_len) {
    this.currPage = currentPage;
    const page = this.currPage || 1;
    const per_page = page_len || 20;
    this.auditService.getFilteredAuditDetails(
      this.userEmailId,
      this.action,
      this.period,
      this.from,
      this.to,
      page,
      per_page
    );
    this.auditService.$auditLogs.subscribe((details: any) => {
      if (details) {
        this.auditDetails = details.records;
        this.totalRecords = details.totalRecords;
        this.totalPages = Math.ceil(details.totalRecords / per_page);
      }
    });
  }

  async export() {
    try {
      this.spinner.showSpinner();
      const csv = await this.auditService.exportAuditDetails(
        this.userEmailId,
        this.action,
        this.period,
        this.from,
        this.to
      );
      if (-1 !== window.location.href.indexOf(environment.domain)) {
        const windowUrl = window.URL || window.webkitURL;
        const blob = new Blob(['\uFEFF' + csv.text], {
          type: 'text/csv; charset=utf-8',
        });
        const url = windowUrl.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        const timestamp = new Date().toISOString();
        anchor.download = `AuditTrail-${timestamp}.csv`;
        anchor.click();
      }
      this.spinner.hideSpinner();
    } catch (error) {
      console.log(error);
      this.spinner.hideSpinner();
    }
  }
}
