<sh-modal
  id="footer-modal"
  sticky
  modal-height="fit-content"
  [visible]="isOpen"
  [label]="'pages.addOrEdit' | translate"
>
  <sh-text [innerText]="'pages.addOrEditDetails' | translate"></sh-text>
  <sh-input-text
    [value]="nameInternal"
    (value-changed)="nameInternal = $event.target.value"
    #nameInternalTextbox
    mandatory
    [label]="'pages.nameInternal' | translate"
  ></sh-input-text>
  <sh-input-text
    [value]="name"
    (value-changed)="name = $event.target.value"
    #nameTextbox
    mandatory
    [label]="'pages.name' | translate"
  ></sh-input-text>
  <sh-button
    slot="footer"
    size="m"
    color="secondary"
    [label]="'general.cancel' | translate"
    (click)="isOpen = false"
  ></sh-button>
  <sh-button
    slot="footer"
    size="m"
    [label]="'general.ok' | translate"
    [disabled]="!nameTextbox.value || !nameInternalTextbox.value"
    (click)="save()"
  ></sh-button>
</sh-modal>
