import {
  Component,
  OnInit,
  ViewChildren,
  QueryList,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { LanguageService } from '../../../../../services/language.service';
import { FeaturesService } from './features.service';
import { PopoverService } from '../../../../../services/popover.service';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { Constants } from '@backend/interfaces';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ParamsService } from '@backend/webapp/services/params.service';
import { DevicesService } from '@backend/webapp/devices/devices.service';

@Component({
  selector: 'backend-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
})
export class FeaturesComponent implements OnInit, OnDestroy {
  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;

  languageSubscription: Subscription;
  searchString = '';
  displayedFeatures: any[] = [];

  public constructor(
    public popoverService: PopoverService,
    public featuresService: FeaturesService,
    public languageService: LanguageService,
    public localization: LocalizationService,
    public paramsService: ParamsService,
    public router: Router,
    public devicesService: DevicesService
  ) {
    router.events.subscribe(async (val) => {
      if (val instanceof NavigationEnd) {
        await this.featuresService.updateFeatures();
        this.displayedFeatures = this.featuresService.features;
      }
    });
  }

  public async ngOnInit(): Promise<void> {
    await this.featuresService.updateFeatures(true);
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          await this.featuresService.updateFeatures(true);
        }
      }
    );
    this.featuresService.checkIfAllFeaturesAdded();
    this.displayedFeatures = this.featuresService.features;
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  public onSearchString(searchedString: string): void {
    this.searchString = searchedString;
    this.searchFeaturesByNameAndDesc();
  }

  public async deleteFeature(id: number): Promise<void> {
    await this.featuresService.deleteFeature(id);
    this.displayedFeatures = this.featuresService.features;
  }

  private searchFeaturesByNameAndDesc(): void {
    if (!this.searchString) {
      this.displayedFeatures = this.featuresService.features;
    } else {
      const searchText = this.searchString.toLocaleLowerCase();
      this.displayedFeatures = this.featuresService.features.filter(
        (feature) => {
          let featureNameAndDesc = feature?.name[0]?.value.toString().trim();
          featureNameAndDesc += feature?.description[0]?.value
            .toString()
            .trim();
          return featureNameAndDesc.toLocaleLowerCase().includes(searchText);
        }
      );
    }
  }
}
