<sh-text
  size="header-2"
  [innerHTML]="
    (isEditMode
      ? 'devices.versions.features.editFeature'
      : 'devices.versions.features.addFeature'
    ) | translate
  "
></sh-text>

<div class="flex-container">
  <div
    class="display-flex-column row"
    style="width: 33.33%; margin-right: 64px"
  >
    <div class="display-flex-row">
      <sh-text
        size="header-4"
        class="sh-text"
        [innerHTML]="'devices.versions.features.name' | translate"
      ></sh-text>
      <sh-icon icon="information" size="xs" id="info-1"></sh-icon>
      <sh-tooltip
        label="Name"
        target="info-1"
        placement="top"
        variation="short"
      ></sh-tooltip>
    </div>
    <div>
      <sh-dropdown
        #inputName
        mandatory
        [label]="'devices.versions.features.name' | translate"
        [value]="currentFeature"
        (value-changed)="name = $event.target.value"
      >
        <sh-menu-item
          *ngFor="let name of featureNames"
          [label]="featuresService.getTranslatedData(name)"
          [value]="featuresService.getTranslatedData(name)"
          [disabled]="isEditMode ? currentFeature !== featuresService.getTranslatedData(name) : isFeatureAdded(featuresService.getTranslatedData(name))"
        ></sh-menu-item>
      </sh-dropdown>
    </div>
  </div>

  <div class="display-flex-column row" style="width: 16%">
    <div class="display-flex-row">
      <sh-text
        size="header-4"
        class="sh-text"
        [innerHTML]="'devices.versions.features.augmentedReality' | translate"
      ></sh-text>
      <sh-icon icon="information" size="xs" id="info-2"></sh-icon>
      <sh-tooltip
        label="Augmented Reality"
        target="info-2"
        placement="top"
        variation="short"
      ></sh-tooltip>
    </div>
    <div class="padding-top-8">
      <sh-checkbox
        [label]="'devices.versions.features.ar' | translate"
        [active]="ar"
        (checked)="ar = $event.target.active"
      ></sh-checkbox>
    </div>
  </div>
</div>

<div class="flex-container">
  <div class="display-flex-column row">
    <div class="display-flex-row">
      <sh-text
        size="header-4"
        class="sh-text"
        [innerHTML]="'devices.versions.features.description' | translate"
      ></sh-text>
      <sh-icon icon="information" size="xs" id="info-3"></sh-icon>
      <sh-tooltip
        label="Description"
        target="info-3"
        placement="top"
        variation="short"
      ></sh-tooltip>
    </div>
    <div>
      <sh-input-text
        textarea
        mandatory
        [label]="'devices.versions.features.description' | translate"
        rows="6"
        #inputDesc
        [value]="descriptionTrans"
        (value-changed)="description = $event.target.value"
      ></sh-input-text>
      <div class="float-right">
        <sh-text size="body-2" color="secondary"
          >Word Count: {{ description?.length }}/1000</sh-text
        >
      </div>
    </div>
  </div>
</div>

<div class="flex-container" style="width: 66.66%">
  <div class="display-flex-column row">
    <div class="display-flex-row">
      <sh-text
        size="header-4"
        class="sh-text"
        color="primary"
        [innerHTML]="'devices.uploadImage' | translate"
      ></sh-text>
    </div>

    <div>
      <img *ngIf="file && !fileChanged" [src]="file | s3Image | async" />
      <img #previewImage *ngIf="file && fileChanged" />
      <sh-text #inputFileTextbox [value]="fileBasename">{{
        fileBasename
      }}</sh-text>
      <sh-button
        size="m"
        [label]="'devices.versions.features.crop' | translate"
        (click)="cropImage()"
        *ngIf="fileChanged && !isCropped"
      ></sh-button>
    </div>

    <div
      *ngIf="!file"
      class="sh-empty-state display-flex-column"
      style="align-items: center; padding: 60px"
      (dragover)="preventDefault($event)"
      (drop)="drop($event)"
    >
      <div>
        <sh-icon icon="page" size="l" class="empty-state-icon"> </sh-icon>
      </div>
      <div>
        <sh-text class="empty-state-text" size="body-1">
          Choose a file or drag & drop it here
        </sh-text>
      </div>
    </div>
    <div class="padding-top-12">
      <sh-button
        class="float-right"
        size="m"
        [label]="'devices.uploadFile' | translate"
        (click)="selectFile()"
      ></sh-button>
    </div>
  </div>
</div>

<!-- Footer -->
<div class="buttons">
  <div style="flex: 1"></div>
  <sh-button
    color="secondary"
    (click)="cancel()"
    size="m"
    [label]="'general.close' | translate"
  ></sh-button>
  <sh-button
    size="m"
    [disabled]="
      !inputName.value ||
      !inputDesc.value ||
      !inputFileTextbox.value ||
      (!isEditMode && !isCropped)
    "
    (click)="save()"
    [label]="'general.save' | translate"
  ></sh-button>
</div>

<input
  #inputFile
  accept=".jpg,.jpeg,.png"
  type="file"
  style="display: none"
  (change)="fileChange($event.target.files[0])"
/>
