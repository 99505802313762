<div class="search-header">
  <sh-search
    size="body-2"
    class="search-box"
    [label]="'general.search' | translate"
    [value]="searchString"
    (value-changed)="onSearchString($event.target.value)"
  ></sh-search>
  <sh-button
    *ngIf="!devicesService.isReleaseVersionActive()"
    [disabled]="featuresService.checkIfAllFeaturesAdded()"
    size="max-content"
    [label]="'general.add' | translate"
    [routerLink]="'add'"
  ></sh-button>
</div>
<sh-table>
  <sh-table-row>
    <sh-table-head unsortable columns="3"></sh-table-head>
    <sh-table-head
      unsortable
      columns="3"
      [innerHTML]="'devices.versions.features.name' | translate"
    ></sh-table-head>
    <sh-table-head
      unsortable
      columns="14"
      [innerHTML]="'devices.versions.features.description' | translate"
    ></sh-table-head>
    <sh-table-head unsortable icon columns="1"></sh-table-head>
    <sh-table-head unsortable icon columns="1"></sh-table-head>
  </sh-table-row>

  <sh-table-row
    *ngFor="let feature of displayedFeatures"
    (contextmenu)="
      popoverService.openPopover(popovers, 'feature-menu-' + feature.id, $event)
    "
  >
    <sh-table-cell columns="3">
      <img
        *ngIf="feature?.thumbnail"
        [src]="feature?.thumbnail | s3Image | async"
      />
    </sh-table-cell>

    <sh-table-cell columns="3">
      <p
        class="flow-text"
        [innerText]="feature.name | getLocaleText : globalLanguage?.key : true"
      ></p>
    </sh-table-cell>

    <sh-table-cell columns="17">
      <p
        class="flow-text"
        [innerText]="
          feature.description | getLocaleText : globalLanguage?.key : true
        "
      ></p>
    </sh-table-cell>

    <sh-table-cell icon columns="1">
      <sh-icon
        *ngIf="!devicesService.isReleaseVersionActive()"
        button
        size="s"
        icon="delete"
        [label]="'general.delete' | translate"
        (click)="deleteFeature(feature.id)"
      >
      </sh-icon>
    </sh-table-cell>
    <sh-table-cell icon columns="1">
      <sh-icon
        *ngIf="!devicesService.isReleaseVersionActive()"
        button
        size="s"
        icon="edit"
        [label]="'general.edit' | translate"
        [routerLink]="[feature.id, 'edit']"
      >
      </sh-icon>
    </sh-table-cell>
  </sh-table-row>

  <sh-table-row *ngIf="featuresService.features?.length === 0">
    <sh-table-cell columns="3"></sh-table-cell>
    <sh-table-cell
      columns="21"
      [innerText]="'devices.versions.features.empty' | translate"
    ></sh-table-cell>
  </sh-table-row>
</sh-table>
<!-- Footer -->
<div
  class="buttons"
  [ngClass]="
    displayedFeatures?.length ? 'position-relative' : 'position-absolute'
  "
>
  <div style="flex: 1"></div>
  <sh-button
    color="tertiary"
    slot="footer"
    size="max-content"
    [label]="'general.close' | translate"
    (click)="router.navigate(['devices', paramsService.deviceId, 'versions'])"
    style="margin: 0px 8px"
  ></sh-button>
  <sh-button
    size="max-content"
    slot="footer"
    [label]="'general.next' | translate"
    (click)="
      router.navigate([
        'devices',
        paramsService.deviceId,
        'versions',
        paramsService.deviceVersionId,
        'hotspots'
      ])
    "
    style="margin: 0px 8px"
  ></sh-button>
</div>
