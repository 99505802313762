import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LanguageService } from '../../../../../services/language.service';
import { ParamsService } from '../../../../../services/params.service';
import { Block } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/blocks/block';
import { Author } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/blocks/author';
import { ConfirmationService } from '@backend/webapp/shared/confirmation/confirmation.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BlocksService {
  private get apiRoute(): string {
    return `/api/workflowguides/workflows/${this.paramsService.workflowId}/workflowversions/${this.paramsService.workflowVersionId}/overviews/${this.paramsService.overviewId}/blocks/`;
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly languageService: LanguageService,
    private readonly paramsService: ParamsService,
    private confirmationService: ConfirmationService
  ) {}

  public async create(): Promise<Block> {
    return await lastValueFrom(this.httpClient.post<Block>(this.apiRoute, {}));
  }

  public async createImage(image: File): Promise<Block> {
    const formData = new FormData();
    formData.append('image', image, image.name);
    return await lastValueFrom(
      this.httpClient.post<Block>(this.apiRoute + 'image', formData)
    );
  }

  public async createText(text: string): Promise<Block> {
    return await lastValueFrom(
      this.httpClient.post<Block>(this.apiRoute + 'text', {
        text,
        langKey: this.languageService.guiLanguageCode,
      })
    );
  }

  public async delete(id: number): Promise<void> {
    if (
      await this.confirmationService.confirm(
        'workflowguides.workflows.workflowVersions.overviews.blocks.delete',
        'workflowguides.workflows.workflowVersions.overviews.blocks.deleteConfirmation',
        { blockId: id }
      )
    ) {
      this.paramsService.isLoading = true;
      try {
        return await lastValueFrom(
          this.httpClient.delete<void>(this.apiRoute + id)
        );
      } catch (e) {
        console.log(e);
        return null;
      } finally {
        this.paramsService.isLoading = false;
      }
    }
  }

  async get(blockId: number): Promise<Block> {
    if (!blockId) {
      return null;
    }
    return await lastValueFrom(
      this.httpClient.get<Block>(this.apiRoute + blockId)
    );
  }

  async editImage(id: number, image: File): Promise<Block> {
    const formData = new FormData();
    formData.append('image', image, image.name);
    return await lastValueFrom(
      this.httpClient.put<Block>(this.apiRoute + 'image/' + id, formData)
    );
  }

  async editText(id: number, text: string): Promise<Block> {
    return await lastValueFrom(
      this.httpClient.put<Block>(this.apiRoute + 'title/' + id, {
        content: text,
        langKey: this.languageService.guiLanguageCode,
      })
    );
  }

  async getAuthor(authorId: number): Promise<Author> {
    if (!authorId) {
      return null;
    }
    return await lastValueFrom(
      this.httpClient.get<Author>(this.apiRoute + 'author/' + authorId)
    );
  }

  async deleteAuthor(authorId: number, title): Promise<void> {
    const author = this.languageService.getTranslateValue(title);
    if (
      await this.confirmationService.confirm(
        'workflowguides.workflows.workflowVersions.overviews.blocks.authors.delete',
        'workflowguides.workflows.workflowVersions.overviews.blocks.authors.deleteConfirmation',
        { author }
      )
    ) {
      this.paramsService.isLoading = true;
      try {
        return await lastValueFrom(
          this.httpClient.delete<void>(this.apiRoute + 'author/' + authorId)
        );
      } catch (e) {
        console.log(e);
        return null;
      } finally {
        this.paramsService.isLoading = false;
      }
    }
  }

  async saveAuthor(author: Author) {
    const formData = new FormData();
    formData.append('name', author.name);
    formData.append('description', author.description);
    formData.append('langKey', this.languageService.guiLanguageCode);
    if (author.portrait)
      formData.append('portrait', author.portrait, author.portrait.name);
    return await lastValueFrom(
      this.httpClient.put<Author>(
        this.apiRoute + 'author/' + author.id,
        formData
      )
    );
  }

  async createAuthor(blockId: number, author: Author) {
    const formData = new FormData();
    formData.append('name', author.name);
    formData.append('description', author.description);
    formData.append('langKey', this.languageService.guiLanguageCode);
    if (author.portrait)
      formData.append('portrait', author.portrait, author.portrait.name);
    return await lastValueFrom(
      this.httpClient.post<Author>(
        this.apiRoute + blockId + '/author',
        formData
      )
    );
  }
}
