<sh-nav-bar slot="nav" style="margin-bottom: 20px; position: relative">
  <sh-tabs>
    <sh-tab-item
      [label]="'devices.versions.features.title' | translate"
      [active]="
        router.url.indexOf('/features') !== -1 && assignActivePage('features')
      "
      [routerLink]="'features'"
    ></sh-tab-item>
    <sh-tab-item
      [label]="'devices.versions.hotspots.title' | translate"
      [active]="
        router.url.indexOf('/hotspots') !== -1 && assignActivePage('hotspots')
      "
      [routerLink]="'hotspots'"
    ></sh-tab-item>
    <sh-tab-item
      [label]="'devices.versions.localization.title' | translate"
      [active]="
        router.url.indexOf('/localization') !== -1 &&
        assignActivePage('localization')
      "
      [routerLink]="'localization'"
    ></sh-tab-item>
    <sh-tab-item
      [label]="'devices.versions.axes.title' | translate"
      [active]="router.url.indexOf('/axes') !== -1 && assignActivePage('axes')"
      [routerLink]="'axes'"
    ></sh-tab-item>
  </sh-tabs>
  <sh-button
    style="position: absolute; right: 0; margin: 18px"
    label="Edit"
    *ngIf="devicesService.isReleaseVersionActive()"
    (click)="createDeviceVersion()"
  ></sh-button>
</sh-nav-bar>

<router-outlet></router-outlet>
