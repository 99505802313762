import { Component, OnInit } from '@angular/core';

import { Notification } from './notification.class';
import { NotificationService } from './notification.service';
import { NotificationType } from './notification-type.enum';

@Component({
  selector: 'backend-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  public notifications: Notification[];

  public constructor(private notificationService: NotificationService) {}

  public ngOnInit(): void {
    this.notifications = this.notificationService.notifications;
  }

  public remove(notification: Notification): void {
    this.notificationService.remove(notification);
  }

  public getType(notificationType: NotificationType): string {
    switch (notificationType) {
      case NotificationType.Confirmation:
        return 'confirmation';
      case NotificationType.Alert:
        return 'alert';
      case NotificationType.Error:
        return 'error';
    }
  }
}
