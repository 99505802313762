export interface LocalizationChange {
  type: LocalizationChangeType;
}

export interface LocalizationCreateChange extends LocalizationChange {
  key: string;
  value: string;
  language: string;
}

export interface LocalizationModifyChange extends LocalizationChange {
  key: string;
  value: string;
  language: string;
}

export interface LocalizationDeleteChange extends LocalizationChange {
  id: string;
}

export enum LocalizationChangeType {
  Create = 0,
  Modify = 1,
  Delete = 2,
}
