import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'xmlparser',
})
export class XmlparserPipe implements PipeTransform {
  transform(value: string): any[] {
    const output = [];
    const xmlToPrase = '<?xml version="1.0"?><page>' + value + '</page>';
    if (this.validate(xmlToPrase)) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlToPrase, 'text/xml');
      if (xmlDoc.firstChild && xmlDoc.firstChild.hasChildNodes()) {
        for (
          let index = 0;
          index < xmlDoc.getElementsByTagName('style').length;
          index++
        ) {
          const node = xmlDoc.getElementsByTagName('style')[index];
          if (node.classList.length === 0) continue;

          if (node.innerHTML.indexOf('href') != -1) {
            const htmlpsr = parser.parseFromString(node.innerHTML, 'text/html');
            const textData = htmlpsr.body.childNodes[0].textContent;
            const doc = document.createElement('div');
            doc.innerHTML = node.innerHTML;
            const links = doc.getElementsByTagName('link');
            const lastURL = links[links.length - 1].href;
            const dataColor = 'primary';
            output.push({
              class: node.classList[0].toLowerCase(),
              text: textData,
              color: dataColor,
              href: lastURL,
            });
          } else {
            output.push({
              class: node.classList[0].toLowerCase(),
              text: node.innerHTML,
              color: null,
              href: null,
            });
          }
        }
      }
    }
    return output;
  }

  private validate(html: string) {
    const parser = new DOMParser();
    const d = parser.parseFromString(html, 'text/xml');
    if (d.querySelector('parsererror')) {
      return false;
    }
    return true;
  }

  public validateEmail(email: any) {
    if (
      email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return true;
    }
    return false;
  }
}
