import {
  Component,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  OnInit,
} from '@angular/core';
import { AxesService } from './axes.service';
import { EditAxisCategoryComponent } from './modals/edit-axis-category/edit-axis-category.component';
import { PopoverService } from '../../../../../services/popover.service';
import { Router } from '@angular/router';
import { ParamsService } from '@backend/webapp/services/params.service';
import { DevicesService } from '@backend/webapp/devices/devices.service';
import { DeviceVersionsService } from '../../../device-versions.service';
import { Status } from '@backend/interfaces';

@Component({
  selector: 'backend-axes',
  templateUrl: './axes.component.html',
  styleUrls: ['./axes.component.scss'],
})
export class AxesComponent implements OnInit {
  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  @ViewChild('editAxisCategoryModal')
  public editAxisCategoryModal: EditAxisCategoryComponent;
  searchString = '';
  displayedAxesCategory: any[] = [];

  showReleaseEquipemtModal: boolean = false;

  public constructor(
    public popoverService: PopoverService,
    public axesService: AxesService,
    public router: Router,
    public paramsService: ParamsService,
    public devicesService: DevicesService,
    public deviceVersionsService: DeviceVersionsService
  ) {
    this.axesService.axes$.subscribe(() => {
      this.displayedAxesCategory = this.axesService.axes;
    });
  }

  public async ngOnInit(): Promise<void> {
    await this.axesService.updateAxes(true);
    this.displayedAxesCategory = this.axesService.axes;
  }

  public async createCategory(): Promise<void> {
    this.editAxisCategoryModal.open();
  }

  public async modifyCategory(axisCategoryId: number): Promise<void> {
    this.editAxisCategoryModal.open(axisCategoryId);
  }

  public async deleteCategory(axisCategoryId: number): Promise<boolean> {
    const isCategoryDeleted = await this.axesService.deleteAxisCategory(
      axisCategoryId
    );
    this.displayedAxesCategory = this.axesService.axes;
    return isCategoryDeleted;
  }

  public async deleteRotationAxis(
    axisCategoryId: number,
    axisId: number
  ): Promise<boolean> {
    const isRotationAxisDeleted = await this.axesService.deleteRotationAxis(
      axisCategoryId,
      axisId
    );
    this.displayedAxesCategory = this.axesService.axes;
    return isRotationAxisDeleted;
  }

  public async deleteTranslationAxis(
    axisCategoryId: number,
    axisId: number
  ): Promise<boolean> {
    const isTranslationAxisDeleted =
      await this.axesService.deleteTranslationAxis(axisCategoryId, axisId);
    this.displayedAxesCategory = this.axesService.axes;
    return isTranslationAxisDeleted;
  }

  public async deleteVirtualAxis(
    axisCategoryId: number,
    axisId: number
  ): Promise<boolean> {
    const isVirtualAxisDeleted = await this.axesService.deleteVirtualAxis(
      axisCategoryId,
      axisId
    );
    this.displayedAxesCategory = this.axesService.axes;
    return isVirtualAxisDeleted;
  }

  public onSearchString(searchedString: string): void {
    this.searchString = searchedString;
    this.searchAxesByNameAndKey();
  }

  public async releaseEquipment(): Promise<void> {
    const isDeviceReleased = await this.deviceVersionsService.modifyDeviceVersion(
      this.paramsService.deviceVersionId,
      null,
      null,
      null,
      Status.RELEASE,
      true
    );
    this.showReleaseEquipemtModal = false;
    if (isDeviceReleased) {
      await this.deviceVersionsService.addDeviceToPackageUsers(this.paramsService.deviceId);
    }
    window.location.reload();
  }

  private searchAxesByNameAndKey(): void {
    if (!this.searchString) {
      this.displayedAxesCategory = this.axesService.axes;
    } else {
      const searchText = this.searchString.toLocaleLowerCase();
      this.displayedAxesCategory = this.axesService.axes.filter(
        (collection) => {
          let rotationAndTranslationAxes = collection?.key?.toString();
          rotationAndTranslationAxes += collection?.name?.toString();

          collection?.rotationAxes.forEach((rotationAxes) => {
            rotationAndTranslationAxes += rotationAxes?.name.toString();
            rotationAndTranslationAxes += rotationAxes?.key.toString();
          });

          collection?.translationAxes.forEach((translationAxes) => {
            rotationAndTranslationAxes += translationAxes?.name.toString();
            rotationAndTranslationAxes += translationAxes?.key.toString();
          });

          return rotationAndTranslationAxes
            .toLocaleLowerCase()
            .includes(searchText);
        }
      );
    }
  }
}
