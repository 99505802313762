import { Component, Renderer2, OnInit } from '@angular/core';
import { ScreensService } from '../../screens.service';
import { ParamsService } from '../../../../../../../services/params.service';
import { DetailsService } from '../../details.service';
import { DetailType } from '../../detail-type.enum';

@Component({
  selector: 'backend-screen-hotspots',
  templateUrl: './screen-hotspots.component.html',
  styleUrls: ['./screen-hotspots.component.scss'],
})
export class ScreenHotspotsComponent implements OnInit {
  public screen: any;
  public hotspotId: number;
  public selectedHotspotComputedId: string;

  public constructor(
    public paramsService: ParamsService,
    public screensService: ScreensService,
    public detailsService: DetailsService,
    public renderer: Renderer2
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.screensService.updateScreens();
    this.hotspotId = this.paramsService.hotspotId;
    this.screen = await this.screensService.getScreen();
  }

  public onDragStart(event: DragEvent): void {
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/plain', 'dragHotspot');
  }

  public onDragOver(event: DragEvent): void {
    if (event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = 'move';
    }
  }

  public async onDragEnd(
    event: DragEvent,
    detailType: DetailType,
    hotspotId: number,
    screenId: number,
    detailId: number,
    targetId?: number
  ): Promise<void> {
    if (event) {
      const hotspotElement = event.target as HTMLElement;
      const img = hotspotElement.parentElement as HTMLImageElement;
      const imgBounds = img.getBoundingClientRect();
      let oldX, oldY;

      if (hotspotElement) {
        oldX = hotspotElement.style.left;
        oldY = hotspotElement.style.top;
      }

      let newX = (event.clientX - imgBounds.left) / imgBounds.width;
      newX = Math.max(0, Math.min(1, newX));
      let newY = (imgBounds.bottom - event.clientY) / imgBounds.height;
      newY = Math.max(0, Math.min(1, newY));

      if (event.target) {
        this.renderer.setStyle(event.target, 'left', `${newX * 100}%`);
        this.renderer.setStyle(event.target, 'bottom', `${newY * 100}%`);
      }

      let success = false;
      switch (detailType) {
        case DetailType.Information:
          success = await this.detailsService.modifyInformationDetailPosition(
            hotspotId,
            screenId,
            detailId,
            newX,
            newY
          );
          break;
        case DetailType.SwitchScreen:
          success = await this.detailsService.modifySwitchScreenDetailPosition(
            hotspotId,
            screenId,
            detailId,
            newX,
            newY,
            targetId
          );
          break;
        case DetailType.Back:
          success = await this.detailsService.modifyBackDetailPosition(
            hotspotId,
            screenId,
            detailId,
            newX,
            newY,
            targetId
          );
          break;
      }

      if (!success && event.target) {
        this.renderer.setStyle(event.target, 'left', oldX);
        this.renderer.setStyle(event.target, 'bottom', oldY);
      }
    }
  }

  public imageLoaded(containerElement: HTMLElement): void {
    containerElement.classList.add('loaded');
  }

  public async onHotspotDeleted(value: boolean) {
    this.screen = await this.screensService.getScreen();
  }

  public async onHotspotSelectedFromRightPane(
    selectedHotspotFromRightPane: any
  ) {
    this.selectedHotspotComputedId = selectedHotspotFromRightPane.computedId;
  }

  public computeHotspotId(prefix, detailId) {
    return `${prefix}-${detailId}`;
  }

  public onHotspotSelection(detailType: DetailType, detail: any) {
    this.selectedHotspotComputedId = this.computeHotspotId(
      detailType,
      detail.id
    );
  }
}
