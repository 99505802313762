<div class="search-header">
  <sh-search
    size="body-2"
    class="search-box"
    [label]="'general.search' | translate"
    [value]="searchString"
    (value-changed)="onSearchString($event.target.value)"
  ></sh-search>
  <sh-button
    *ngIf="!devicesService.isReleaseVersionActive()"
    size="max-content"
    [label]="'general.add' | translate"
    (click)="createCategory()"
  ></sh-button>
</div>
<sh-table>
  <sh-table-row slot="header">
    <sh-table-head
      unsortable
      columns="10"
      [innerHTML]="'devices.versions.axes.name' | translate"
    ></sh-table-head>
    <sh-table-head
      unsortable
      columns="13"
      [innerHTML]="'devices.versions.axes.key' | translate"
    ></sh-table-head>
    <sh-table-head unsortable icon columns="1"></sh-table-head>
    <sh-table-head unsortable icon columns="1"></sh-table-head>
    <sh-table-head unsortable icon columns="1"></sh-table-head>
  </sh-table-row>

  <sh-table-row
    *ngFor="let axisCategory of displayedAxesCategory"
    (contextmenu)="
      popoverService.openPopover(
        popovers,
        'axis-category-menu-' + axisCategory.id,
        $event
      )
    "
  >
    <sh-table-cell columns="10" [innerText]="axisCategory.name"></sh-table-cell>
    <sh-table-cell columns="13" [innerText]="axisCategory.key"></sh-table-cell>
    <sh-table-cell icon columns="1">
      <sh-icon
        *ngIf="!devicesService.isReleaseVersionActive()"
        button
        size="s"
        icon="edit"
        [label]="'general.edit' | translate"
        (click)="modifyCategory(axisCategory.id)"
      >
      </sh-icon>
    </sh-table-cell>
    <sh-table-cell icon columns="1">
      <sh-icon
        *ngIf="!devicesService.isReleaseVersionActive()"
        button
        size="s"
        icon="delete"
        [label]="'general.delete' | translate"
        (click)="deleteCategory(axisCategory.id)"
      >
      </sh-icon>
    </sh-table-cell>
    <sh-table-cell icon columns="1">
      <sh-icon
        *ngIf="!devicesService.isReleaseVersionActive()"
        button
        size="s"
        icon="plus"
        [label]="'devices.versions.axes.addAxis' | translate"
        [routerLink]="[axisCategory.id, 'add']"
      >
      </sh-icon>
    </sh-table-cell>

    <sh-table-row
      *ngFor="let axis of axisCategory.rotationAxes"
      (contextmenu)="
        popoverService.openPopover(popovers, 'axis-menu-r-' + axis.id, $event)
      "
    >
      <sh-table-cell columns="10" [innerText]="axis.name"></sh-table-cell>
      <sh-table-cell columns="13" [innerText]="axis.key"></sh-table-cell>

      <sh-table-cell icon columns="1">
        <sh-icon
          *ngIf="!devicesService.isReleaseVersionActive()"
          button
          size="s"
          icon="edit"
          [label]="'general.edit' | translate"
          [routerLink]="[axisCategory.id, 'rotation', axis.id, 'edit']"
        >
        </sh-icon>
      </sh-table-cell>
      <sh-table-cell icon columns="1">
        <sh-icon
          *ngIf="!devicesService.isReleaseVersionActive()"
          button
          size="s"
          icon="delete"
          [label]="'general.delete' | translate"
          (click)="deleteRotationAxis(axisCategory.id, axis.id)"
        >
        </sh-icon>
      </sh-table-cell>
    </sh-table-row>

    <sh-table-row
      *ngFor="let axis of axisCategory.translationAxes"
      (contextmenu)="
        popoverService.openPopover(popovers, 'axis-menu-t-' + axis.id, $event)
      "
    >
      <sh-table-cell columns="10" [innerText]="axis.name"></sh-table-cell>
      <sh-table-cell columns="13" [innerText]="axis.key"></sh-table-cell>

      <sh-table-cell icon columns="1">
        <sh-icon
          *ngIf="!devicesService.isReleaseVersionActive()"
          button
          size="s"
          icon="edit"
          [label]="'general.edit' | translate"
          [routerLink]="[axisCategory.id, 'translation', axis.id, 'edit']"
        >
        </sh-icon>
      </sh-table-cell>
      <sh-table-cell icon columns="1">
        <sh-icon
          *ngIf="!devicesService.isReleaseVersionActive()"
          button
          size="s"
          icon="delete"
          [label]="'general.delete' | translate"
          (click)="deleteTranslationAxis(axisCategory.id, axis.id)"
        >
        </sh-icon>
      </sh-table-cell>
    </sh-table-row>

    <sh-table-row
      *ngFor="let axis of axisCategory.virtualAxes"
      (contextmenu)="
        popoverService.openPopover(popovers, 'axis-menu-v-' + axis.id, $event)
      "
    >
      <sh-table-cell columns="10" [innerText]="axis.name"></sh-table-cell>
      <sh-table-cell columns="13" [innerText]="axis.key"></sh-table-cell>

      <sh-table-cell icon columns="1">
        <sh-icon
          *ngIf="!devicesService.isReleaseVersionActive()"
          button
          icon="more"
          [id]="'axis-menu-v-' + axis.id"
        ></sh-icon>
      </sh-table-cell>

      <sh-popover
        #popover
        [target]="'axis-menu-v-' + axis.id"
        position="left-up"
      >
        <sh-menu-item
          icon="edit"
          [label]="'general.edit' | translate"
          [routerLink]="[axisCategory.id, 'virtual', axis.id, 'edit']"
        ></sh-menu-item>
        <sh-menu-item
          icon="delete"
          [label]="'general.delete' | translate"
          (click)="deleteVirtualAxis(axisCategory.id, axis.id)"
        ></sh-menu-item>
      </sh-popover>
    </sh-table-row>
  </sh-table-row>

  <sh-table-row *ngIf="axesService.axes?.length === 0">
    <sh-table-cell
      columns="24"
      [innerText]="'devices.versions.axes.empty' | translate"
    ></sh-table-cell>
  </sh-table-row>
</sh-table>

<!-- Footer -->
<div
  class="buttons"
  [ngClass]="
    displayedAxesCategory?.length ? 'position-relative' : 'position-absolute'
  "
>
  <div style="flex: 1"></div>
  <sh-button
    color="tertiary"
    slot="footer"
    size="max-content"
    [label]="'general.close' | translate"
    (click)="
      router.navigate([
        'devices',
        this.paramsService.deviceId,
        'versions',
        this.paramsService.deviceVersionId,
        'localization'
      ])
    "
    style="margin: 0px 8px"
  ></sh-button>
  <sh-button
    *ngIf="!devicesService.isReleaseVersionActive()"
    size="max-content"
    slot="footer"
    [label]="'general.release' | translate"
    style="margin: 0px 8px"
    (click)="showReleaseEquipemtModal = true"
  ></sh-button>
</div>

<backend-edit-axis-category #editAxisCategoryModal></backend-edit-axis-category>

<sh-modal
  sticky
  id="sticky-modal"
  type="warning"
  label="Release Draft Version"
  modal-height="250px"
  modal-width="500px"
  [visible]="showReleaseEquipemtModal"
>
  You have selected draft version to release. This shall release the draft
  equipment to production. Any existing released equipment will be overwritten.
  <br />
  <br />
  Do you want to continue?
  <sh-button
    color="secondary"
    slot="footer"
    label="No"
    (click)="showReleaseEquipemtModal = false"
  ></sh-button>
  <sh-button slot="footer" label="Yes" (click)="releaseEquipment()"></sh-button>
</sh-modal>
