import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public constructor() {
    const lsTheme = localStorage.getItem('theme');
    if (lsTheme === Theme.Dark) {
      this.theme = Theme.Dark;
    } else {
      this.theme = Theme.Light;
    }
  }

  private _theme: Theme = Theme.Light;
  public get theme(): Theme {
    return this._theme;
  }
  public set theme(value: Theme) {
    this._theme = value;
    localStorage.setItem('theme', value);
  }

  public toggle(): void {
    if (this.theme === Theme.Light) {
      this.theme = Theme.Dark;
    } else {
      this.theme = Theme.Light;
    }
  }
}

enum Theme {
  Light = 'light',
  Dark = 'dark',
}
