<sh-modal
  id="footer-modal"
  sticky
  modal-height="fit-content"
  [visible]="visible"
  [label]="
    (isEditMode ? 'adminusers.editUser' : 'adminusers.addUser') | translate
  "
>
  <sh-text [innerText]="'adminusers.addUserDetail' | translate"></sh-text>
  <sh-input-text
    [value]="siemensId"
    (value-changed)="siemensId = $event.target.value"
    #nameTextbox
    mandatory
    [label]="'adminusers.siemensId' | translate"
  ></sh-input-text>
  <sh-input-text
    [value]="firstName"
    (value-changed)="firstName = $event.target.value"
    #firstNameTextbox
    mandatory
    [label]="'adminusers.firstName' | translate"
  ></sh-input-text>
  <sh-input-text
    [value]="lastName"
    (value-changed)="lastName = $event.target.value"
    #lastNameTextbox
    mandatory
    [label]="'adminusers.lastName' | translate"
  ></sh-input-text>
  <sh-dropdown
    #inputRole
    mandatory
    rows="1"
    [value]="role"
    [label]="'adminusers.role' | translate"
    (value-changed)="role = $event.target.value"
  >
    <sh-menu-item *ngFor="let r of roles" [label]="r"></sh-menu-item>
  </sh-dropdown>
  <sh-button
    slot="footer"
    size="m"
    color="secondary"
    [label]="'general.cancel' | translate"
    (click)="cancel()"
  ></sh-button>
  <sh-button
    slot="footer"
    size="m"
    [label]="'general.ok' | translate"
    [disabled]="
      !nameTextbox.value ||
      !firstNameTextbox.value ||
      !lastNameTextbox.value ||
      !inputRole.value
    "
    (click)="close()"
  ></sh-button>
</sh-modal>
