import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { WorkflowVersionService } from './workflow-version.service';
import { LanguageService } from '../../../services/language.service';
import { PopoverService } from '../../../services/popover.service';
import { Status } from '@backend/interfaces';
import { WorkflowGuidesService } from '../../workflowguides.service';
import { ParamsService } from '@backend/webapp/services/params.service';
import { pairwise } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'backend-workflow-versions',
  templateUrl: './workflow-versions.component.html',
  styleUrls: ['./workflow-versions.component.scss'],
})
export class WorkflowVersionsComponent implements OnInit, OnDestroy {
  public workflowVersions = this.workflowVersionService.workflowVersions;

  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  paramSubscription: Subscription;

  public constructor(
    public readonly languageService: LanguageService,
    public readonly popoverService: PopoverService,
    public readonly workflowVersionService: WorkflowVersionService,
    public readonly router: Router,
    private readonly paramsService: ParamsService,
    public readonly linksService: WorkflowGuidesService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.workflowVersionService.getWorkflowVersions();
    this.workflowVersions = this.workflowVersionService.workflowVersions;
    this.paramSubscription = this.paramsService.workflowId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          await this.workflowVersionService.getWorkflowVersions();
          this.workflowVersions = this.workflowVersionService.workflowVersions;
        }
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }

  public deleteWorkflowVersion(id: number) {
    this.workflowVersionService.deleteWorkflowVersion(id);
    this.linksService.loadWfLinks();
  }

  getStatus(status: number): string {
    return Status[status];
  }
}
