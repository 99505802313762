<sh-modal
  id="footer-modal"
  sticky
  modal-height="fit-content"
  modal-width="700px"
  [visible]="reportService.visible"
  [label]="reportService.title | translate"
  [icon]="
    reportService.type === 1
      ? 'success'
      : reportService.type === 2
      ? 'information'
      : 'error'
  "
>
  <sh-progress
    *ngIf="reportService.type === 2"
    [header]="'report.loadingHeader' | translate"
    [info]="'report.loadingInfo' | translate"
    indeterminate
    style="margin: 32px 0"
  ></sh-progress>

  <sh-text
    *ngIf="reportService.message"
    style="width: 100%; background-color: rgba(0, 0, 0, 0.1)"
  >
    <sh-text
      [innerText]="
        '\n' + reportService.message | translate : reportService.messageParams
      "
    ></sh-text>
  </sh-text>

  <sh-text
    *ngIf="reportService.errors !== null"
    slot="footer"
    size="body-2"
    [innerText]="'Copied with ' + reportService.errors + ' errors.'"
  ></sh-text>

  <sh-button
    *ngIf="reportService.type !== 2"
    slot="footer"
    size="m"
    color="secondary"
    [label]="'general.close' | translate"
    data-toggle="tooltip"
    id="close"
    (click)="reportService.close()"
  ></sh-button>

  <sh-tooltip
    target="close"
    label="The report is not saved and will be removed after closing."
  ></sh-tooltip>
</sh-modal>
