import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable, from, lastValueFrom } from 'rxjs';
import { ConfirmationService } from '../shared/confirmation/confirmation.service';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public constructor(
    public auth: AuthService,
    private readonly confirmation: ConfirmationService,
    private readonly spinner: SpinnerService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handle(request, next));
  }

  private async handle(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Promise<HttpEvent<any>> {
    const token = await lastValueFrom(this.auth.getTokenSilently$());
    if (request.headers && request.headers.has('valAPI'))
      return lastValueFrom(next.handle(request));

    // Handle Token Expiration before handling the request
    if (new Date() > this.auth.expiresOn) {
      this.spinner.hideSpinner();
      if (
        await this.confirmation.confirmWarn(
          'Session Expired',
          'Your Login session has expired! To continue working, please login again.'
        )
      ) {
        this.auth.logout();
        this.auth.login();
        this.auth.redirectAfterLogin();
      }
    }
    request = request.clone({
      setHeaders: {
        Authorization: token,
      },
    });
    return lastValueFrom(next.handle(request));
  }
}
