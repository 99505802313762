import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SpinnerService } from '../spinner/spinner.service';
import { LanguageService } from '../services/language.service';
import { ConfirmationService } from '../shared/confirmation/confirmation.service';
import { NotificationService } from '../shared/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class PageService {
  private $pagesGateway: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public get $pages(): Observable<any[]> {
    return this.$pagesGateway.asObservable();
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly spinner: SpinnerService,
    private readonly languageService: LanguageService,
    private readonly confirmationService: ConfirmationService,
    private readonly notificationService: NotificationService
  ) {
    this.getAllPages();
  }

  public async getAllPages(): Promise<boolean> {
    this.spinner.showSpinner();
    try {
      this.$pagesGateway.next(
        await lastValueFrom(this.http.get<any[]>('/api/pages'))
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'pages.getError',
        null,
        error.error.message
      );
      return false;
    } finally {
      this.spinner.hideSpinner();
    }
  }

  public getPageById(id: number): Observable<any> {
    return this.$pagesGateway.pipe(
      map((pages) => pages.find((page) => page.id === parseInt(id as any, 10)))
    );
  }

  public async editPageContent(pageId: number, content: string): Promise<any> {
    this.spinner.showSpinner();
    try {
      await lastValueFrom(
        this.http.put(
          `/api/pages/${pageId}/content/${this.languageService.guiLanguageCode}`,
          { content: content }
        )
      );
    } catch (error) {
      this.spinner.hideSpinner();
    } finally {
      await this.getAllPages();
      this.spinner.hideSpinner();
    }
  }

  public async createPage(
    pageNameInternal: string,
    pageName: string
  ): Promise<any> {
    this.spinner.showSpinner();
    try {
      const page: any = await lastValueFrom(
        this.http.post('/api/pages', { name: pageNameInternal })
      );
      await lastValueFrom(
        this.http.put(
          `/api/pages/${page.id}/title/${this.languageService.guiLanguageCode}`,
          { title: pageName }
        )
      );
    } catch (error) {
      this.spinner.hideSpinner();
    } finally {
      await this.getAllPages();
      this.spinner.hideSpinner();
    }
  }

  public async deletePage(pageId: number): Promise<any> {
    if (
      await this.confirmationService.confirmDelete(
        'pages.deletePage',
        'pages.deletePageConfirmation'
      )
    ) {
      this.spinner.showSpinner();
      try {
        await lastValueFrom(this.http.delete(`/api/pages/${pageId}`));
      } catch (error) {
        this.spinner.hideSpinner();
      } finally {
        await this.getAllPages();
        this.spinner.hideSpinner();
      }
    }
  }

  public async editPage(
    pageId: number,
    pageNameInternal: string,
    pageName: string
  ): Promise<any> {
    this.spinner.showSpinner();
    try {
      await lastValueFrom(
        this.http.put(`/api/pages/${pageId}/`, { name: pageNameInternal })
      );
      await lastValueFrom(
        this.http.put(
          `/api/pages/${pageId}/title/${this.languageService.guiLanguageCode}`,
          { title: pageName }
        )
      );
    } catch (error) {
      this.spinner.hideSpinner();
    } finally {
      await this.getAllPages();
      this.spinner.hideSpinner();
    }
  }
}
