import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { NotificationService } from '../shared/notification/notification.service';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class SitesService {
  private sitesSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public get $sites(): Observable<any[]> {
    return this.sitesSubject.asObservable();
  }
  public get sites(): any[] {
    return this.sitesSubject.value;
  }

  private assignedSitesSubject: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);
  public get $assignedSites(): Observable<any[]> {
    return this.assignedSitesSubject.asObservable();
  }

  appUserEmail: string;

  public constructor(
    private readonly http: HttpClient,
    private readonly spinnerService: SpinnerService,
    private readonly notificationService: NotificationService
  ) {}

  public async updateSites() {
    await this.getAssignedSites(this.appUserEmail);
    await this.getAllSites();
  }

  public isSiteAssignedToUser(user: string, site: any): boolean {
    return (
      site.assignedUsers !== null &&
      site.assignedUsers.length > 0 &&
      site.assignedUsers.includes(user)
    );
  }

  public async getAssignedSites(user: string): Promise<any> {
    this.spinnerService.showSpinner();
    try {
      if (user) {
        this.assignedSitesSubject.next(
          await lastValueFrom(
            this.http.get<any[]>('/api/val/getAssignedSites?user=' + user)
          )
        );
      }
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.sitesFetchError',
        null,
        error.error.message
      );
    }
    this.spinnerService.hideSpinner();
  }

  public async getAllSites(): Promise<void> {
    try {
      this.sitesSubject.next(
        await lastValueFrom(this.http.get<any[]>('/api/val/getAllSites'))
      );
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.sitesFetchError',
        null,
        error.error.message
      );
    }
  }

  public async assignSiteToUser(user: string, site: string): Promise<void> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.post('/api/val/assignSite', { user: user, site: site })
      );
      this.notificationService.confirmation(
        'general.success',
        'appusers.siteAssignSuccess'
      );
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.siteAssignError',
        null,
        error.error.message
      );
    }
    this.spinnerService.hideSpinner();
    await this.getAssignedSites(this.appUserEmail);
  }

  public async removeSiteFromUser(user: string, site: string): Promise<void> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.post('/api/val/removeSite', { user: user, site: site })
      );
      this.notificationService.confirmation(
        'general.success',
        'appusers.siteRemoveSuccess'
      );
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.siteRemoveError',
        null,
        error.error.message
      );
    }
    this.spinnerService.hideSpinner();
    await this.getAssignedSites(this.appUserEmail);
  }

  public async removeUser(user: string): Promise<void> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(this.http.delete('/api/val/removeUser/' + user));
    } catch (error) {
      console.log(error);
    }
    this.spinnerService.hideSpinner();
    await this.getAssignedSites(this.appUserEmail);
  }


  public async registerWebhook(): Promise<void> {
    try {
      const isRegistered = await lastValueFrom(
        this.http.get('/api/val/registerWebhook')
      );
      if (isRegistered) {
        console.info('Webhook registered successfully.');
      } else {
        console.error('Webhook registration failed.');
      }
    } catch (error) {
      console.error('Webhook registration failed.\n' + error.error.message);
    }
  }
}
