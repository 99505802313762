<div>
  <sh-table>
    <sh-table-row>
      <sh-table-cell unsortable columns="4">
        <sh-text
          size="title-1"
          [innerText]="
            'workflowguides.workflows.workflowVersions.name' | translate
          "
        ></sh-text>
      </sh-table-cell>
      <sh-table-cell unsortable columns="4">
        <sh-text
          size="title-1"
          [innerText]="
            'workflowguides.workflows.workflowVersions.version' | translate
          "
        ></sh-text>
      </sh-table-cell>
      <sh-table-cell unsortable columns="4">
        <sh-text
          size="title-1"
          [innerText]="
            'workflowguides.workflows.workflowVersions.appVersion' | translate
          "
        ></sh-text>
      </sh-table-cell>
      <sh-table-cell unsortable columns="3">
        <sh-text
          size="title-1"
          [innerText]="
            'workflowguides.workflows.workflowVersions.arSupport' | translate
          "
        ></sh-text>
      </sh-table-cell>
      <sh-table-cell unsortable columns="4">
        <sh-text
          size="title-1"
          [innerText]="
            'workflowguides.workflows.workflowVersions.simulationScene'
              | translate
          "
        ></sh-text>
      </sh-table-cell>
      <sh-table-cell unsortable columns="4">
        <sh-text
          size="title-1"
          [innerText]="
            'workflowguides.workflows.workflowVersions.simulationProduct'
              | translate
          "
        ></sh-text>
      </sh-table-cell>
      <sh-table-cell unsortable columns="4">
        <sh-text
          size="title-1"
          [innerText]="
            'workflowguides.workflows.workflowVersions.status' | translate
          "
        ></sh-text>
      </sh-table-cell>
      <sh-table-cell unsortable columns="3">
        <sh-text
          size="title-1"
          [innerText]="
            'workflowguides.workflows.workflowVersions.forceUpdate' | translate
          "
        ></sh-text>
      </sh-table-cell>
      <sh-table-cell unsortable icon columns="1"></sh-table-cell>
    </sh-table-row>

    <ng-container *ngIf="workflowVersions">
      <sh-table-row
        *ngFor="let workflowVersion of workflowVersions | async"
        (contextmenu)="
          popoverService.openPopover(
            popovers,
            'wv-menu-' + workflowVersion.id,
            $event
          )
        "
        (click)="popoverService.goToRoute(popovers, [workflowVersion.id])"
      >
        <sh-table-cell columns="4">
          <p class="flow-text" [innerText]="workflowVersion.name"></p>
        </sh-table-cell>
        <sh-table-cell columns="4">
          <p class="flow-text" [innerText]="workflowVersion.version"></p>
        </sh-table-cell>
        <sh-table-cell columns="4">
          <p class="flow-text" [innerText]="workflowVersion.appVersion"></p>
        </sh-table-cell>
        <sh-table-cell columns="3">
          <sh-checkbox
            disabled
            [active]="workflowVersion.arSupport"
            [label]="''"
          ></sh-checkbox>
        </sh-table-cell>
        <sh-table-cell columns="4">
          <p
            class="flow-text"
            [innerText]="workflowVersion.simulationScene"
          ></p>
        </sh-table-cell>
        <sh-table-cell columns="4">
          <p
            class="flow-text"
            [innerText]="workflowVersion.simulationProduct"
          ></p>
        </sh-table-cell>
        <sh-table-cell columns="4">
          <p
            class="flow-text"
            [innerText]="getStatus(workflowVersion.status)"
          ></p>
        </sh-table-cell>
        <sh-table-cell columns="3">
          <sh-checkbox
            disabled
            [active]="workflowVersion.forceUpdate"
            [label]="''"
          ></sh-checkbox>
        </sh-table-cell>
        <sh-table-cell icon columns="1">
          <sh-icon
            button
            icon="more"
            [id]="'wv-menu-' + workflowVersion.id"
            (click)="
              popoverService.preventDefault($event);
              popoverService.openPopover(
                popovers,
                'wv-menu-' + workflowVersion.id,
                $event
              )
            "
          >
          </sh-icon>
        </sh-table-cell>
        <sh-popover
          #popover
          [target]="'wv-menu-' + workflowVersion.id"
          position="left-up"
        >
          <sh-menu-item
            icon="edit"
            [label]="'general.edit' | translate"
            [routerLink]="[workflowVersion.id]"
          >
          </sh-menu-item>
          <sh-menu-item
            icon="delete"
            [label]="'general.delete' | translate"
            (click)="
              deleteWorkflowVersion(workflowVersion.id)
            "
          ></sh-menu-item>
        </sh-popover>
      </sh-table-row>
    </ng-container>

    <sh-table-row *ngIf="(workflowVersions | async).length === 0">
      <sh-table-cell columns="5"></sh-table-cell>
      <sh-table-cell
        columns="19"
        [innerText]="
          'workflowguides.workflows.workflowVersions.empty' | translate
        "
      >
      </sh-table-cell>
    </sh-table-row>
  </sh-table>

  <div class="buttons">
    <sh-button
      size="m"
      [label]="'general.add' | translate"
      [routerLink]="'add'"
    ></sh-button>
  </div>
</div>
