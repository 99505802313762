<div>
  <sh-text
    size="title-1"
    [innerHTML]="
      (isEditMode
        ? 'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.edit'
        : 'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.add'
      ) | translate
    "
  ></sh-text>

  <div class="content">
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.type'
            | translate
        "
      ></sh-text>
      <sh-dropdown
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        mandatory
        [disabled]="isEditMode"
        [value]="hotspotType"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.type'
            | translate
        "
        (value-changed)="hotspotType = $event.target.value"
      >
        <sh-menu-item value="Icon" label="Icon"></sh-menu-item>
        <sh-menu-item value="Frame" label="Frame"></sh-menu-item>
      </sh-dropdown>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.text'
            | translate
        "
      ></sh-text>
      <sh-input-text
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        textarea
        rows="6"
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.text'
            | translate
        "
        [value]="textTrans"
        (value-changed)="text = $event.target.value"
      ></sh-input-text>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.interactable'
            | translate
        "
      ></sh-text>
      <sh-checkbox
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        [active]="interactableInitial"
        (click)="interactable = !interactable"
        label=""
      ></sh-checkbox>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.position'
            | translate
        "
      ></sh-text>
      <sh-input-number
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        mandatory
        no-arrows
        min="0"
        step="1"
        class="position-box"
        [label]="'X'"
        [value]="x"
        (value-changed)="x = $event.target.value"
      ></sh-input-number>
      <sh-input-number
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        mandatory
        no-arrows
        min="0"
        step="1"
        class="position-box"
        [label]="'Y'"
        [value]="y"
        (value-changed)="y = $event.target.value"
      ></sh-input-number>
    </div>
    <div
      class="content-row"
      [style.display]="hotspotType === 'Icon' ? 'flex' : 'none'"
    >
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.glyph'
            | translate
        "
      ></sh-text>
      <sh-dropdown
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        [value]="glyphValue"
        mandatory
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.glyph'
            | translate
        "
        (value-changed)="glyphValue = $event.target.value"
      >
        <sh-menu-item
          [label]="glyph"
          *ngFor="let glyph of iconGlyphs"
        ></sh-menu-item>
      </sh-dropdown>
    </div>
    <div
      class="content-row"
      [style.display]="hotspotType === 'Icon' ? 'flex' : 'none'"
    >
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.rotation'
            | translate
        "
      ></sh-text>
      <sh-input-number
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        no-arrows
        min="0"
        max="360"
        mandatory
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.rotation'
            | translate
        "
        [value]="rotation"
        (value-changed)="rotation = $event.target.value"
      ></sh-input-number>
    </div>
    <div
      class="content-row"
      [style.display]="hotspotType === 'Frame' ? 'flex' : 'none'"
    >
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.width'
            | translate
        "
      ></sh-text>
      <sh-input-number
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        no-arrows
        min="0"
        step="1"
        mandatory
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.width'
            | translate
        "
        [value]="width"
        (value-changed)="width = $event.target.value"
      ></sh-input-number>
    </div>
    <div
      class="content-row"
      [style.display]="hotspotType === 'Frame' ? 'flex' : 'none'"
    >
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.height'
            | translate
        "
      ></sh-text>
      <sh-input-number
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        no-arrows
        min="0"
        step="1"
        mandatory
        [label]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.height'
            | translate
        "
        [value]="height"
        (value-changed)="height = $event.target.value"
      ></sh-input-number>
    </div>
  </div>

  <div class="buttons" *ngIf="!workflowGuidesService.isReleaseVersionActive()">
    <sh-button
      [disabled]="!canSave()"
      size="m"
      (click)="save()"
      [label]="'general.save' | translate"
    ></sh-button>
    <sh-button
      color="secondary"
      (click)="cancel()"
      size="m"
      [label]="'general.cancel' | translate"
    ></sh-button>
  </div>
</div>
