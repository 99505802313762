import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { NotificationService } from '../shared/notification/notification.service';
import { SpinnerService } from '../spinner/spinner.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ValTokenService {
  private $valTokenSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public get $valToken(): Observable<any[]> {
    return this.$valTokenSubject.asObservable();
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly spinnerService: SpinnerService,
    private readonly notificationService: NotificationService
  ) {}

  setToken(token) {
    localStorage.setItem('valToken', JSON.stringify(token));
    localStorage.setItem('valTokenStoredAt', new Date().toISOString());
  }

  getToken() {
    return JSON.parse(localStorage.getItem('valToken'));
  }

  isTokenAvailable(): boolean {
    if (
      localStorage.getItem('valToken') !== 'null' &&
      localStorage.getItem('valToken') !== undefined &&
      localStorage.getItem('valToken') !== null
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearToken() {
    localStorage.setItem('valToken', null);
  }

  public async getAccessToken(): Promise<any> {
    this.spinnerService.showSpinner();
    try {
      this.$valTokenSubject.next(
        await lastValueFrom(this.http.get<any>('/api/val/getToken'))
      );
      this.$valToken.subscribe((response) => {
        this.setToken(response);
      });
      return this.getToken();
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'val.getError',
        null,
        error.error.message
      );
      return error;
    } finally {
      this.spinnerService.hideSpinner();
    }
  }
}
