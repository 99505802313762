import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { HttpClientModule } from '@angular/common/http';

import { SharedModule } from '../../../shared/shared.module';
import { DetailsRoutingModule } from './details-routing.module';

import { FeaturesComponent } from './content/features/features.component';
import { AssetsComponent } from './content/assets/assets.component';
import { EditAssetsComponent } from './content/assets/edit-assets/edit-assets.component';
import { DeviceLocalizationComponent } from './content/device-localization/device-localization.component';
import { AxesComponent } from './content/axes/axes.component';
import { EditAxisCategoryComponent } from './content/axes/modals/edit-axis-category/edit-axis-category.component';
import { EditAxisComponent } from './content/axes/edit-axis/edit-axis.component';
import { HotspotsComponent } from './content/hotspots/hotspots.component';
import { DetailsComponent } from './details.component';
import { EditFeatureComponent } from './content/features/edit-feature/edit-feature.component';
import { EditHotspotComponent } from './content/hotspots/edit-hotspot/edit-hotspot.component';
import { EditScreenComponent } from './content/hotspots/hotspot-screens/edit-screen/edit-screen.component';
import { EditDetailsComponent } from './content/hotspots/edit-details/edit-details.component';
import { HotspotsCollectionComponent } from './content/hotspots/hotspots-collection/hotspots-collection/hotspots-collection.component';
import { HotspotScreensComponent } from './content/hotspots/hotspot-screens/hotspot-screens.component';
import { ScreenHotspotsComponent } from './content/hotspots/hotspot-screens/screen-hotspots/screen-hotspots.component';
import { HotspotsRightPaneComponent } from './content/hotspots/hotspot-screens/hotspots-right-pane/hotspots-right-pane.component';
import { TargetScreenModalComponent } from './content/hotspots/edit-details/modals/target-screen-modal/target-screen-modal.component';

@NgModule({
  declarations: [
    DetailsComponent,
    AssetsComponent,
    EditAssetsComponent,
    AxesComponent,
    EditAxisCategoryComponent,
    EditAxisComponent,
    DeviceLocalizationComponent,
    FeaturesComponent,
    EditFeatureComponent,
    HotspotsComponent,
    EditHotspotComponent,
    EditScreenComponent,
    EditDetailsComponent,
    HotspotsCollectionComponent,
    HotspotScreensComponent,
    ScreenHotspotsComponent,
    HotspotsRightPaneComponent,
    TargetScreenModalComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    RouterModule,
    DetailsRoutingModule,
    HttpClientModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DetailsModule {}
