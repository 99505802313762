import { Component } from '@angular/core';
import { Constants } from '@backend/interfaces';

@Component({
  selector: 'backend-user-guide',
  templateUrl: './user-guide.component.html',
  styleUrls: ['./user-guide.component.css'],
})
export class UserGuideComponent {
  public userGuide: any[] = Constants.USER_GUIDE;
  public selected = Constants.USER_GUIDE[0];
}
