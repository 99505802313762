import { BusinessLine } from '@backend/interfaces';

export class BusinessLineUtility {
  public static businessLines: Array<{ name: string; value: BusinessLine }> =
    null;

  // Get Business Line Names
  public static getBusinessLinesNames() {
    return Object.keys(BusinessLine);
  }

  // Get Business Line as name and value pair
  private static getBusinessLines() {
    if (BusinessLineUtility.businessLines !== null) {
      return BusinessLineUtility.businessLines;
    }

    BusinessLineUtility.businessLines = Object.keys(BusinessLine).map(
      (name) => {
        return {
          name,
          value: BusinessLine[name as keyof typeof BusinessLine],
        };
      }
    );

    return BusinessLineUtility.businessLines;
  }

  // Get Business Line Name
  public static getBusinessLineName(value: string): string {
    const businessLines = BusinessLineUtility.getBusinessLines();
    return businessLines.find((x) => x.value === value).name;
  }

  // Get Business Line enum value
  public static getBusinessLine(name: string): BusinessLine {
    const businessLines = BusinessLineUtility.getBusinessLines();
    return businessLines.find((x) => x.name === name).value;
  }
}
