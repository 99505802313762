import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private showSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public get Show(): Observable<boolean> {
    return this.showSubject.asObservable();
  }
  private messageSubject: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  public get Message(): Observable<string> {
    return this.messageSubject.asObservable();
  }

  public showSpinner(message?: string) {
    this.showSubject.next(true);
    this.messageSubject.next(message);
  }

  public hideSpinner() {
    this.showSubject.next(false);
  }
}
