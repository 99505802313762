import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

import { PageService } from '../page.service';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'backend-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit, OnDestroy {
  public text = '';
  private sub: Subscription = new Subscription();
  private pageId: number;

  public constructor(
    private readonly pageService: PageService,
    private readonly router: ActivatedRoute,
    private readonly route: Router,
    private readonly languageService: LanguageService
  ) {}

  public ngOnInit(): void {
    this.sub.add(
      this.router.params.subscribe((params) => {
        this.pageId = params['pageId'];
        this.sub.add(
          this.pageService
            .getPageById(params['pageId'])
            .subscribe((page: any) => {
              if (page && page.contents && page.contents.length > 0) {
                const content = page.contents.find(
                  (x) => x.languageKey === this.languageService.guiLanguageCode
                );
                if (content) {
                  this.text = content.value;
                }
              }
            })
        );
      })
    );
  }

  public ngOnDestroy() {
    this.sub.unsubscribe();
  }

  public async save() {
    await this.pageService.editPageContent(this.pageId, this.text);
    this.route.navigate(['/', 'pages']);
  }
}
