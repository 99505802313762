<div class="search-header">
  <sh-search
    size="body-2"
    class="search-box"
    [label]="'general.search' | translate"
    [value]="searchScreenString"
    (value-changed)="onSearchScreenString($event.target.value)"
  ></sh-search>
  <sh-button
    *ngIf="!devicesService.isReleaseVersionActive()"
    size="max-content"
    [label]="'devices.versions.hotspots.screens.addScreen' | translate"
    [routerLink]="'add'"
  ></sh-button>
</div>
<div class="container">
  <div class="left-pane">
    <sh-table>
      <sh-table-row
        *ngFor="let screen of displayedScreens"
        (click)="onScreenSelection(screen.id)"
        (dblclick)="gotoScreenHotspots(screen.id)"
      >
        <sh-table-cell columns="19">
          <p class="flow-text" [innerText]="screen.key"></p>
        </sh-table-cell>
        <sh-table-cell columns="5">
          <img class="thumbnailImg" [src]="screen.image | s3Image | async" />
        </sh-table-cell>
        <sh-table-cell icon columns="1">
          <sh-icon
            *ngIf="!devicesService.isReleaseVersionActive()"
            button
            size="s"
            icon="delete"
            (click)="
              deleteScreen(hotspot?.id, screen?.id, screen?.key);
              $event.stopPropagation()
            "
          >
          </sh-icon>
        </sh-table-cell>
        <sh-table-cell icon columns="1">
          <sh-icon
            *ngIf="!devicesService.isReleaseVersionActive()"
            button
            size="s"
            icon="edit"
            [routerLink]="[screen.id, 'edit']"
          >
          </sh-icon>
        </sh-table-cell>
      </sh-table-row>
    </sh-table>
  </div>
  <div class="right-pane">
    <backend-hotspots-right-pane
      [selectedScreen]="selectedScreen"
    ></backend-hotspots-right-pane>
  </div>
</div>

<!-- Footer -->
<div class="buttons">
  <div style="flex: 1"></div>
  <sh-button
    color="tertiary"
    slot="footer"
    size="max-content"
    [label]="'general.close' | translate"
    routerLink="../.."
    style="margin: 0px 8px"
  ></sh-button>
</div>
