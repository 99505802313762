<div>
  <sh-table>
    <sh-table-row>
      <sh-table-head
        unsortable
        columns="3"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.name' | translate
        "
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="6"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.description'
            | translate
        "
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="2"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.ar' | translate
        "
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="2"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.version'
            | translate
        "
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="4"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.versionDescription'
            | translate
        "
      >
      </sh-table-head>
      <sh-table-head
        unsortable
        columns="4"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.thumbnail'
            | translate
        "
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="2"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.status' | translate
        "
      ></sh-table-head>
      <sh-table-head unsortable icon columns="1"></sh-table-head>
    </sh-table-row>

    <ng-container *ngIf="phaseVersions">
      <sh-table-row
        *ngFor="let phaseVersion of phaseVersions"
        (contextmenu)="
          popoverService.openPopover(
            popovers,
            'pv-menu-' + phaseVersion.id,
            $event
          )
        "
        (click)="popoverService.goToRoute(popovers, [phaseVersion.id])"
      >
        <sh-table-cell columns="3">
          <p
            class="flow-text"
            [innerText]="
              phaseVersion.name | getLocaleText : globalLanguage?.key : true
            "
          ></p>
        </sh-table-cell>

        <sh-table-cell columns="6">
          <p
            class="flow-text"
            [innerText]="
              phaseVersion.description
                | getLocaleText : globalLanguage?.key : true
            "
          ></p>
        </sh-table-cell>

        <sh-table-cell columns="2">
          <sh-checkbox [active]="phaseVersion.ar" [label]="''"></sh-checkbox>
        </sh-table-cell>

        <sh-table-cell columns="2">
          <p class="flow-text" [innerText]="phaseVersion.version"></p>
        </sh-table-cell>

        <sh-table-cell columns="4">
          <p
            class="flow-text"
            [innerText]="phaseVersion.versionDescription"
          ></p>
        </sh-table-cell>

        <sh-table-cell columns="4">
          <img
            *ngIf="phaseVersion.thumbnail"
            [src]="phaseVersion.thumbnail | s3Image | async"
          />
        </sh-table-cell>

        <sh-table-cell columns="2">
          <p
            class="flow-text"
            [innerText]="getStatusString(phaseVersion.status)"
          ></p>
        </sh-table-cell>

        <sh-table-cell icon columns="1">
          <sh-icon
            button
            icon="more"
            [id]="'pv-menu-' + phaseVersion.id"
            (click)="
              popoverService.preventDefault($event);
              popoverService.openPopover(
                popovers,
                'pv-menu-' + phaseVersion.id,
                $event
              )
            "
          >
          </sh-icon>
        </sh-table-cell>

        <sh-popover
          #popover
          [target]="'pv-menu-' + phaseVersion.id"
          position="left-up"
        >
          <sh-menu-item
            icon="edit"
            [label]="'general.edit' | translate"
            [routerLink]="[phaseVersion.id]"
          >
          </sh-menu-item>
          <sh-menu-item
            icon="delete"
            [label]="'general.delete' | translate"
            (click)="deletePhaseVersion(phaseVersion.id, phaseVersion.name)"
          ></sh-menu-item>
        </sh-popover>
      </sh-table-row>
    </ng-container>

    <sh-table-row *ngIf="phaseVersions?.length === 0">
      <sh-table-cell columns="5"></sh-table-cell>
      <sh-table-cell
        columns="19"
        [innerText]="
          'workflowguides.phases.phaseWrappers.phaseVersions.empty' | translate
        "
      >
      </sh-table-cell>
    </sh-table-row>
  </sh-table>
  <div class="buttons">
    <sh-button
      size="m"
      [label]="'general.add' | translate"
      [routerLink]="'add'"
    ></sh-button>
  </div>
</div>
