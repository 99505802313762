import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { LanguageService } from '@backend/webapp/services/language.service';
import { SitesService } from '@backend/webapp/siteplanner/sites.service';
import { Observable, Subscription } from 'rxjs';

import { DevicesService } from '../../../devices/devices.service';
import { WorkflowService } from '../../../workflowguides/workflows/workflow.service';
import { AppUsersService } from '../../appuser.service';
import {
  Constants,
  LicenseCategory,
  PCM,
  TableType,
} from '@backend/interfaces';
import * as moment from 'moment';
import { PopoverService } from '@backend/webapp/services/popover.service';
import { EditUserInfoService } from './edit-user-info.service';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { Router } from '@angular/router';
import { LicensePackageService } from '@backend/webapp/license-package/license-package.service';

@Component({
  selector: 'backend-appuser-settings',
  templateUrl: './appuser-settings.component.html',
  styleUrls: ['./appuser-settings.component.scss'],
})
export class AppUserSettingsComponent implements OnInit, OnDestroy {
  @Output() closeEmitted = new EventEmitter<boolean>();
  @Output() updateUserInfo = new EventEmitter<string>();

  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  public $devices: Observable<any>;
  public $workflows: Observable<any>;
  public $sites: Observable<any>;
  public $assignedSites: Observable<any>;

  public open = false;
  public user = '';
  public email = '';
  public currentTab = 'userinfo';
  public subTab = 'betaDevices';
  public tableTypeEnum = TableType;
  public pcmEnum = PCM;
  public durationValues = [30, 60, 90, 120, 365];
  public isEditable: boolean[] = [];
  public duration: number[] = [];
  public licenseStart: string[] = [];
  public dateFormat = 'YYYY/MM/DD';
  public isClicked: boolean[] = [];
  public isChecked: boolean[] = [];
  public magnusChecked: boolean[] = [];
  public siemensChecked: boolean[] = [];
  public pcm1Checked: boolean[] = [];
  public pcm2Checked: boolean[] = [];

  public tempDuration: number[] = [];
  public tempStartDate: string[] = [];
  private searchTerm = '';
  private _filteredSites: any[];

  public get filteredSites(): any[] {
    return this._filteredSites;
  }

  public organization = '';
  public businessLine = '';
  public role = '';
  public country = '';
  public region = '';
  public updateAll = false;
  public orgVal = '';
  public businessLineVal = '';
  public roleVal = '';
  public countryVal = '';
  public regionVal = '';

  allUsers: any[];

  public currPage = 1;

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  languageSubscription: Subscription;

  public constructor(
    private devicesService: DevicesService,
    public appUsersService: AppUsersService,
    private workflowService: WorkflowService,
    private sitesService: SitesService,
    public languageService: LanguageService,
    public popoverService: PopoverService,
    private editUserInfoService: EditUserInfoService,
    public router: Router,
    private localization: LocalizationService,
    private licensePackageService: LicensePackageService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.currentTab = 'userinfo';
    this.subTab = 'betaDevices';
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        this.globalLanguage = data;
      }
    );

    this.$devices = this.devicesService.devices$;
    this.$workflows = this.workflowService.workflows;
    this.$sites = this.sitesService.$sites;
    this.$assignedSites = this.sitesService.$assignedSites;
    this.$sites.subscribe((sites: any[]) => (this._filteredSites = sites));
    this.isEditable = [];
    this.duration = [];
    this.licenseStart = [];
    this.isClicked = [];
    this.isChecked = [];
    this.magnusChecked = [];
    this.siemensChecked = [];
    this.tempDuration = [];
    this.tempStartDate = [];
    this.updateAll = false;
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  public onSearch(searchTerm: string = ''): void {
    this.$sites.subscribe((sites: any[]) => (this._filteredSites = sites));
    this.searchTerm = searchTerm;
    if (searchTerm) {
      const lcSearchTerm = this.searchTerm.toLowerCase();
      this._filteredSites = this._filteredSites.filter(
        (site) => site.siteName.toLowerCase().indexOf(lcSearchTerm) !== -1
      );
    }
  }

  public select(
    checked: boolean,
    device: string,
    licenseStart: string,
    licenseDuration: number,
    siemens: boolean,
    magnus: boolean,
    pcm1: boolean,
    pcm2: boolean,
    saveToBeta: boolean
  ): void {
    const hasDevice = this.appUsersService.userHasDevice(this.user, device);
    if (checked === undefined || checked === null) {
      checked = hasDevice;
    }
    if (checked) {
      if (!hasDevice && this.appUsersService.hasPheno(device)) {
        this.addDeviceToUser(
          this.tableTypeEnum.Magnus,
          device,
          licenseStart,
          licenseDuration,
          true,
          true,
          true,
          true,
          saveToBeta,
          checked
        );
      } else {
        this.addDeviceToUser(
          this.tableTypeEnum.Siemens,
          device,
          licenseStart,
          licenseDuration,
          siemens,
          magnus,
          pcm1,
          pcm2,
          saveToBeta,
          checked
        );
      }
    } else {
      this.appUsersService.removeDeviceFromUser(this.user, device, null);
    }
  }

  addDeviceToUser(
    table: TableType,
    device: string,
    licenseStart: string,
    licenseDuration: number,
    siemens: boolean,
    magnus: boolean,
    pcm1: boolean,
    pcm2: boolean,
    saveToBeta: boolean,
    isDraftAssinged: boolean
  ) {
    this.appUsersService
      .addDeviceToUser(
        this.user,
        device,
        table,
        licenseStart,
        licenseDuration,
        saveToBeta,
        isDraftAssinged
      )
      .then((isAssigned) => {
        if (isAssigned) {
          const tableType = [];
          if (siemens === undefined || siemens === null) {
            siemens = this.appUsersService.userHasTableForDevice(
              this.user,
              device,
              this.tableTypeEnum.Siemens
            );
          }
          if (siemens) {
            tableType.push(this.tableTypeEnum.Siemens);
          }
          if (magnus === undefined || magnus === null) {
            magnus = this.appUsersService.userHasTableForDevice(
              this.user,
              device,
              this.tableTypeEnum.Magnus
            );
          }
          if (magnus) {
            tableType.push(this.tableTypeEnum.Magnus);
          }
          const pcm = [];
          if (pcm1 === undefined || pcm1 === null) {
            pcm1 = this.appUsersService.userHasPcmForDevice(
              this.user,
              device,
              this.pcmEnum.PCM_1
            );
          }
          if (pcm1) {
            pcm.push(this.pcmEnum.PCM_1);
          }
          if (pcm2 === undefined || pcm2 === null) {
            pcm2 = this.appUsersService.userHasPcmForDevice(
              this.user,
              device,
              this.pcmEnum.PCM_2
            );
          }
          if (pcm2) {
            pcm.push(this.pcmEnum.PCM_2);
          }
          const config = [
            {
              user: this.email,
              deviceName: device,
              tableType: tableType,
              pcm: pcm,
            },
          ];
          this.appUsersService.addDeviceConfigToUser(this.email, config);
        }
      });
  }

  public selectSite($event, site: any): void {
    if ($event.target.active) {
      this.sitesService.assignSiteToUser(this.email, site);
      this.appUsersService.sendEmailNotification(this.email);
    } else {
      this.sitesService.removeSiteFromUser(this.email, site);
    }
  }

  public selectWorkflow(
    checked: boolean,
    workflow: any,
    licenseStart: string,
    licenseDuration,
    siemens: boolean,
    magnus: boolean,
    pcm1: boolean,
    pcm2: boolean,
    saveToBeta: boolean
  ): void {
    const hasWorkflow = this.appUsersService.userHasWorkflow(
      this.user,
      workflow.key
    );
    if (checked === undefined || checked === null) {
      checked = hasWorkflow;
    }
    if (checked) {
      if (!hasWorkflow && this.appUsersService.hasPheno(workflow.key)) {
        this.addWorkflowToUser(
          this.tableTypeEnum.Magnus,
          workflow,
          licenseStart,
          licenseDuration,
          true,
          true,
          true,
          true,
          saveToBeta,
          checked
        );
      } else {
        this.addWorkflowToUser(
          this.tableTypeEnum.Siemens,
          workflow,
          licenseStart,
          licenseDuration,
          siemens,
          magnus,
          pcm1,
          pcm2,
          saveToBeta,
          checked
        );
      }
    } else {
      this.appUsersService.removeWorkflowFromUser(this.user, workflow, null);
    }
  }

  addWorkflowToUser(
    table: TableType,
    workflow: any,
    licenseStart: string,
    licenseDuration,
    siemens: boolean,
    magnus: boolean,
    pcm1: boolean,
    pcm2: boolean,
    saveToBeta: boolean,
    isDraftAssinged: boolean
  ) {
    this.appUsersService
      .addWorkflowToUser(
        this.user,
        workflow,
        table,
        licenseStart,
        licenseDuration,
        saveToBeta,
        isDraftAssinged
      )
      .then((isAssigned) => {
        if (isAssigned) {
          const tableType = [];
          if (siemens === undefined || siemens === null) {
            siemens = this.appUsersService.userHasTableForWorkflow(
              this.user,
              workflow.key,
              this.tableTypeEnum.Siemens
            );
          }
          if (siemens) {
            tableType.push(this.tableTypeEnum.Siemens);
          }
          if (magnus === undefined || magnus === null) {
            magnus = this.appUsersService.userHasTableForWorkflow(
              this.user,
              workflow.key,
              this.tableTypeEnum.Magnus
            );
          }
          if (magnus) {
            tableType.push(this.tableTypeEnum.Magnus);
          }
          const pcm = [];
          if (pcm1 === undefined || pcm1 === null) {
            pcm1 = this.appUsersService.userHasPcmForWorkflow(
              this.user,
              workflow.key,
              this.pcmEnum.PCM_1
            );
          }
          if (pcm1) {
            pcm.push(this.pcmEnum.PCM_1);
          }
          if (pcm2 === undefined || pcm2 === null) {
            pcm2 = this.appUsersService.userHasPcmForWorkflow(
              this.user,
              workflow.key,
              this.pcmEnum.PCM_2
            );
          }
          if (pcm2) {
            pcm.push(this.pcmEnum.PCM_2);
          }
          const config = [
            {
              user: this.email,
              workflowKey: workflow.key,
              tableType: tableType,
              pcm: pcm,
            },
          ];
          this.appUsersService.addWorkflowConfigToUser(this.email, config);
        }
      });
  }

  public selectTechnicianTraining($event): void {
    if ($event.target.active) {
      this.appUsersService.addOrRemoveTechnicianTraining(this.user, true);
    } else {
      this.appUsersService.addOrRemoveTechnicianTraining(this.user, false);
    }
  }

  public resetUserPassword() {
    this.open = false;
    this.appUsersService
      .resetUserPassword(this.user, this.email)
      .then((data) => {
        if (!data) {
          this.open = true;
        }
      });
  }

  public getUserLicense(category: LicenseCategory, item: string) {
    const appuser = this.appUsersService.users.value.find(
      (u) => u.email === this.email
    );
    if (!appuser || !appuser.licenseInformation) {
      return null;
    }
    return appuser.licenseInformation.filter(
      (info) => info.category === category && info.item.toString() === item
    );
  }

  public filterWorkflows(workflowList: any[], workflowStatus: any) {
    let list: any[];
    if (workflowStatus === 0) {
      list = workflowList.filter((workflow) => workflow.workflowDraftVersion != null);
    } else if (workflowStatus === 2) {
      list = workflowList.filter(
        (workflow) => workflow.workflowReleasedVersion != null
      );
    }
    let i = 0;
    list.forEach((item) => {
      const license = this.getUserLicense(
        LicenseCategory.WORKFLOW_LICENSE,
        item.key
      );
      if (license && license.length) {
        if (license[0].licenseDuration) {
          this.duration[i] = license[0].licenseDuration;
        } else {
          this.duration[i] = 0;
        }
        if (license[0].licenseStart) {
          this.licenseStart[i] = moment(license[0].licenseStart).format(
            this.dateFormat
          );
        } else {
          this.licenseStart[i] = moment(new Date()).format(this.dateFormat);
        }
      } else {
        this.duration[i] = 0;
        this.licenseStart[i] = moment(new Date()).format(this.dateFormat);
      }
      i = i + 1;
    });
    return list;
  }

  public filterDevices(deviceList: any[], deviceStatus: any) {
    let list: any[];
    if (deviceStatus === 0) {
      list = deviceList.filter((device) => device.deviceDraftVersion != null);
    } else if (deviceStatus === 2) {
      list = deviceList.filter(
        (device) => device.deviceReleasedVersion != null
      );
    }
    let i = 0;
    list.forEach((item) => {
      const license = this.getUserLicense(
        LicenseCategory.DEVICE_LICENSE,
        item.name
      );
      if (license && license.length && license[0]) {
        if (license[0].licenseDuration) {
          this.duration[i] = license[0].licenseDuration;
        } else {
          this.duration[i] = 0;
        }
        if (license[0].licenseStart) {
          this.licenseStart[i] = moment(license[0].licenseStart).format(
            this.dateFormat
          );
        } else {
          this.licenseStart[i] = moment(new Date()).format(this.dateFormat);
        }
      } else {
        this.duration[i] = 0;
        this.licenseStart[i] = moment(new Date()).format(this.dateFormat);
      }
      i = i + 1;
    });
    return list;
  }

  getFromDate(start) {
    return moment(start).format(this.dateFormat);
  }

  getMinDate(start) {
    return moment(start).format('YYYY-MM-DD');
  }

  getToDate(start, span: number) {
    const futureDate = new Date(start);
    futureDate.setDate(futureDate.getDate() + Number(span));
    return moment(futureDate).format(this.dateFormat);
  }

  public changeDate(date, i) {
    this.licenseStart[i] = date;
    this.tempStartDate[i] = date;
    const dateEle = document.getElementById('date' + i);
    if (dateEle) {
      dateEle.setAttribute(
        'value',
        this.getToDate(this.licenseStart[i], this.tempDuration[i])
      );
    }
  }

  public changeDuration(duration: number, i) {
    this.duration[i] = duration;
    this.tempDuration[i] = duration;
    const dateEle = document.getElementById('date' + i);
    if (dateEle) {
      dateEle.setAttribute(
        'value',
        this.getToDate(this.tempStartDate[i], this.duration[i])
      );
    }
  }

  public sortSites(sites: any[]) {
    const assigned = sites
      .filter((site) => this.hasSite(site))
      .sort((a, b) =>
        a.siteName.toLowerCase() < b.siteName.toLowerCase() ? -1 : 1
      );
    const unassigned = sites
      .filter((site) => !this.hasSite(site))
      .sort((a, b) =>
        a.siteName.toLowerCase() < b.siteName.toLowerCase() ? -1 : 1
      );
    return assigned.concat(unassigned);
  }

  public closeModal(): void {
    this.currentTab = 'userinfo';
    this.subTab = 'betaDevices';
    this.open = false;
    this.closeEmitted.emit(true);
  }

  async loadData() {
    await this.appUsersService.valSiteUsers();
    await this.appUsersService.loadUsers();
  }

  hasSite(site: any): boolean {
    let assigned,
      isPresent = false;
    this.sitesService.$assignedSites.subscribe((data: any) => {
      assigned = data;
    });
    assigned.forEach((ele) => {
      if (
        ele.siteId === site.siteId &&
        ele.assignedUsers.includes(this.email)
      ) {
        isPresent = true;
      }
    });
    return isPresent;
  }

  public searchOrgData(): void {
    const orgList = JSON.stringify(this.appUsersService.organizations);
    document.getElementById('orgText').setAttribute('search-array', orgList);
  }

  public searchBLData(): void {
    const blList = JSON.stringify(this.appUsersService.businessLines);
    document.getElementById('bLText').setAttribute('search-array', blList);
  }

  public searchRoleData(): void {
    const roleList = JSON.stringify(this.appUsersService.roles);
    document.getElementById('roleText').setAttribute('search-array', roleList);
  }

  public searchCountryData(): void {
    const countriesList = JSON.stringify(
      this.appUsersService.locations.map((loc) => {
        return loc.country;
      })
    );
    document
      .getElementById('countryText')
      .setAttribute('search-array', countriesList);
  }

  public searchRegionData(): void {
    const regionList = JSON.stringify(
      this.appUsersService.locations.map((loc) => {
        return loc.region;
      })
    );
    document
      .getElementById('regionText')
      .setAttribute('search-array', regionList);
  }

  changeRegion(country) {
    const loc = this.appUsersService.locations.find(
      (loc) => loc.country === country
    );
    if (loc) {
      this.regionVal = this.appUsersService.locations.find(
        (loc) => loc.country === country
      ).region;
    }
  }

  public onSearchOrg(): void {
    this.orgVal = '';
  }

  public onSearchBL(): void {
    this.businessLineVal = '';
  }

  public onSearchRole(): void {
    this.roleVal = '';
  }

  public async okChecked(): Promise<void> {
    if (this.updateAll) {
      await this.editUserInfoService.updateEntries(
        this.organization,
        this.orgVal,
        this.email
      );
    }
    await this.editUserInfoService.updateUserInfo(
      this.orgVal,
      this.businessLineVal,
      this.roleVal,
      this.countryVal,
      this.regionVal,
      this.email
    );
    this.organization = this.orgVal;
    this.businessLine = this.businessLineVal;
    this.role = this.roleVal;
    this.country = this.countryVal;
    this.region = this.regionVal;
    this.updateUserInfo.emit(this.email);
  }

  public isInvalid() {
    let valid = false,
      dirty = false;
    const orgEle = document.getElementById('orgText');
    const bLEle = document.getElementById('bLText');
    const roleEle = document.getElementById('roleText');
    const countryEle = document.getElementById('countryText');
    const regionEle = document.getElementById('regionText');

    if (
      orgEle.getAttribute('value') &&
      bLEle.getAttribute('value') &&
      roleEle.getAttribute('value') &&
      countryEle.getAttribute('value') &&
      regionEle.getAttribute('value')
    ) {
      valid = true;
    }
    if (
      orgEle.getAttribute('value') !== orgEle.getAttribute('label') ||
      bLEle.getAttribute('value') !== bLEle.getAttribute('label') ||
      roleEle.getAttribute('value') !== roleEle.getAttribute('label') ||
      countryEle.getAttribute('value') != countryEle.getAttribute('role')
    ) {
      dirty = true;
    }
    return (
      !valid ||
      !dirty ||
      !this.isCountryAvailable(countryEle.getAttribute('value'))
    );
  }

  isCountryAvailable(country) {
    return this.appUsersService.locations
      .map((loc) => {
        return loc.country;
      })
      .includes(country);
  }

  public async packageUpdated() {
    await this.appUsersService.loadUsers();
    this.updateUserInfo.emit(this.email);
    await this.licensePackageService.getAssignedPackagesToUser(this.user);
  }
  public isPackageDevice(deviceName: string) {
    const packageDevice =
      this.licensePackageService.assignedLicensePackages.find((x) =>
        x.devices.find((device) => device.name === deviceName)
      );
    return packageDevice !== undefined;
  }

  public isPackageWorkflow(workflowName: string) {
    const packageWorkflow =
      this.licensePackageService.assignedLicensePackages.find((x) =>
        x.workflows.find((workflow) => workflow.key === workflowName)
      );
    return packageWorkflow !== undefined;
  }
}
