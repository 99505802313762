<div class="flex flex-col">
  <sh-text
    size="header-1"
    [innerText]="'package.assignPackage' | translate"
  ></sh-text>
  <sh-text [innerText]="'package.assignReleasedPackages' | translate"></sh-text>
  <div class="package-content">
    <sh-table>
      <sh-table-row slot="header">
        <sh-table-cell unsortable columns="7">
          <sh-text
            size="title-1"
            [innerText]="'appusers.packageName' | translate"
          ></sh-text>
        </sh-table-cell>
        <sh-table-cell unsortable columns="4">
          <sh-text
            size="title-1"
            [innerText]="'appusers.licenseDuration' | translate"
          ></sh-text>
        </sh-table-cell>
        <sh-table-cell unsortable columns="4">
          <sh-text
            size="title-1"
            [innerText]="'appusers.startDate' | translate"
          ></sh-text>
        </sh-table-cell>
        <sh-table-cell unsortable columns="4">
          <sh-text
            size="title-1"
            [innerText]="'appusers.endDate' | translate"
          ></sh-text>
        </sh-table-cell>

        <sh-table-cell unsortable columns="3">
          <sh-text
            size="title-1"
            [innerText]="'appusers.maxDownload' | translate"
          ></sh-text>
        </sh-table-cell>

        <sh-table-cell unsortable columns="2">
          <sh-text
            size="title-1"
            [innerText]="'appusers.assigned' | translate"
          ></sh-text>
        </sh-table-cell>

        <sh-table-cell columns="1" unsortable icon> </sh-table-cell>
      </sh-table-row>

      <sh-table-row
        *ngFor="let licensePackage of licensePackages$ | async; index as idx"
      >
        <sh-table-cell columns="7">
          <sh-text
            style="padding-top: 0.5rem"
            [innerText]="licensePackage.packageName"
            id="packageName"
          ></sh-text>
        </sh-table-cell>

        <sh-table-cell columns="4">
          <sh-dropdown
            mandatory
            editable
            id="{{ idx }}"
            [disabled]="!isEditable[idx]"
            [label]="'appusers.validityPh' | translate"
            [value]="setLicenseDuration(licensePackage.id)"
            (value-changed)="changeDuration($event?.target?.value, idx)"
          >
            <sh-menu-item
              *ngFor="let duration of durationValues"
              [label]="duration"
            ></sh-menu-item>
          </sh-dropdown>
        </sh-table-cell>

        <sh-table-cell columns="4">
          <sh-datepicker
            [disabled]="!isEditable[idx]"
            [editable]="isEditable[idx]"
            format="YYYYMMDD"
            label="From"
            past-date-disable
            [value]="getFromDate(licenseStart[idx])"
            (value-changed)="changeDate($event?.target?.value, idx)"
          >
          </sh-datepicker>
        </sh-table-cell>

        <sh-table-cell columns="4">
          <sh-datepicker
            format="YYYYMMDD"
            id="{{ 'date' + idx }}"
            [value]="getToDate(licenseStart[idx], licenseDuration[idx])"
            readonly
            label="To"
          ></sh-datepicker>
        </sh-table-cell>

        <sh-table-cell columns="3">
          <sh-input-number
            label="Max Down."
            min="1"
            [max]="maxDownloadNumber(idx)"
            no-arrows="true"
            mandatory
            [disabled]="!isEditable[idx]"
            [value]="setMaxDownload(licensePackage.id)"
            (value-changed)="getMaxDownload($event?.target?.value, idx)"
          ></sh-input-number>
        </sh-table-cell>

        <sh-table-cell columns="2">
          <sh-checkbox
            style="padding-top: 0.5rem"
            [active]="userHasLicensePackage(licensePackage.id)"
            [disabled]="!isEditable[idx]"
            [label]="''"
            (checked)="isPackageSelected[idx] = $event.target.active"
          >
          </sh-checkbox>
        </sh-table-cell>
        <sh-table-cell columns="1">
          <sh-icon
            button
            icon="edit"
            style="padding-top: 0.2rem"
            *ngIf="!isEditable[idx]"
            (click)="
              isPackageSelected[idx] = userHasLicensePackage(licensePackage.id);
              isEditable[idx] = true
            "
          >
          </sh-icon>

          <sh-icon
            *ngIf="isEditable[idx]"
            button
            icon="save"
            style="padding-top: 0.2rem"
            [disabled]="
              isPackageSelected[idx] &&
              (licenseDuration[idx] === null || maxDownload[idx] === null)
            "
            (click)="modifyPackage(idx); isEditable[idx] = false"
          >
          </sh-icon>
        </sh-table-cell>

        <sh-table-row>
          <sh-table-cell columns="24">Equipments</sh-table-cell>
          <sh-table-row *ngFor="let device of licensePackage.devices">
            <sh-table-cell>
              <sh-checkbox
                [label]="getTranslatedName(device.deviceNames)"
                active
                disabled>
              </sh-checkbox>
            </sh-table-cell>
          </sh-table-row>
        </sh-table-row>
        <sh-table-row>
          <sh-table-cell columns="24">Workflows</sh-table-cell>
          <sh-table-row *ngFor="let workflow of licensePackage.workflows">
            <sh-table-cell>
              <sh-checkbox
                [label]="getTranslatedName(workflow.workflowName)"
                active
                disabled>
              </sh-checkbox>
            </sh-table-cell>
          </sh-table-row>
        </sh-table-row>
      </sh-table-row>
    </sh-table>
  </div>
</div>
