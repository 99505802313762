import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { Breadcrumb } from '../shared/breadcrumb/breadcrumb.interface';
import { ParamsService } from '../services/params.service';
import { Constants } from '@backend/interfaces';
import { Router } from '@angular/router';
import { Workflow } from '@backend/api/WorkflowGuides/Workflow/workflow.model';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class WorkflowGuidesService {
  private wflinksSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );
  public get wflinks(): Observable<any[]> {
    return this.wflinksSubject.asObservable();
  }

  private phaselinksSubject: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);
  public get phaselinks(): Observable<any[]> {
    return this.phaselinksSubject.asObservable();
  }

  private _links: Array<Breadcrumb> = [];
  public get links(): Array<Breadcrumb> {
    return this._links;
  }

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;

  public constructor(
    private readonly http: HttpClient,
    public readonly router: Router,
    public readonly paramsService: ParamsService,
    public readonly spinnerService: SpinnerService,
  ) {}

  public async loadWfLinks(workflow?: Workflow[]) {
    this.spinnerService.showSpinner();
    if (this.router.url.indexOf('workflows') !== -1 && this.router.url.indexOf('versions') !== -1) {
      let links = await lastValueFrom(
        this.http.get<any[]>('api/workflowguides/workflows/links')
      );
      this.wflinksSubject.next(links);
    }else if (this.router.url.indexOf('workflows') !== -1 && this.router.url.indexOf('versions') == -1) {
      this.wflinksSubject.next(workflow);
    }
    this.spinnerService.hideSpinner();
    return true;
  }

  public async loadPhaseLinks() {
    this.spinnerService.showSpinner();
    if (
      this.router.url.indexOf('phases') !== -1 ||
      this.router.url.indexOf('workflows') !== -1
    ) {
      const links = await lastValueFrom(
        this.http.get<any[]>('api/workflowguides/phasewrappers/links')
      );
      this.phaselinksSubject.next(links);
    }
    this.spinnerService.hideSpinner();
    return true;
  }

  public isReleaseVersionActive(): boolean {
    let workflow = this.getCurrentWorkFlow();
    
    if (!workflow?.workflowReleasedVersion) {
      return false;
    } else {
      return (
        this.router.url.indexOf(
          workflow.workflowReleasedVersion.id.toString()
        ) !== -1
      );
    }
  }
 
  public getCurrentWorkFlow(): any {
    let allWorkflows: Workflow[] = this.wflinksSubject.getValue();
    let workflow = allWorkflows.find((workflow) => workflow.id === this.paramsService.workflowId);
    return workflow;
  }
}
