<sh-text
  size="header-2"
  [innerHTML]="
    (isEditMode
      ? 'devices.versions.hotspots.screens.editScreen'
      : 'devices.versions.hotspots.screens.addScreen'
    ) | translate
  "
></sh-text>

<div class="content">
  <div class="content-row">
    <sh-text
      size="header-3"
      [innerHTML]="'devices.versions.hotspots.screens.key' | translate"
    ></sh-text>
    <sh-input-text
      #inputKey
      mandatory
      [label]="'devices.versions.hotspots.screens.key' | translate"
      [value]="key"
      (value-changed)="key = $event.target.value"
    ></sh-input-text>
  </div>

  <div class="content-row">
    <sh-text
      [innerHTML]="'devices.versions.hotspots.screens.file' | translate"
    ></sh-text>
    <sh-input-text
      #inputFileTextbox
      mandatory
      readonly
      [value]="fileBasename"
      [label]="'devices.versions.hotspots.screens.file' | translate"
    ></sh-input-text>
    <sh-button
      size="m"
      [label]="'devices.versions.hotspots.screens.openFile' | translate"
      (click)="selectFile()"
    ></sh-button>
  </div>

  <div class="content-row">
    <sh-text size="header-3"></sh-text>
    <img *ngIf="file && !fileChanged" [src]="file | s3Image | async" />
    <img #previewImage *ngIf="fileChanged" />
  </div>
</div>

<div class="buttons">
  <div style="flex: 1"></div>
  <sh-button
    color="secondary"
    (click)="cancel()"
    size="s"
    [label]="'general.close' | translate"
  ></sh-button>
  <sh-button
    [disabled]="!inputKey.value || !inputFileTextbox.value"
    size="s"
    (click)="save()"
    [label]="'general.save' | translate"
  ></sh-button>
</div>

<input
  #inputFile
  accept=".jpg,.jpeg,.png"
  type="file"
  style="display: none"
  (change)="fileChange($event.target.files[0])"
/>
