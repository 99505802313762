/**
 * Checks if the given value is empty or not.
 *
 * A given `value` would be empty if it is null or undefined
 * or '' or '   ' (only spaces).
 *
 * @param {string} value
 * @returns {boolean}
 */
export const isEmpty = (value: string): boolean => Boolean(!value || !value.trim());
