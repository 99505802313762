<sh-text size="header-2" [innerHTML]="screen?.key"></sh-text>
<div class="container">
  <div class="left-pane">
    <div class="hotspot-image-container" #container>
      <img
        *ngIf="screen?.image"
        [src]="screen?.image | s3Image | async"
        (dragover)="onDragOver($event)"
        (load)="imageLoaded(container)"
      />

      <div
        *ngFor="let detail of screen?.informations"
        class="hotspot information"
        draggable="true"
        [style.left]="detail?.x * 100 + '%'"
        [style.bottom]="detail?.y * 100 + '%'"
        [title]="detail?.title | getLocaleText : globalLanguage?.key : true"
        (dragstart)="
          onHotspotSelection('informations', detail); onDragStart($event)
        "
        (dragover)="onDragOver($event)"
        (dragend)="
          onDragEnd($event, 'informations', hotspotId, screen?.id, detail?.id)
        "
        (click)="onHotspotSelection('informations', detail)"
        [id]="computeHotspotId('informations', detail?.id)"
        [style.border]="
          selectedHotspotComputedId ===
          computeHotspotId('informations', detail?.id)
            ? '2px solid red'
            : ''
        "
      ></div>
      <div
        *ngFor="let detail of screen?.switchToScreens"
        class="hotspot switchscreen"
        draggable="true"
        [style.left]="detail?.x * 100 + '%'"
        [style.bottom]="detail?.y * 100 + '%'"
        [title]="detail?.title | getLocaleText : globalLanguage?.key : true"
        (dragstart)="
          onHotspotSelection('switchscreens', detail); onDragStart($event)
        "
        (dragover)="onDragOver($event)"
        (dragend)="
          onDragEnd(
            $event,
            'switchscreens',
            hotspotId,
            screen?.id,
            detail?.id,
            detail?.switchToScreenId
          )
        "
        (click)="onHotspotSelection('switchscreens', detail)"
        [id]="computeHotspotId('switchscreens', detail?.id)"
        [style.border]="
          selectedHotspotComputedId ===
          computeHotspotId('switchscreens', detail?.id)
            ? '2px solid red'
            : ''
        "
      ></div>
      <div
        *ngFor="let detail of screen?.backToScreens"
        class="hotspot back"
        draggable="true"
        [style.left]="detail?.x * 100 + '%'"
        [style.bottom]="detail?.y * 100 + '%'"
        (dragstart)="onHotspotSelection('backs', detail); onDragStart($event)"
        (dragover)="onDragOver($event)"
        (dragend)="
          onDragEnd(
            $event,
            'backs',
            hotspotId,
            screen?.id,
            detail?.id,
            detail?.backToScreenId
          )
        "
        (click)="onHotspotSelection('backs', detail)"
        [id]="computeHotspotId('backs', detail?.id)"
        [style.border]="
          selectedHotspotComputedId === computeHotspotId('backs', detail?.id)
            ? '2px solid red'
            : ''
        "
      ></div>
    </div>
  </div>
  <div class="right-pane">
    <backend-hotspots-right-pane
      [selectedScreen]="screen"
      [addEditEnabled]="true"
      [computedHotspotId]="selectedHotspotComputedId"
      (hotspotDeleted)="onHotspotDeleted($event)"
      (hotspotSelected)="onHotspotSelectedFromRightPane($event)"
    ></backend-hotspots-right-pane>
  </div>
</div>

<!-- Footer -->
<div class="buttons">
  <div style="flex: 1"></div>
  <sh-button
    color="tertiary"
    slot="footer"
    size="max-content"
    [label]="'general.close' | translate"
    routerLink="../.."
    style="margin: 0px 8px"
  ></sh-button>
</div>
