import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';

import { NotificationComponent } from './notification/notification.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { LocalizationComponent } from './localization/localization.component';
import { CreateLocalizationEntryComponent } from './localization/modals/create-localization-entry/create-localization-entry.component';
import { S3ImagePipe } from './s3-image.pipe';
import { GetLangValuePipe } from '../get-lang-value.pipe';
import { TokenInterceptor } from '../auth/auth.interceptor';
import { ReportComponent } from './report/report.component';
import { GetLocaleTextPipe } from '../get-locale-text.pipe';
import { CreateLanguageComponent } from './localization/modals/create-language/create-language.component';

@NgModule({
  declarations: [
    LocalizationComponent,
    CreateLanguageComponent,
    CreateLocalizationEntryComponent,
    NotificationComponent,
    ConfirmationComponent,
    BreadcrumbComponent,
    S3ImagePipe,
    GetLangValuePipe,
    GetLocaleTextPipe,
    ReportComponent,
  ],
  imports: [CommonModule, TranslateModule],
  exports: [
    NotificationComponent,
    ConfirmationComponent,
    BreadcrumbComponent,
    LocalizationComponent,
    CreateLanguageComponent,
    CreateLocalizationEntryComponent,
    S3ImagePipe,
    GetLangValuePipe,
    GetLocaleTextPipe,
    ReportComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
