import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IValSitesUser } from '@backend/interfaces';
import { AppUsersService } from './appuser.service';
import { AddUserComponent } from './modals/add-user/add-user.component';
import { AppUserSettingsComponent } from './modals/appuser-settings/appuser-settings.component';
import { PopoverService } from '../services/popover.service';
import { UserAssignedSitesComponent } from './modals/user-assigned-sites/user-assigned-sites.component';
import { SitesService } from '../siteplanner/sites.service';
import { SpinnerService } from '../spinner/spinner.service';
import { environment } from '../../environments/environment';
import { ThemeService } from '../services/theme.service';
import {LicensePackageService} from "@backend/webapp/license-package/license-package.service";
import { NotificationService } from '../shared/notification/notification.service';
import { DevicesService } from '../devices/devices.service';
import { WorkflowService } from '../workflowguides/workflows/workflow.service';

@Component({
  selector: 'backend-appuser',
  templateUrl: './appuser.component.html',
  styleUrls: ['./appuser.component.scss'],
})
export class AppuserComponent implements OnInit {
  private searchTerm = '';
  public moreOption = false;

  public internalFilter: boolean;
  public externalFilter: boolean;
  public ALL_USERS = 'All';
  public INTERNAL_USERS = 'Internal';
  public EXTERNAL_USERS = 'External';
  public HEALTHINEERS_DOMAIN = '@siemens-healthineers.com';
  public domainFilters = [
    this.ALL_USERS,
    this.INTERNAL_USERS,
    this.EXTERNAL_USERS,
  ];
  public filteredVal = this.ALL_USERS;

  public _filteredUsers: any[];
  private allUsers: any[];
  private _valsites: IValSitesUser[];

  public activeRow = null;
  public $sites: Observable<any>;

  public get filteredUsers(): any[] {
    return this._filteredUsers;
  }

  public get valSiteUsers(): IValSitesUser[] {
    return this._valsites;
  }

  public $valUsers: Observable<IValSitesUser[]>;
  public $users: Observable<any[]>;

  @ViewChild('adduser')
  public addUserModal: AddUserComponent;

  @ViewChild('userSettings')
  public appuserSettingsModal: AppUserSettingsComponent;

  @ViewChild('userassignsites')
  public userAssignedSites: UserAssignedSitesComponent;

  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  private showSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public get show(): Observable<boolean> {
    return this.showSubject.asObservable();
  }

  pageIndex = 1;
  itemsPerPage = 20;
  dispalyedAppUsers: any;
  isChildComponentsInitialized: boolean;
  public users: any[];
  public isExpanded: boolean[] = [false];
  noToggle = false;
  isAllUserHasCompleteInfo = false;
  showListView = false;
  filteredRegions = [];
  isAscending = true;
  filterOptions: any = {
    searchKey: '',
    sortOrder: 'ASC',
    userType: this.ALL_USERS,
    organizations: [],
    businessLines: [],
    roles: [],
    countries: []
  }

  public constructor(
    public popoverService: PopoverService,
    public devicesService: DevicesService,
    public workflowService: WorkflowService,
    public appUserService: AppUsersService,
    private sitesService: SitesService,
    private spinnerService: SpinnerService,
    private licensePackageService: LicensePackageService,
    private notificationService: NotificationService,
    public readonly themeService: ThemeService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.dispalyedAppUsers = [];
    this.isChildComponentsInitialized = false;
    this.isAllUserHasCompleteInfo = false;
    await this.appUserService.loadUsers();
    this.appUserService.$users.subscribe(
      (users: any[]) => (this.allUsers = JSON.parse(JSON.stringify(users)))
    );
    this._filteredUsers = this.allUsers;
    this.manageDevicesInfiniteScroll(); 
  }

  public async ngAfterContentInit(): Promise<void> {
    try {
      await this.appUserService.setAvailableMetrics();
      this.filteredRegions = this.appUserService.regions;
      await this.sitesService.registerWebhook();
      await this.appUserService.pulishedExpiredLicenses();
    }catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.getError',
        null,
        error.error.message
      );
    }
  }

  scrollToActiveRow(row: string): void {
    setTimeout(() => {
      this.activeRow = row;
      const scrollItem = document.getElementById(row);
      if (scrollItem) {
        scrollItem.scrollIntoView({ block: 'center' });
      }
    }, 500);
  }

  public async onAddClick() {
    this.spinnerService.showSpinner();
    this.addUserModal.currentTab = 'userinfo';
    this.addUserModal.subTab = 'betaDevices';
    this.addUserModal.open = true;
    await this.getDataForChildComponent();
    this.activeRow = null;
    this.spinnerService.hideSpinner();
  }

  public setFeedBack(user: string): void {
    this.appUserService.setFeedBack(user);
  }

  public deleteUser(user: string, email: string): void {
    this.appUserService.removeUser(user).then(() => {
      this.sitesService.removeUser(email);
      const searchBox = document.getElementById('searchBox');
      if (searchBox) {
        this.searchTerm = '';
        searchBox.setAttribute('value', '');
      }
      this.internalFilter = this.externalFilter = false;
      this.activeRow = '';
      this._filteredUsers = this.allUsers;
      this.resetInfiniteScroll();
    }).catch((error) => {
      this.notificationService.error(
        'general.error',
        'appusers.getError',
        null,
        error.error.message
      );
    });
  }

  public async appuserSettings(user: any) {
    this.activeRow =
      this.appuserSettingsModal.email =
      this.sitesService.appUserEmail =
        user.email;
    this.appuserSettingsModal.user = user.userId;
    this.appuserSettingsModal.organization = this.appuserSettingsModal.orgVal =
      user.organization;
    this.appuserSettingsModal.businessLine =
      this.appuserSettingsModal.businessLineVal = user.businessLine;
    this.appuserSettingsModal.role = this.appuserSettingsModal.roleVal =
      user.role;
    this.appuserSettingsModal.country = this.appuserSettingsModal.countryVal =
      user.country;
    this.appuserSettingsModal.region = this.appuserSettingsModal.regionVal =
      user.region;
    this.appuserSettingsModal.currentTab = 'userinfo';
    this.appuserSettingsModal.subTab = 'betaDevices';
    if (!this.isAllUserHasCompleteInfo) {
      await this.appUserService.updateFullDataForUser(user);
    }
    await this.sitesService.getAssignedSites(user.email);
    await this.licensePackageService.getAssignedPackagesToUser(user.userId);
    this.appuserSettingsModal.open = true;
    await this.getDataForChildComponent();
  }

  public assignedSitesData(assignedsites: any): void {
    this.userAssignedSites.open = true;
    this.userAssignedSites.sitesList = assignedsites;
  }

  public onSearch(searchTerm: string = ''): void {
    this.searchTerm = searchTerm;
    if (this.searchTerm) {
      this.applyFilter(this.searchTerm.toLowerCase());
    } else {
      this.appUserService.$users.subscribe(
        (users: any[]) => (this.allUsers = users)
      );
      this._filteredUsers = JSON.parse(JSON.stringify(this.allUsers));
    }
    this.resetInfiniteScroll();
  }

  public highlightActiveUser(event: boolean): void {
    if (event) {
      this.onSearch(this.searchTerm);
      this.scrollToActiveRow(this.activeRow);
      this.changeHeight(this.activeRow, true);
    }
  }

  public async highlightNewUser(event: string): Promise<void> {
    //Calls after new user has been addded.
    if (event && event.trim() !== '') {
      await this.updateUserInfo(event);
      this._filteredUsers = this.allUsers;
      this.resetInfiniteScroll();
      this.scrollToActiveRow(event);
      this.changeHeight(this.activeRow, true);
    }
  }

  public showFullData(user): void {
    const c = this._filteredUsers.filter((userdata) =>
      userdata.email.match(user.email)
    );
    this.$valUsers.subscribe(
      (valUsers: IValSitesUser[]) => (this._valsites = valUsers)
    );
    const valusers = JSON.parse(JSON.stringify(this._valsites));
    const sendsites = [];
    const sites = [];
    valusers.forEach((element) => {
      if (element.assignedUsers) {
        element.assignedUsers.forEach((element2) => {
          if (c[0].email === element2) {
            const idx = element.siteName.indexOf('-');
            if (idx === -1) {
              sites.push(element.siteName);
              sendsites.push(element.siteName);
            } else {
              sites.push(
                element.siteName.substring(idx + 2, element.siteName.length)
              );
              sendsites.push(element.siteName);
            }
            if (sendsites.length > 2 && sites.length) {
              this.assignedSitesData(this.processSites(sendsites, sites));
            }
          }
        });
      }
    });
  }

  public processSites(sendsites, sites) {
    const pan = [],
      arrsites = [];
    for (let a = 0; a < sites.length; a++) {
      pan.push({ key1: sites[a], key2: sendsites[a] });
    }
    pan.sort(function (a, b) {
      const nameA = a.key1.toLowerCase(),
        nameB = b.key1.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
    pan.forEach((a) => {
      arrsites.push(a.key2);
    });
    return arrsites;
  }

  public async mapSitesToUsers(): Promise<void> {
    this.$valUsers = this.appUserService.$valSiteusers;
    this.appUserService.$users.subscribe((users: any[]) => {
      this._filteredUsers = users;
      this.showSitesOnFilter();
    });
  }

  showSitesOnFilter() {
    this.appUserService.$valSiteusers.subscribe(
      (valUsers: IValSitesUser[]) => (this._valsites = valUsers)
    );
    const valusers = JSON.parse(JSON.stringify(this._valsites));
    this._filteredUsers.forEach((element3) => {
      valusers.forEach((element) => {
        if (element.assignedUsers) {
          element.assignedUsers.forEach((element2) => {
            if (!element3.sites) {
              element3.sites = '';
              element3.enabled = false;
            }
            if (element3.email === element2) {
              if (element3.sites !== '') {
                element3.sites += ',' + ' ' + element.siteName;
              } else {
                element3.sites += element.siteName;
              }
              if (element3.sites.split(',').length - 1 >= 2) {
                element3.sites = element3.sites.replace(
                  element3.sites.substring(
                    element3.sites.lastIndexOf(','),
                    element3.sites.length
                  ),
                  ' '
                );
                element3.enabled = true;
              }
            }
          });
        }
      });
    });
    this.resetInfiniteScroll();
  }

  applyFilter(searchTerm = ''): void {
    this.appUserService.$users.subscribe(
      (users: any[]) => (this.allUsers = users)
    );

    // Top Level 'User Type' Filter
    this.filterOptions.userType === this.INTERNAL_USERS ? this._filteredUsers = this.allUsers.filter((user) =>
      user.email.toLowerCase().endsWith(this.HEALTHINEERS_DOMAIN)
    ) : this.filterOptions.userType === this.EXTERNAL_USERS ? this._filteredUsers = this.allUsers.filter(
      (user) => !user.email.toLowerCase().endsWith(this.HEALTHINEERS_DOMAIN)
    ) : this._filteredUsers = JSON.parse(JSON.stringify(this.allUsers));

    // Filter wrt extended filter options
    this._filteredUsers = this._filteredUsers.filter((user) =>
    (!this.filterOptions.organizations.length || this.filterOptions.organizations.includes(user.organization)) &&
    (!this.filterOptions.businessLines.length || this.filterOptions.businessLines.includes(user.businessLine)) &&
    (!this.filterOptions.roles.length || this.filterOptions.roles.includes(user.role)) &&
    (!this.filterOptions.countries.length || this.filterOptions.countries.includes(user.country))
    );

    if (searchTerm !== '') {
      this._filteredUsers = this._filteredUsers.filter(
        (user) => user.email.toLowerCase().indexOf(searchTerm) !== -1
      );
    }
    this.showSitesOnFilter();
  }

  async downloadCSV() {
    try {
      this.spinnerService.showSpinner();
      this.filterOptions.searchKey = this.searchTerm;
      const csv = await this.appUserService.export(
        this.filterOptions
      );
      if (-1 !== window.location.href.indexOf(environment.domain)) {
        const windowUrl = window.URL || window.webkitURL;
        const blob = new Blob(['\uFEFF' + csv.text], {
          type: 'text/csv; charset=utf-8',
        });
        const url = windowUrl.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'AppUsers.csv';
        anchor.click();
      }
      this.spinnerService.hideSpinner();
    } catch (error) {
      console.log(error);
      this.spinnerService.hideSpinner();
    }
  }

  changeFilter() {
    this.searchTerm = '';
    const searchBox = document.getElementById('searchBox');
    if (searchBox) {
      searchBox.setAttribute('value', '');
    }
    this.applyFilter();
    this.resetInfiniteScroll();
  }

  public resetUserPassword(user, email) {
    this.appUserService.resetUserPassword(user, email).then((data) => {
      if (data) {
        this.changeFilter();
      }
    });
  }

  public getSubstring(value, pre: boolean) {
    return pre
      ? value.substring(0, value.indexOf('@'))
      : value.substring(value.indexOf('@'), value.length);
  }

  changeHeight(cardId, expand) {
    const cardsToCollapse = document.getElementsByClassName('userCard');
    for (let index = 0; index < cardsToCollapse.length; index++) {
      (cardsToCollapse.item(index) as HTMLElement).style.height = '270px';
      this.isExpanded[index] = false;
    }
    const userCard = document.getElementById(cardId);
    if (userCard) {
      if (expand) {
        userCard.style.height = '370px';
        this.activeRow = cardId;
      } else {
        userCard.style.height = '270px';
      }
    }
  }

  getOrderedLetters() {
    const letters = new Set();
    this.appUserService.regions.forEach(item => {
      letters.add(item.region.charAt(0).toUpperCase());
    });
    return Array.from(letters).sort();
  }

  filterRegions(letter: string) {
    if(letter !== '') {
      this.filteredRegions = this.appUserService.regions.filter((item) => item.region.toUpperCase().startsWith(letter));
    } else {
      this.filteredRegions = this.appUserService.regions;
    }
  }

  public searchByCountry(searchTerm: string = ''): void {
    this.filteredRegions = JSON.parse(JSON.stringify(this.appUserService.regions));
    if (searchTerm) {
      this.filteredRegions.forEach(item => {
        item.countries = item.countries.filter((country) => country.toLowerCase().includes(searchTerm.toLowerCase()));
      });
      this.filteredRegions = this.filteredRegions.filter((item) => item.countries && item.countries.length);
    }
    this.resetInfiniteScroll();
  }

  clearFilters() {
    this.filterOptions = {
      searchKey: '',
      userType: this.ALL_USERS,
      organizations: [],
      businessLines: [],
      roles: [],
      countries: []
    };
    const searchBox = document.getElementById('countrySearch');
    if (searchBox) {
      searchBox.setAttribute('value', '');
    }
    this.filterRegions('');
    this.changeFilter();
  }

  toggleSortOrder() {
    const icon = document.getElementById('sortOrder');
    if (this.isAscending) {
      icon.style.transform = 'rotateX(180deg)';
      this.dispalyedAppUsers.sort((a, b) => {
        if (a.email.toLowerCase() > b.email.toLowerCase()) {
          return -1;
        }
        return a.email.toLowerCase() < b.email.toLowerCase() ? 1 : 0;
      });
    } else {
      icon.style.transform = 'rotate(0deg)';
      this.dispalyedAppUsers.sort((a, b) => {
        if (a.email.toLowerCase() < b.email.toLowerCase()) {
          return -1;
        }
        return a.email.toLowerCase() > b.email.toLowerCase() ? 1 : 0;
      });
    }
    this.isAscending = !this.isAscending;
    this.filterOptions.sortOrder = this.isAscending ? 'ASC': 'DESC';
  }

  resetInfiniteScroll(): void{
    this.pageIndex = 1;
    this.manageDevicesInfiniteScroll();
  }

  public async getDataForChildComponent(): Promise<void>{
    if (this.isChildComponentsInitialized) {
      return;
    }
    try {
      await this.devicesService.updateDevices();
      this.spinnerService.showSpinner();
      await this.workflowService.loadWorkflows();
      await this.sitesService.getAllSites();
      this.isChildComponentsInitialized = true;
      this.spinnerService.hideSpinner();
    }catch (error) {
      this.notificationService.error(
        'general.error',
        'Error while fetching data.',
        null,
        error.error.message
      );
      this.spinnerService.hideSpinner();
    }
  }


  public async getCompleteDataForListView(): Promise<void>{
    try {
      //set assigned sites for each user in list view page
      this.$sites = this.sitesService.$sites;
      if (this.activeRow) {
        this.scrollToActiveRow(this.activeRow);
      }

      if (this.isAllUserHasCompleteInfo) {
        this.showListView = true;
        return;
      }
      
      await this.appUserService.valSiteUsers();
      await this.mapSitesToUsers();
      await this.appUserService.updateFullDataForAllUsers();
      this.isAllUserHasCompleteInfo = true;
      this.showListView = true;
    }catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.getError',
        null,
        error.error.message
      );
    }
  }

  public onScrollEnd(): void {
    this.pageIndex = this.pageIndex + 1;
    this.manageDevicesInfiniteScroll();
  }

  public manageDevicesInfiniteScroll(): void {
    const startIndex = (this.pageIndex - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    if (this.pageIndex == 1) {
      this.dispalyedAppUsers = [];
    }

    for (let i = startIndex; i < endIndex; i++) {
      if (i < this.filteredUsers.length) {
        this.dispalyedAppUsers.push(this.filteredUsers[i]);
      }
    } 
  }

  public async updateUserInfo(email: string): Promise<void> {
    try {
      if (email && email.trim() !== '') {
        if (this.isAllUserHasCompleteInfo) {
          await this.appUserService.updateFullDataForAllUsers();
        } else {
            let user = await this.appUserService.users.value.find((appuser) => appuser.email === email);
            await this.appUserService.updateFullDataForUser(user);
        }
      }
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.getError',
        null,
        error.error.message
      );
    }
  }
}
