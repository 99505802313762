import { Injectable, EventEmitter } from '@angular/core';
import { ConfirmationType } from './confirmation-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  public closed = new EventEmitter();

  private _title = null;
  public set title(value: string) {
    this._title = value;
  }
  public get title(): string {
    return this._title;
  }

  private _message = null;
  public set message(value: string) {
    this._message = value;
  }
  public get message(): string {
    return this._message;
  }

  private _messageParams = {};
  public set messageParams(value: any) {
    this._messageParams = value;
  }
  public get messageParams(): any {
    return this._messageParams;
  }

  private _type = ConfirmationType.Default;
  public set type(value: ConfirmationType) {
    this._type = value;
  }
  public get type(): ConfirmationType {
    return this._type;
  }

  private _visible = false;
  public set visible(value: boolean) {
    this._visible = value;
  }
  public get visible(): boolean {
    return this._visible;
  }

  public confirm(
    title: string,
    message: string,
    messageParams?: any
  ): Promise<boolean> {
    return this.confirmInternal(
      title,
      message,
      messageParams,
      ConfirmationType.Default
    );
  }

  public confirmDelete(
    title: string,
    message: string,
    messageParams?: any
  ): Promise<boolean> {
    return this.confirmInternal(
      title,
      message,
      messageParams,
      ConfirmationType.Delete
    );
  }

  public confirmWarn(
    title: string,
    message: string,
    messageParams?: any
  ): Promise<boolean> {
    return this.confirmInternal(
      title,
      message,
      messageParams,
      ConfirmationType.Warning
    );
  }

  private confirmInternal(
    title: string,
    message: string,
    messageParams?: any,
    type?: ConfirmationType
  ): Promise<boolean> {
    this.title = title;
    this.message = message;
    this.messageParams = messageParams;
    this.type = type;
    this.visible = true;

    return new Promise<boolean>((resolve, reject) => {
      this.closed.subscribe((result) => {
        resolve(result);
      });
    });
  }

  public close(): void {
    this.closed.emit(true);
    this.hideModal();
  }

  public cancel(): void {
    this.closed.emit(false);
    this.hideModal();
  }

  private hideModal(): void {
    this.visible = false;
  }
}
