import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { SpinnerService } from '../../../../../spinner/spinner.service';
import { NotificationService } from '../../../../../shared/notification/notification.service';
import { ParamsService } from '../../../../../services/params.service';
import { DetailType } from './detail-type.enum';
import { LanguageService } from '../../../../../services/language.service';
import { ScreensService } from './screens.service';
import { ConfirmationService } from '../../../../../shared/confirmation/confirmation.service';
import { areObjectsIdentical } from '../../../../../tools';

@Injectable({
  providedIn: 'root',
})
export class DetailsService {
  private informationsSubject$: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >(null);
  public get informations$(): Observable<any[]> {
    return this.informationsSubject$.asObservable();
  }
  public get informations(): any[] {
    return this.informationsSubject$.value;
  }

  private switchScreensSubject$: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >(null);
  public get switchScreens$(): Observable<any[]> {
    return this.switchScreensSubject$.asObservable();
  }
  public get switchScreens(): any[] {
    return this.switchScreensSubject$.value;
  }

  private backsSubject$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  public get backs$(): Observable<any[]> {
    return this.backsSubject$.asObservable();
  }
  public get backs(): any[] {
    return this.backsSubject$.value;
  }

  private detailSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public get detail$(): Observable<any> {
    return this.detailSubject$.asObservable();
  }
  public get detail(): any {
    return this.detailSubject$.value;
  }

  private lastHotspotId = null;
  private lastScreenId = null;
  private lastScreenType = null;

  public constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
    private screensService: ScreensService,
    private languageService: LanguageService,
    private paramsService: ParamsService,
    private confirmationService: ConfirmationService
  ) {}

  public async updateDetails(): Promise<boolean> {
    await this.screensService.updateScreen();

    const screen = this.screensService.screen;
    if (screen) {
      if (!areObjectsIdentical(this.informations, screen.informations)) {
        this.informationsSubject$.next(screen.informations);
      }
      if (!areObjectsIdentical(this.switchScreens, screen.switchToScreens)) {
        this.switchScreensSubject$.next(screen.switchToScreens);
      }
      if (!areObjectsIdentical(this.backs, screen.backToScreens)) {
        this.backsSubject$.next(screen.backToScreens);
      }
      return true;
    } else {
      this.informationsSubject$.next(null);
      this.switchScreensSubject$.next(null);
      this.backsSubject$.next(null);
      return false;
    }
  }

  public async updateDetail(): Promise<boolean> {
    this.paramsService.updateParams();
    const detailId = this.paramsService.detailId;
    const detailType = this.paramsService.detailType;
    if (detailType !== null && detailId !== null) {
      const detail = await this.getDetail(detailType, detailId);
      if (detail && this.detail !== detail) {
        this.detailSubject$.next(detail);
        return true;
      }
    }

    this.detailSubject$.next(null);
    return false;
  }

  public async getDetail(
    detailType = this.paramsService.detailType,
    detailId: number = this.paramsService.detailId
  ): Promise<any> {
    this.paramsService.updateParams();
    if (
      !this.informations ||
      !this.switchScreens ||
      !this.backs ||
      this.paramsService.screenId !== this.lastScreenId ||
      this.paramsService.screenType !== this.lastScreenType ||
      this.paramsService.hotspotId !== this.lastHotspotId
    ) {
      const updateSuccess = await this.updateDetails();
      if (!updateSuccess) {
        return null;
      }
    }

    this.lastHotspotId = this.paramsService.hotspotId;
    this.lastScreenId = this.paramsService.screenId;
    this.lastScreenType = this.paramsService.screenType;

    if (detailType) {
      switch (detailType) {
        case DetailType.Information:
          return this.informations.find((a) => a.id === detailId);
        case DetailType.SwitchScreen:
          return this.switchScreens.find((a) => a.id === detailId);
        case DetailType.Back:
          return this.backs.find((a) => a.id === detailId);
      }
    } else {
      return [...this.informations, ...this.switchScreens, ...this.backs].find(
        (a) => a.id === detailId
      );
    }
  }

  public async createInformationDetail(
    x: number,
    y: number,
    title: string,
    text: string
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      const detail = await lastValueFrom(
        this.http.post(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${this.paramsService.screenId}/informations`,
          {
            x,
            y,
          }
        )
      );
      const detailId = detail['id'];
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${this.paramsService.screenId}/informations/${detailId}/title/${this.languageService.guiLanguageCode}`,
          { title }
        )
      );
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${this.paramsService.screenId}/informations/${detailId}/text/${this.languageService.guiLanguageCode}`,
          { text }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.hotspots.screens.details.informations.createSuccess'
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.hotspots.screens.details.informations.createError',
        null,
        error.error.message
      );
      return false;
    } finally {
      await this.updateDetails();
      this.spinnerService.hideSpinner();
    }
  }

  public async modifyInformationDetail(
    detailId: number,
    x: number,
    y: number,
    title?: string,
    text?: string
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${this.paramsService.screenId}/informations/${detailId}`,
          {
            x,
            y,
          }
        )
      );
      if (title) {
        await lastValueFrom(
          this.http.put(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${this.paramsService.screenId}/informations/${detailId}/title/${this.languageService.guiLanguageCode}`,
            { title }
          )
        );
      }
      if (text) {
        await lastValueFrom(
          this.http.put(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${this.paramsService.screenId}/informations/${detailId}/text/${this.languageService.guiLanguageCode}`,
            { text }
          )
        );
      }
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.hotspots.screens.details.informations.modifySuccess'
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.hotspots.screens.details.informations.modifyError',
        null,
        error.error.message
      );
      return false;
    } finally {
      await this.updateDetails();
      this.spinnerService.hideSpinner();
    }
  }

  public async modifyInformationDetailPosition(
    hotspotId: number,
    screenId: number,
    detailId: number,
    x: number,
    y: number
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${hotspotId}/screens/${screenId}/informations/${detailId}`,
          {
            x,
            y,
          }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.hotspots.screens.details.informations.modifySuccess'
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.hotspots.screens.details.informations.modifyError',
        null,
        error.error.message
      );
      return false;
    } finally {
      await this.updateDetails();
      this.spinnerService.hideSpinner();
    }
  }

  public async deleteInformationDetail(
    hotspotId: number,
    screenId: number,
    detailId: number,
    detail
  ): Promise<boolean> {
    const name = detail;
    if (
      await this.confirmationService.confirmDelete(
        'devices.versions.hotspots.screens.details.informations.deleteDetail',
        'devices.versions.hotspots.screens.details.informations.deleteDetailConfirmation',
        { name }
      )
    ) {
      this.spinnerService.showSpinner();
      try {
        await lastValueFrom(
          this.http.delete<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${hotspotId}/screens/${screenId}/informations/${detailId}`
          )
        );
        this.notificationService.confirmation(
          'general.success',
          'devices.versions.hotspots.screens.details.informations.deleteSuccess'
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.hotspots.screens.details.informations.deleteError',
          null,
          error.error.message
        );
        return false;
      } finally {
        await this.updateDetails();
        this.spinnerService.hideSpinner();
      }
    }
  }

  public async createSwitchScreenDetail(
    x: number,
    y: number,
    targetId: number,
    title: string,
    text: string
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      const detail = await lastValueFrom(
        this.http.post(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${this.paramsService.screenId}/switchscreens`,
          {
            x,
            y,
            switchToScreenId:
              typeof targetId === 'object' ? (targetId as any).id : targetId,
          }
        )
      );
      const detailId = detail['id'];
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${this.paramsService.screenId}/switchscreens/${detailId}/title/${this.languageService.guiLanguageCode}`,
          { title }
        )
      );
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${this.paramsService.screenId}/switchscreens/${detailId}/text/${this.languageService.guiLanguageCode}`,
          { text: text }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.hotspots.screens.details.switchScreens.createSuccess'
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.hotspots.screens.details.switchScreens.createError',
        null,
        error.error.message
      );
      return false;
    } finally {
      await this.updateDetails();
      this.spinnerService.hideSpinner();
    }
  }

  public async modifySwitchScreenDetail(
    detailId: number,
    x: number,
    y: number,
    targetId: number,
    title?: string,
    text?: string
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${this.paramsService.screenId}/switchscreens/${detailId}`,
          {
            x,
            y,
            switchToScreenId:
              typeof targetId === 'object' ? (targetId as any).id : targetId,
          }
        )
      );
      if (title) {
        await lastValueFrom(
          this.http.put(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${this.paramsService.screenId}/switchscreens/${detailId}/title/${this.languageService.guiLanguageCode}`,
            { title }
          )
        );
      }
      if (text) {
        await lastValueFrom(
          this.http.put(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${this.paramsService.screenId}/switchscreens/${detailId}/text/${this.languageService.guiLanguageCode}`,
            { text }
          )
        );
      }
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.hotspots.screens.details.switchScreens.modifySuccess'
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.hotspots.screens.details.switchScreens.modifyError',
        null,
        error.error.message
      );
      return false;
    } finally {
      await this.updateDetails();
      this.spinnerService.hideSpinner();
    }
  }

  public async modifySwitchScreenDetailPosition(
    hotspotId: number,
    screenId: number,
    detailId: number,
    x: number,
    y: number,
    targetId: number
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${hotspotId}/screens/${screenId}/switchscreens/${detailId}`,
          {
            x,
            y,
            switchToScreenId:
              typeof targetId === 'object' ? (targetId as any).id : targetId,
          }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.hotspots.screens.details.switchScreens.modifySuccess'
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.hotspots.screens.details.switchScreens.modifyError',
        null,
        error.error.message
      );
      return false;
    } finally {
      await this.updateDetails();
      this.spinnerService.hideSpinner();
    }
  }

  public async deleteSwitchScreenDetail(
    hotspotId: number,
    screenId: number,
    detailId: number,
    detail
  ): Promise<boolean> {
    const name = detail;
    if (
      await this.confirmationService.confirmDelete(
        'devices.versions.hotspots.screens.details.switchScreens.deleteDetail',
        'devices.versions.hotspots.screens.details.switchScreens.deleteDetailConfirmation',
        { name }
      )
    ) {
      this.spinnerService.showSpinner();
      try {
        await lastValueFrom(
          this.http.delete<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${hotspotId}/screens/${screenId}/switchscreens/${detailId}`
          )
        );
        this.notificationService.confirmation(
          'general.success',
          'devices.versions.hotspots.screens.details.switchScreens.deleteSuccess'
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.hotspots.screens.details.switchScreens.deleteError',
          null,
          error.error.message
        );
        return false;
      } finally {
        await this.updateDetails();
        this.spinnerService.hideSpinner();
      }
    }
  }

  public async createBackDetail(
    x: number,
    y: number,
    targetId: number
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.post(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${this.paramsService.screenId}/backs`,
          {
            x,
            y,
            gotoScreenId:
              typeof targetId === 'object' ? (targetId as any).id : targetId,
          }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.hotspots.screens.details.backs.createSuccess'
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.hotspots.screens.details.backs.createError',
        null,
        error.error.message
      );
      return false;
    } finally {
      await this.updateDetails();
      this.spinnerService.hideSpinner();
    }
  }

  public async modifyBackDetail(
    detailId: number,
    x: number,
    y: number,
    targetId: number
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${this.paramsService.screenId}/backs/${detailId}`,
          {
            x,
            y,
            gotoScreenId:
              typeof targetId === 'object' ? (targetId as any).id : targetId,
          }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.hotspots.screens.details.backs.modifySuccess'
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.hotspots.screens.details.backs.modifyError',
        null,
        error.error.message
      );
      return false;
    } finally {
      await this.updateDetails();
      this.spinnerService.hideSpinner();
    }
  }

  public async modifyBackDetailPosition(
    hotspotId: number,
    screenId: number,
    detailId: number,
    x: number,
    y: number,
    targetId: number
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${hotspotId}/screens/${screenId}/backs/${detailId}`,
          {
            x,
            y,
            gotoScreenId:
              typeof targetId === 'object' ? (targetId as any).id : targetId,
          }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.hotspots.screens.details.backs.modifySuccess'
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.hotspots.screens.details.backs.modifyError',
        null,
        error.error.message
      );
      return false;
    } finally {
      await this.updateDetails();
      this.spinnerService.hideSpinner();
    }
  }

  public async deleteBackDetail(
    hotspotId: number,
    screenId: number,
    detailId: number,
    detail
  ): Promise<boolean> {
    const name = detail;
    if (
      await this.confirmationService.confirmDelete(
        'devices.versions.hotspots.screens.details.backs.deleteDetail',
        'devices.versions.hotspots.screens.details.backs.deleteDetailConfirmation',
        { name }
      )
    ) {
      this.spinnerService.showSpinner();
      try {
        await lastValueFrom(
          this.http.delete<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${hotspotId}/screens/${screenId}/backs/${detailId}`
          )
        );
        this.notificationService.confirmation(
          'general.success',
          'devices.versions.hotspots.screens.details.backs.deleteSuccess'
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.hotspots.screens.details.backs.deleteError',
          null,
          error.error.message
        );
        return false;
      } finally {
        await this.updateDetails();
        this.spinnerService.hideSpinner();
      }
    }
  }
}
