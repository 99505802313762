<div class="search">
  <div style="margin-right: 20px">
    <sh-dropdown
      style="padding-right: 5px; min-width: 40px"
      [value]="lang"
      label="Select a language"
      (value-changed)="lang = $event.target.value"
    >
      <sh-menu-item
        *ngFor="let p of this.localizationService.languages"
        (click)="selectLanguage(p.key)"
        [value]="p.name"
        [label]="p.name"
        [active]="lang === p.name"
        readonly
      >
      </sh-menu-item>
    </sh-dropdown>
  </div>
  <sh-search
    style="margin-right: 20px; width: -webkit-fill-available"
    #searchBox
    [label]="'general.search' | translate"
    [value]="searchString"
    (value-changed)="onSearchString($event.target.value)"
  ></sh-search>
  <div *ngIf="!devicesService.isReleaseVersionActive()">
    <sh-text size="title-2" color="secondary">CSV</sh-text>
    <sh-icon
      button
      icon="export"
      id="Export"
      label="Export"
      (click)="export()"
    ></sh-icon>
  </div>

  <div style="width: 6px; padding-right: 10px"></div>
  <sh-icon
    *ngIf="!devicesService.isReleaseVersionActive()"
    button
    icon="import"
    id="Import"
    label="Import"
    (click)="filey.click()"
  ></sh-icon>
  <sh-button
    *ngIf="!devicesService.isReleaseVersionActive()"
    style="float: left; margin-left: 12px; width: 100px"
    size="m"
    [label]="'localization.addEntry' | translate"
    (click)="openCreateEntryModal()"
  ></sh-button>
</div>

<sh-table>
  <sh-table-row slot="header">
    <sh-table-head
      unsortable
      columns="8"
      [innerText]="'localization.key' | translate"
    ></sh-table-head>
    <sh-table-head
      unsortable
      columns="16"
      [innerText]="'localization.localization' | translate"
    ></sh-table-head>
    <sh-table-head unsortable icon columns="1"></sh-table-head>
  </sh-table-row>

  <sh-table-row
    *ngFor="let entry of translations"
    (contextmenu)="
      popoverService.openPopover(
        popovers,
        'localization-menu-' + entry.id,
        $event
      )
    "
  >
    <sh-table-cell columns="8" [innerText]="entry.key"></sh-table-cell>

    <sh-table-cell columns="16">
      <sh-input-text
        [disabled]="devicesService.isReleaseVersionActive()"
        rows="2"
        condensed
        textarea
        [label]="'localization.value' | translate"
        [value]="entry.value"
        (value-changed)="modifyItem(entry, $event.target.value)"
      ></sh-input-text>
    </sh-table-cell>

    <sh-table-cell icon columns="1" style="height: auto">
      <sh-icon
        *ngIf="!devicesService.isReleaseVersionActive()"
        button
        size="s"
        icon="delete"
        [label]="'general.delete' | translate"
        (click)="deleteItem(entry)"
      ></sh-icon>
    </sh-table-cell>
  </sh-table-row>

  <sh-table-row *ngIf="filteredEntries?.length === 0">
    <sh-table-cell
      columns="24"
      [innerText]="'localization.empty' | translate"
    ></sh-table-cell>
  </sh-table-row>
</sh-table>

<sh-pagination
  [pages]="totalPages"
  [attr.current-page]="currPage"
  (clicked-page-move)="paginate($event?.target?.currentPage, pageLen)"
  (next-page-move)="paginate($event?.target?.currentPage, pageLen)"
  (previous-page-move)="paginate($event?.target?.currentPage, pageLen)"
>
</sh-pagination>

<!-- Footer -->
<div
  class="buttons"
  [ngClass]="
    filteredEntries?.length ? 'position-relative' : 'position-absolute'
  "
>
  <div style="flex: 1"></div>
  <sh-button
    *ngIf="!hasChanges"
    color="tertiary"
    slot="footer"
    size="max-content"
    [label]="'general.close' | translate"
    (click)="
      router.navigate([
        'devices',
        this.paramsService.deviceId,
        'versions',
        this.paramsService.deviceVersionId,
        'hotspots'
      ])
    "
    style="margin: 0px 8px"
  ></sh-button>
  <sh-button
    *ngIf="!hasChanges"
    size="max-content"
    slot="footer"
    [label]="'general.next' | translate"
    (click)="
      router.navigate([
        'devices',
        this.paramsService.deviceId,
        'versions',
        this.paramsService.deviceVersionId,
        'axes'
      ])
    "
    style="margin: 0px 8px"
  ></sh-button>

  <sh-button
    *ngIf="!devicesService.isReleaseVersionActive() && hasChanges"
    color="tertiary"
    slot="footer"
    size="max-content"
    [label]="'general.discard' | translate"
    (click)="discard()"
    style="margin: 0px 8px"
  ></sh-button>
  <sh-button
    *ngIf="!devicesService.isReleaseVersionActive() && hasChanges"
    size="max-content"
    slot="footer"
    [label]="'general.save' | translate"
    (click)="save()"
    style="margin: 0px 8px"
  ></sh-button>
</div>

<backend-create-localization-entry
  #createEntryModal
></backend-create-localization-entry>
<backend-create-language #createLanguageModal></backend-create-language>
<input
  #filey
  accept=".csv"
  type="file"
  style="display: none"
  (change)="import()"
/>
