import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'backend-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  showHeader: boolean = true;

  public constructor(
    public themeService: ThemeService,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter(
          (event) => event instanceof NavigationEnd && event.url === '/login'
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.showHeader = false;
      });
  }
}
