<sh-modal
  id="footer-modal"
  sticky
  modal-height="fit-content"
  [visible]="confirmationService.visible"
  [label]="confirmationService.title | translate"
  [icon]="
    confirmationService.type === 1
      ? 'delete'
      : confirmationService.type === 2
      ? 'warning'
      : 'confirmation'
  "
>
  <sh-text
    *ngIf="confirmationService.type !== 2"
    [innerText]="
      confirmationService.message
        | translate : confirmationService.messageParams
    "
  ></sh-text>

  <sh-text *ngIf="confirmationService.type === 2"
    >Your Login session has expired! To continue working, please login
    again.</sh-text
  >

  <sh-button
    *ngIf="confirmationService.type !== 2"
    slot="footer"
    size="m"
    color="secondary"
    [label]="'general.cancel' | translate"
    (click)="confirmationService.cancel()"
  ></sh-button>

  <sh-button
    *ngIf="confirmationService.type === 2"
    slot="footer"
    size="m"
    color="secondary"
    label="Cancel"
    (click)="confirmationService.cancel()"
  ></sh-button>

  <sh-button
    *ngIf="confirmationService.type !== 2"
    slot="footer"
    size="m"
    [label]="
      (confirmationService.type === 1 ? 'general.delete' : 'general.ok')
        | translate
    "
    (click)="confirmationService.close()"
  ></sh-button>

  <sh-button
    *ngIf="confirmationService.type === 2"
    slot="footer"
    size="m"
    label="Login"
    (click)="confirmationService.close()"
  ></sh-button>
</sh-modal>
