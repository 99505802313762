<sh-splash-screen
  modal
  label="ExpertGuidance"
  info="© 2021 Siemens Healthcare GmbH, all rights reserved."
  image="url('/assets/images/SplashScreen_KeyVisual.jpg')"
>
  <sh-empty-state icon="user-avatar">
    <sh-button slot="footer" label="Login" (click)="login()"></sh-button>
  </sh-empty-state>
</sh-splash-screen>
