import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceVersionsService } from '../device-versions.service';
import { ParamsService } from '@backend/webapp/services/params.service';
import { DevicesService } from '../../devices.service';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';
import { ReportService } from '@backend/webapp/shared/report/report.service';

@Component({
  selector: 'backend-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss'],
})
export class DetailsComponent implements OnInit {
  activePage = '';

  public constructor(
    public router: Router,
    public paramsService: ParamsService,
    public devicesService: DevicesService,
    public deviceVersionService: DeviceVersionsService,
    public spinnerService: SpinnerService
  ) {}

  async ngOnInit(): Promise<void> {
    this.devicesService.activeDevice = await this.devicesService.getDevice();
  }

  assignActivePage(activePage: string): boolean {
    this.activePage = activePage;
    return true;
  }

  checkIfDeviceDraftVersionExists(deviceId: number): boolean {
    const allDevices = this.devicesService.devices;
    let isDraftVersionAvailable = false;

    if (!allDevices) {
      return false;
    }

    for (let device of allDevices) {
      if (device.id === deviceId) {
        if (device.deviceDraftVersion) {
          isDraftVersionAvailable = true;
        } else {
          isDraftVersionAvailable = false;
        }
        break;
      }
    }

    return isDraftVersionAvailable;
  }

  async createDeviceVersion(): Promise<void> {
    let allDevices = this.devicesService.devices;
    let deviceReleasedVersion = void 0;
    let deviceDraftVersion = void 0;
    let selectedDevice;
    for (let device of allDevices) {
      if (device.id === this.paramsService.deviceId) {
        selectedDevice = device;
        deviceReleasedVersion = device.deviceReleasedVersion;
        break;
      }
    }

    if (this.checkIfDeviceDraftVersionExists(this.paramsService.deviceId)) {
      this.router.navigate([
        'devices',
        selectedDevice?.id,
        'versions',
        selectedDevice.deviceDraftVersion
          ? selectedDevice.deviceDraftVersion?.id
          : selectedDevice.deviceReleasedVersion?.id,
        this.activePage,
      ]);
    } else {
      await this.deviceVersionService.createDeviceVersion(
        0,
        false,
        deviceReleasedVersion.id
      );

      await this.devicesService.updateDevices(true);
      allDevices = this.devicesService.devices;
      for (let device of allDevices) {
        if (device.id === this.paramsService.deviceId) {
          deviceDraftVersion = device.deviceDraftVersion;
          break;
        }
      }
      this.router.navigate([
        'devices',
        this.paramsService.deviceId,
        'versions',
        deviceDraftVersion?.id,
        this.activePage,
      ]);
    }
  }
}
