import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import { PopoverService } from '@backend/webapp/services/popover.service';
import { HotspotsService } from '../../hotspots.service';
import { DetailsService } from '../../details.service';
import { ScreensService } from '../../screens.service';

import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { LanguageService } from '@backend/webapp/services/language.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Constants } from '@backend/interfaces';
import { ParamsService } from '@backend/webapp/services/params.service';
import { DevicesService } from '@backend/webapp/devices/devices.service';

@Component({
  selector: 'backend-hotspots-collection',
  templateUrl: './hotspots-collection.component.html',
  styleUrls: ['./hotspots-collection.component.css'],
})
export class HotspotsCollectionComponent implements OnInit, OnDestroy {
  @ViewChildren('tableRow')
  public tableRows: QueryList<ElementRef<HTMLElement>>;

  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  public selectedHotspotId = null;
  public selectedScreenId = null;

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  searchString = '';
  languageSubscription: Subscription;
  displayedHotspotsCollection: any[] = [];

  public constructor(
    public popoverService: PopoverService,
    public hotspotsService: HotspotsService,
    public detailsService: DetailsService,
    public screensService: ScreensService,
    public languageService: LanguageService,
    public renderer: Renderer2,
    public localization: LocalizationService,
    public paramsService: ParamsService,
    public router: Router,
    public devicesService: DevicesService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.scrollandUpdateHotspot();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          await this.scrollandUpdateHotspot();
        }
      }
    );
    this.displayedHotspotsCollection = this.hotspotsService.hotspots;
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  public onSearchString(searchedString: string): void {
    this.searchString = searchedString;
    this.searchHotspotsByNameAndDesc();
  }

  private searchHotspotsByNameAndDesc(): void {
    if (!this.searchString) {
      this.displayedHotspotsCollection = this.hotspotsService.hotspots;
    } else {
      const searchText = this.searchString.toLocaleLowerCase();
      this.displayedHotspotsCollection = this.hotspotsService.hotspots.filter(
        (collection) => {
          let hostspotNameAndDescription =
            collection?.title[0]?.value.toString();
          hostspotNameAndDescription +=
            collection?.description[0]?.value.toString();
          return hostspotNameAndDescription
            .toLocaleLowerCase()
            .includes(searchText);
        }
      );
    }
  }

  async scrollandUpdateHotspot() {
    await this.hotspotsService.updateHotspots(true);
    if (this.hotspotsService.editedHotspot) {
      const eh = this.hotspotsService.editedHotspot.toString();
      if (this.tableRows) {
        const tableRows = this.tableRows.toArray();
        for (const tableRow of tableRows) {
          const elemId = tableRow.nativeElement.getAttribute('id');
          if (elemId === eh) {
            const row = document.getElementById(elemId) as HTMLElement;
            this.selectedHotspotId = this.hotspotsService.editedHotspot;
            row.setAttribute('expanded', 'true');
            tableRow.nativeElement.scrollIntoView();
            break;
          }
        }
      }
    }
    this.scrollToScreen();
  }

  scrollToScreen() {
    const screen = document.getElementById(
      this.screensService.lastModifiedScreenId
    );
    if (
      screen == null ||
      this.hotspotsService.editedHotspot !== this.selectedHotspotId
    )
      return;
    if (this.selectedScreenId === this.screensService.lastModifiedScreenId)
      return;
    this.selectedScreenId = this.screensService.lastModifiedScreenId;
    screen.setAttribute('expanded', 'true');
    screen.scrollIntoView();
  }

  public async deleteHotspot(id: number): Promise<void> {
    await this.hotspotsService.deleteHotspot(id);
    this.displayedHotspotsCollection = this.hotspotsService.hotspots;
  }
}
