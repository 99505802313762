import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { SpinnerService } from '../../spinner/spinner.service';
import { Status } from '@backend/interfaces';
import { NotificationService } from '../../shared/notification/notification.service';
import { ConfirmationService } from '../../shared/confirmation/confirmation.service';
import { ParamsService } from '../../services/params.service';
import { areObjectsIdentical } from '../../tools';
import { ReportService } from '@backend/webapp/shared/report/report.service';
import { ReportType } from '@backend/webapp/shared/report/report-type.enum';
import { DevicesService } from '../devices.service';
import { Device } from '@backend/api/Device/device.model';

@Injectable({
  providedIn: 'root',
})
export class DeviceVersionsService {
  private deviceVersionsSubject$: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >(null);
  public get deviceVersions$(): Observable<any[]> {
    return this.deviceVersionsSubject$.asObservable();
  }
  public get deviceVersions(): any[] {
    return this.deviceVersionsSubject$.value;
  }

  private deviceVersionSubject$: BehaviorSubject<any> =
    new BehaviorSubject<any>(null);
  public get deviceVersion$(): Observable<any> {
    return this.deviceVersionSubject$.asObservable();
  }
  public get deviceVersion(): any {
    return this.deviceVersionSubject$.value;
  }

  private devicesWithVersionsSubject$: BehaviorSubject<any> =
    new BehaviorSubject([]);
  public get devicesWithVersions$(): Observable<any> {
    return this.devicesWithVersionsSubject$.asObservable();
  }

  public constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private paramsService: ParamsService,
    private reportService: ReportService,
    private deviceService: DevicesService
  ) {
    this.loadDevicesWithVersions();
  }

  private async loadDevicesWithVersions() {
    const devices = await lastValueFrom(this.http.get<any[]>(`/api/devices`));
    this.devicesWithVersionsSubject$.next(devices);
  }

  public async updateDeviceVersions(
    spinner: boolean = false
  ): Promise<boolean> {
    if (spinner) {
      this.spinnerService.showSpinner();
    }
    this.paramsService.updateParams();
    this.loadDevicesWithVersions();
    if (this.paramsService.deviceId !== null) {
      try {
        const versions = await lastValueFrom(
          this.http.get<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions`
          )
        );
        if (!areObjectsIdentical(this.deviceVersions, versions)) {
          this.deviceVersionsSubject$.next(versions);
        }
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.getError',
          null,
          error.error.message
        );
        return false;
      } finally {
        this.spinnerService.hideSpinner();
      }
    }

    this.deviceVersionsSubject$.next(null);
    this.spinnerService.hideSpinner();
    return false;
  }

  public async updateDeviceVersion(): Promise<boolean> {
    this.paramsService.updateParams();
    const deviceVersionId = this.paramsService.deviceVersionId;
    if (deviceVersionId !== null) {
      const deviceVersion = await this.getDeviceVersion(deviceVersionId);
      if (deviceVersion && this.deviceVersion !== deviceVersion) {
        this.deviceVersionSubject$.next(deviceVersion);
        return true;
      }
    }

    this.deviceVersionSubject$.next(null);
    return false;
  }

  public async getDeviceVersion(
    deviceVersionId: number = this.paramsService.deviceVersionId
  ): Promise<any> {
    if (!this.deviceVersions) {
      const updateSuccess = await this.updateDeviceVersions();
      if (!updateSuccess) {
        return null;
      }
    }

    return this.deviceVersions.find((a) => a.id === deviceVersionId);
  }

  public async createDeviceVersion(
    status: number,
    forceUpdate: boolean,
    copy?: string,
    deviceId: number = this.paramsService.deviceId
  ): Promise<boolean> {
    try {
      let query = {},
        showReport = false;
      if (copy !== null && copy !== 'Do not copy') {
        query = { copyVersion: copy };
        showReport = true;
      }
      if (showReport) {
        this.reportService.progress(null, null, null, ReportType.Progress);
      }
      const result = await lastValueFrom(
        this.http.post<any>(
          `/api/devices/${deviceId}/versions`,
          {
            status: parseInt(status as any, 10),
            forceUpdate: forceUpdate,
          },
          { params: query }
        )
      );
      this.reportService.close();
      if (showReport && result && result.report) {
        this.reportService.confirm(
          'Device Version: ' + name + ' Copied!',
          result.report,
          result.errors,
          ReportType.Success
        );
      }
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.createSuccess',
        { deviceVersionName: name }
      );
      return true;
    } catch (error) {
      this.reportService.close();
      this.notificationService.error(
        'general.error',
        'devices.versions.createError',
        { deviceVersionName: name },
        error.error.message
      );
      return false;
    } finally {
      await this.updateDeviceVersions(true);
    }
  }

  public async modifyDeviceVersion(
    deviceVersionId: number,
    name: string,
    version: number,
    appVersion: number,
    status: number,
    forceUpdate: boolean
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.put<any[]>(
          `/api/devices/${this.paramsService.deviceId}/versions/${deviceVersionId}`,
          {
            name: name,
            version: parseInt(version as any, 10),
            appVersion: parseInt(appVersion as any, 10),
            status: parseInt(status as any, 10),
            forceUpdate: forceUpdate,
          }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.modifySuccess',
        { deviceVersionName: name }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.modifyError',
        { deviceVersionName: name },
        error.error.message
      );
      return false;
    } finally {
      await this.updateDeviceVersions();
      this.spinnerService.hideSpinner();
    }
  }

  public async deleteDeviceVersion(
    deviceVersionId: number,
    isDraftDeleteReq?: boolean
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.delete<any[]>(
          `/api/devices/${this.paramsService.deviceId}/versions/${deviceVersionId}`
        )
      );
      if (isDraftDeleteReq) {
        this.notificationService.confirmation(
          'general.success',
          'devices.versions.deleteDraftSuccess',
          null
        );
      } else {
        this.notificationService.confirmation(
          'general.success',
          'devices.versions.deleteReleaseSuccess',
          null
        );
      }

      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.deleteError',
        null,
        error.error.message
      );
      return false;
    } finally {
      await this.updateDeviceVersions();
      this.spinnerService.hideSpinner();
    }
  }

  public getStatusEnumKeys(): Array<string> {
    const keys = Object.keys(Status);
    const slice = keys.slice(keys.length / 2, keys.length);
    for (let i = 0; i < slice.length; i++) {
      slice[i] = slice[i]
        .replace('DRAFT', 'Draft')
        .replace('REVIEW', 'Review')
        .replace('RELEASE', 'Release')
        .replace('DEPRECATED', 'Deprecated');
    }
    return slice;
  }

  public async deleteDevice(
    device: Device,
    deleteReleaseVersionReq: boolean,
    deleteDraftVersionReq: boolean
  ): Promise<boolean> {
    this.spinnerService.showSpinner();

    if (device?.deviceReleasedVersion?.id && deleteReleaseVersionReq) {
      if (!device?.deviceDraftVersion?.id) {
        await this.deviceService.deleteDevice(device.id);
      } else {
        await this.deleteDeviceVersion(
          device?.deviceReleasedVersion?.id,
          false
        );
      }
    }

    if (device?.deviceDraftVersion?.id && deleteDraftVersionReq) {
      if (!device?.deviceReleasedVersion?.id) {
        await this.deviceService.deleteDevice(device.id);
      } else {
        await this.deleteDeviceVersion(device?.deviceDraftVersion?.id, true);
      }
    }

    await this.deviceService.updateDevices();
    this.spinnerService.hideSpinner();
    return true;
  }

  public async addDeviceToPackageUsers(deviceId: number): Promise<void>{
    try {
      this.spinnerService.showSpinner();
      const deviceDetails = await lastValueFrom(
        this.http.get<Device>(`/api/devices/${deviceId}`)
      );
      if (deviceDetails) {
        const packageId = await lastValueFrom(
          this.http.get<number>(`/api/package/packageIdByBusinessLine/${deviceDetails.businessLine}`)
        );
        if (packageId) {
          await lastValueFrom(
            this.http.post(`/api/package/addDeviceToPackageUsers/${packageId}`, {})
          );
        }
      }
    } catch (error) {
      console.log(error);
      this.notificationService.error(
        'general.error',
        'appusers.addDeviceToPackageUsersError',
        null,
        error.error.message
      );
    } finally {
      this.spinnerService.hideSpinner();
    }
  }

  public async removeDeviceFromPackageUsers(device: Device): Promise<void>{
    try {
      this.spinnerService.showSpinner();
      //Remove device from pacakge users need only for released devices.
      if (!device.deviceReleasedVersion) {
        return;
      }
      const packageId = await lastValueFrom(
        this.http.get<number>(`/api/package/packageIdByBusinessLine/${device.businessLine}`)
      );
      if (packageId) {
        await lastValueFrom(
          this.http.post(`/api/package/removeDeviceFromPackageUsers/${packageId}`, { device })
        );
      }    
    }catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.removeDeviceToPackageUsersError',
        null,
        error.error.message
      );
    } finally {
      this.spinnerService.hideSpinner();
    }
  }
}
