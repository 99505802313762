<div>
  <div
    class="hotspot-image-container"
    [style.display]="hotspots?.length > 0 ? 'block' : 'none'"
  >
    <img
      [style.display]="asset && assetType === 'image' ? 'block' : 'none'"
      #image
      [src]="asset | s3Image | async"
      (dragover)="onDragOver($event)"
    />
    <div
      class="placeholder-img"
      *ngIf="!asset"
      (dragover)="onDragOver($event)"
    ></div>
    <video
      #videoPlayer
      [style.display]="
        assetType === 'video' && videoSource && videoType ? 'block' : 'none'
      "
      [muted]="true"
      autoplay
      loop
      controls
    >
      <source [src]="videoSource" [type]="videoType" />
    </video>
    <div
      *ngFor="let hotspot of hotspots"
      [className]="'hotspot information ' + getType(hotspot)"
      draggable="true"
      [style.left.px]="hotspot.position.x * assetScaleX"
      [style.top.px]="hotspot.position.y * assetScaleY"
      [style.width.px]="
        hotspot?.frame?.width ? hotspot.frame.width * assetScaleX : 30
      "
      [style.height.px]="
        hotspot?.frame?.height ? hotspot.frame.height * assetScaleY : 30
      "
      [title]="hotspot.text | getLocaleText : globalLanguage?.key : true"
      (dragstart)="onDragStart($event)"
      (dragover)="onDragOver($event)"
      (dragend)="onDragEnd($event, hotspot.id)"
    ></div>
  </div>

  <sh-table>
    <sh-table-row>
      <sh-table-head
        unsortable
        columns="4"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.position'
            | translate
        "
      >
      </sh-table-head>
      <sh-table-head
        unsortable
        columns="4"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.interactable'
            | translate
        "
      >
      </sh-table-head>
      <sh-table-head
        unsortable
        columns="10"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.text'
            | translate
        "
      >
      </sh-table-head>
      <sh-table-head
        unsortable
        columns="4"
        [innerHTML]="
          'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.type'
            | translate
        "
      >
      </sh-table-head>
      <sh-table-head unsortable icon columns="2"></sh-table-head>
    </sh-table-row>

    <ng-container *ngIf="hotspots">
      <sh-table-row *ngFor="let hotspot of hotspots">
        <sh-table-cell columns="4" [routerLink]="['hotspots', hotspot.id]">
          <p
            class="flow-text"
            [innerText]="
              '(' +
              hotspot.position?.x.toFixed(3) +
              ' | ' +
              hotspot.position?.y.toFixed(3) +
              ')'
            "
          ></p>
        </sh-table-cell>
        <sh-table-cell columns="4" [routerLink]="['hotspots', hotspot.id]">
          <sh-checkbox
            disabled
            label=""
            [active]="hotspot.interactable"
          ></sh-checkbox>
        </sh-table-cell>
        <sh-table-cell columns="10" [routerLink]="['hotspots', hotspot.id]">
          <p
            class="flow-text"
            [innerText]="
              hotspot.text | getLocaleText : globalLanguage?.key : true
            "
          ></p>
        </sh-table-cell>
        <sh-table-cell columns="4" [routerLink]="['hotspots', hotspot.id]">
          <p
            class="flow-text"
            [innerText]="
              'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.types.' +
                getType(hotspot) | translate
            "
          ></p>
        </sh-table-cell>
        <sh-table-cell icon columns="2" (click)="delete(hotspot.id)">
          <sh-icon button *ngIf="!workflowGuidesService.isReleaseVersionActive()" icon="delete"></sh-icon>
        </sh-table-cell>
      </sh-table-row>
    </ng-container>
  </sh-table>

  <div class="buttons" *ngIf="!workflowGuidesService.isReleaseVersionActive()">
    <sh-button
      size="m"
      class="add-button"
      [label]="'general.add' | translate"
      [routerLink]="['hotspots', 'add']"
    >
    </sh-button>
  </div>
</div>
