<sh-table>
  <sh-table-row slot="header">
    <sh-table-head
      unsortable
      columns="12"
      [innerHTML]="'devices.versions.assets.name' | translate"
    ></sh-table-head>
    <sh-table-head
      unsortable
      columns="11"
      [innerHTML]="'devices.versions.assets.file' | translate"
    ></sh-table-head>
    <sh-table-head unsortable icon columns="1"></sh-table-head>
  </sh-table-row>

  <sh-table-row *ngFor="let platform of platforms; index as i">
    <sh-table-cell columns="24" [innerText]="platform"></sh-table-cell>

    <ng-container *ngFor="let asset of assetsService.assets">
      <sh-table-row
        *ngIf="asset.platform === i"
        (contextmenu)="
          popoverService.openPopover(popovers, 'asset-menu-' + asset.id, $event)
        "
      >
        <sh-table-cell columns="12" [innerText]="asset.name"></sh-table-cell>
        <sh-table-cell
          columns="11"
          [innerText]="getBasename(asset.path)"
        ></sh-table-cell>

        <sh-table-cell icon columns="1">
          <sh-icon button icon="more" [id]="'asset-menu-' + asset.id"></sh-icon>
        </sh-table-cell>

        <sh-popover
          #popover
          [target]="'asset-menu-' + asset.id"
          position="left-up"
        >
          <sh-menu-item
            icon="edit"
            [label]="'general.edit' | translate"
            [routerLink]="[asset.id, 'edit']"
          ></sh-menu-item>
          <sh-menu-item
            icon="delete"
            [label]="'general.delete' | translate"
            (click)="assetsService.deleteAsset(asset.id)"
          ></sh-menu-item>
        </sh-popover>
      </sh-table-row>
    </ng-container>
  </sh-table-row>

  <div class="buttons">
    <sh-button
      class="new-button"
      size="m"
      icon="plus"
      [label]="'general.add' | translate"
      [routerLink]="['add']"
    ></sh-button>
  </div>
</sh-table>
