import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

import { IAudit } from '@backend/interfaces';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  private auditLogs: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public get $auditLogs(): Observable<any> {
    return this.auditLogs.asObservable();
  }

  private auditUsers: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public get $auditUsers(): Observable<any[]> {
    return this.auditUsers.asObservable();
  }

  public constructor(
    private http: HttpClient,
    private spinner: SpinnerService
  ) {}

  public async getAuditDetails(user: string) {
    this.spinner.showSpinner();
    this.auditLogs.next(
      await lastValueFrom(this.http.get<IAudit[]>(`/api/audit?user=${user}`))
    );
    this.spinner.hideSpinner();
  }

  public async getAuditUsers() {
    this.spinner.showSpinner();
    this.auditUsers.next(
      await lastValueFrom(this.http.get<any[]>(`/api/audit/users`))
    );
    this.spinner.hideSpinner();
  }

  public async getFilteredAuditDetails(
    user: string,
    action: string,
    period: string,
    from: string,
    to: string,
    currPage: number,
    perPage: number
  ) {
    this.spinner.showSpinner();
    this.auditLogs.next(
      await lastValueFrom(
        this.http.post<any>(`/api/audit`, {
          user,
          action,
          period,
          from,
          to,
          currPage,
          perPage,
        })
      )
    );
    this.spinner.hideSpinner();
  }

  public async exportAuditDetails(
    user: string,
    action: string,
    period: string,
    from: string,
    to: string
  ) {
    return await lastValueFrom(
      this.http.post<any>(`/api/audit/export`, {
        user,
        action,
        period,
        from,
        to,
      })
    );
  }
}
