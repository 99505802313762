import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { DevicesComponent } from './devices.component';
import { SharedModule } from '../shared/shared.module';
import { EditDeviceComponent } from './edit-device/edit-device.component';
import { RouterModule } from '@angular/router';
import { DeviceVersionsComponent } from './device-versions/device-versions.component';
import { EditDeviceVersionComponent } from './device-versions/modals/edit-device-version/edit-device-version.component';
import { DetailsModule } from './device-versions/details/details.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '../auth/auth.interceptor';

@NgModule({
  declarations: [
    DevicesComponent,
    EditDeviceComponent,
    DeviceVersionsComponent,
    EditDeviceVersionComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    RouterModule,
    DetailsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class DevicesModule {}
