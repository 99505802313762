<sh-modal
  icon="information"
  [label]="'Target Details'"
  [visible]="open"
  modal-height="600px"
  modal-width="1100px"
  (keyup.esc)="closeModal()"
  (modal-closed)="closeModal()"
>
  <sh-search
    size="body-2"
    class="search-box"
    [label]="'general.search' | translate"
    [value]="searchScreenString"
    (value-changed)="onSearchScreenString($event.target.value)"
  ></sh-search>

  <div class="card-wrapper">
    <div
      class="card"
      *ngFor="let screen of displayedScreens"
      (click)="onScreenSelection(screen?.key)"
    >
      <sh-card image-contain [image]="screen.image | s3Image | async">
        {{ screen.key }}
      </sh-card>
    </div>
  </div>
</sh-modal>
