import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { SpinnerService } from '../../../../../spinner/spinner.service';
import { NotificationService } from '../../../../../shared/notification/notification.service';
import { Platform } from '@backend/interfaces';
import { ConfirmationService } from '../../../../../shared/confirmation/confirmation.service';
import { ParamsService } from '../../../../../services/params.service';
import { areObjectsIdentical } from '../../../../../tools';

@Injectable({
  providedIn: 'root',
})
export class AssetsService {
  private assetsSubject$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  public get assets$(): Observable<any[]> {
    return this.assetsSubject$.asObservable();
  }
  public get assets(): any[] {
    return this.assetsSubject$.value;
  }

  private assetSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public get asset$(): Observable<any> {
    return this.assetSubject$.asObservable();
  }
  public get asset(): any {
    return this.assetSubject$.value;
  }

  public constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private paramsService: ParamsService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService
  ) {}

  public async updateAssets(spinner: boolean = false): Promise<boolean> {
    if (spinner) {
      this.spinnerService.showSpinner();
    }
    this.paramsService.updateParams();
    if (
      this.paramsService.deviceId !== null &&
      this.paramsService.deviceVersionId !== null
    ) {
      try {
        const values = await lastValueFrom(
          this.http.get<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/assets`
          )
        );
        if (!areObjectsIdentical(this.assets, values)) {
          this.assetsSubject$.next(values);
        }
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.assets.createError',
          null,
          error.error.message
        );
        return false;
      } finally {
        this.spinnerService.hideSpinner();
      }
    }

    this.assetsSubject$.next(null);
    this.spinnerService.hideSpinner();
    return false;
  }

  public async updateAsset(): Promise<boolean> {
    this.paramsService.updateParams();
    const assetId = this.paramsService.assetId;
    if (assetId !== null) {
      const asset = await this.getAsset(assetId);
      if (asset && this.asset !== asset) {
        this.assetSubject$.next(asset);
        return true;
      }
    }

    this.assetSubject$.next(null);
    return false;
  }

  public async getAsset(
    assetId: number = this.paramsService.assetId
  ): Promise<any> {
    if (!this.assets) {
      const updateSuccess = await this.updateAssets();
      if (!updateSuccess) {
        return null;
      }
    }

    return this.assets.find((a) => a.id === assetId);
  }

  public async createAsset(
    assetName: string,
    platform: string,
    file: File
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      const formData = new FormData();
      formData.append('name', assetName);
      formData.append('platform', Platform[platform.toUpperCase()]);
      formData.append('asset', file, file.name);
      await lastValueFrom(
        this.http.post(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/assets`,
          formData
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.assets.createSuccess',
        { assetName }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.assets.createError',
        { assetName },
        error.error.message
      );
      return false;
    } finally {
      await this.updateAssets();
      this.spinnerService.hideSpinner();
    }
  }

  public async modifyAsset(
    assetId: number,
    assetName: string,
    platform: string,
    file?: File
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      const formData = new FormData();
      formData.append('name', assetName);
      formData.append('platform', Platform[platform.toUpperCase()]);
      if (file) {
        formData.append('asset', file, file.name);
      }
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/assets/${assetId}`,
          formData
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.assets.modifySuccess',
        { assetName }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.assets.modifyError',
        { assetName },
        error.error.message
      );
      return false;
    } finally {
      await this.updateAssets();
      this.spinnerService.hideSpinner();
    }
  }

  public async deleteAsset(assetId: number): Promise<boolean> {
    const asset = await this.getAsset(assetId);
    const assetName = asset.name;
    if (
      await this.confirmationService.confirmDelete(
        'devices.versions.assets.deleteAsset',
        'devices.versions.assets.deleteAssetConfirmation',
        { assetName }
      )
    ) {
      this.spinnerService.showSpinner();
      try {
        await lastValueFrom(
          this.http.delete<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/assets/${assetId}`
          )
        );
        this.notificationService.confirmation(
          'general.success',
          'devices.versions.assets.deleteSuccess',
          { assetName }
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.assets.deleteError',
          { assetName },
          error.error.message
        );
        return false;
      } finally {
        await this.updateAssets();
        this.spinnerService.hideSpinner();
      }
    }
  }

  public getPlatformEnumKeys(): Array<string> {
    const keys = Object.keys(Platform);
    const slice = keys.slice(keys.length / 2, keys.length);
    for (let i = 0; i < slice.length; i++) {
      slice[i] = slice[i]
        .replace('IOS', 'iOS')
        .replace('ANDROID', 'Android')
        .replace('UNIVERSAL', 'Universal');
    }
    return slice;
  }
}
