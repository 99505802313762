import { Injectable, EventEmitter } from '@angular/core';
import { ReportType } from './report-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  public closed = new EventEmitter();

  private _title = null;
  public set title(value: string) {
    this._title = value;
  }
  public get title(): string {
    return this._title;
  }

  private _message = null;
  public set message(value: string) {
    this._message = value;
  }
  public get message(): string {
    return this._message;
  }

  private _messageParams = {};
  public set messageParams(value: any) {
    this._messageParams = value;
  }
  public get messageParams(): any {
    return this._messageParams;
  }

  private _type = ReportType.Success;
  public set type(value: ReportType) {
    this._type = value;
  }
  public get type(): ReportType {
    return this._type;
  }

  private _errors = 0;
  public set errors(value: number) {
    this._errors = value;
  }
  public get errors(): number {
    return this._errors;
  }

  private _visible = false;
  public set visible(value: boolean) {
    this._visible = value;
  }
  public get visible(): boolean {
    return this._visible;
  }

  public confirm(
    title: string,
    message: string,
    errors: number,
    type?: ReportType
  ): Promise<boolean> {
    return this.confirmInternal(title, message, errors, type);
  }

  public progress(
    title: string,
    message: string,
    errors: number,
    type?: ReportType
  ): Promise<boolean> {
    return this.confirmInternal(title, message, errors, type);
  }

  private confirmInternal(
    title: string,
    message: string,
    errors: number,
    type?: ReportType
  ): Promise<boolean> {
    this.title = title;
    this.message = message;
    this.type = type;
    this.visible = true;
    this.errors = errors;

    return new Promise<boolean>((resolve, reject) => {
      this.closed.subscribe((result) => {
        resolve(result);
      });
    });
  }

  public close(): void {
    this.closed.emit(true);
    this.hideModal();
  }

  private hideModal(): void {
    this.visible = false;
  }
}
