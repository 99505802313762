import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { GlobalLocalizationComponent } from './global-localization/global-localization.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { SharedModule } from './shared/shared.module';
import { DetailsModule } from './devices/device-versions/details/details.module';
import { DevicesModule } from './devices/devices.module';
import { AppuserComponent } from './appuser/appuser.component';
import { AddUserComponent } from './appuser/modals/add-user/add-user.component';
import { AdminusersComponent } from './adminusers/adminusers.component';
import { AddAdminUserComponent } from './adminusers/modals/add-admin-user/add-admin-user.component';
import { TokenInterceptor } from './auth/auth.interceptor';
import { PagesComponent } from './pages/pages.component';
import { EditorComponent } from './pages/editor/editor.component';
import { XmlparserPipe } from './pages/editor/xmlparser.pipe';
import { AddPageComponent } from './pages/add-page/add-page.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { WorkflowGuidesModule } from './workflowguides/workflowguides.module';
import { AuditComponent } from './audit/audit.component';
import { AppdashboardComponent } from './appdashboard/appdashboard.component';
import { ImgConvertorComponent } from './img-convertor/img-convertor.component';
import { UserAssignedSitesComponent } from './appuser/modals/user-assigned-sites/user-assigned-sites.component';
import { SiteplannerModule } from './siteplanner/siteplanner.module';
import { AppUserSettingsComponent } from './appuser/modals/appuser-settings/appuser-settings.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import {LicensePackageModule} from "./license-package/license-package.module";

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SpinnerComponent,
    GlobalLocalizationComponent,
    AppuserComponent,
    AddAdminUserComponent,
    AddUserComponent,
    AppUserSettingsComponent,
    UserAssignedSitesComponent,
    PagesComponent,
    FeedbackComponent,
    EditorComponent,
    XmlparserPipe,
    AddPageComponent,
    AdminusersComponent,
    AuditComponent,
    AppdashboardComponent,
    ImgConvertorComponent,
    UserGuideComponent
  ],
  imports: [
    AppRoutingModule,
    AuthModule,
    BrowserModule,
    WorkflowGuidesModule,
    DevicesModule,
    SiteplannerModule,
    DetailsModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    LicensePackageModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
