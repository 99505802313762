<sh-page>
  <sh-pane slot="left" behavior="collapse">
    <sh-accordion label="Welcome">
      <sh-search label="Search"></sh-search>
      <sh-menu-item label="Introduction"> </sh-menu-item>
      <sh-menu-item label="User Guides"> </sh-menu-item>
      <sh-menu-item label="Quick Links"> </sh-menu-item>
      <sh-menu-item label="What's New"> </sh-menu-item>
    </sh-accordion>
    <sh-accordion label="Equipments" expanded>
      <sh-menu-item
        *ngFor="let device of allDevices"
        [label]="
          device.deviceDescription?.name
            | getLocaleText : globalLanguage?.key : true
        "
        [expanded]="device?.id == paramsService.deviceId"
        [toggle]="false"
        (click)="
          $event.stopPropagation();
          devicesService.activeDevice = device;
          router.navigate([
            'devices',
            device?.id,
            'versions',
            device.deviceDraftVersion
              ? device.deviceDraftVersion?.id
              : device.deviceReleasedVersion?.id,
            'features'
          ]);
          $event.stopPropagation()
        "
      >
        <ng-container>
          <sh-menu-item
            *ngIf="device.deviceReleasedVersion?.id"
            label=""
            style="position: relative"
            [expanded]="
              router.url.indexOf(
                device.deviceReleasedVersion?.id.toString()
              ) !== -1
            "
            [toggle]="false"
            (click)="
              router.navigate([
                'devices',
                device?.id,
                'versions',
                device.deviceReleasedVersion?.id,
                'features'
              ]);
              $event.stopPropagation()
            "
          >
            <sh-badge
              slot="functions"
              label="RELEASE"
              style="float: right; position: absolute"
            ></sh-badge>
            <ng-container>
              <sh-menu-item
                [label]="'devices.versions.features.title' | translate"
                [active]="
                  router.url.indexOf(
                    device.deviceReleasedVersion?.id.toString()
                  ) !== -1 && router.url.indexOf('/features') !== -1
                "
                (click)="
                  router.navigate([
                    'devices',
                    device?.id,
                    'versions',
                    device.deviceReleasedVersion?.id,
                    'features'
                  ]);
                  $event.stopPropagation()
                "
              >
              </sh-menu-item>
              <sh-menu-item
                [label]="'devices.versions.hotspots.title' | translate"
                [active]="
                  router.url.indexOf(
                    device.deviceReleasedVersion?.id.toString()
                  ) !== -1 && router.url.indexOf('/hotspots') !== -1
                "
                (click)="
                  router.navigate([
                    'devices',
                    device?.id,
                    'versions',
                    device.deviceReleasedVersion?.id,
                    'hotspots'
                  ]);
                  $event.stopPropagation()
                "
              >
              </sh-menu-item>
              <sh-menu-item
                [label]="'devices.versions.localization.title' | translate"
                [active]="
                  router.url.indexOf(
                    device.deviceReleasedVersion?.id.toString()
                  ) !== -1 && router.url.indexOf('/localization') !== -1
                "
                (click)="
                  router.navigate([
                    'devices',
                    device?.id,
                    'versions',
                    device.deviceReleasedVersion?.id,
                    'localization'
                  ]);
                  $event.stopPropagation()
                "
              ></sh-menu-item>
              <sh-menu-item
                [label]="'devices.versions.axes.title' | translate"
                [active]="
                  router.url.indexOf(
                    device.deviceReleasedVersion?.id.toString()
                  ) !== -1 && router.url.indexOf('/axes') !== -1
                "
                (click)="
                  router.navigate([
                    'devices',
                    device?.id,
                    'versions',
                    device.deviceReleasedVersion?.id,
                    'axes'
                  ]);
                  $event.stopPropagation()
                "
              ></sh-menu-item>
            </ng-container>
          </sh-menu-item>
          <sh-menu-item
            *ngIf="device.deviceDraftVersion?.id"
            label=""
            [toggle]="false"
            style="position: relative"
            (click)="
              router.navigate([
                'devices',
                device?.id,
                'versions',
                device.deviceDraftVersion?.id,
                'features'
              ]);
              $event.stopPropagation()
            "
            [expanded]="
              router.url.indexOf(device.deviceDraftVersion?.id.toString()) !==
              -1
            "
          >
            <sh-badge
              slot="functions"
              label="DRAFT"
              style="float: right; position: absolute; background: '#999999'"
            ></sh-badge>
            <ng-container>
              <sh-menu-item
                [label]="'devices.versions.features.title' | translate"
                [active]="
                  router.url.indexOf(
                    device.deviceDraftVersion?.id.toString()
                  ) !== -1 && router.url.indexOf('/features') !== -1
                "
                (click)="
                  router.navigate([
                    'devices',
                    device?.id,
                    'versions',
                    device.deviceDraftVersion?.id,
                    'features'
                  ]);
                  $event.stopPropagation()
                "
              >
              </sh-menu-item>
              <sh-menu-item
                [label]="'devices.versions.hotspots.title' | translate"
                [active]="
                  router.url.indexOf(
                    device.deviceDraftVersion?.id.toString()
                  ) !== -1 && router.url.indexOf('/hotspots') !== -1
                "
                (click)="
                  router.navigate([
                    'devices',
                    device?.id,
                    'versions',
                    device.deviceDraftVersion?.id,
                    'hotspots'
                  ]);
                  $event.stopPropagation()
                "
              >
              </sh-menu-item>
              <sh-menu-item
                [label]="'devices.versions.localization.title' | translate"
                [active]="
                  router.url.indexOf(
                    device.deviceDraftVersion?.id.toString()
                  ) !== -1 && router.url.indexOf('/localization') !== -1
                "
                (click)="
                  router.navigate([
                    'devices',
                    device?.id,
                    'versions',
                    device.deviceDraftVersion?.id,
                    'localization'
                  ]);
                  $event.stopPropagation()
                "
              ></sh-menu-item>
              <sh-menu-item
                [label]="'devices.versions.axes.title' | translate"
                [active]="
                  router.url.indexOf(
                    device.deviceDraftVersion?.id.toString()
                  ) !== -1 && router.url.indexOf('/axes') !== -1
                "
                (click)="
                  router.navigate([
                    'devices',
                    device?.id,
                    'versions',
                    device.deviceDraftVersion?.id,
                    'axes'
                  ]);
                  $event.stopPropagation()
                "
              ></sh-menu-item>
            </ng-container>
          </sh-menu-item>
        </ng-container>
      </sh-menu-item>
    </sh-accordion>
  </sh-pane>
  <sh-card
    *ngIf="
      router.url.indexOf('/devices') !== -1 &&
      router.url.indexOf('/versions/') === -1
    "
    style="margin: -16px; width: calc(100% + 32px)"
  >
    <div class="search-header">
      <sh-search
        size="body-2"
        class="search-box"
        [label]="'general.search' | translate"
        [value]="searchString"
        (value-changed)="onSearchString($event.target.value)"
      ></sh-search>
      <sh-button
        size="max-content"
        [label]="'general.add' | translate"
        [routerLink]="'add'"
      ></sh-button>
    </div>
    <div class="equipment-header">
      <sh-text size="header-1">
        {{ 'header.tabs.devices' | translate }}
        {{ getallEquipmentsCount() }}
      </sh-text>
      <div class="sort-by">
        <sh-text size="header-1"> {{ 'general.sort' | translate }}: </sh-text>
        <sh-icon
          *ngIf="isAscending"
          button
          size="s"
          icon="sort-ascending"
          class="sort-icon"
          (click)="toggleSort()"
        >
        </sh-icon>
        <sh-icon
          *ngIf="!isAscending"
          button
          size="s"
          icon="sort-descending"
          class="sort-icon"
          (click)="toggleSort()"
        >
        </sh-icon>
      </div>
    </div>

    <sh-table (scroll-end)="onScrollEnd()">
      <sh-table-row slot="header">
        <sh-table-head
          unsortable
          columns="3"
          [innerHTML]="'devices.thumbnail' | translate"
        ></sh-table-head>

        <sh-table-head
          unsortable
          columns="3"
          [innerHTML]="'devices.name' | translate"
        ></sh-table-head>

        <sh-table-head
          unsortable
          columns="12"
          [innerHTML]="'devices.description' | translate"
        ></sh-table-head>

        <sh-table-head
          unsortable
          columns="3"
          [innerHTML]="'devices.businessLine' | translate"
        ></sh-table-head>

        <sh-table-head
          unsortable
          columns="2"
          [innerHTML]="'devices.versions.status' | translate"
        ></sh-table-head>

        <sh-table-head unsortable icon columns="1"></sh-table-head>
        <sh-table-head unsortable icon columns="1"></sh-table-head>
      </sh-table-row>

      <sh-table-row
        *ngFor="let device of displayedDevices"
        [routerLink]="[
          device?.id,
          'versions',
          device.deviceDraftVersion
            ? device.deviceDraftVersion?.id
            : device.deviceReleasedVersion?.id,
          'features'
        ]"
      >
        <sh-table-cell columns="3">
          <img
            class="thumbnailImg"
            [src]="device?.deviceDescription?.thumbnail | s3Image | async"
          />
        </sh-table-cell>

        <sh-table-cell columns="3">
          <p
            class="flow-text"
            [innerText]="
              device.deviceDescription?.name
                | getLocaleText : globalLanguage?.key : true
            "
          ></p>
        </sh-table-cell>

        <sh-table-cell columns="12">
          <p
            class="flow-text"
            [innerText]="
              device.deviceDescription?.description
                | getLocaleText : globalLanguage?.key : true
            "
          ></p>
        </sh-table-cell>

        <sh-table-cell columns="3">
          <p
            class="flow-text"
            [innerText]="
              BusinessLineUtility.getBusinessLineName(device.businessLine)
            "
          ></p>
        </sh-table-cell>

        <sh-table-cell columns="2">
          <sh-badge
            *ngIf="device.deviceReleasedVersion"
            label="Release"
          ></sh-badge>
          <sh-badge
            *ngIf="device.deviceDraftVersion"
            label="Draft"
            style="background: '#999999'; margin: 4px"
          ></sh-badge>
        </sh-table-cell>
        <sh-table-cell icon columns="1">
          <sh-icon
            button
            size="s"
            icon="delete"
            (click)="promtDeleteDevice(device); $event.stopPropagation()"
          >
          </sh-icon>
        </sh-table-cell>
        <sh-table-cell icon columns="1">
          <sh-icon
            button
            size="s"
            icon="edit"
            [routerLink]="['edit', device.id]"
          >
          </sh-icon>
        </sh-table-cell>
      </sh-table-row>

      <sh-table-row
        *ngIf="devicesService.devices != null && displayedDevices.length == 0"
      >
        <sh-table-cell columns="11"></sh-table-cell>
        <sh-table-cell
          columns="13"
          [innerText]="'devices.empty' | translate"
        ></sh-table-cell>
      </sh-table-row>
    </sh-table>
  </sh-card>
  <sh-modal
    sticky
    id="sticky-modal"
    type="warning"
    [label]="'devices.deleteDevice' | translate"
    modal-height="300px"
    modal-width="500px"
    [visible]="showDeleteDeviceModal"
  >
    You are going to delete
    {{
      deviceTobeDeleted?.deviceDescription.name
        | getLocaleText : globalLanguage?.key : true
    }}. Deleting will permanently remove the content from CMS portal and will
    not be available for viewing the ExpertGuidance app.
    <br />
    <br />
    Do you want to delete ?
    <br />
    <br />
    <div style="padding-left: 10px">
      <sh-checkbox
        label="Release"
        *ngIf="deviceTobeDeleted?.deviceReleasedVersion"
        (checked)="deleteReleaseVersionReq = !deleteReleaseVersionReq"
        [active]="deleteReleaseVersionReq"
      ></sh-checkbox>
      <sh-checkbox
        label="Draft"
        *ngIf="deviceTobeDeleted?.deviceDraftVersion"
        (checked)="deleteDraftVersionReq = !deleteDraftVersionReq"
        [active]="deleteDraftVersionReq"
      ></sh-checkbox>
    </div>

    <sh-button
      color="secondary"
      slot="footer"
      label="No"
      (click)="showDeleteDeviceModal = false"
    ></sh-button>
    <sh-button
      [disabled]="
        !(
          deleteDraftVersionReq ||
          deleteReleaseVersionReq ||
          deleteDeviceWithoutVersion
        )
      "
      slot="footer"
      label="Yes"
      (click)="deleteDevice()"
    ></sh-button>
  </sh-modal>
  <sh-card
    *ngIf="
      this.router.url.indexOf('/devices/') !== -1 &&
      this.router.url.indexOf('/versions/') !== -1
    "
    style="margin: -16px; width: calc(100% + 32px)"
  >
    <router-outlet></router-outlet>
  </sh-card>
</sh-page>
