import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

import { SpinnerService } from '../spinner/spinner.service';
import { NotificationService } from '../shared/notification/notification.service';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  private _feedbacks$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public get feedbacks$(): Observable<any[]> {
    return this._feedbacks$.asObservable();
  }
  public get feedbacks(): any[] {
    return this._feedbacks$.value;
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly spinner: SpinnerService,
    private readonly notificationService: NotificationService
  ) {
    this.getAllFeedbacks();
  }

  private async getAllFeedbacks(): Promise<boolean> {
    this.spinner.showSpinner();
    try {
      this._feedbacks$.next(
        await lastValueFrom(this.http.get<any[]>('/api/feedbacks'))
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'feedback.getError',
        null,
        error.error.message
      );
      return false;
    } finally {
      this.spinner.hideSpinner();
    }
  }
}
