import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParamsService } from '@backend/webapp/services/params.service';
import { LanguageService } from '@backend/webapp/services/language.service';
import { Hotspot } from '@backend/webapp/workflowguides/phases/phase-versions/key-activities/steps/assets/hotspots/hotspot';
import { ConfirmationService } from '@backend/webapp/shared/confirmation/confirmation.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HotspotsService {
  private get apiRoute(): string {
    return (
      `/api/workflowguides/phases/${this.paramsService.phaseWrapperId}/versions/${this.paramsService.phaseVersionId}/keyactivities/${this.paramsService.keyActivityId}/` +
      `steps/${this.paramsService.stepId}/assets/${this.paramsService.assetId}/hotspots/`
    );
  }

  constructor(
    private readonly httpClient: HttpClient,
    private readonly paramsService: ParamsService,
    private confirmationService: ConfirmationService
  ) {}

  public async getAll(): Promise<Hotspot[]> {
    return await lastValueFrom(this.httpClient.get<Hotspot[]>(this.apiRoute));
  }

  public async delete(id: any) {
    if (
      await this.confirmationService.confirm(
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.delete',
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.hotspots.deleteConfirmation',
        { hotspot: id }
      )
    ) {
      this.paramsService.isLoading = true;
      try {
        return await lastValueFrom(this.httpClient.delete(this.apiRoute + id));
      } catch (e) {
        console.log(e);
        return null;
      } finally {
        this.paramsService.isLoading = false;
      }
    }
  }

  async editPosition(
    id: number,
    position: { x: number; y: number }
  ): Promise<Hotspot> {
    return await lastValueFrom(
      this.httpClient.put<Hotspot>(this.apiRoute + id + '/position', position)
    );
  }

  async create(hotspot: any): Promise<Hotspot> {
    return await lastValueFrom(
      this.httpClient.post<Hotspot>(this.apiRoute, hotspot)
    );
  }

  async editText(id: number, text: string, langKey: string) {
    return await lastValueFrom(
      this.httpClient.put<Hotspot>(this.apiRoute + id + '/text', {
        text,
        langKey,
      })
    );
  }

  public async get(
    hotspotId: number = this.paramsService.hotspotId
  ): Promise<Hotspot> {
    if (!hotspotId) return null;
    return await lastValueFrom(
      this.httpClient.get<Hotspot>(this.apiRoute + hotspotId)
    );
  }

  public async edit(hotspotId: number, interactable: boolean) {
    return await lastValueFrom(
      this.httpClient.put<Hotspot>(this.apiRoute + hotspotId, { interactable })
    );
  }

  public async editIcon(
    id: number,
    x: number,
    y: number,
    glyph: string,
    rotation: number
  ): Promise<Hotspot> {
    return await lastValueFrom(
      this.httpClient.put<Hotspot>(this.apiRoute + id + '/icon', {
        x,
        y,
        glyph,
        rotation,
      })
    );
  }

  public async editFrame(
    id: number,
    x: number,
    y: number,
    width: number,
    height: number
  ): Promise<Hotspot> {
    return await lastValueFrom(
      this.httpClient.put<Hotspot>(this.apiRoute + id + '/frame', {
        x,
        y,
        width,
        height,
      })
    );
  }
}
