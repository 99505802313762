<sh-card>
  <sh-text
    size="header-2"
    [innerHTML]="
      (isEditMode ? 'devices.editPhase' : 'devices.addPhase') | translate
    "
  ></sh-text>
  <div class="flex-container">
    <div
      class="display-flex-column row"
      style="padding-top: 9px"
      *ngIf="!isEditMode"
    >
      <div class="display-flex-row">
        <sh-text
          size="header-4"
          class="sh-text"
          [innerHTML]="'devices.selectExistingWorkflow' | translate"
        ></sh-text>
        <sh-icon icon="information" size="xs" id="info-2"></sh-icon>
        <sh-tooltip
          label="Phase Name"
          target="info-2"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div class="display-flex-row">
        <sh-dropdown [label]="'devices.selectExistingWorkflow' | translate">
          <sh-menu-item
            *ngFor="let workflow of workflowList"
            [label]="
              workflow.title | getLocaleText : globalLanguage?.key : true
            "
            (click)="GetPhasesForSelectedWorkFlow(workflow.id)"
          ></sh-menu-item>
        </sh-dropdown>
      </div>
    </div>
    <div
      class="display-flex-column row"
      style="padding-top: 12px"
      *ngIf="!isEditMode"
    >
      <div class="display-flex-row">
        <sh-checkbox
          [disabled]="!workflowListClicked"
          [label]="'devices.copyExistingPhase' | translate"
          (click)="copyExistingPhase = !copyExistingPhase"
        ></sh-checkbox>
        <sh-icon icon="information" size="xs" id="info-1"></sh-icon>
        <sh-tooltip
          label="Copy Existing Phase"
          target="info-1"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div class="display-flex-row">
        <sh-dropdown
          *ngIf="!isEditMode"
          [disabled]="!copyExistingPhase"
          [label]="'devices.copyExistingPhase' | translate"
        >
          <sh-menu-item
            *ngFor="let phase of phaseList"
            [label]="phase.name | getLocaleText : globalLanguage?.key : true"
            (click)="copyPhaseWrapperName(phase)"
          ></sh-menu-item>
        </sh-dropdown>
      </div>
    </div>
    <div class="display-flex-column row">
      <div class="display-flex-row">
        <sh-text
          size="header-4"
          class="sh-text"
          [innerHTML]="
            (isEditMode ? 'devices.phaseName' : 'devices.newPhaseName')
              | translate
          "
        ></sh-text>
        <sh-icon icon="information" size="xs" id="info-2"></sh-icon>
        <sh-tooltip
          label="Workflow Name"
          target="info-2"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div>
        <sh-input-text
          mandatory
          [error]="!isPhaseNameValid"
          error-message="Phase with same name already exists."
          [label]="'devices.newPhaseName' | translate"
          #inputName
          mandatory
          [label]="'workflowguides.workflows.titleCol' | translate"
          [value]="name"
          (value-changed)="checkForValidPhaseName($event.target.value)"
        ></sh-input-text>
      </div>
    </div>
  </div>
  <div class="flex-container">
    <div class="display-flex-column row">
      <div class="display-flex-row">
        <sh-text
          size="header-4"
          class="sh-text"
          [innerHTML]="'devices.description' | translate"
        ></sh-text>
        <sh-icon icon="information" size="xs" id="info-4"></sh-icon>
        <sh-tooltip
          label="Description"
          target="info-4"
          placement="top"
          variation="short"
        ></sh-tooltip>
      </div>
      <div>
        <sh-input-text
          textarea
          mandatory
          [label]="'devices.description' | translate"
          rows="6"
          #inputDesc
          [value]="description"
          (value-changed)="description = $event.target.value"
        ></sh-input-text>
        <div class="float-right">
          <sh-text size="body-2" color="secondary"
            >Word Count: {{ description?.length }}/1000</sh-text
          >
        </div>
      </div>
    </div>
  </div>
  <div class="flex-container" style="width: 66.66%">
    <div class="display-flex-column row">
      <div class="display-flex-row">
        <sh-text
          size="header-4"
          class="sh-text"
          color="primary"
          [innerHTML]="'devices.uploadImage' | translate"
        ></sh-text>
      </div>

      <div>
        <img
          id="workflowimage"
          *ngIf="thumbnail && !thumbnailChanged"
          [src]="thumbnail | s3Image | async"
        />
        <img #previewImage *ngIf="thumbnail && thumbnailChanged" />
        <sh-text #inputFileEditbox [value]="thumbnailBasename">{{
          thumbnailBasename
        }}</sh-text>
      </div>

      <divs
        *ngIf="!thumbnail"
        class="sh-empty-state display-flex-column"
        style="align-items: center; padding: 60px"
        (dragover)="preventDefault($event)"
        (drop)="drop($event)"
      >
        <div>
          <sh-icon icon="page" size="l" class="empty-state-icon"> </sh-icon>
        </div>
        <div>
          <sh-text class="empty-state-text" size="body-1">
            Choose a file or drag & drop it here
          </sh-text>
        </div>
      </divs>
      <div class="padding-top-12">
        <sh-button
          class="float-right"
          size="m"
          [label]="'devices.uploadFile' | translate"
          (click)="selectFile()"
        ></sh-button>
      </div>
    </div>
  </div>
  <div class="buttons">
    <sh-button
      [disabled]="!isPhaseNameValid || !inputName.value"
      size="m"
      (click)="save()"
      [label]="'general.save' | translate"
    ></sh-button>
    <sh-button
      color="secondary"
      (click)="cancel()"
      size="m"
      [label]="'general.cancel' | translate"
    ></sh-button>
  </div>
</sh-card>

<input
  #inputFile
  type="file"
  accept=".jpg,.jpeg,.png"
  style="display: none"
  (change)="fileChange($event.target.files[0])"
/>
