<sh-modal
  *ngIf="open"
  [visible]="open"
  sticky="true"
  modal-width="85%"
  modal-height="90%"
>
  <sh-text slot="header">
    <sh-icon icon="user-edit" size="s"></sh-icon>
    <sh-text size="header-1"
      >Add User
      <sh-icon
        button
        icon="cancel"
        class="close-button"
        size="s"
        (click)="closeModal()"
      ></sh-icon>
    </sh-text>
  </sh-text>
  <sh-grid spacing="s">
    <div columns="2">
      <sh-menu-item
        [label]="'appusers.userDetails' | translate"
        [active]="currentTab === 'userinfo'"
        (click)="currentTab = 'userinfo'"
      ></sh-menu-item>
      <sh-menu-item
        [label]="'appusers.assignDevice' | translate"
        [disabled]="isInvalid()"
        [active]="currentTab === 'devices'"
        (click)="currentTab = 'devices'"
      ></sh-menu-item>
      <sh-menu-item
        [label]="'appusers.assignWorkflow' | translate"
        [disabled]="isInvalid()"
        [active]="currentTab === 'workflows'"
        (click)="currentTab = 'workflows'"
      ></sh-menu-item>
      <sh-menu-item
        [label]="'appusers.assignSite' | translate"
        [disabled]="isInvalid()"
        [active]="currentTab === 'sites'"
        (click)="currentTab = 'sites'"
      ></sh-menu-item>
      <sh-menu-item
        [label]="'appusers.assignTraining' | translate"
        [disabled]="isInvalid()"
        [active]="currentTab === 'technicianTraining'"
        (click)="currentTab = 'technicianTraining'"
      ></sh-menu-item>
      <sh-menu-item
        [label]="'appusers.assignBeta' | translate"
        [disabled]="isInvalid()"
        [active]="currentTab === 'beta'"
        (click)="currentTab = 'beta'"
      ></sh-menu-item>
    </div>

    <sh-card style="margin-top: 5px" columns="10">
      <div [hidden]="currentTab !== 'userinfo'">
        <sh-text
          size="header-1"
          [innerText]="'appusers.userDetails' | translate"
        ></sh-text>
        <sh-icon
          button
          icon="warning"
          *ngIf="isInvalid()"
          style="float: right"
          data-toggle="tooltip"
          id="info"
          label="Info"
          data-toggle="tooltip"
        >
        </sh-icon>
        <sh-text [innerText]="'appusers.addUserDetail' | translate"></sh-text>
        <sh-text
          size="body-2"
          [innerText]="'appusers.addRequiredUserDetail' | translate"
        ></sh-text>
        <br />
        <sh-grid>
          <div columns="12">
            <label for="emailText"
              >{{ 'appusers.email' | translate
              }}<b style="color: red">*</b></label
            >
            <sh-input-text
              id="emailText"
              [value]="email"
              (value-changed)="email = $event.target.value"
              [label]="'appusers.email' | translate"
              email
              #emailText
            ></sh-input-text>
          </div>
          <div columns="12">
            <label for="orgText"
              >{{ 'appusers.organization' | translate
              }}<b style="color: red">*</b></label
            >
            <sh-search
              id="orgText"
              [label]="'appusers.organization' | translate"
              (click)="searchOrgData()"
              [value]="org"
              (value-changed)="org = $event.target.value"
              (clearsearch)="onSearchOrg()"
              #orgText
              mandatory
            >
            </sh-search>
          </div>
          <div columns="6">
            <label for="bLText"
              >{{ 'appusers.businessLine' | translate
              }}<b style="color: red">*</b></label
            >
            <sh-search
              id="bLText"
              [label]="'appusers.businessLine' | translate"
              (click)="searchBLData()"
              [value]="businessLine"
              (value-changed)="businessLine = $event.target.value"
              (clearsearch)="onSearchBL()"
              #bLText
              mandatory
            ></sh-search>
          </div>
          <div columns="6">
            <label for="roleText"
              >{{ 'appusers.role' | translate
              }}<b style="color: red">*</b></label
            >
            <sh-search
              id="roleText"
              [label]="'appusers.role' | translate"
              (click)="searchRoleData()"
              [value]="role"
              (value-changed)="role = $event.target.value"
              (clearsearch)="onSearchRole()"
              #roleText
              mandatory
            ></sh-search>
          </div>
          <div columns="6">
            <label for="countryText"
              >{{ 'appusers.country' | translate
              }}<b style="color: red">*</b></label
            >
            <sh-dropdown
              id="countryText"
              style="padding-left: 0px !important"
              #countryText
              editable
              rows="1"
              [value]="country"
              [label]="'appusers.country' | translate"
              (value-changed)="
                country = $event.target.value; changeRegion(country)
              "
              [error]="!isCountryAvailable(country)"
            >
              <sh-menu-item
                *ngFor="let l of appUsersService.locations"
                [label]="l.country"
              ></sh-menu-item>
            </sh-dropdown>
          </div>
          <div columns="6">
            <label for="regionText"
              >{{ 'appusers.region' | translate
              }}<b style="color: red">*</b></label
            >
            <sh-input-text
              id="regionText"
              [label]="''"
              [value]="region"
              (value-changed)="region = $event.target.value"
              readonly
              #regionText
            ></sh-input-text>
          </div>
          <sh-tooltip
            *ngIf="isInvalid()"
            #tooltip
            target="info"
            placement="bottom"
            [label]="'appusers.addRequiredDetailWarning' | translate"
          ></sh-tooltip>
        </sh-grid>
      </div>

      <div [hidden]="currentTab !== 'devices'">
        <sh-text
          size="header-1"
          [innerText]="'appusers.assignDevice' | translate"
        ></sh-text>
        <sh-text
          [innerText]="'appusers.assignReleasedDeviceDetail' | translate"
        ></sh-text>
        <sh-table *ngIf="$devices | async as devices">
          <sh-table-row slot="header" style="padding-bottom: 2.5rem">
            <sh-table-cell unsortable columns="5">
              <sh-text
                size="title-1"
                [innerText]="'appusers.devices' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="6">
              <sh-text
                size="title-1"
                [innerText]="'appusers.validity' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="6">
              <sh-text
                size="title-1"
                [innerText]="'appusers.startDate' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="6">
              <sh-text
                size="title-1"
                [innerText]="'appusers.endDate' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="3">
              <sh-text
                size="title-1"
                [innerText]="'appusers.assigned' | translate"
              ></sh-text>
            </sh-table-cell>
          </sh-table-row>
          <sh-table-row
            #tableRow
            *ngFor="let device of filterDevices(devices, 2); let i = index"
            [expanded]="isDeviceActive(device.name) && hasPheno(device.name)"
          >
            <sh-table-cell columns="5" class="text-align-center">
              <sh-text
                *ngIf="isDeviceActive(device.name) && hasPheno(device.name)"
                [innerText]="device.deviceDescription.name | getLocaleText : globalLanguage?.key : true">
              </sh-text>
              <sh-text
                *ngIf="!isDeviceActive(device.name) || !hasPheno(device.name)"
                [innerText]="device.deviceDescription.name | getLocaleText : globalLanguage?.key : true">
              </sh-text>
            </sh-table-cell>
            <sh-table-cell columns="6">
              <sh-dropdown
                mandatory
                editable
                [value]="durationDvc[i]"
                [label]="'appusers.validityPh' | translate"
                (value-changed)="
                  changeDuration($event?.target?.value, i, true, device.name)
                "
              >
                <sh-menu-item
                  *ngFor="let d of durationValues"
                  [label]="d"
                ></sh-menu-item>
              </sh-dropdown>
            </sh-table-cell>
            <sh-table-cell columns="6">
              <sh-datepicker
                format="YYYYMMDD"
                [value]="getFromDate(startDtDvc[i])"
                (value-changed)="
                  changeDate($event?.target?.value, i, true, device.name)
                "
                label="From"
                past-date-disable
              >
              </sh-datepicker>
            </sh-table-cell>
            <sh-table-cell columns="6">
              <sh-datepicker
                id="{{ 'dvc' + i }}"
                format="YYYYMMDD"
                [value]="getToDate(tmpstartDtDvc[i], tmpdurationDvc[i])"
                readonly
                label="To"
              ></sh-datepicker>
            </sh-table-cell>
            <sh-table-cell columns="3">
              <sh-checkbox
                [label]="''"
                [active]="isDeviceActive(device.name)"
                [disabled]="
                  tmpdurationDvc[i] === undefined ||
                  tmpdurationDvc[i] === null ||
                  tmpdurationDvc[i] === 0
                "
                (checked)="
                  $event.target.active
                    ? assignDeviceAndTable(device.name, i)
                    : removeDeviceAndTable(device.name)
                "
              >
              </sh-checkbox>
            </sh-table-cell>
            <sh-table-row
              *ngIf="isDeviceActive(device.name) && hasPheno(device.name)"
              [expanded]="isDeviceActive(device.name) && hasPheno(device.name)"
            >
              <sh-table-cell>
                <sh-text
                  style="padding-top: 4px; padding-bottom: 4px"
                  [innerText]="'appusers.config' | translate"
                >
                  Configuration</sh-text
                >
              </sh-table-cell>
              <sh-table-row>
                <sh-table-cell columns="7">
                  <sh-text [innerText]="'appusers.table' | translate"></sh-text>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [label]="'appusers.siemens' | translate"
                    [active]="
                      userHasTableForDevice(
                        email,
                        device.name,
                        tableTypeEnum.Siemens
                      )
                    "
                    (checked)="
                      $event.target.active
                        ? addToDevice(
                            email,
                            device.name,
                            tableTypeEnum.Siemens,
                            i
                          )
                        : removeFromDevice(
                            email,
                            device.name,
                            tableTypeEnum.Siemens
                          )
                    "
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [label]="'appusers.magnus' | translate"
                    [active]="
                      userHasTableForDevice(
                        email,
                        device.name,
                        tableTypeEnum.Magnus
                      )
                    "
                    (checked)="
                      $event.target.active
                        ? addToDevice(
                            email,
                            device.name,
                            tableTypeEnum.Magnus,
                            i
                          )
                        : removeFromDevice(
                            email,
                            device.name,
                            tableTypeEnum.Magnus
                          )
                    "
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="9"> </sh-table-cell>
                <sh-table-cell unsortable icon> </sh-table-cell>
              </sh-table-row>
              <sh-table-row>
                <sh-table-cell columns="7">
                  <sh-text [innerText]="'appusers.pcm' | translate"></sh-text>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [label]="'appusers.pcm1' | translate"
                    [active]="
                      userHasPcmForDevice(email, device.name, pcmEnum.PCM_1)
                    "
                    (checked)="
                      $event.target.active
                        ? addPcmToDevice(email, device.name, pcmEnum.PCM_1, i)
                        : removePcmFromDevice(email, device.name, pcmEnum.PCM_1)
                    "
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [label]="'appusers.pcm2' | translate"
                    [active]="
                      userHasPcmForDevice(email, device.name, pcmEnum.PCM_2)
                    "
                    (checked)="
                      $event.target.active
                        ? addPcmToDevice(email, device.name, pcmEnum.PCM_2, i)
                        : removePcmFromDevice(email, device.name, pcmEnum.PCM_2)
                    "
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="9"> </sh-table-cell>
                <sh-table-cell unsortable icon> </sh-table-cell>
              </sh-table-row>
            </sh-table-row>
          </sh-table-row>
        </sh-table>
      </div>

      <div [hidden]="currentTab !== 'workflows'">
        <sh-text
          size="header-1"
          [innerText]="'appusers.assignWorkflow' | translate"
        ></sh-text>
        <sh-text
          [innerText]="'appusers.assignReleasedWorkflowDetail' | translate"
        ></sh-text>
        <sh-table *ngIf="$workflows | async as workflows">
          <sh-table-row slot="header" style="padding-bottom: 2.5rem">
            <sh-table-cell unsortable columns="5">
              <sh-text
                size="title-1"
                [innerText]="'appusers.workflows' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="6">
              <sh-text
                size="title-1"
                [innerText]="'appusers.validity' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="6">
              <sh-text
                size="title-1"
                [innerText]="'appusers.startDate' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="6">
              <sh-text
                size="title-1"
                [innerText]="'appusers.endDate' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="3">
              <sh-text
                size="title-1"
                [innerText]="'appusers.assigned' | translate"
              ></sh-text>
            </sh-table-cell>
          </sh-table-row>
          <sh-table-row
            #tableRow
            *ngFor="
              let workflow of filterWorkflows(workflows, 2);
              let i = index
            "
            [expanded]="
              isWorkflowActive(workflow.key) && hasPheno(workflow.key)
            "
          >
            <sh-table-cell columns="5" class="text-align-center">
              <sh-text
                [innerText]="
                  workflow.title | getLocaleText : globalLanguage?.key : true
                "
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell columns="6">
              <sh-dropdown
                mandatory
                editable
                [value]="durationWf[i]"
                [label]="'appusers.validityPh' | translate"
                (value-changed)="
                  changeDuration($event?.target?.value, i, false, workflow.key)
                "
              >
                <sh-menu-item
                  *ngFor="let d of durationValues"
                  [label]="d"
                ></sh-menu-item>
              </sh-dropdown>
            </sh-table-cell>
            <sh-table-cell columns="6">
              <sh-datepicker
                format="YYYYMMDD"
                [value]="getFromDate(startDtWf[i])"
                (value-changed)="
                  changeDate($event?.target?.value, i, false, workflow.key)
                "
                label="From"
                past-date-disable
              >
              </sh-datepicker>
            </sh-table-cell>
            <sh-table-cell columns="6">
              <sh-datepicker
                id="{{ 'wf' + i }}"
                format="YYYYMMDD"
                [value]="getToDate(tmpstartDtWf[i], tmpdurationWf[i])"
                readonly
                label="To"
              ></sh-datepicker>
            </sh-table-cell>
            <sh-table-cell columns="3">
              <sh-checkbox
                [label]="''"
                [active]="isWorkflowActive(workflow.key)"
                [disabled]="
                  tmpdurationWf[i] === undefined ||
                  tmpdurationWf[i] === null ||
                  tmpdurationWf[i] === 0
                "
                (checked)="
                  $event.target.active
                    ? assignWorkflowAndTable(workflow.key, i)
                    : removeWorkflowAndTable(workflow.key)
                "
              >
              </sh-checkbox>
            </sh-table-cell>
            <sh-table-row
              *ngIf="isWorkflowActive(workflow.key) && hasPheno(workflow.key)"
              [expanded]="
                isWorkflowActive(workflow.key) && hasPheno(workflow.key)
              "
            >
              <sh-table-cell>
                <sh-text
                  style="padding-top: 4px; padding-bottom: 4px"
                  [innerText]="'appusers.config' | translate"
                >
                  Configuration</sh-text
                >
              </sh-table-cell>
              <sh-table-row>
                <sh-table-cell columns="7">
                  <sh-text [innerText]="'appusers.table' | translate"></sh-text>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [label]="'appusers.siemens' | translate"
                    [active]="
                      userHasTableForWorkflow(
                        email,
                        workflow.key,
                        tableTypeEnum.Siemens
                      )
                    "
                    (checked)="
                      $event.target.active
                        ? addToWorkflow(
                            email,
                            workflow.key,
                            tableTypeEnum.Siemens,
                            i
                          )
                        : removeFromWorkflow(
                            email,
                            workflow.key,
                            tableTypeEnum.Siemens
                          )
                    "
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [label]="'appusers.magnus' | translate"
                    [active]="
                      userHasTableForWorkflow(
                        email,
                        workflow.key,
                        tableTypeEnum.Magnus
                      )
                    "
                    (checked)="
                      $event.target.active
                        ? addToWorkflow(
                            email,
                            workflow.key,
                            tableTypeEnum.Magnus,
                            i
                          )
                        : removeFromWorkflow(
                            email,
                            workflow.key,
                            tableTypeEnum.Magnus
                          )
                    "
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="9"> </sh-table-cell>
                <sh-table-cell unsortable icon> </sh-table-cell>
              </sh-table-row>
              <sh-table-row>
                <sh-table-cell columns="7">
                  <sh-text [innerText]="'appusers.pcm' | translate"></sh-text>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [label]="'appusers.pcm1' | translate"
                    [active]="
                      userHasPcmForWorkflow(email, workflow.key, pcmEnum.PCM_1)
                    "
                    (checked)="
                      $event.target.active
                        ? addPcmToWorkflow(
                            email,
                            workflow.key,
                            pcmEnum.PCM_1,
                            i
                          )
                        : removePcmFromWorkflow(
                            email,
                            workflow.key,
                            pcmEnum.PCM_1
                          )
                    "
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [label]="'appusers.pcm2' | translate"
                    [active]="
                      userHasPcmForWorkflow(email, workflow.key, pcmEnum.PCM_2)
                    "
                    (checked)="
                      $event.target.active
                        ? addPcmToWorkflow(
                            email,
                            workflow.key,
                            pcmEnum.PCM_2,
                            i
                          )
                        : removePcmFromWorkflow(
                            email,
                            workflow.key,
                            pcmEnum.PCM_2
                          )
                    "
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="9"> </sh-table-cell>
                <sh-table-cell unsortable icon> </sh-table-cell>
              </sh-table-row>
            </sh-table-row>
          </sh-table-row>
        </sh-table>
      </div>

      <div [hidden]="currentTab !== 'sites'">
        <sh-text
          size="header-1"
          [innerText]="'appusers.assignedSites' | translate"
        ></sh-text>
        <sh-text
          [innerText]="'appusers.assignSiteDetail' | translate"
        ></sh-text>
        <sh-table *ngIf="filteredSites">
          <sh-table-row slot="header" style="padding-bottom: 2.5rem">
            <sh-table-cell style="width: 100%">
              <sh-text>
                <sh-search
                  style="margin-left: 5px"
                  #searchBox
                  whole-string
                  [label]="'general.searchSite' | translate"
                  (search)="onSearchSite($event.target.value)"
                  (clearsearch)="onSearchSite()"
                ></sh-search>
              </sh-text>
            </sh-table-cell>
          </sh-table-row>
          <sh-table-row *ngFor="let site of sortSites(filteredSites)">
            <sh-table-cell>
              <sh-checkbox
                [label]="site.siteName"
                [active]="hasSite(site)"
                (checked)="
                  $event.target.active
                    ? assignSites.push(site)
                    : assignSites.splice(assignSites.indexOf(site, 1))
                "
              >
              </sh-checkbox>
            </sh-table-cell>
          </sh-table-row>
        </sh-table>
      </div>

      <div [hidden]="currentTab !== 'technicianTraining'">
        <sh-text
          size="header-1"
          [innerText]="'appusers.assignTraining' | translate"
        ></sh-text>
        <sh-text
          [innerText]="('appusers.technicianTraining' | translate) + ':'"
        ></sh-text>
        <sh-text
          size="body-2"
          [innerText]="
            ('appusers.technicianTrainingDetail' | translate) + email
          "
        ></sh-text>
        <sh-table>
          <sh-table-row>
            <sh-table-cell>
              <sh-checkbox
                [label]="'appusers.technicianTraining' | translate"
                [active]="technicianTraining"
                (checked)="
                  $event.target.active
                    ? (technicianTraining = true)
                    : (technicianTraining = false)
                "
              >
              </sh-checkbox>
            </sh-table-cell>
          </sh-table-row>
        </sh-table>
      </div>

      <div [hidden]="currentTab !== 'beta'">
        <sh-text
          size="header-1"
          [innerText]="'appusers.assignBeta' | translate"
        ></sh-text>
        <sh-tabs>
          <sh-tab-item
            [label]="'appusers.devices' | translate"
            [active]="subTab === 'betaDevices'"
            (click)="subTab = 'betaDevices'"
          ></sh-tab-item>
          <sh-tab-item
            [label]="'appusers.workflows' | translate"
            [active]="subTab === 'betaWorkflows'"
            (click)="subTab = 'betaWorkflows'"
          >
          </sh-tab-item>
        </sh-tabs>
        <div [hidden]="currentTab !== 'beta' || subTab !== 'betaDevices'">
          <sh-table *ngIf="$devices | async as devices">
            <sh-table-row slot="header" style="padding-bottom: 2.5rem">
              <sh-table-cell unsortable columns="5">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.devices' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="6">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.validity' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="6">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.startDate' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="6">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.endDate' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="3">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.assigned' | translate"
                ></sh-text>
              </sh-table-cell>
            </sh-table-row>
            <sh-table-row
              #tableRow
              *ngFor="let device of filterDevices(devices, 0); let i = index"
              [expanded]="
                isDeviceActive(device?.name) && hasPheno(device?.name)
              "
            >
              <sh-table-cell columns="5" class="text-align-center">
                <sh-text
                  *ngIf="isDeviceActive(device.name) && hasPheno(device.name)"
                  [innerText]="device.deviceDescription.name | getLocaleText : globalLanguage?.key : true"
                >
                </sh-text>
                <sh-text
                  *ngIf="!isDeviceActive(device.name) || !hasPheno(device.name)"
                  [innerText]="device.deviceDescription.name | getLocaleText : globalLanguage?.key : true"
                >
                </sh-text>
              </sh-table-cell>
              <sh-table-cell columns="6">
                <sh-dropdown
                  mandatory
                  editable
                  [readonly]="false"
                  [condensed]="false"
                  [multi-select]="false"
                  [value]="durationDvc[i + dvcOffset]"
                  [label]="'appusers.validityPh' | translate"
                  (value-changed)="
                    changeDuration(
                      $event?.target?.value,
                      i + dvcOffset,
                      true,
                      device.name
                    )
                  "
                >
                  <sh-menu-item
                    *ngFor="let d of durationValues"
                    [label]="d"
                  ></sh-menu-item>
                </sh-dropdown>
              </sh-table-cell>
              <sh-table-cell columns="6">
                <sh-datepicker
                  format="YYYYMMDD"
                  [value]="getFromDate(startDtDvc[i + dvcOffset])"
                  (value-changed)="
                    changeDate(
                      $event?.target?.value,
                      i + dvcOffset,
                      true,
                      device.name
                    )
                  "
                  label="From"
                  past-date-disable
                >
                </sh-datepicker>
              </sh-table-cell>
              <sh-table-cell columns="6">
                <sh-datepicker
                  id="{{ 'dvc' + (i + dvcOffset) }}"
                  format="YYYYMMDD"
                  [value]="
                    getToDate(
                      tmpstartDtDvc[i + dvcOffset],
                      tmpdurationDvc[i + dvcOffset]
                    )
                  "
                  readonly
                  label="To"
                >
                </sh-datepicker>
              </sh-table-cell>
              <sh-table-cell columns="3">
                <sh-checkbox
                  [label]="''"
                  [active]="isDeviceActive(device.name)"
                  [disabled]="
                    tmpdurationDvc[i + dvcOffset] === undefined ||
                    tmpdurationDvc[i + dvcOffset] === null ||
                    tmpdurationDvc[i + dvcOffset] === 0
                  "
                  (checked)="
                    $event.target.active
                      ? assignDeviceAndTable(device.name, i + dvcOffset)
                      : removeDeviceAndTable(device.name)
                  "
                >
                </sh-checkbox>
              </sh-table-cell>
              <sh-table-row
                *ngIf="isDeviceActive(device?.name) && hasPheno(device?.name)"
                [expanded]="
                  isDeviceActive(device?.name) && hasPheno(device?.name)
                "
              >
                <sh-table-cell>
                  <sh-text
                    style="padding-top: 4px; padding-bottom: 4px"
                    [innerText]="'appusers.config' | translate"
                  >
                    Configuration</sh-text
                  >
                </sh-table-cell>
                <sh-table-row>
                  <sh-table-cell columns="7">
                    <sh-text
                      [innerText]="'appusers.table' | translate"
                    ></sh-text>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [label]="'appusers.siemens' | translate"
                      [active]="
                        userHasTableForDevice(
                          email,
                          device.name,
                          tableTypeEnum.Siemens
                        )
                      "
                      (checked)="
                        $event.target.active
                          ? addToDevice(
                              email,
                              device.name,
                              tableTypeEnum.Siemens,
                              i + dvcOffset
                            )
                          : removeFromDevice(
                              email,
                              device.name,
                              tableTypeEnum.Siemens
                            )
                      "
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [label]="'appusers.magnus' | translate"
                      [active]="
                        userHasTableForDevice(
                          email,
                          device.name,
                          tableTypeEnum.Magnus
                        )
                      "
                      (checked)="
                        $event.target.active
                          ? addToDevice(
                              email,
                              device.name,
                              tableTypeEnum.Magnus,
                              i + dvcOffset
                            )
                          : removeFromDevice(
                              email,
                              device.name,
                              tableTypeEnum.Magnus
                            )
                      "
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="9"> </sh-table-cell>
                  <sh-table-cell unsortable icon> </sh-table-cell>
                </sh-table-row>
                <sh-table-row>
                  <sh-table-cell columns="7">
                    <sh-text [innerText]="'appusers.pcm' | translate"></sh-text>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [label]="'appusers.pcm1' | translate"
                      [active]="
                        userHasPcmForDevice(email, device.name, pcmEnum.PCM_1)
                      "
                      (checked)="
                        $event.target.active
                          ? addPcmToDevice(
                              email,
                              device.name,
                              pcmEnum.PCM_1,
                              i + dvcOffset
                            )
                          : removePcmFromDevice(
                              email,
                              device.name,
                              pcmEnum.PCM_1
                            )
                      "
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [label]="'appusers.pcm2' | translate"
                      [active]="
                        userHasPcmForDevice(email, device.name, pcmEnum.PCM_2)
                      "
                      (checked)="
                        $event.target.active
                          ? addPcmToDevice(
                              email,
                              device.name,
                              pcmEnum.PCM_2,
                              i + dvcOffset
                            )
                          : removePcmFromDevice(
                              email,
                              device.name,
                              pcmEnum.PCM_2
                            )
                      "
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="9"> </sh-table-cell>
                  <sh-table-cell unsortable icon> </sh-table-cell>
                </sh-table-row>
              </sh-table-row>
            </sh-table-row>
          </sh-table>
        </div>
        <div [hidden]="currentTab !== 'beta' || subTab !== 'betaWorkflows'">
          <sh-table *ngIf="$workflows | async as workflows">
            <sh-table-row slot="header" style="padding-bottom: 2.5rem">
              <sh-table-cell unsortable columns="5">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.workflows' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="6">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.validity' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="6">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.startDate' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="6">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.endDate' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="3">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.assigned' | translate"
                ></sh-text>
              </sh-table-cell>
            </sh-table-row>
            <sh-table-row
              #tableRow
              *ngFor="
                let workflow of filterWorkflows(workflows, 0);
                let i = index
              "
              [expanded]="
                isWorkflowActive(workflow.key) && hasPheno(workflow.key)
              "
            >
              <sh-table-cell columns="5" class="text-align-center">
                <sh-text
                  [innerText]="
                    workflow.title | getLocaleText : globalLanguage?.key : true
                  "
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell columns="6">
                <sh-dropdown
                  mandatory
                  editable
                  [value]="durationWf[i + wfOffset]"
                  [label]="'appusers.validityPh' | translate"
                  (value-changed)="
                    changeDuration(
                      $event?.target?.value,
                      i + wfOffset,
                      false,
                      workflow.key
                    )
                  "
                >
                  <sh-menu-item
                    *ngFor="let d of durationValues"
                    [label]="d"
                  ></sh-menu-item>
                </sh-dropdown>
              </sh-table-cell>
              <sh-table-cell columns="6">
                <sh-datepicker
                  format="YYYYMMDD"
                  [value]="getFromDate(startDtWf[i + wfOffset])"
                  (value-changed)="
                    changeDate(
                      $event?.target?.value,
                      i + wfOffset,
                      false,
                      workflow.key
                    )
                  "
                  label="From"
                  past-date-disable
                >
                </sh-datepicker>
              </sh-table-cell>
              <sh-table-cell columns="6">
                <sh-datepicker
                  id="{{ 'wf' + (i + wfOffset) }}"
                  format="YYYYMMDD"
                  [value]="
                    getToDate(
                      tmpstartDtWf[i + wfOffset],
                      tmpdurationWf[i + wfOffset]
                    )
                  "
                  readonly
                  label="To"
                >
                </sh-datepicker>
              </sh-table-cell>
              <sh-table-cell columns="3">
                <sh-checkbox
                  [label]="''"
                  [active]="isWorkflowActive(workflow.key)"
                  [disabled]="
                    tmpdurationWf[i + wfOffset] === undefined ||
                    tmpdurationWf[i + wfOffset] === null ||
                    tmpdurationWf[i + wfOffset] === 0
                  "
                  (checked)="
                    $event.target.active
                      ? assignWorkflowAndTable(workflow.key, i + wfOffset)
                      : removeWorkflowAndTable(workflow.key)
                  "
                >
                </sh-checkbox>
              </sh-table-cell>
              <sh-table-row
                *ngIf="isWorkflowActive(workflow.key) && hasPheno(workflow.key)"
                [expanded]="
                  isWorkflowActive(workflow.key) && hasPheno(workflow.key)
                "
              >
                <sh-table-cell>
                  <sh-text
                    style="padding-top: 4px; padding-bottom: 4px"
                    [innerText]="'appusers.config' | translate"
                  >
                    Configuration</sh-text
                  >
                </sh-table-cell>
                <sh-table-row>
                  <sh-table-cell columns="7">
                    <sh-text
                      [innerText]="'appusers.table' | translate"
                    ></sh-text>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [label]="'appusers.siemens' | translate"
                      [active]="
                        userHasTableForWorkflow(
                          email,
                          workflow.key,
                          tableTypeEnum.Siemens
                        )
                      "
                      (checked)="
                        $event.target.active
                          ? addToWorkflow(
                              email,
                              workflow.key,
                              tableTypeEnum.Siemens,
                              i + wfOffset
                            )
                          : removeFromWorkflow(
                              email,
                              workflow.key,
                              tableTypeEnum.Siemens
                            )
                      "
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [label]="'appusers.magnus' | translate"
                      [active]="
                        userHasTableForWorkflow(
                          email,
                          workflow.key,
                          tableTypeEnum.Magnus
                        )
                      "
                      (checked)="
                        $event.target.active
                          ? addToWorkflow(
                              email,
                              workflow.key,
                              tableTypeEnum.Magnus,
                              i + wfOffset
                            )
                          : removeFromWorkflow(
                              email,
                              workflow.key,
                              tableTypeEnum.Magnus
                            )
                      "
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="9"> </sh-table-cell>
                  <sh-table-cell unsortable icon> </sh-table-cell>
                </sh-table-row>
                <sh-table-row>
                  <sh-table-cell columns="7">
                    <sh-text [innerText]="'appusers.pcm' | translate"></sh-text>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [label]="'appusers.pcm1' | translate"
                      [active]="
                        userHasPcmForWorkflow(
                          email,
                          workflow.key,
                          pcmEnum.PCM_1
                        )
                      "
                      (checked)="
                        $event.target.active
                          ? addPcmToWorkflow(
                              email,
                              workflow.key,
                              pcmEnum.PCM_1,
                              i + wfOffset
                            )
                          : removePcmFromWorkflow(
                              email,
                              workflow.key,
                              pcmEnum.PCM_1
                            )
                      "
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [label]="'appusers.pcm2' | translate"
                      [active]="
                        userHasPcmForWorkflow(
                          email,
                          workflow.key,
                          pcmEnum.PCM_2
                        )
                      "
                      (checked)="
                        $event.target.active
                          ? addPcmToWorkflow(
                              email,
                              workflow.key,
                              pcmEnum.PCM_2,
                              i + wfOffset
                            )
                          : removePcmFromWorkflow(
                              email,
                              workflow.key,
                              pcmEnum.PCM_2
                            )
                      "
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="9"> </sh-table-cell>
                  <sh-table-cell unsortable icon> </sh-table-cell>
                </sh-table-row>
              </sh-table-row>
            </sh-table-row>
          </sh-table>
        </div>
      </div>
      <sh-button
        slot="footer"
        size="m"
        color="secondary"
        [label]="'general.cancel' | translate"
        (click)="open = false; resetData()"
      ></sh-button>
      <sh-button
        slot="footer"
        size="m"
        [label]="'general.save' | translate"
        [disabled]="isInvalid()"
        (click)="open = false; add()"
      >
      </sh-button>
    </sh-card>
  </sh-grid>
</sh-modal>
