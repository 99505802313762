import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '../../../../../../services/language.service';
import { ParamsService } from '../../../../../../services/params.service';
import { FeaturesService } from '../features.service';
import Cropper from 'cropperjs';
import { ImgConvertorComponent } from '@backend/webapp/img-convertor/img-convertor.component';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { Constants } from '@backend/interfaces';
import { Subscription } from 'rxjs';

@Component({
  selector: 'backend-edit-feature',
  templateUrl: './edit-feature.component.html',
  styleUrls: ['./edit-feature.component.scss'],
})
export class EditFeatureComponent implements OnInit, OnDestroy {
  @ViewChild('inputFile')
  public inputFile: ElementRef<HTMLInputElement>;

  @ViewChild('previewImage')
  public previewImage: ElementRef<HTMLImageElement>;

  private cropper: Cropper;

  public isCropped = false;

  public croppedFile: File;

  public isEditMode = false;

  public file: string;
  public fileBasename: string;
  public fileChanged = false;

  public name = '';
  public description = '';
  public ar = false;
  private imgConvertor: ImgConvertorComponent;

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  descriptionTrans: any[];
  languageSubscription: Subscription;
  currentFeature: any;
  featureNames: Array<Array<any>>;

  public constructor(
    private router: Router,
    private featuresService: FeaturesService,
    private paramsService: ParamsService,
    private renderer: Renderer2,
    public languageService: LanguageService,
    public localization: LocalizationService
  ) {
    this.imgConvertor = new ImgConvertorComponent();
  }

  public async ngOnInit(): Promise<void> {
    await this.featuresService.updateFeature();
    await this.updateFields();
    this.featureNames = this.featuresService.allFeatures;
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          await this.updateFields();
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  public fileChange(file: File): void {
    if (file) {
      this.file = file.name;
      this.fileBasename = file.name.replace(/.*[\/\\]/, '');
      this.fileChanged = true;

      const reader = new FileReader();
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      reader.onloadend = (e) => {
        if (this.cropper) {
          this.cropper.destroy();
        }
        this.renderer.setAttribute(
          this.previewImage.nativeElement,
          'src',
          e.target.result.toString()
        );
        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          window.URL.revokeObjectURL(img.src);
          if (width / height !== 4 / 3) {
            this.isCropped = false;
            this.cropper = new Cropper(this.previewImage.nativeElement, {
              initialAspectRatio: 4 / 3,
              aspectRatio: 4 / 3,
              dragMode: 'move',
              viewMode: 1,
            });
          } else {
            this.isCropped = true;
            this.croppedFile = this.inputFile.nativeElement.files[0];
          }
        };
      };
      reader.readAsDataURL(file);
    } else {
      this.file = '';
      this.fileBasename = '';
    }
  }

  private async updateFields(): Promise<void> {
    const feature = await this.featuresService.getFeature();
    if (feature) {
      this.name = this.languageService.getTranslationByKey(
        feature.name,
        this.globalLanguage.key
      );
      this.currentFeature = this.featuresService.getCurrentFeatureByName(feature.name[0].value);
      this.description = this.languageService.getTranslationByKey(
        feature.description,
        this.globalLanguage.key
      );
      this.ar = feature.ar;
      this.descriptionTrans = this.featuresService.getTranslatedData(feature.description);
      this.file = feature.thumbnail;
      if (this.file) {
        this.fileBasename = this.file.replace(/.*[\/\\]/, '');
      } else {
        this.fileBasename = '';
      }
      this.isEditMode = true;
    } else {
      this.resetFields();
      this.isEditMode = false;
    }
  }

  private resetFields(): void {
    this.name = '';
    this.description = '';
    this.file = '';
    this.fileBasename = '';
    this.ar = false;
    this.fileChanged = false;
  }

  public selectFile() {
    this.inputFile.nativeElement.click();
  }

  public async save() {
    const file = this.fileChanged ? this.croppedFile : null;
    let result: boolean;
    if (!this.isEditMode) {
      result = await this.featuresService.createFeature(
        this.name,
        this.description,
        this.ar,
        file
      );
    } else {
      result = await this.featuresService.modifyFeature(
        this.paramsService.featureId,
        this.name,
        this.description,
        this.ar,
        file
      );
    }

    if (result) {
      this.gotoFeatures();
    }
  }

  public async cancel() {
    this.gotoFeatures();
  }

  public gotoFeatures(): void {
    this.router.navigate([
      'devices',
      this.paramsService.deviceId,
      'versions',
      this.paramsService.deviceVersionId,
      'features',
    ]);
  }

  public cropImage() {
    const canvas = this.cropper.getCroppedCanvas();
    this.renderer.setAttribute(
      this.previewImage.nativeElement,
      'src',
      canvas.toDataURL()
    );
    this.croppedFile = this.imgConvertor.canvasToFile(
      canvas,
      this.fileBasename
    );
    this.cropper.destroy();
    // this.cropper.getCroppedCanvas().toBlob(async (res) => {
    //   this.croppedFile = new File([res], this.inputFile.nativeElement.files[0].name);
    //   this.cropper.destroy();
    // });
    this.isCropped = true;
  }

  public isFeatureAdded(featureName: string): boolean {
    if (!this.featuresService.features) {
      return;
    }
    let listedFeaturesForDevice = [];
    const featureList = this.featuresService.features;
    featureList.forEach((feature) => {
      listedFeaturesForDevice.push(
        this.languageService.getTranslationByKey(
          feature.name,
          this.globalLanguage.key
        )
      );
    });

    return listedFeaturesForDevice.includes(featureName);
  }

  preventDefault(event: Event): void {
    // Prevent default behavior (Prevent file from being opened)
    if (event) {
      event.preventDefault();
    }
  }

  drop(event: DragEvent): void {
    this.preventDefault(event);
    this.fileChange(event.dataTransfer.files[0]);
    this.removeDragData(event);
  }

  // Clean up. Remove drag data
  private removeDragData(event: DragEvent): void {
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to remove the drag data
      event.dataTransfer.items.clear();
    } else {
      // Use DataTransfer interface to remove the drag data
      event.dataTransfer.clearData();
    }
  }
}
