import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminUsersService } from './adminusers.service';
import { PopoverService } from '../services/popover.service';
import { Observable } from 'rxjs';
import { AddAdminUserComponent } from './modals/add-admin-user/add-admin-user.component';
import { SpinnerService } from '../spinner/spinner.service';
import { NotificationService } from '../shared/notification/notification.service';

@Component({
  selector: 'backend-adminusers',
  templateUrl: './adminusers.component.html',
  styleUrls: ['./adminusers.component.scss'],
})
export class AdminusersComponent implements OnInit {
  @ViewChild('addAdminUser')
  public addAdminUserModal: AddAdminUserComponent;
  
  allAdminUsers: any;
  displayedAdminUsers: any;
  pageIndex = 1;
  itemsPerPage = 20;
  public constructor(
    public readonly popoverService: PopoverService,
    public readonly adminUserService: AdminUsersService,
    private readonly spinnerService: SpinnerService,
    private readonly notificationService: NotificationService,

  ) {
    this.adminUserService.adminUsers$.subscribe(() => {
      this.allAdminUsers = this.adminUserService.adminUsers;
      this.displayedAdminUsers = this.adminUserService.adminUsers;
    });
  }

  public getallAdminUsersCount(): string {
    if (!this.allAdminUsers ||  this.allAdminUsers.length === 0) {
      return '';
    }
    return `(${this.allAdminUsers.length})`;
  }

  public async ngOnInit(): Promise<void> {
    this.spinnerService.showSpinner('Loading admin users ...');
    try {
      this.allAdminUsers = [];
      this.displayedAdminUsers = [];
      const isAdminUsersLoaded = await this.adminUserService.loadAdminUsers();
      if (!isAdminUsersLoaded) {
       return;
      }
      this.allAdminUsers = this.adminUserService.adminUsers;
      this.manageDevicesInfiniteScroll();
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'adminusers.getError',
        null,
        error.error.message
      );
    } finally {
      this.spinnerService.hideSpinner();
      
    }
  }

  public onScrollEnd(): void {
    this.pageIndex = this.pageIndex + 1;
    this.manageDevicesInfiniteScroll();
  }

  manageDevicesInfiniteScroll(): void {
    const startIndex = (this.pageIndex - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    if (this.pageIndex == 1) {
      this.displayedAdminUsers = [];
    }

    for (let i = startIndex; i < endIndex; i++) {
      if (i < this.allAdminUsers.length) {
        this.displayedAdminUsers.push(this.allAdminUsers[i]);
      }
    } 
  }

  public onAddClick() {
    this.addAdminUserModal.open();
  }

  public delete(id: number) {
    this.adminUserService.deleteAdminUser(id);
  }

  public edit(id: number) {
    this.addAdminUserModal.open(id);
  }
}
