import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'backend-spinner',
  template:
    '<sh-spinner *ngIf="show | async" [label]="message | async" overlay size="m"></sh-spinner>',
})
export class SpinnerComponent implements OnInit {
  public show: Observable<boolean>;
  public message: Observable<string>;

  public constructor(private spinnerService: SpinnerService) {}

  ngOnInit(): void {
    this.show = this.spinnerService.Show;
    this.message = this.spinnerService.Message;
  }
}
