<div>
  <div class="search-header">
    <sh-search
            size="body-2"
            class="search-box"
            [label]="'general.search' | translate"
    ></sh-search>
    <sh-button
            size="max-content"
            [label]="'general.add' | translate"
            [routerLink]="'add'"
    ></sh-button>
    </div>
  <sh-table>
    <sh-table-row>
      <sh-table-head
        unsortable
        columns="7"
        [innerHTML]="'workflowguides.phases.phaseWrappers.key' | translate"
      >
      </sh-table-head>
      <sh-table-head
        unsortable
        columns="16"
        [innerHTML]="'workflowguides.phases.phaseWrappers.name' | translate"
      >
      </sh-table-head>
      <sh-table-head unsortable icon columns="1"></sh-table-head>
    </sh-table-row>

    <ng-container *ngIf="phaseWrappers">
      <sh-table-row
        *ngFor="let phaseWrapper of phaseWrappers | async"
        (contextmenu)="
          popoverService.openPopover(
            popovers,
            'pw-menu-' + phaseWrapper.id,
            $event
          )
        "
        (click)="
          popoverService.goToRoute(popovers, [phaseWrapper.id, 'versions'])
        "
      >
        <sh-table-cell columns="7">
          <p class="flow-text" [innerText]="phaseWrapper.key"></p>
        </sh-table-cell>

        <sh-table-cell columns="16">
          <p class="flow-text" [innerText]="phaseWrapper.name"></p>
        </sh-table-cell>

        <sh-table-cell icon columns="1">
          <sh-icon
            button
            icon="more"
            [id]="'pw-menu-' + phaseWrapper.id"
            (click)="
              popoverService.preventDefault($event);
              popoverService.openPopover(
                popovers,
                'pw-menu-' + phaseWrapper.id,
                $event
              )
            "
          >
          </sh-icon>
        </sh-table-cell>

        <sh-popover
          #popover
          [target]="'pw-menu-' + phaseWrapper.id"
          position="left-up"
        >
          <sh-menu-item
            icon="edit"
            [label]="'general.edit' | translate"
            [routerLink]="[phaseWrapper.id, 'edit']"
          >
          </sh-menu-item>
          <sh-menu-item
            icon="delete"
            [label]="'general.delete' | translate"
            (click)="deletePhaseWrapper(phaseWrapper.id, phaseWrapper.key)"
          ></sh-menu-item>
        </sh-popover>
      </sh-table-row>
    </ng-container>

    <sh-table-row *ngIf="(phaseWrappers | async).length === 0">
      <sh-table-cell columns="5"></sh-table-cell>
      <sh-table-cell
        columns="19"
        [innerText]="'workflowguides.phases.phaseWrappers.empty' | translate"
      ></sh-table-cell>
    </sh-table-row>
  </sh-table>
</div>
