import { ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';

export class LazyComponentHelper<T> {
  constructor(
    /**
     * The name of the component to be imported.
     */
    private readonly componentName: string,

    /**
     * The view container ref where the component will be injected.
     *
     * Also responsible for creating the component.
     */
    private readonly viewContainerRef: ViewContainerRef,

    /**
     *  Retrieves the factory object to create the component of the given type.
     */
    private readonly componentFactoryResolver: ComponentFactoryResolver,

    
    private readonly spinner: SpinnerService,

    /**
     * A promise returning the path of the lazy component.
     *
     * This is required because webpack needs to be statically determine the path of the
     * lazy component.
     */
    private readonly getImportPromise: () => Promise<any>,
  ) {}

  /**
   * Provides a ref to the component using the component factory.
   */
  componentRef: ComponentRef<T>;

  /**
   * Dynamically imports the lazy component and creates an instance of the component using
   * the `viewContainerRef`.
   *
   * A loading spinner is shown while the component is being imported if `silent` is false
   * else no loading spinner is shown.
   *
   */
  async show(silent = false): Promise<void> {
    this.viewContainerRef.clear();

    if (!silent) {
      this.spinner.showSpinner();
    }

    const componentMetadata = await this.getImportPromise();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<T>(
      componentMetadata[this.componentName]
    );

    this.componentRef = this.viewContainerRef.createComponent(componentFactory);

    if (!silent) {
      this.spinner.hideSpinner();
    }
  }

  /**
   * Hides the lazy component.
   *
   * Will also destroy the component if it was created.
   *
   */
  hide(): void {
    this.viewContainerRef.clear();
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = void 0;
    }
  }
}
