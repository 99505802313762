import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { ParamsService } from '../../services/params.service';
import { WorkflowGuidesService } from '../workflowguides.service';
import { ConfirmationService } from '@backend/webapp/shared/confirmation/confirmation.service';
import { PhaseVersionService } from './phase-versions/phase-version.service';
import { WorkflowService } from '../workflows/workflow.service';
import { LanguageService } from '@backend/webapp/services/language.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PhaseService {
  private phasenSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );

  public get phasen(): Observable<any[]> {
    return this.phasenSubject.asObservable();
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly paramsService: ParamsService,
    private readonly linksService: WorkflowGuidesService,
    private confirmationService: ConfirmationService,
    private readonly phaseVersionService: PhaseVersionService,
    public readonly workflowService: WorkflowService,
    private readonly languageService: LanguageService,
    public router: Router
  ) {
    this.loadPhaseWrappers();
  }

  public async deletePhaseWrapper(id: number, phaseWrapper) {
    return true;
  }

  public async getPhaseWrapper(id: number = this.paramsService.phaseWrapperId) {
    if (id) {
      this.paramsService.isLoading = true;
      const result = await lastValueFrom(
        this.http.get('/api/workflowguides/phasewrappers/' + id)
      );
      this.paramsService.isLoading = false;
      return result;
    }
    return null;
  }

  public async getAllVersions() {
    this.paramsService.isLoading = true;
    const result = await lastValueFrom(
      this.http.get<any[]>('/api/workflowguides/phases/all')
    );
    this.paramsService.isLoading = false;
    return result;
  }

  public async loadPhaseWrappers() {
    this.paramsService.isLoading = true;
    this.phasenSubject.next(
      await lastValueFrom(this.http.get<any[]>('/api/workflowguides/phasewrappers'))
    );
    this.paramsService.isLoading = false;
    await this.linksService.loadPhaseLinks();
    return true;
  }

  public async checkForSamePhaseInDraft(): Promise<any> {
    let selectedWorkflow = this.linksService.getCurrentWorkFlow();
    if (!selectedWorkflow.workflowDraftVersion) {
      await this.workflowService.createWorkflowDraftVersion();
      return;
    }

    let phaseDetails: any = await this.phaseVersionService.getPhaseVersion();
    let phaseName = this.languageService.getTranslateValue(phaseDetails?.name)
    const phase = selectedWorkflow.workflowDraftVersion.phasen.filter((phase) => phaseName === this.languageService.getTranslateValue(phase.name));
    if (phase.length === 0) {
      this.router.navigate(['workflowguides/workflows', selectedWorkflow.id, 'versions', selectedWorkflow.workflowDraftVersion.id]);
    } else {
      this.router.navigate(['workflowguides/workflows',this.paramsService.workflowId,'versions',selectedWorkflow.workflowDraftVersion.id,'phases',phase[0].id]);
    }

    return phase.length === 0 ? null : phase[0];
  }
}
