import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IAppDashboard } from '@backend/interfaces';
import { AppdashboardService } from './appdashboard.service';

@Component({
  selector: 'backend-appdashboard',
  templateUrl: './appdashboard.component.html',
  styleUrls: ['./appdashboard.component.css'],
})
export class AppdashboardComponent implements OnInit {
  public internalUsers: IAppDashboard[];
  public externalUsers: IAppDashboard[];
  public organization = [];
  public arr = Array.from({ length: 12 }).fill(0);
  public previousCount = Array.from({ length: 12 }).fill(0);
  public array = [];
  public newprev = [];
  public intU = 0;
  public extU = 0;
  public orgArray = [];
  public created = [];
  public yr: string;
  public switchYear = false;
  public switchpreviousYear = false;
  public sum = 0;
  public month: number;
  public dataValues: any;
  public barValues: any;
  public orgValues: any;
  public switchValues: any;
  public switchValue: any;
  public prevLabel = [];
  public currLabel = [];
  public barCounter = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December       ',
  ];
  public dateDisplay: any;
  public datePrevious: any;
  public selectedYear = 'Select Year';

  private _filteredUsers: IAppDashboard[];
  public siemensHealthineersDomain: string = '@siemens-healthineers.com';
  public get filteredUsers(): IAppDashboard[] {
    return this._filteredUsers;
  }

  public $users: Observable<IAppDashboard[]>;

  constructor(private appDashboardService: AppdashboardService) {}

  ngOnInit(): void {
    this.$users = this.appDashboardService.$users;
    this.$users.subscribe((users: IAppDashboard[]) => {
      this._filteredUsers = users;
    });
    this.dateDisplay = new Date();
    this.datePrevious = (this.dateDisplay.getFullYear() - 1).toString();
    this.dateDisplay = this.dateDisplay.getFullYear().toString();
    this.countUsers();
    this.loadBarGraphUsers();
    this.pieGraphOrganisation();
  }

  // Zipper is used to append 0 as prefix for digits 1-9
  public Zipper(i: string) {
    if (i.length == 1) {
      return '0' + i;
    } else {
      return i;
    }
  }

  public async countUsers(): Promise<void> {
    this.$users.subscribe((users: IAppDashboard[]) => {
      this._filteredUsers = users;
      if (this._filteredUsers.length != 0) {
        this.internalUsers = this._filteredUsers.filter((user) =>
          user.email.toLowerCase().endsWith(this.siemensHealthineersDomain)
        );
        this.externalUsers = this._filteredUsers.filter(
          (user) =>
            !user.email.toLowerCase().endsWith(this.siemensHealthineersDomain)
        );
        this.extU = this.externalUsers.length;
        this.intU = this.internalUsers.length;
        this.dataValues = {
          labels: ['Internal users', 'External users'],
          datasets: [
            {
              label: 'Total users',
              data: [this.intU, this.extU],
            },
          ],
        };
      }
    });
  }

  public async pieGraphOrganisation(): Promise<void> {
    this.$users.subscribe((users: IAppDashboard[]) => {
      this._filteredUsers = users;
      let visited = Array.from(
        { length: this._filteredUsers.length },
        (_, i) => false
      );
      this._filteredUsers.forEach((users) => {
        if (users.organization) {
          users.organization = users.organization
            .toLowerCase()
            .replace('-', '');
          users.organization = users.organization.replace(' ', '');
          if (users.organization.toLowerCase() == 'healthineers') {
            users.organization = 'siemenshealthineers';
          }
        }
      });
      if (this._filteredUsers.length >= 1) {
        for (let i = 0; i < this._filteredUsers.length; i++) {
          if (visited[i] == true) continue;

          let count = 1;
          for (let j = i + 1; j < this._filteredUsers.length; j++) {
            if (
              this._filteredUsers[i].organization ==
              this._filteredUsers[j].organization
            ) {
              visited[j] = true;
              count++;
            }
          }
          this.organization.push(
            this._filteredUsers[i].organization.toString()
          );
          this.orgArray.push(count);
        }

        this.orgValues = {
          labels: this.organization,
          datasets: [
            {
              label: 'Number of organizations',
              data: this.orgArray,
            },
          ],
        };
      }
    });
  }

  public async loadBarGraphUsers(): Promise<void> {
    this.$users.subscribe((users: IAppDashboard[]) => {
      this._filteredUsers = users;
      let date = new Date();

      const add = (arr) => arr.reduce((a, b) => a + b, 0);
      this.yr = this.datePrevious + '-' + this.dateDisplay;
      let defPrevYearArr = this.calculatingNoUsersInMonth(
        date.getFullYear() - 1,
        this._filteredUsers
      ).slice(date.getMonth() + 1);
      let defCurrYearArr = this.calculatingNoUsersInMonth(
        date.getFullYear(),
        this._filteredUsers
      ).slice(1);
      let defaultBarArray = defPrevYearArr.concat(defCurrYearArr);
      //   // taking the count of users in every month
      let years = new Date();
      years = this.addMonths(date, -12);

      for (let i = years.getMonth(); i < 12; i++) {
        this.prevLabel.push(this.barCounter[i]);
      }
      for (let j = 0; j <= date.getMonth(); j++) {
        this.currLabel.push(this.barCounter[j]);
      }
      this.prevLabel = this.prevLabel.concat(this.currLabel);
      let neArr = [];
      defaultBarArray.forEach((a) => {
        if (isNaN(a)) {
          a = 0;
        }
        neArr.push(a);
      });
      this.sum = add(neArr);

      this.barValues = {
        labels: this.prevLabel,
        datasets: [
          {
            label: 'Number of users',
            data: neArr,
          },
        ],
      };

      this.prevLabel = [];
      this.currLabel = [];
    });
  }

  addMonths(date, months) {
    let d = date.getDate();
    date.setMonth(date.getMonth() + +months);
    if (date.getDate() != d) {
      date.setDate(0);
    }
    return date;
  }

  public async previourYearBar(value): Promise<void> {
    let date = new Date();
    this.month = date.getMonth();
    const add = (arr) => arr.reduce((a, b) => a + b, 0);
    if (value == this.datePrevious) {
      this.yr = this.datePrevious;
      this.previousCount = this.calculatingNoUsersInMonth(
        value,
        this._filteredUsers
      );
      this.barValues = {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        datasets: [
          {
            label: 'Number of users',
            data: this.previousCount,
          },
        ],
      };
      this.previousCount.splice(0, 1);
      this.sum = add(this.previousCount);
    } else if (value == this.dateDisplay) {
      this.yr = this.dateDisplay;
      let MonthCntArr = this.calculatingNoUsersInMonth(
        value,
        this._filteredUsers
      );
      let neArr = [];
      MonthCntArr.forEach((a) => {
        if (isNaN(a)) {
          a = 0;
        }
        neArr.push(a);
      });
      this.barCounter.splice(this.month + 1, this.barCounter.length);
      this.barValues = {
        labels: this.barCounter,
        datasets: [
          {
            label: 'Number of users',
            data: neArr,
          },
        ],
      };

      neArr.splice(0, 1);
      this.sum = add(neArr);
    }
  }
  /*
  Function logic
    - pass the sorted data as input ( non descending order)
    - Iterate till the second last index
    - if months match for ith and i+1th index, increase the counter
    - if month does not match for ith and i+1th index, push cnt to output array 
      and push the last record as well if ith index is second last one.
 */

  public calculatingNoUsersInMonth(value, _filteredUsers) {
    //DefineDefault Month and MonthCountArray
    let MonthArray = [];
    let MonthCountArray = [];
    let curryear;
    let monthcnt;
    let date = new Date();
    if (value == this.datePrevious) {
      monthcnt = 13;
      curryear = (date.getFullYear() - 1).toString();
    } else if (value == this.dateDisplay) {
      monthcnt = date.getMonth();
      curryear = date.getFullYear().toString();
    }
    for (let i = 1; i <= monthcnt; i++) {
      MonthArray.push(this.Zipper(i.toString()));
      MonthCountArray.push(0);
    }

    this.created = _filteredUsers.filter((graph) =>
      graph.created.substring(0, 4).match(curryear)
    );
    this.created.sort(
      (a, b) => new Date(a.created).getMonth() - new Date(b.created).getMonth()
    );
    let cnt = 1;
    if (this.created.length == 1) {
      let n = MonthArray.indexOf(this.created[0].created.substring(5, 7));
      MonthCountArray[
        MonthArray.indexOf(this.created[0].created.substring(5, 7)) + 1
      ] += cnt;
    } else {
      for (let i = 0; i < this.created.length - 1; i++) {
        if (
          this.created[i].created.substring(5, 7) ==
          this.created[i + 1].created.substring(5, 7)
        ) {
          cnt = cnt + 1;
        } else {
          MonthCountArray[
            MonthArray.indexOf(this.created[i].created.substring(5, 7)) + 1
          ] += cnt;
          cnt = 1;
          if (i + 2 == this.created.length) {
            MonthCountArray[
              MonthArray.indexOf(
                this.created[this.created.length - 1].created.substring(5, 7)
              ) + 1
            ] = cnt;
          }
        }
      }
    }
    return MonthCountArray;
  }
}
