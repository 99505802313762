<sh-card>
  <sh-text
    slot="header"
    size="header-1"
    [innerText]="'header.tabs.appdashboard' | translate"
  ></sh-text>
  <div></div>
  <sh-grid fit-content>
    <sh-card columns="6">
      <sh-chart id="demo-pie-chart" [data]="dataValues" type="pie"></sh-chart>
      <sh-text size="body-2" style="text-align: center" color="primary"
        ><strong>Overall- User Types</strong></sh-text
      >
    </sh-card>
    <sh-card columns="6">
      <sh-chart id="demo-pie-chart" [data]="orgValues" type="pie"> </sh-chart>
      <sh-text size="body-2" color="primary" style="text-align: center"
        ><strong>Number of Organizations</strong></sh-text
      >
    </sh-card>

    <sh-card columns="12" col-s="12" col-m="14" style="overflow: unset">
      <sh-text size="body-2" color="primary"
        >Overall number of users for selected year
        <strong>{{ sum }}</strong></sh-text
      >
      <sh-dropdown
        id="yearchange"
        style="width: 300px; margin-left: 5px; margin-bottom: 6px"
        [value]="selectedYear"
        label="Selected Year"
        class="child-tab audit-tab"
      >
        <sh-menu-item
          label="{{ datePrevious }}"
          (click)="previourYearBar(datePrevious)"
          >{{ datePrevious }}</sh-menu-item
        >
        <sh-menu-item
          label="{{ dateDisplay }}"
          (click)="previourYearBar(dateDisplay)"
          aria-selected
          >{{ dateDisplay }}
        </sh-menu-item>
      </sh-dropdown>
      <sh-chart
        id="demo-bar-chart"
        [data]="barValues"
        type="bar"
        legend
      ></sh-chart>
      <sh-text size="body-2" color="primary" style="text-align: center"
        >Month <strong>{{ yr }}</strong></sh-text
      >
    </sh-card>
  </sh-grid>
</sh-card>
