import { Component, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'backend-create-localization-entry',
  templateUrl: './create-localization-entry.component.html',
  styleUrls: ['./create-localization-entry.component.scss'],
})
export class CreateLocalizationEntryComponent {
  public closed = new EventEmitter<any>();

  private _key = '';
  @Input()
  public set key(value: string) {
    this._key = value;
  }
  public get key(): string {
    return this._key;
  }

  private _valueData = '';
  @Input()
  public set valueData(value: string) {
    this._valueData = value;
  }
  public get valueData(): string {
    return this._valueData;
  }

  private _visible = false;
  @Input()
  public set visible(value: boolean) {
    this._visible = value;
  }
  public get visible(): boolean {
    return this._visible;
  }

  public reset(): void {
    this._key = '';
    this._valueData = '';
  }

  public open(): void {
    this.reset();
    this.visible = true;
  }

  public close(): void {
    this.visible = false;
    this.closed.emit(this._key);
  }

  public cancel(): void {
    this.visible = false;
    this.closed.emit();
  }
}
