import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DevicesService } from '../../../../../../devices/devices.service';
import { DeviceVersionsService } from '../../../../device-versions.service';
import { AssetsService } from '../assets.service';
import { ParamsService } from 'apps/webapp/src/app/services/params.service';

@Component({
  selector: 'backend-edit-assets',
  templateUrl: './edit-assets.component.html',
  styleUrls: ['./edit-assets.component.scss'],
})
export class EditAssetsComponent implements OnInit {
  @ViewChild('inputFile')
  public inputFile: ElementRef<HTMLInputElement>;

  public platforms = this.assetsService.getPlatformEnumKeys();

  public isEditMode = false;

  public file: string;
  public fileBasename: string;
  public fileChanged = false;

  public name = '';
  public platform = 'Universal';

  public constructor(
    private router: Router,
    private paramsService: ParamsService,
    private assetsService: AssetsService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.assetsService.updateAsset();
    this.updateFields();
  }

  public fileChange(file: File): void {
    this.file = file.name;
    this.fileBasename = file.name.replace(/.*[\/\\]/, '');
    this.fileChanged = true;
  }

  private async updateFields(): Promise<void> {
    const asset = await this.assetsService.getAsset();
    if (asset) {
      this.name = asset.name;
      const enumKeys = this.assetsService.getPlatformEnumKeys();
      this.platform = enumKeys[asset.platform];
      this.file = asset.path;
      if (this.file) {
        this.fileBasename = this.file.replace(/.*[\/\\]/, '');
      } else {
        this.fileBasename = '';
      }
      this.isEditMode = true;
    } else {
      this.resetFields();
      this.isEditMode = false;
    }
  }

  private resetFields(): void {
    this.name = '';
    this.platform = 'Universal';
    this.file = '';
    this.fileBasename = '';
    this.fileChanged = false;
  }

  public selectFile() {
    this.inputFile.nativeElement.click();
  }

  public async save() {
    const file = this.fileChanged
      ? this.inputFile.nativeElement.files[0]
      : null;
    let result: boolean;
    if (!this.isEditMode) {
      result = await this.assetsService.createAsset(
        this.name,
        this.platform,
        file
      );
    } else {
      result = await this.assetsService.modifyAsset(
        this.paramsService.assetId,
        this.name,
        this.platform,
        file
      );
    }

    if (result) {
      this.gotoAssets();
    }
  }

  public async cancel() {
    this.gotoAssets();
  }

  public gotoAssets(): void {
    this.router.navigate([
      'devices',
      this.paramsService.deviceId,
      'versions',
      this.paramsService.deviceVersionId,
      'assets',
    ]);
  }
}
