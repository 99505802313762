import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ParamsService } from '@backend/webapp/services/params.service';
import { Router } from '@angular/router';
import { BlocksService } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/blocks/blocks.service';
import { Block } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/blocks/block';
import { LanguageService } from '@backend/webapp/services/language.service';
import { Author } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/blocks/author';
import { ImgConvertorComponent } from '@backend/webapp/img-convertor/img-convertor.component';
import { Constants } from '@backend/interfaces';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { pairwise } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';

@Component({
  selector: 'backend-edit-block',
  templateUrl: './edit-block.component.html',
  styleUrls: ['./edit-block.component.scss'],
})
export class EditBlockComponent implements OnInit, OnDestroy {
  @ViewChild('previewImage')
  public previewImage: ElementRef<HTMLImageElement>;

  public isEditMode: boolean;
  public blockType: string;

  public text: string;
  public image: string;
  public imageBasename: string;

  public imageChanged = false;

  public authors: Author[];

  private imageFile: File;
  private imgConvertor: ImgConvertorComponent;

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  textTrans: any[];

  paramSubscription: Subscription;
  languageSubscription: Subscription;

  constructor(
    private readonly blockService: BlocksService,
    private readonly paramsService: ParamsService,
    private readonly languageService: LanguageService,
    private readonly renderer: Renderer2,
    private readonly router: Router,
    public readonly localization: LocalizationService,
    public readonly workflowGuidesService: WorkflowGuidesService
  ) {
    this.imgConvertor = new ImgConvertorComponent();
  }

  async ngOnInit(): Promise<void> {
    this.blockType = this.paramsService.blockType;
    this.text = this.image = this.imageBasename = '';
    this.isEditMode = false;
    if (this.paramsService.blockId == null) {
      return;
    }
    this.isEditMode = true;
    await this.updateBlock();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          await this.updateBlock();
        }
      }
    );
    this.paramSubscription = this.paramsService.blockId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          await this.updateBlock();
        }
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  async updateBlock() {
    const block: Block = await this.blockService.get(
      this.paramsService.blockId
    );
    this.image = block.image;
    this.authors = block.authors;
    this.text = this.languageService.getTranslationByKey(
      block.text,
      this.globalLanguage.key
    );
    this.textTrans = new GetLocaleTextPipe(this.localization).transform(
      block.text,
      this.globalLanguage.key
    );
    if (this.image) {
      this.imageBasename = this.image.replace(/.*[\/\\]/, '');
    } else {
      this.imageBasename = '';
    }
  }

  async save() {
    if (!this.isEditMode) {
      switch (this.blockType) {
        case 'image':
          const file = this.imageChanged
            ? this.imgConvertor.getImageFile()
            : null;
          await this.blockService.createImage(file);
          break;
        case 'text':
          await this.blockService.createText(this.text);
          break;
        case 'author':
          break; // Ignore -> block has been created already
      }
    } else {
      switch (this.blockType) {
        case 'image':
          const file = this.imageChanged
            ? this.imgConvertor.getImageFile()
            : null;
          await this.blockService.editImage(this.paramsService.blockId, file);
          break;
        case 'text':
          await this.blockService.editText(
            this.paramsService.blockId,
            this.text
          );
          break;
      }
    }

    this.goBack();
  }

  cancel() {
    this.goBack();
  }

  canSave(): boolean {
    switch (this.blockType) {
      case 'image':
        return this.imageFile != null;
      case 'text':
        return this.text != null && this.text.length > 0;
      case 'author':
        return this.authors != null && this.authors.length > 0;
      default:
        return false;
    }
  }

  fileChange(file: File) {
    if (file) {
      this.image = file.name;
      this.imageFile = file;
      this.imageBasename = file.name.replace(/.*[\/\\]/, '');
      this.imageChanged = true;

      const reader = new FileReader();
      reader.onloadend = (e) => {
        let img = new Image();
        this.renderer.setAttribute(
          this.previewImage.nativeElement,
          'src',
          e.target.result.toString()
        );
        img.src = e.target.result.toString();
        img.onload = () => {
          this.imgConvertor.drawImageOnCanvas(img, this.imageBasename);
        };
      };
      reader.readAsDataURL(file);
    } else {
      this.image = '';
      this.imageBasename = '';
    }
  }

  private goBack() {
    this.router
      .navigate([
        'workflowguides',
        'workflows',
        this.paramsService.workflowId,
        'versions',
        this.paramsService.workflowVersionId,
        'overviews',
        this.paramsService.overviewId,
      ])
      .then();
  }

  public async deleteAuthor(author: Author) {
    await this.blockService.deleteAuthor(author.id, author.name);
    this.updateBlock();
  }

  public addAuthor(): void {
    this.router
      .navigate([
        'workflowguides',
        'workflows',
        this.paramsService.workflowId,
        'versions',
        this.paramsService.workflowVersionId,
        'overviews',
        this.paramsService.overviewId,
        'blocks',
        this.paramsService.blockId,
        'author',
        'edit',
      ])
      .then();
  }
}
