<sh-card>
  <sh-grid>
    <sh-input-text
      columns="6"
      mandatory
      textarea
      autofocus
      no-border
      rows="28"
      [label]="'pages.editor' | translate"
      [value]="text"
      (value-changed)="text = $event.target.value"
    ></sh-input-text>
    <div class="preview-container" columns="6">
      <sh-card [label]="'pages.preview' | translate">
        <sh-text
          *ngFor="let item of text | xmlparser"
          [color]="item.color"
          [href]="item.href"
          [size]="item.class"
          >{{ item.text }}</sh-text
        >
      </sh-card>
    </div>
  </sh-grid>
  <sh-button
    [routerLink]="['/', 'pages']"
    slot="footer"
    size="m"
    color="secondary"
    [label]="'general.cancel' | translate"
  ></sh-button>
  <sh-button
    slot="footer"
    size="m"
    [label]="'general.ok' | translate"
    (click)="save()"
  ></sh-button>
</sh-card>
