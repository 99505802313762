import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ImageOptimizationService {
    public async resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<File> {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
            const canvas = document.createElement('canvas');
            let width = img.width;
            let height = img.height;
        
            if (width > height) {
                if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
                }
            } else {
                if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
                }
            }
        
            canvas.width = width;
            canvas.height = height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0, width, height);
            canvas.toBlob((blob: Blob) => {
                const resizedFile = new File([blob], file.name, { type: file.type });
                resolve(resizedFile);
            }, file.type);
            };
            img.onerror = error => reject(error);
            img.src = URL.createObjectURL(file);
        });
    }
}