<sh-modal
  id="footer-modal"
  sticky
  modal-height="fit-content"
  [visible]="visible"
  [label]="'localization.addEntry' | translate"
>
  <sh-text [innerText]="'localization.enterNewKey' | translate"></sh-text>

  <sh-input-text
    #keyTextbox
    mandatory
    [label]="'localization.key' | translate"
    [value]="key"
    (value-changed)="key = $event.target.value"
  ></sh-input-text>
  <sh-input-text
    #valueTextbox
    textarea
    rows="4"
    label="Value"
    [value]="valueData"
    (value-changed)="valueData = $event.target.value"
  ></sh-input-text>
  <sh-button
    slot="footer"
    size="m"
    color="secondary"
    [label]="'general.cancel' | translate"
    (click)="cancel()"
  ></sh-button>

  <sh-button
    slot="footer"
    size="m"
    [label]="'general.ok' | translate"
    [disabled]="!keyTextbox.value"
    (click)="close()"
  ></sh-button>
</sh-modal>
