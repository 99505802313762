import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { ImgConvertorComponent } from '@backend/webapp/img-convertor/img-convertor.component';
import { Constants } from '@backend/interfaces';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { LanguageService } from '../../../../../../services/language.service';
import { ParamsService } from '../../../../../../services/params.service';
import { HotspotsService } from '../hotspots.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'backend-edit-hotspot',
  templateUrl: './edit-hotspot.component.html',
  styleUrls: ['./edit-hotspot.component.scss'],
})
export class EditHotspotComponent implements OnInit, OnDestroy {
  @ViewChild('inputFile')
  public inputFile: ElementRef<HTMLInputElement>;

  @ViewChild('previewImage')
  public previewImage: ElementRef<HTMLImageElement>;

  public isEditMode = false;

  public file: string;
  public fileBasename: string;
  public fileChanged = false;

  public name = '';
  public description = '';
  public key = '';
  private imgConvertor: ImgConvertorComponent;

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  nameTrans: any[];
  descriptionTrans: any[];

  languageSubscription: Subscription;

  public constructor(
    private router: Router,
    private hotspotsService: HotspotsService,
    private paramsService: ParamsService,
    private renderer: Renderer2,
    public languageService: LanguageService,
    public localization: LocalizationService
  ) {
    this.imgConvertor = new ImgConvertorComponent();
  }

  public async ngOnInit(): Promise<void> {
    await this.hotspotsService.updateHotspot();
    this.updateFields();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          await this.updateFields();
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  public fileChange(file: File): void {
    if (file) {
      this.file = file.name;
      this.fileBasename = file.name.replace(/.*[\/\\]/, '');
      this.fileChanged = true;

      const reader = new FileReader();
      reader.onloadend = (e) => {
        let image = new Image();
        this.renderer.setAttribute(
          this.previewImage.nativeElement,
          'src',
          e.target.result.toString()
        );
        image.src = e.target.result.toString();
        image.onload = (e) => {
          this.imgConvertor.drawImageOnCanvas(image, this.fileBasename);
        };
      };
      reader.readAsDataURL(file);
    } else {
      this.file = '';
      this.fileBasename = '';
    }
  }

  private async updateFields(): Promise<void> {
    const hotspot = await this.hotspotsService.getHotspot();
    if (hotspot) {
      this.name = this.languageService.getTranslationByKey(
        hotspot.title,
        this.globalLanguage.key
      );
      this.nameTrans = new GetLocaleTextPipe(this.localization).transform(
        hotspot.title,
        this.globalLanguage.key
      );
      this.description = this.languageService.getTranslationByKey(
        hotspot.description,
        this.globalLanguage.key
      );
      this.descriptionTrans = new GetLocaleTextPipe(
        this.localization
      ).transform(hotspot.description, this.globalLanguage.key);
      this.key = hotspot.key;
      this.file = hotspot.thumbnail;
      if (this.file) {
        this.fileBasename = this.file.replace(/.*[\/\\]/, '');
      } else {
        this.fileBasename = '';
      }
      this.isEditMode = true;
    } else {
      this.resetFields();
      this.isEditMode = false;
    }
  }

  private resetFields(): void {
    this.name = '';
    this.description = '';
    this.key = '';
    this.file = '';
    this.fileBasename = '';
    this.fileChanged = false;
  }

  public selectFile() {
    this.inputFile.nativeElement.click();
  }

  public async save() {
    const file = this.fileChanged ? this.imgConvertor.getImageFile() : null;
    let result: boolean;
    if (!this.isEditMode) {
      result = await this.hotspotsService.createHotspot(
        this.name,
        this.description,
        this.key,
        file
      );
    } else {
      result = await this.hotspotsService.modifyHotspot(
        this.paramsService.hotspotId,
        this.name,
        this.description,
        this.key,
        file
      );
    }

    if (result) {
      this.gotoHotspots();
    }
  }

  public async cancel() {
    this.gotoHotspots();
  }

  public gotoHotspots(): void {
    this.router.navigate([
      'devices',
      this.paramsService.deviceId,
      'versions',
      this.paramsService.deviceVersionId,
      'hotspots',
    ]);
  }
}
