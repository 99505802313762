import { Injectable, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ParamsService {
  public updated = new EventEmitter();
  public isLoading = false;

  private _deviceId$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public get deviceId$(): Observable<number> {
    return this._deviceId$.asObservable();
  }
  public get deviceId(): number {
    return this._deviceId$.value;
  }

  private _deviceVersionId$: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);
  public get deviceVersionId$(): Observable<number> {
    return this._deviceVersionId$.asObservable();
  }
  public get deviceVersionId(): number {
    return this._deviceVersionId$.value;
  }

  private _assetId$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public get assetId$(): Observable<number> {
    return this._assetId$.asObservable();
  }
  public get assetId(): number {
    return this._assetId$.value;
  }

  private _axisCategoryId$: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);
  public get axisCategoryId$(): Observable<number> {
    return this._axisCategoryId$.asObservable();
  }
  public get axisCategoryId(): number {
    return this._axisCategoryId$.value;
  }

  private _axisId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public get axisId$(): Observable<number> {
    return this._axisId$.asObservable();
  }
  public get axisId(): number {
    return this._axisId$.value;
  }

  private _axisType$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  public get axisType$(): Observable<string> {
    return this._axisType$.asObservable();
  }
  public get axisType(): string {
    return this._axisType$.value;
  }

  private _featureId$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public get featureId$(): Observable<number> {
    return this._featureId$.asObservable();
  }
  public get featureId(): number {
    return this._featureId$.value;
  }

  private _hotspotId$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public get hotspotId$(): Observable<number> {
    return this._hotspotId$.asObservable();
  }
  public get hotspotId(): number {
    return this._hotspotId$.value;
  }

  private _screenType$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  public get screenType$(): Observable<string> {
    return this._screenType$.asObservable();
  }
  public get screenType(): string {
    return this._screenType$.value;
  }

  private _screenId$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public get screenId$(): Observable<number> {
    return this._screenId$.asObservable();
  }
  public get screenId(): number {
    return this._screenId$.value;
  }

  private _detailType$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  public get detailType$(): Observable<string> {
    return this._detailType$.asObservable();
  }
  public get detailType(): string {
    return this._detailType$.value;
  }

  private _detailId$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public get detailId$(): Observable<number> {
    return this._detailId$.asObservable();
  }
  public get detailId(): number {
    return this._detailId$.value;
  }

  private _phaseWrapperId$: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);
  public get phaseWrapperId$(): Observable<number> {
    return this._phaseWrapperId$.asObservable();
  }
  public get phaseWrapperId(): number {
    return this._phaseWrapperId$.value;
  }

  private _phaseVersionId$: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);
  public get phaseVersionId$(): Observable<number> {
    return this._phaseVersionId$.asObservable();
  }
  public get phaseVersionId(): number {
    return this._phaseVersionId$.value;
  }

  private _keyActivityId$: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);
  public get keyActivityId$(): Observable<number> {
    return this._keyActivityId$.asObservable();
  }
  public get keyActivityId(): number {
    return this._keyActivityId$.value;
  }

  private _stepId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  public get stepId$(): Observable<number> {
    return this._stepId$.asObservable();
  }
  public get stepId(): number {
    return this._stepId$.value;
  }

  private _workflowId$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public get workflowId$(): Observable<number> {
    return this._workflowId$.asObservable();
  }
  public get workflowId(): number {
    return this._workflowId$.value;
  }

  private _workflowVersionId$: BehaviorSubject<number> =
    new BehaviorSubject<number>(null);
  public get workflowVersionId$(): Observable<number> {
    return this._workflowVersionId$.asObservable();
  }
  public get workflowVersionId(): number {
    return this._workflowVersionId$.value;
  }

  private _overviewId$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public get overviewId$(): Observable<number> {
    return this._overviewId$.asObservable();
  }
  public get overviewId(): number {
    return this._overviewId$.value;
  }

  private _blockType$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  public get blockType$(): Observable<string> {
    return this._blockType$.asObservable();
  }
  public get blockType(): string {
    return this._blockType$.value;
  }

  private _blockId$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public get blockId$(): Observable<number> {
    return this._blockId$.asObservable();
  }
  public get blockId(): number {
    return this._blockId$.value;
  }

  private _authorId$: BehaviorSubject<number> = new BehaviorSubject<number>(
    null
  );
  public get authorId$(): Observable<number> {
    return this._authorId$.asObservable();
  }
  public get authorId(): number {
    return this._authorId$.value;
  }

  private _assetType$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );
  public get assetType$(): Observable<string> {
    return this._assetType$.asObservable();
  }
  public get assetType(): string {
    return this._assetType$.value;
  }

  public constructor(public router: Router) {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .subscribe(() => {
        this.updateParams();
      });

    this.updateParams();
  }

  public updateParams(): void {
    const allParams = this.getAllParams();
    let valueChanged = false;

    const deviceIdParsed = parseInt(allParams.deviceId, 10);
    const deviceId = isNaN(deviceIdParsed) ? null : deviceIdParsed;
    if (this.deviceId !== deviceId) {
      this._deviceId$.next(deviceId);
      valueChanged = true;
    }

    const deviceVersionIdParsed = parseInt(allParams.deviceVersionId, 10);
    const deviceVersionId = isNaN(deviceVersionIdParsed)
      ? null
      : deviceVersionIdParsed;
    if (this.deviceVersionId !== deviceVersionId) {
      this._deviceVersionId$.next(deviceVersionId);
      valueChanged = true;
    }

    const assetIdParsed = parseInt(allParams.assetId, 10);
    const assetId = isNaN(assetIdParsed) ? null : assetIdParsed;
    if (this.assetId !== assetId) {
      this._assetId$.next(assetId);
      valueChanged = true;
    }

    const axisCategoryIdParsed = parseInt(allParams.axisCategoryId, 10);
    const axisCategoryId = isNaN(axisCategoryIdParsed)
      ? null
      : axisCategoryIdParsed;
    if (this.axisCategoryId !== axisCategoryId) {
      this._axisCategoryId$.next(axisCategoryId);
      valueChanged = true;
    }

    const axisIdParsed = parseInt(allParams.axisId, 10);
    const axisId = isNaN(axisIdParsed) ? null : axisIdParsed;
    if (this.axisId !== axisId) {
      this._axisId$.next(axisId);
      valueChanged = true;
    }

    const axisType = allParams.axisType ? allParams.axisType : null;
    if (this.axisType !== axisType) {
      this._axisType$.next(axisType);
      valueChanged = true;
    }

    const featureIdParsed = parseInt(allParams.featureId, 10);
    const featureId = isNaN(featureIdParsed) ? null : featureIdParsed;
    if (this.featureId !== featureId) {
      this._featureId$.next(featureId);
      valueChanged = true;
    }

    const hotspotIdParsed = parseInt(allParams.hotspotId, 10);
    const hotspotId = isNaN(hotspotIdParsed) ? null : hotspotIdParsed;
    if (this.hotspotId !== hotspotId) {
      this._hotspotId$.next(hotspotId);
      valueChanged = true;
    }

    const screenType = allParams.screenType ? allParams.screenType : null;
    if (this.screenType !== screenType) {
      this._screenType$.next(screenType);
      valueChanged = true;
    }

    const screenIdParsed = parseInt(allParams.screenId, 10);
    const screenId = isNaN(screenIdParsed) ? null : screenIdParsed;
    if (this.screenId !== screenId) {
      this._screenId$.next(screenId);
      valueChanged = true;
    }

    const detailType = allParams.detailType ? allParams.detailType : null;
    if (this.detailType !== detailType) {
      this._detailType$.next(detailType);
      valueChanged = true;
    }

    const detailIdParsed = parseInt(allParams.detailId, 10);
    const detailId = isNaN(detailIdParsed) ? null : detailIdParsed;
    if (this.detailId !== detailId) {
      this._detailId$.next(detailId);
      valueChanged = true;
    }

    const phaseWrapperIdParsed = parseInt(allParams.phaseWrapperId, 10);
    const phaseWrapperId = isNaN(phaseWrapperIdParsed)
      ? null
      : phaseWrapperIdParsed;
    if (this.phaseWrapperId !== phaseWrapperId) {
      this._phaseWrapperId$.next(phaseWrapperId);
      valueChanged = true;
    }

    const phaseVersionIdParsed = parseInt(allParams.phaseVersionId, 10);
    const phaseVersionId = isNaN(phaseVersionIdParsed)
      ? null
      : phaseVersionIdParsed;
    if (this.phaseVersionId !== phaseVersionId) {
      this._phaseVersionId$.next(phaseVersionId);
      valueChanged = true;
    }

    const keyActivityIdParsed = parseInt(allParams.keyActivityId, 10);
    const keyActivityId = isNaN(keyActivityIdParsed)
      ? null
      : keyActivityIdParsed;
    if (this.keyActivityId !== keyActivityId) {
      this._keyActivityId$.next(keyActivityId);
      valueChanged = true;
    }

    const stepIdParsed = parseInt(allParams.stepId, 10);
    const stepId = isNaN(stepIdParsed) ? null : stepIdParsed;
    if (this.stepId !== stepId) {
      this._stepId$.next(stepId);
      valueChanged = true;
    }

    const workflowIdParsed = parseInt(allParams.workflowId, 10);
    const workflowId = isNaN(workflowIdParsed) ? null : workflowIdParsed;
    if (this.workflowId !== workflowId) {
      this._workflowId$.next(workflowId);
      valueChanged = true;
    }

    const workflowVersionIdParsed = parseInt(allParams.workflowVersionId, 10);
    const workflowVersionId = isNaN(workflowVersionIdParsed)
      ? null
      : workflowVersionIdParsed;
    if (this.workflowVersionId !== workflowVersionId) {
      this._workflowVersionId$.next(workflowVersionId);
      valueChanged = true;
    }

    const overviewIdParsed = parseInt(allParams.overviewId, 10);
    const overviewId = isNaN(overviewIdParsed) ? null : overviewIdParsed;
    if (this.overviewId !== overviewId) {
      this._overviewId$.next(overviewId);
      valueChanged = true;
    }

    const blockType = allParams.blockType;
    if (this.blockType !== blockType) {
      this._blockType$.next(blockType);
      valueChanged = true;
    }

    const blockIdParsed = parseInt(allParams.blockId, 10);
    const blockId = isNaN(blockIdParsed) ? null : blockIdParsed;
    if (this.blockId !== blockId) {
      this._blockId$.next(blockId);
      valueChanged = true;
    }

    const authorIdParsed = parseInt(allParams.authorId, 10);
    const authorId = isNaN(authorIdParsed) ? null : authorIdParsed;
    if (this.authorId !== authorId) {
      this._authorId$.next(authorId);
      valueChanged = true;
    }

    const assetType = allParams.assetType;
    if (this.assetType !== assetType) {
      this._assetType$.next(assetType);
      valueChanged = true;
    }

    if (valueChanged) {
      this.updated.emit();
    }
  }

  private getAllParams(): any {
    const params = {};
    let route = this.router.routerState.snapshot.root;

    while (route) {
      for (const key in route.params) {
        if (route.params.hasOwnProperty(key)) {
          params[key] = route.params[key];
        }
      }

      route = route.firstChild;
    }

    return params;
  }
}
