<div>
  <div class="content">
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.workflows.workflowVersions.overviews.blocks.authors.name'
            | translate
        "
      ></sh-text>
      <sh-input-text
        mandatory
        [label]="
          'workflowguides.workflows.workflowVersions.overviews.blocks.authors.name'
            | translate
        "
        [value]="nameTrans"
        (value-changed)="name = $event.target.value"
      ></sh-input-text>
    </div>
    <div class="content-row">
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.workflows.workflowVersions.overviews.blocks.authors.description'
            | translate
        "
      ></sh-text>
      <sh-input-text
        mandatory
        [label]="
          'workflowguides.workflows.workflowVersions.overviews.blocks.authors.description'
            | translate
        "
        [value]="descriptionTrans"
        textarea
        mandatory
        (value-changed)="description = $event.target.value"
      ></sh-input-text>
    </div>
    <div class="content-row">
      <sh-text
        [innerHTML]="
          'workflowguides.workflows.workflowVersions.overviews.blocks.authors.portrait'
            | translate
        "
      ></sh-text>
      <sh-input-text
        readonly
        [value]="portraitBaseName"
        [label]="
          'workflowguides.workflows.workflowVersions.overviews.blocks.authors.portrait'
            | translate
        "
      ></sh-input-text>
      <sh-button
        size="m"
        [label]="'general.openFile' | translate"
        (click)="inputFile.click()"
      ></sh-button>
    </div>
    <div class="content-row">
      <sh-text size="header-3"></sh-text>
      <img
        *ngIf="portrait && !portraitChanged"
        [src]="portrait | s3Image | async"
      />
      <img #previewImage *ngIf="portraitChanged" />
    </div>
  </div>

  <div class="buttons">
    <sh-button
      [disabled]="
        name === null ||
        name === '' ||
        !description === null ||
        description === ''
      "
      size="m"
      (click)="save()"
      [label]="'general.save' | translate"
    ></sh-button>
    <sh-button
      color="secondary"
      (click)="cancel()"
      size="m"
      [label]="'general.cancel' | translate"
    ></sh-button>
  </div>
</div>

<input
  #inputFile
  type="file"
  accept=".jpg,.jpeg,.png"
  style="display: none"
  (change)="fileChange($event.target['files'][0])"
/>
