<sh-access-bar [label]="'general.pageTitle' | translate">
  <sh-tabs slot="tabs" style="z-index: 1">
    <sh-tab-item
      [label]="'header.tabs.devices' | translate"
      [active]="router.url.startsWith('/devices')"
      [routerLink]="'/devices'"
    ></sh-tab-item>
    <sh-tab-item
      [label]="'header.tabs.workflowguides' | translate"
      [active]="router.url.startsWith('/workflowguides')"
      [routerLink]="'/workflowguides'"
    ></sh-tab-item>
    <sh-tab-item
      [label]="'header.tabs.siteplanner' | translate"
      [active]="router.url.startsWith('/siteplanner')"
      [routerLink]="'/siteplanner'"
    ></sh-tab-item>
    <sh-tab-item
      [class.hidden]="($role | async).role !== 2"
      [label]="'header.tabs.appusers' | translate"
      [active]="router.url.startsWith('/appusers')"
      [routerLink]="'/appusers'"
    ></sh-tab-item>
    <sh-tab-item
      [class.hidden]="($role | async).role !== 2"
      [label]="'header.tabs.adminusers' | translate"
      [active]="router.url.startsWith('/adminusers')"
      [routerLink]="'/adminusers'"
    ></sh-tab-item>
    <sh-tab-item
      [label]="'header.tabs.localization' | translate"
      [active]="router.url === '/localization'"
      [routerLink]="'/localization'"
    ></sh-tab-item>
    <sh-tab-item
      [class.hidden]="($role | async).role !== 2"
      [label]="'header.tabs.pages' | translate"
      [active]="router.url.startsWith('/pages')"
      [routerLink]="'/pages'"
    ></sh-tab-item>
    <sh-tab-item
      [label]="'header.tabs.feedback' | translate"
      [active]="router.url.startsWith('/feedback')"
      [routerLink]="'/feedback'"
    ></sh-tab-item>
  </sh-tabs>

  <sh-menu-item
    class="header-icon"
    [toggle]="noToggle"
    icon-color="gray"
    icon-size="s"
    size="body-2"
    icon="region-language"
    slot="functions"
    [label]="''"
    data-toggle="tooltip"
    id="lang"
  ></sh-menu-item>

  <sh-menu-item
    class="header-icon"
    icon-color="gray"
    icon-size="s"
    size="body-2"
    icon="help"
    slot="functions"
    [toggle]="noToggle"
    [label]="''"
    data-toggle="tooltip"
    id="help"
    onclick="document.querySelector('#helpModal').visible = true"
  ></sh-menu-item>

  <sh-user-identifier
    [class.hidden]="!($auth | async) || !($role | async) || !($profile | async)"
    [name]="($profile | async)?.name"
    [info]="($role | async)?.role === 1 ? 'Content Admin' : 'Super Admin'"
    slot="user"
    id="header-user-menu"
  ></sh-user-identifier>
</sh-access-bar>

<sh-popover target="header-user-menu" position="bottom-left">
  <sh-text
    size="body-2"
    [innerText]="($profile | async)?.idTokenClaims?.email"
  ></sh-text>
  <sh-divider spacing="s"></sh-divider>
  <sh-menu-item
    icon-size="s"
    icon="disc-space-3"
    [label]="themeService?.theme !== 'dark' ? 'Dark Mode' : 'Light Mode'"
    (click)="
      onThemeToggleClick($event); resetActive(); isActive[0] = !isActive[0]
    "
    [active]="isActive[0]"
  >
  </sh-menu-item>

  <sh-divider spacing="s"></sh-divider>

  <sh-menu-item
    icon-size="s"
    icon="combined-trending-graph"
    [class.hidden]="($role | async).role !== 2"
    [label]="'header.tabs.appdashboard' | translate"
    [routerLink]="'/appdashboard'"
    (click)="resetActive(); isActive[1] = !isActive[1]"
    [active]="isActive[1]"
  >
  </sh-menu-item>
  <sh-menu-item
    icon-size="s"
    icon="report-template-search"
    [label]="'header.tabs.auditTrail' | translate"
    [class.hidden]="($role | async).role !== 2 && ($role | async).role !== 1"
    [label]="'header.tabs.auditTrail' | translate"
    [routerLink]="'/audit'"
    (click)="resetActive(); isActive[2] = !isActive[2]"
    [active]="isActive[2]"
  >
  </sh-menu-item>

  <sh-menu-item
    id="language"
    icon-size="s"
    icon="region-language"
    [label]="'language.language' | translate"
    [class.hidden]="($role | async).role !== 2"
    (click)="resetActive(); isActive[4] = !isActive[4]"
    [active]="isActive[4]"
  >
    <sh-icon slot="functions" icon="arrow-face-right-s"></sh-icon>
  </sh-menu-item>
  <sh-popover sub-menu target="language" position="left">
    <span class="languageList" *ngIf="languageService.languages">
      <sh-menu-item
        class="item"
        [label]="l?.name"
        [active]="
          l?.key === languageService.globalLanguage?.key &&
          l?.name === languageService.globalLanguage?.name
        "
        *ngFor="let l of languageService?.languages"
        (click)="changeGlobalLanguage(l)"
      >
        <!-- <sh-icon size="s" slot="functions" icon="delete"
          (click)="deleteLanguage(l);$event?.stopPropagation();"></sh-icon> -->
      </sh-menu-item>
    </span>
    <sh-button
      slot="footer"
      size="s"
      [label]="'language.addNew' | translate"
      (click)="createLanguage()"
    >
    </sh-button>
  </sh-popover>

  <sh-menu-item
    icon-size="s"
    icon="quick-guidance"
    [label]="'auth.userGuide' | translate"
    [routerLink]="'/userguide'"
    (click)="resetActive(); isActive[3] = !isActive[3]"
    [active]="isActive[3]"
  >
  </sh-menu-item>
  <sh-divider spacing="s"></sh-divider>

  <sh-menu-item
    icon-size="s"
    icon="logoff"
    [label]="'auth.doLogoff' | translate"
    (click)="authService.logout()"
  >
  </sh-menu-item>
</sh-popover>

<sh-modal
  id="helpModal"
  [label]="'header.userMenu.appdetails' | translate"
  modal-height=""
  modal-width="500px"
>
  <sh-divider spacing="s"></sh-divider>
  <sh-table>
    <sh-table-row>
      <sh-table-cell columns="8">
        <sh-text size="title-1">Product Name</sh-text>
      </sh-table-cell>
      <sh-table-cell columns="16">
        <sh-text size="title-1">{{ productName }}</sh-text>
      </sh-table-cell>
    </sh-table-row>
    <sh-table-row>
      <sh-table-cell columns="8">
        <sh-text size="title-1">Product Version</sh-text>
      </sh-table-cell>
      <sh-table-cell columns="16">
        <sh-text size="title-1">{{ productVersion }}</sh-text>
      </sh-table-cell>
    </sh-table-row>
    <sh-table-row>
      <sh-table-cell columns="8">
        <sh-text size="title-1">Front End Version</sh-text>
      </sh-table-cell>
      <sh-table-cell columns="16">
        <sh-text size="title-1">{{ frontEndVersion }}</sh-text>
      </sh-table-cell>
    </sh-table-row>
  </sh-table>
</sh-modal>

<div
  [ngClass]="
    themeService?.theme !== 'dark'
      ? 'header-wrapper-light'
      : 'header-wrapper-dark'
  "
>
  <sh-icon
    color="primary"
    id="notification-icon"
    size="s"
    icon="information"
    tabindex="-1"
  ></sh-icon>
  <sh-text size="body-1" id="label" color="primary"
    >Please ensure that uploaded contents
    <strong>&nbsp;should not contain&nbsp;</strong> patient data.</sh-text
  >
</div>

<div
  [hidden]="!isVisible"
  [ngClass]="
    themeService?.theme !== 'dark'
      ? 'header-wrapper-light'
      : 'header-wrapper-dark'
  "
>
  <sh-icon
    color="primary"
    id="notification-icon"
    size="s"
    icon="information"
    tabindex="-1"
  ></sh-icon>
  <sh-text size="body-1" id="label" color="primary"
    >Language content which is not available in the selected language<strong
      *ngIf="languageService.globalLanguage?.name !== 'English'"
      >:&nbsp;{{ languageService.globalLanguage?.name }}&nbsp;</strong
    >
    will be displayed in <strong>&nbsp;English&nbsp;</strong> by
    default.</sh-text
  >
  <sh-icon
    button=""
    icon="cancel"
    size="s"
    tabindex="0"
    id="close-button"
    (click)="isVisible = !isVisible"
  ></sh-icon>
</div>

<sh-tooltip
  target="lang"
  [label]="languageService.globalLanguage?.name"
></sh-tooltip>
<sh-tooltip target="help" [label]="'header.tabs.help' | translate"></sh-tooltip>
<backend-create-language
  style="z-index: 999"
  #createLanguageModal
></backend-create-language>
