<div>
  <sh-table>
    <sh-table-row>
      <sh-table-head
        unsortable
        columns="6"
        [innerHTML]="
          'workflowguides.workflows.workflowVersions.overviews.key' | translate
        "
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="12"
        [innerHTML]="
          'workflowguides.workflows.workflowVersions.overviews.title'
            | translate
        "
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="4"
        [innerHTML]="
          '#' +
          ('workflowguides.workflows.workflowVersions.overviews.blocksCol'
            | translate)
        "
      ></sh-table-head>
      <sh-table-head unsortable columns="2" [innerHTML]=""></sh-table-head>
    </sh-table-row>

    <sh-table-row *ngFor="let overview of overviews">
      <sh-table-cell columns="6" [routerLink]="['overviews', overview.id]">
        <p class="flow-text" [innerText]="overview.key"></p>
      </sh-table-cell>
      <sh-table-cell columns="12" [routerLink]="['overviews', overview.id]">
        <p
          class="flow-text"
          [innerText]="
            overview.title | getLocaleText : globalLanguage?.key : true
          "
        ></p>
      </sh-table-cell>
      <sh-table-cell columns="4" [routerLink]="['overviews', overview.id]">
        <p class="flow-text" [innerText]="overview.blocks.length"></p>
      </sh-table-cell>
      <sh-table-cell icon columns="2">
        <sh-icon *ngIf="!workflowGuidesService.isReleaseVersionActive()" button icon="delete" (click)="delete(overview)"></sh-icon>
      </sh-table-cell>
    </sh-table-row>
  </sh-table>
  <div class="buttons">
    <sh-button *ngIf="!workflowGuidesService.isReleaseVersionActive()"
      class="add-button"
      size="m"
      routerLink="overviews"
      [label]="'general.add' | translate"
    ></sh-button>
  </div>
</div>
