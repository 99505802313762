import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { HotspotsService } from '@backend/webapp/workflowguides/phases/phase-versions/key-activities/steps/assets/hotspots/hotspots.service';
import { ParamsService } from '@backend/webapp/services/params.service';
import { HttpClient } from '@angular/common/http';
import { Constants } from '@backend/interfaces';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { Router } from '@angular/router';
import { pairwise } from 'rxjs/operators';
import { lastValueFrom, Subscription } from 'rxjs';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';

@Component({
  selector: 'backend-hotspots',
  templateUrl: './hotspots.component.html',
  styleUrls: ['./hotspots.component.scss'],
})
export class HotspotsComponent implements OnInit, OnDestroy {
  @ViewChild('videoPlayer', { static: true })
  public videoPlayer: ElementRef<HTMLVideoElement>;

  @ViewChild('image', { static: true })
  public image: ElementRef<HTMLImageElement>;

  public assetScaleX = 1;
  public assetScaleY = 1;
  public assetXoffset: number;
  public assetYoffset: number;

  @Input()
  set Asset(value: string) {
    if (this.paramsService.assetType === 'video' && value?.length > 0) {
      lastValueFrom(
        this.http.get('/api/tools/cloudFrontUrl/', {
          params: { key: value },
          responseType: 'text',
        })
      ).then((source) => {
        this.videoSource = source;
        this.videoType = 'video/mp4';
        this.asset = value;
        this.videoPlayer.nativeElement.load();
      });
    } else {
      this.asset = value;
    }
  }

  @Input()
  assetType: string;

  public asset: string;

  public hotspots: any[];
  public videoType: string;
  public videoSource: string;
  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;

  paramSubscription: Subscription;
  languageSubscription: Subscription;

  constructor(
    private readonly hotspotsService: HotspotsService,
    private readonly paramsService: ParamsService,
    private readonly http: HttpClient,
    private readonly renderer: Renderer2,
    public readonly localization: LocalizationService,
    public readonly workflowGuidesService: WorkflowGuidesService,
    public router: Router
  ) {}

  public async ngOnInit(): Promise<void> {
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        this.globalLanguage = data;
      }
    );
    this.hotspots = await this.hotspotsService.getAll();
    this.paramSubscription = this.paramsService.assetId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          this.hotspots = await this.hotspotsService.getAll();
        }
      });
    this.videoPlayer.nativeElement.addEventListener(
      'loadeddata',
      () => {
        const ratio =
          this.videoPlayer.nativeElement.videoWidth /
          this.videoPlayer.nativeElement.videoHeight;
        this.assetScaleX = 600 / this.videoPlayer.nativeElement.videoWidth;
        this.assetScaleY =
          600 / ratio / this.videoPlayer.nativeElement.videoHeight;
      },
      false
    );

    this.image.nativeElement.onload = () => {
      const ratio =
        this.image.nativeElement.width / this.image.nativeElement.height;
      this.assetScaleX =
        Math.min(this.image.nativeElement.width, 600) /
        this.image.nativeElement.width;
      this.assetScaleY =
        Math.min(this.image.nativeElement.height, 600 / ratio) /
        this.image.nativeElement.height;

      // Resize the image
      this.image.nativeElement.width = Math.min(
        this.image.nativeElement.width,
        600
      );
      this.image.nativeElement.height = Math.min(
        this.image.nativeElement.height,
        600 / ratio
      );
    };
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  public async delete(id: any) {
    await this.hotspotsService.delete(id);
    this.hotspots = await this.hotspotsService.getAll();
  }

  public getType(hotspot: any): string {
    if (hotspot.icon)
    {
      switch (hotspot.icon.glyph) {
        case "Pointer":
          return "pointer";
        case "Spot":
          return "spot";
        case "Arrow":
          return "arrow";
        default:
          return "icon";
      }
    }
    else if (hotspot.frame) return 'frame';
  }

  public onDragStart(event: DragEvent): void {
    event.dataTransfer.effectAllowed = 'move';
    const hotspotElement = event.target as HTMLElement;
    const hotspotBounds = hotspotElement.getBoundingClientRect();
    this.assetXoffset = event.clientX - hotspotBounds.x;
    this.assetYoffset = event.clientY - hotspotBounds.y;
    event.dataTransfer.setData('text/plain', 'dragHotspot');
  }

  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }

  public async onDragEnd(event: DragEvent, hotspotId: number): Promise<void> {
    const hotspotElement = event.target as HTMLElement;
    const img = hotspotElement.parentElement as HTMLImageElement;
    const imgBounds = img.getBoundingClientRect();

    let newX = event.clientX - imgBounds.left - this.assetXoffset;
    newX = Math.max(0, Math.min(10000, newX / this.assetScaleX));
    let newY = event.clientY - imgBounds.top - this.assetYoffset;
    newY = Math.max(0, Math.min(10000, newY / this.assetScaleY));

    const newHotspot = await this.hotspotsService.editPosition(hotspotId, {
      x: newX,
      y: newY,
    });

    const oldHotspot = this.hotspots.find((h) => h.id === hotspotId);
    oldHotspot.position.x = newHotspot.position.x;
    oldHotspot.position.y = newHotspot.position.y;
  }
}
