import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '@backend/interfaces';
import { LocalizationService } from './shared/localization/localization.service';

@Pipe({
  name: 'getLocaleText',
})
export class GetLocaleTextPipe implements PipeTransform {
  public constructor(public languageService: LocalizationService) {}

  public transform(
    values: any[],
    langKey: string,
    sendDefault: boolean = false
  ) {
    this.languageService.globalLanguage$.subscribe(async (data) => {
      if (data && data.key) {
        langKey = data.key;
      }
    });
    if (values && Array.isArray(values) && values.length > 0) {
      const entry = values.find((x) => x.languageKey === langKey);
      if (entry) {
        return entry.value;
      } else {
        if (sendDefault) {
          if (
            values.find(
              (x) => x.languageKey === Constants.DEFAULT_GLOBAL_LANGUAGE.key
            )
          ) {
            return values.find(
              (x) => x.languageKey === Constants.DEFAULT_GLOBAL_LANGUAGE.key
            ).value;
          }
        }
      }
    }
    return '';
  }
}
