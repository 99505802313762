import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LicensePackageService } from '../license-package/license-package.service';
import { Observable } from 'rxjs';
import { Package } from '@backend/webapp/license-package/models/package';
import { GetLocaleTextPipe } from '../get-locale-text.pipe';
import { UpdateUserPackage } from '../license-package/models/updateUserPackage';
import { LocalizationService } from '../shared/localization/localization.service';
import * as moment from 'moment';
import {
  Constants
} from '@backend/interfaces';

@Component({
  selector: 'backend-license-package',
  templateUrl: './license-package.component.html',
  styleUrls: ['./license-package.component.css'],
})
export class LicensePackageComponent implements OnInit {
  @Input() user;
  @Output() packageUpdated = new EventEmitter<boolean>();

  public licensePackages$: Observable<Package[]>;
  public licensePackages: Package[];
  public durationValues = [30, 60, 90, 120, 365];
  public dateFormat = 'YYYY/MM/DD';
  public licenseDuration: number[] = [];
  public licenseStart: string[] = [];
  public maxDownload: number[] = [];
  public isEditable: boolean[] = [];
  public isPackageSelected: boolean[] = [];

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  public localeTextPipe = new GetLocaleTextPipe(this.localization);

  constructor(public localization: LocalizationService, private licensePackageService: LicensePackageService) {}
  
  async ngOnInit(): Promise<void> {
    await this.licensePackageService.getPackages();
    this.licensePackages$ = this.licensePackageService.licensePackages$;
    this.licensePackages = this.licensePackageService.licensePackages;
  }

  public getTranslatedName(entityNames: any[]) {
    let result = this.localeTextPipe.transform(entityNames, this.globalLanguage?.key, true);
    return result;
  }

  public userHasLicensePackage(licensePackageId: number): boolean {
    const hasPackage = this.licensePackageService.assignedLicensePackages.find(
      (x) => x.id === licensePackageId
    );
    return hasPackage !== undefined;
  }
  public async modifyPackage(packageIndex) {
    if (this.isPackageSelected[packageIndex]) {
      const updatePackageForUser: UpdateUserPackage = {
        userId: this.user,
        packageId: this.licensePackages[packageIndex].id,
        maxDownload: Number(this.maxDownload[packageIndex]),
        licenseStart: this.licenseStart[packageIndex],
        licenseDuration: Number(this.licenseDuration[packageIndex]),
      };
      await this.licensePackageService.updatePackageForUser(
        updatePackageForUser
      );
      this.isPackageSelected[packageIndex] = true;
    } else {
      await this.licensePackageService.removePackageFromUser(
        this.user,
        this.licensePackages[packageIndex].id
      );
      this.isPackageSelected[packageIndex] = false;
    }
    this.packageUpdated.emit(this.user);
  }

  public changeDuration(duration: number, packageIndex) {
    this.licenseDuration[packageIndex] = duration;
    const dateEle = document.getElementById('date' + packageIndex);
    if (dateEle) {
      dateEle.setAttribute(
        'value',
        this.getToDate(
          this.licenseStart[packageIndex],
          this.licenseDuration[packageIndex]
        )
      );
    }
  }

  getToDate(start, span: number) {
    const futureDate = new Date(start);
    futureDate.setDate(futureDate.getDate() + Number(span));
    return moment(futureDate).format(this.dateFormat);
  }

  public changeDate(date, i) {
    this.licenseStart[i] = date;
    const dateEle = document.getElementById('date' + i);
    if (dateEle) {
      dateEle.setAttribute(
        'value',
        this.getToDate(this.licenseStart[i], this.licenseDuration[i])
      );
    }
  }
  getFromDate(start) {
    return moment(start).format(this.dateFormat);
  }

  getMaxDownload(value: number, packageIndex) {
    this.maxDownload[packageIndex] = value;
  }

  setLicenseDuration(packageId: number) {
    const packageInfo = this.licensePackageService.assignedLicensePackages.find(
      (licensePackage) => licensePackage.id === packageId
    );
    if (packageInfo === undefined) return null;
    return packageInfo.licenseDuration;
  }

  setMaxDownload(packageId: number) {
    const packageInfo = this.licensePackageService.assignedLicensePackages.find(
      (licensePackage) => licensePackage.id === packageId
    );
    if (packageInfo === undefined) return null;
    return packageInfo.maxDownload;
  }
  maxDownloadNumber(index: number) {
    let numberOfModules: number = 0;
    numberOfModules += this.licensePackages[index].devices.length;
    numberOfModules += this.licensePackages[index].workflows.length;
    return numberOfModules;
  }
}
