import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'img-convertor',
  templateUrl: './img-convertor.component.html',
  styleUrls: ['./img-convertor.component.scss'],
})
export class ImgConvertorComponent implements OnInit {
  constructor() {}
  private canvas: HTMLCanvasElement;
  private fileBasename: string;
  ngOnInit() {}

  public drawImageOnCanvas(
    image: HTMLImageElement,
    fileBasename: string
  ): void {
    this.canvas = document.createElement('canvas');
    let context = this.canvas.getContext('2d');
    this.canvas.width = image.width;
    this.canvas.height = image.height;
    this.fileBasename = fileBasename;
    context.drawImage(image, 0, 0, this.canvas.width, this.canvas.height);
  }

  public getImageFile(): File {
    let imageUrl = this.canvas.toDataURL('image/jpeg', 1);
    let imageFile = this.createFileFromURL(imageUrl);
    return imageFile;
  }

  public canvasToFile(
    inputCanvas: HTMLCanvasElement,
    fileBasename: string
  ): File {
    this.fileBasename = fileBasename;
    let dataUrl = inputCanvas.toDataURL('image/jpeg', 1);
    let dataFile = this.createFileFromURL(dataUrl);
    return dataFile;
  }

  private createFileFromURL(imageUrl: string): File {
    let base64Array = imageUrl.split(','),
      mime = base64Array[0].match(/:(.*?);/)[1],
      binaryStr = atob(base64Array[1]),
      n = binaryStr.length,
      uint8arr = new Uint8Array(n);

    while (n--) {
      uint8arr[n] = binaryStr.charCodeAt(n);
    }
    return new File([uint8arr], this.fileBasename.split('.')[0] + '.jpeg', {
      type: 'image/jpeg',
    });
  }
}
