import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '../auth/auth.interceptor';
import { SharedModule } from '../shared/shared.module';
import { SiteplannerComponent } from './siteplanner.component';
import { RouterModule } from '@angular/router';
import { SitesService } from './sites.service';
import { ValTokenService } from './valToken.service';
import { ValImagePipe } from './val-image.pipe';

@NgModule({
  declarations: [SiteplannerComponent, ValImagePipe],
  imports: [CommonModule, SharedModule, TranslateModule, RouterModule],
  providers: [
    SitesService,
    ValTokenService,
    ValImagePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SiteplannerModule {}
