import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ParamsService } from '@backend/webapp/services/params.service';
import { Overview } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/overview';
import { LanguageService } from '@backend/webapp/services/language.service';
import { ConfirmationService } from '@backend/webapp/shared/confirmation/confirmation.service';
import { lastValueFrom } from 'rxjs';

const apiRoute = (workflowId: number, versionId: number): string =>
  `/api/workflowguides/workflows/${workflowId}/workflowversions/${versionId}/overviews/`;

@Injectable({
  providedIn: 'root',
})
export class OverviewsService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly languageService: LanguageService,
    private readonly paramsService: ParamsService,
    private confirmationService: ConfirmationService
  ) {}

  async getAllOverviews(): Promise<Overview[]> {
    if (
      !this.paramsService.workflowId ||
      !this.paramsService.workflowVersionId
    ) {
      return null;
    }
    return await lastValueFrom(
      this.httpClient.get<Overview[]>(
        apiRoute(
          this.paramsService.workflowId,
          this.paramsService.workflowVersionId
        )
      )
    );
  }

  async getOverview(id: number) {
    if (
      !this.paramsService.workflowId ||
      !this.paramsService.workflowVersionId ||
      !id
    ) {
      return null;
    }
    return await lastValueFrom(
      this.httpClient.get<Overview>(
        apiRoute(
          this.paramsService.workflowId,
          this.paramsService.workflowVersionId
        ) + id
      )
    );
  }

  async create(key: string): Promise<Overview> {
    return await lastValueFrom(
      this.httpClient.post<Overview>(
        apiRoute(
          this.paramsService.workflowId,
          this.paramsService.workflowVersionId
        ),
        {
          key: key,
        }
      )
    );
  }

  async editTile(id: number, title: string): Promise<Overview> {
    return await lastValueFrom(
      this.httpClient.put<Overview>(
        apiRoute(
          this.paramsService.workflowId,
          this.paramsService.workflowVersionId
        ) +
          id +
          '/title',
        {
          content: title,
          langKey: this.languageService.guiLanguageCode,
        }
      )
    );
  }

  async edit(id: number, key: string): Promise<Overview> {
    return await lastValueFrom(
      this.httpClient.put<Overview>(
        apiRoute(
          this.paramsService.workflowId,
          this.paramsService.workflowVersionId
        ) + id,
        {
          key: key,
        }
      )
    );
  }

  async delete(id: number, title): Promise<void> {
    const overview = this.languageService.getTranslateValue(title);
    if (
      await this.confirmationService.confirm(
        'workflowguides.workflows.workflowVersions.overviews.delete',
        'workflowguides.workflows.workflowVersions.overviews.deleteConfirmation',
        { overview }
      )
    ) {
      this.paramsService.isLoading = true;
      try {
        return await lastValueFrom(
          this.httpClient.delete<void>(
            apiRoute(
              this.paramsService.workflowId,
              this.paramsService.workflowVersionId
            ) + id
          )
        );
      } catch (e) {
        console.log(e);
        return null;
      } finally {
        this.paramsService.isLoading = false;
      }
    }
  }
}
