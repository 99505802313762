import {
  Component,
  OnInit,
  ViewChild,
  ViewChildren,
  QueryList,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { PageService } from './page.service';
import { AddPageComponent } from './add-page/add-page.component';
import { LanguageService } from '../services/language.service';
import { PopoverService } from '../services/popover.service';
import { LocalizationService } from '../shared/localization/localization.service';
import { Constants } from '@backend/interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'backend-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent implements OnInit, OnDestroy {
  @ViewChild('addPage')
  public addModal: AddPageComponent;

  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  public $pages: Observable<any[]>;
  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;

  languageSubscription: Subscription;

  public constructor(
    public readonly languageService: LanguageService,
    public readonly popoverService: PopoverService,
    private readonly pageService: PageService,
    public readonly localization: LocalizationService,
    public router: Router
  ) {}

  public ngOnInit(): void {
    this.$pages = this.pageService.$pages;
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          await this.pageService.getAllPages();
          this.$pages = this.pageService.$pages;
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  public edit(page): void {
    this.addModal.pageId = page.id;
    this.addModal.nameInternal = page.name;
    this.addModal.name = this.languageService.getTranslateValue(page.title);
    this.addModal.isOpen = true;
  }

  public delete(pageId: number): void {
    this.pageService.deletePage(pageId);
  }

  public addNewPage(): void {
    this.addModal.pageId = undefined;
    this.addModal.nameInternal = '';
    this.addModal.name = '';
    this.addModal.isOpen = true;
  }
}
