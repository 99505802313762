<sh-card>
  <sh-text size="header-1" [innerText]="'feedback.title' | translate"></sh-text>
  <sh-table>
    <sh-table-row slot="header">
      <sh-table-head
        unsortable
        columns="12"
        [innerText]="'feedback.notes' | translate"
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="4"
        [innerText]="'feedback.rating' | translate"
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="4"
        [innerText]="'feedback.deviceVersion' | translate"
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="4"
        [innerText]="'feedback.sceneName' | translate"
      ></sh-table-head>
    </sh-table-row>
    <ng-container *ngIf="feedbackService.feedbacks">
      <sh-table-row *ngFor="let feedback of feedbackService.feedbacks">
        <sh-table-cell
          columns="12"
          [innerText]="feedback.notes"
        ></sh-table-cell>
        <sh-table-cell
          columns="4"
          [innerText]="feedback.rating"
        ></sh-table-cell>
        <sh-table-cell
          columns="4"
          [innerText]="feedback.deviceVersion.name"
        ></sh-table-cell>
        <sh-table-cell columns="4" [innerText]="feedback.scene"></sh-table-cell>
      </sh-table-row>
    </ng-container>
  </sh-table>
</sh-card>
