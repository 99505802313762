import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AxesService } from '../axes.service';
import { AxisType } from '../axis-type.enum';
import { ParamsService } from '../../../../../../services/params.service';

@Component({
  selector: 'backend-edit-axis',
  templateUrl: './edit-axis.component.html',
  styleUrls: ['./edit-axis.component.scss'],
})
export class EditAxisComponent implements OnInit {
  public axisTypeEnum = AxisType;

  public isEditMode = false;

  private _type: AxisType = null;
  public set type(value: AxisType) {
    if (AxisType[value]) {
      value = AxisType[value];
    }

    this._type = value;
  }
  public get type(): AxisType {
    return this._type;
  }

  public name = '';
  public key = '';
  public values = {
    rotation: {
      start: 0,
      type: 0,
      maxSpeed: 0,
      acceleration: 0,
      deceleration: 0,
      range: 0,
      offset: 0,
      axisX: 0,
      axisY: 0,
      axisZ: 0,
    },
    translation: {
      start: 0,
      type: 0,
      maxSpeed: 0,
      acceleration: 0,
      deceleration: 0,
      startX: 0,
      startY: 0,
      startZ: 0,
      axisX: 0,
      axisY: 0,
      axisZ: 0,
      distance: 0,
    },
    virtual: {
      start: 0,
      type: 0,
      maxSpeed: 0,
      acceleration: 0,
      deceleration: 0,
    },
  };

  public constructor(
    private router: Router,
    private axesService: AxesService,
    private paramsService: ParamsService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.axesService.updateAxis();
    this.updateFields();
  }

  private async updateFields(): Promise<void> {
    if (this.paramsService.axisId && this.paramsService.axisType) {
      const type = this.paramsService.axisType as AxisType;
      const axis = await this.axesService.getAxis(
        type,
        this.paramsService.axisId
      );
      this.name = axis.name;
      this.key = axis.key;
      this.type = type;

      if (this.paramsService.axisType === AxisType.Rotation) {
        this.values.rotation.start = axis.startValue;
        this.values.rotation.type = axis.type;
        this.values.rotation.maxSpeed = axis.maxSpeed;
        this.values.rotation.acceleration = axis.acceleration;
        this.values.rotation.deceleration = axis.deceleration;
        this.values.rotation.range = axis.range;
        this.values.rotation.offset = axis.offset;
        this.values.rotation.axisX = axis.axisX;
        this.values.rotation.axisY = axis.axisY;
        this.values.rotation.axisZ = axis.axisZ;
      } else if (this.paramsService.axisType === AxisType.Translation) {
        this.values.translation.start = axis.startValue;
        this.values.translation.type = axis.type;
        this.values.translation.maxSpeed = axis.maxSpeed;
        this.values.translation.acceleration = axis.acceleration;
        this.values.translation.deceleration = axis.deceleration;
        this.values.translation.startX = axis.offsetX;
        this.values.translation.startY = axis.offsetY;
        this.values.translation.startZ = axis.offsetZ;
        this.values.translation.axisX = axis.axisX;
        this.values.translation.axisY = axis.axisY;
        this.values.translation.axisZ = axis.axisZ;
        this.values.translation.distance = axis.range;
      } else if (this.paramsService.axisType === AxisType.Virtual) {
        this.values.virtual.start = axis.startValue;
        this.values.virtual.type = axis.type;
        this.values.virtual.maxSpeed = axis.maxSpeed;
        this.values.virtual.acceleration = axis.acceleration;
        this.values.virtual.deceleration = axis.deceleration;
      }

      this.isEditMode = true;
    } else {
      this.resetFields();
      this.isEditMode = false;
    }
  }

  private resetFields(): void {
    this.name = '';
    this.type = null;
    this.values.rotation.start = 0;
    this.values.rotation.type = 0;
    this.values.rotation.maxSpeed = 0;
    this.values.rotation.acceleration = 0;
    this.values.rotation.deceleration = 0;
    this.values.rotation.range = 0;
    this.values.rotation.offset = 0;
    this.values.rotation.axisX = 0;
    this.values.rotation.axisY = 0;
    this.values.rotation.axisZ = 0;
    this.values.translation.start = 0;
    this.values.translation.type = 0;
    this.values.translation.maxSpeed = 0;
    this.values.translation.acceleration = 0;
    this.values.translation.deceleration = 0;
    this.values.translation.startX = 0;
    this.values.translation.startY = 0;
    this.values.translation.startZ = 0;
    this.values.translation.axisX = 0;
    this.values.translation.axisY = 0;
    this.values.translation.axisZ = 0;
    this.values.translation.distance = 0;
    this.values.virtual.start = 0;
    this.values.virtual.type = 0;
    this.values.virtual.maxSpeed = 0;
    this.values.virtual.acceleration = 0;
    this.values.virtual.deceleration = 0;
  }

  public async save() {
    if (this.type === AxisType.Rotation) {
      if (!this.isEditMode) {
        await this.axesService.createRotationAxis(
          this.paramsService.axisCategoryId,
          this.name,
          this.key,
          this.values.rotation.start,
          this.values.rotation.type,
          this.values.rotation.maxSpeed,
          this.values.rotation.acceleration,
          this.values.rotation.deceleration,
          this.values.rotation.range,
          this.values.rotation.offset,
          this.values.rotation.axisX,
          this.values.rotation.axisY,
          this.values.rotation.axisZ
        );
      } else {
        await this.axesService.modifyRotationAxis(
          this.paramsService.axisCategoryId,
          this.paramsService.axisId,
          this.name,
          this.key,
          this.values.rotation.start,
          this.values.rotation.type,
          this.values.rotation.maxSpeed,
          this.values.rotation.acceleration,
          this.values.rotation.deceleration,
          this.values.rotation.range,
          this.values.rotation.offset,
          this.values.rotation.axisX,
          this.values.rotation.axisY,
          this.values.rotation.axisZ
        );
      }
    } else if (this.type === AxisType.Translation) {
      if (!this.isEditMode) {
        await this.axesService.createTranslationAxis(
          this.paramsService.axisCategoryId,
          this.name,
          this.key,
          this.values.translation.start,
          this.values.translation.type,
          this.values.translation.maxSpeed,
          this.values.translation.acceleration,
          this.values.translation.deceleration,
          this.values.translation.startX,
          this.values.translation.startY,
          this.values.translation.startZ,
          this.values.translation.axisX,
          this.values.translation.axisY,
          this.values.translation.axisZ,
          this.values.translation.distance
        );
      } else {
        await this.axesService.modifyTranslationAxis(
          this.paramsService.axisCategoryId,
          this.paramsService.axisId,
          this.name,
          this.key,
          this.values.translation.start,
          this.values.translation.type,
          this.values.translation.maxSpeed,
          this.values.translation.acceleration,
          this.values.translation.deceleration,
          this.values.translation.startX,
          this.values.translation.startY,
          this.values.translation.startZ,
          this.values.translation.axisX,
          this.values.translation.axisY,
          this.values.translation.axisZ,
          this.values.translation.distance
        );
      }
    } else if (this.type === AxisType.Virtual) {
      if (!this.isEditMode) {
        await this.axesService.createVirtualAxis(
          this.paramsService.axisCategoryId,
          this.name,
          this.key,
          this.values.virtual.start,
          this.values.virtual.type,
          this.values.virtual.maxSpeed,
          this.values.virtual.acceleration,
          this.values.virtual.deceleration
        );
      } else {
        await this.axesService.modifyVirtualAxis(
          this.paramsService.axisCategoryId,
          this.paramsService.axisId,
          this.name,
          this.key,
          this.values.virtual.start,
          this.values.virtual.type,
          this.values.virtual.maxSpeed,
          this.values.virtual.acceleration,
          this.values.virtual.deceleration
        );
      }
    }
    this.gotoAxes();
  }

  public async cancel() {
    this.gotoAxes();
  }

  public gotoAxes(): void {
    this.router.navigate([
      'devices',
      this.paramsService.deviceId,
      'versions',
      this.paramsService.deviceVersionId,
      'axes',
    ]);
  }
}
