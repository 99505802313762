import { Injectable, QueryList, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class PopoverService {
  public constructor(private router: Router) {}

  public openPopover(
    popovers: QueryList<ElementRef<HTMLElement>>,
    id: string,
    event: MouseEvent
  ): void {
    event.preventDefault();
    event.stopPropagation();

    if (!popovers) {
      return;
    }

    popovers.forEach((p) => {
      p.nativeElement.removeAttribute('visible');
    });
    const popover = this.findPopover(popovers, id);
    if (popover) {
      let parent = popover.parentElement.parentElement;
      while (parent) {
        if (parent.tagName.toLowerCase() === 'sh-table-row') {
          parent.setAttribute('expanded', 'true');
        } else {
          break;
        }

        parent = parent.parentElement;
      }

      popover.setAttribute('visible', 'true');
      popover.style.position = 'fixed';
      popover.style.left = event.clientX + 'px';
      popover.style.top = event.clientY + 'px';
    }
  }

  public preventDefault(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
  }

  public goToRoute(
    popovers: QueryList<ElementRef<HTMLElement>>,
    route: string[],
    isLink = false
  ): void {
    const popoverVisible = popovers.find(
      (p) => (p.nativeElement as any).visible
    );
    if (!popoverVisible && !isLink) {
      this.router.navigate([this.router.url, ...route]);
    } else if (!popoverVisible && isLink) {
      const newRoute = '/workflowguides/' + route.join('/');
      if (this.router.url !== newRoute) {
        this.router.navigate(['workflowguides', ...route]);
      }
    }
  }

  private findPopover(
    popovers: QueryList<ElementRef<HTMLElement>>,
    id: string
  ): HTMLElement {
    const popoversArray = popovers.toArray();
    for (const popover of popoversArray) {
      const attrs = popover.nativeElement.attributes;
      if (attrs) {
        const targetAttr = attrs.getNamedItem('target');
        if (targetAttr && targetAttr.value === id) {
          return popover.nativeElement;
        }
      }
    }

    return null;
  }
}
