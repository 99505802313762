import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { HotspotsService } from './hotspots.service';
import { SpinnerService } from '../../../../../spinner/spinner.service';
import { NotificationService } from '../../../../../shared/notification/notification.service';
import { ParamsService } from '../../../../../services/params.service';
import { areObjectsIdentical } from '../../../../../tools';
import { ConfirmationService } from '@backend/webapp/shared/confirmation/confirmation.service';
import { ImageOptimizationService } from '@backend/webapp/services/imageoptimization.service';

@Injectable({
  providedIn: 'root',
})
export class ScreensService {
  private screensSubject$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  public lastModifiedScreenId = null;
  public get screens$(): Observable<any[]> {
    return this.screensSubject$.asObservable();
  }
  public get screens(): any[] {
    return this.screensSubject$.value;
  }

  private screenSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public get screen$(): Observable<any> {
    return this.screenSubject$.asObservable();
  }
  public get screen(): any {
    return this.screenSubject$.value;
  }

  public constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
    private hotspotsService: HotspotsService,
    private paramsService: ParamsService,
    private confirmationService: ConfirmationService,
    private imageOptimizationService: ImageOptimizationService
  ) {}

  public async updateScreens(): Promise<boolean> {
    await this.hotspotsService.updateHotspot();

    const hotspot = this.hotspotsService.hotspot;
    if (hotspot && hotspot.screens) {
      if (!areObjectsIdentical(this.screens, hotspot.screens)) {
        this.screensSubject$.next(hotspot.screens);
      }
      return true;
    }

    this.screensSubject$.next(null);
    return false;
  }

  public async updateScreen(): Promise<boolean> {
    this.paramsService.updateParams();
    const screenId = this.paramsService.screenId;
    if (screenId !== null) {
      const screen = await this.getScreen(screenId);
      if (screen && this.screen !== screen) {
        this.screenSubject$.next(screen);
        this.lastModifiedScreenId = this.screen.id;
        return true;
      }
    }
    this.screenSubject$.next(null);
    return false;
  }

  public async getScreen(
    screenId: number = this.paramsService.screenId
  ): Promise<any> {
    if (!this.screens) {
      const updateSuccess = await this.updateScreens();
      if (!updateSuccess) {
        return null;
      }
    }
    return this.screens.find((a) => a.id === screenId);
  }

  public async createScreen(screenKey: string, image: File): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      const formData = new FormData();
      formData.append('key', screenKey);
      if (image) {
        image = await this.imageOptimizationService.resizeImage(image, 1280, 720);
        formData.append('image', image, image.name);
      }
      await lastValueFrom(
        this.http.post(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens`,
          formData
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.hotspots.screens.createSuccess',
        { screenName: screenKey }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.hotspots.screens.createError',
        { screenName: screenKey },
        error.error.message
      );
      return false;
    } finally {
      await this.updateScreens();
      this.spinnerService.hideSpinner();
    }
  }

  public async modifyScreen(
    screenId: number,
    screenKey: string,
    image?: File
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      const formData = new FormData();
      formData.append('key', screenKey);
      if (image) {
        image = await this.imageOptimizationService.resizeImage(image, 1280, 720);
        formData.append('image', image, image.name);
      }

      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${this.paramsService.hotspotId}/screens/${screenId}`,
          formData
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.hotspots.screens.modifySuccess',
        { screenName: screenKey }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.hotspots.screens.modifyError',
        { screenName: screenKey },
        error.error.message
      );
      return false;
    } finally {
      this.lastModifiedScreenId = screenId;
      await this.updateScreens();
      this.spinnerService.hideSpinner();
    }
  }

  public async deleteScreen(
    hotspotId: number,
    screenId: number,
    key
  ): Promise<boolean> {
    if (
      await this.confirmationService.confirmDelete(
        'devices.versions.hotspots.screens.deleteScreen',
        'devices.versions.hotspots.screens.deleteScreenConfirmation',
        { key }
      )
    ) {
      this.spinnerService.showSpinner();
      try {
        await lastValueFrom(
          this.http.delete<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${hotspotId}/screens/${screenId}`
          )
        );
        this.notificationService.confirmation(
          'general.success',
          'devices.versions.hotspots.screens.deleteSuccess'
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.hotspots.screens.deleteError',
          null,
          error.error.message
        );
        return false;
      } finally {
        await this.updateScreens();
        this.spinnerService.hideSpinner();
      }
    }
  }
}
