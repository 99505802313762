import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  OnInit,
  OnDestroy,
  ViewContainerRef,
  ComponentFactoryResolver,
} from '@angular/core';
import { DevicesService } from '../devices.service';
import { Router } from '@angular/router';
import { LanguageService } from '../../services/language.service';
import { ParamsService } from '../../services/params.service';
import { DeviceAndWorkflowStatus } from '@backend/interfaces';
import { ImgConvertorComponent } from '@backend/webapp/img-convertor/img-convertor.component';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { Constants } from '@backend/interfaces';
import { Subscription } from 'rxjs';
import { BusinessLineUtility } from '@backend/webapp/shared/businessLine/business-line.utility';
import { MessageModalComponent } from '../../shared/lazy-components/message-modal/message-modal.component';
import { LazyComponentHelper } from '@backend/webapp/shared/lazy-components/lazy-component-helper';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';
import { Device } from '@backend/api/Device/device.model';
import { DeviceVersionsService } from '../device-versions/device-versions.service';
import { NotificationService } from '@backend/webapp/shared/notification/notification.service';
import ShortUniqueId from 'short-unique-id';

const uidGen = new ShortUniqueId();

@Component({
  selector: 'backend-edit-device',
  templateUrl: './edit-device.component.html',
  styleUrls: ['./edit-device.component.scss'],
})
export class EditDeviceComponent implements OnInit, OnDestroy {
  public isEditMode = false;

  @ViewChild('inputFile')
  public inputFile: ElementRef<HTMLInputElement>;

  @ViewChild('previewImage')
  public previewImage: ElementRef<HTMLImageElement>;

  public file: string;
  public fileBasename: string;
  public fileChanged = false;

  public nameInternal: string;
  public name: string;
  public description: string;
  public status = '';
  private imgConvertor: ImgConvertorComponent;
  public statusValues = this.devicesService.getStatusEnumKeys();
  public businessLine: string = '';
  public businessLines: string[];
  public addressableGroupName: string = '';
  public addressableGroupNames: string[] = ['none'];
  private readonly closeAddDeviceModalComponent: LazyComponentHelper<MessageModalComponent>;
  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  nameTrans: any[];
  descriptionTrans: any[];
  copyExistingDevice = false;
  copyedDevice: Device;
  languageSubscription: Subscription;
  isDeviceNameValid = true;

  public constructor(
    private router: Router,
    public devicesService: DevicesService,
    private renderer: Renderer2,
    private paramsService: ParamsService,
    public languageService: LanguageService,
    public localization: LocalizationService,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private spinnerService: SpinnerService,
    public deviceVersionService: DeviceVersionsService,
    public notificationService: NotificationService
  ) {
    this.imgConvertor = new ImgConvertorComponent();
    this.businessLines = BusinessLineUtility.getBusinessLinesNames();
    this.closeAddDeviceModalComponent = new LazyComponentHelper(
      'MessageModalComponent',
      this.viewContainerRef,
      this.componentFactoryResolver,
      this.spinnerService,
      () =>
        import(
          '../../shared/lazy-components/message-modal/message-modal.component'
        )
    );
  }

  public async ngOnInit(): Promise<void> {
    this.addressableGroupNames = this.addressableGroupNames.concat(
      await this.devicesService.getAddressableGroupNames()
    );
    await this.devicesService.updateDevice();
    this.resetFields();
    this.updateFields();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          this.resetFields();
          this.updateFields();
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  public fileChange(file: File): void {
    if (file) {
      this.file = file.name;
      this.fileBasename = file.name.replace(/.*[\/\\]/, '');
      this.fileChanged = true;
      const reader = new FileReader();
      reader.onloadend = (e) => {
        let image = new Image();
        this.renderer.setAttribute(
          this.previewImage.nativeElement,
          'src',
          e.target.result.toString()
        );
        image.src = e.target.result.toString();
        image.onload = () => {
          this.imgConvertor.drawImageOnCanvas(image, this.fileBasename);
        };
      };
      reader.readAsDataURL(file);
    } else {
      this.file = '';
      this.fileBasename = '';
    }
  }

  private async updateFields(): Promise<void> {
    const device = await this.devicesService.getDevice();
    if (device) {
      this.updateDeviceField(device);
      if (this.file) {
        this.fileBasename = this.file.replace(/.*[\/\\]/, '');
      } else {
        this.fileBasename = '';
      }
      this.nameInternal = device.name;
      this.status = this.statusValues[device.status];
      this.isEditMode = true;
    } else {
      this.resetFields();
      this.isEditMode = false;
    }
  }

  public copyDevice(device: Device): void {
    this.copyedDevice = device;
    this.nameInternal = uidGen.randomUUID(8);
    this.updateDeviceField(device);
    this.fileBasename = this.file.replace(/.*[\/\\]/, '');
    this.status = this.statusValues[device.status];
  }

  public async checkForValidDeviceName(deviceName: string): Promise<void> {
    this.name = deviceName?.trim();
    if (this.isEditMode) {
      const editDevice = await this.devicesService.getDevice(
        this.paramsService.deviceId
      );
      if (this.name.toLowerCase() === editDevice.name.trim().toLowerCase()) {
        this.isDeviceNameValid = true;
        return;
      }
    }

    this.isDeviceNameValid = this.devicesService.devices?.find(
      (device) => device.name.trim()?.toLowerCase() === this.name?.toLowerCase()
    )
      ? false
      : true;
  }

  public updateDeviceField(device: Device): void {
    this.name = this.languageService.getTranslationByKey(
      device.deviceDescription.name,
      this.globalLanguage.key
    );
    this.nameTrans = new GetLocaleTextPipe(this.localization).transform(
      device.deviceDescription.name,
      this.globalLanguage.key
    );
    this.description = this.languageService.getTranslationByKey(
      device.deviceDescription.description,
      this.globalLanguage.key
    );
    this.descriptionTrans = new GetLocaleTextPipe(this.localization).transform(
      device.deviceDescription.description,
      this.globalLanguage.key
    );
    this.businessLine = BusinessLineUtility.getBusinessLineName(
      device.businessLine
    );
    this.addressableGroupName = device.addressableGroupName;
    this.file = device.deviceDescription.thumbnail;
  }

  private resetFields(): void {
    this.nameInternal = '';
    this.name = '';
    this.description = '';
    this.file = '';
    this.fileBasename = '';
    this.fileChanged = false;
    this.status = null;
  }

  public selectFile() {
    this.inputFile.nativeElement.click();
  }

  public async save() {
    this.status = 'Draft';
    let file = null;
    let thumbnail = null;
    let newDevice: Device;

    if (this.fileChanged) {
      file = this.fileChanged ? this.imgConvertor.getImageFile() : null;
      thumbnail = null;
    } else {
      thumbnail = this.file;
      file = null;
    }

    try {
      if (this.copyExistingDevice) {
        newDevice = await this.devicesService.createDevice(
          uidGen.randomUUID(8),
          this.name,
          this.description,
          DeviceAndWorkflowStatus[this.status.toUpperCase()],
          BusinessLineUtility.getBusinessLine(this.businessLine),
          this.addressableGroupName,
          thumbnail,
          file
        );
        await this.deviceVersionService.createDeviceVersion(
          0,
          false,
          this.copyedDevice.deviceReleasedVersion
            ? this.copyedDevice.deviceReleasedVersion?.id.toString()
            : this.copyedDevice.deviceDraftVersion?.id.toString(),
          newDevice.id
        );
      } else {
        const file = this.fileChanged ? this.imgConvertor.getImageFile() : null;
        if (!this.isEditMode) {
          newDevice = await this.devicesService.createDevice(
            uidGen.randomUUID(8),
            this.name,
            this.description,
            DeviceAndWorkflowStatus[this.status.toUpperCase()],
            BusinessLineUtility.getBusinessLine(this.businessLine),
            this.addressableGroupName,
            thumbnail,
            file
          );
          await this.deviceVersionService.createDeviceVersion(
            0,
            false,
            null,
            newDevice.id
          );
        } else {
          await this.devicesService.modifyDevice(
            this.paramsService.deviceId,
            this.nameInternal,
            this.name,
            this.description,
            DeviceAndWorkflowStatus[this.status.toUpperCase()],
            BusinessLineUtility.getBusinessLine(this.businessLine),
            this.addressableGroupName,
            file
          );
        }
      }
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'An error occured while creating the equipment',
        null,
        error.error?.message
      );
    } finally {
      this.gotoDevices();
    }
  }

  public async cancel() {
    await this.closeAddDeviceModalComponent.show();
    const ref = this.closeAddDeviceModalComponent.componentRef;
    const confirmationMessage1 =
      'Closing will automatically save modification and return to the previous screen.';
    const confirmationMessage2 = 'Are you sure you want to close ?';

    ref.instance.label = 'Warning';
    ref.instance.firstMessages = confirmationMessage1;
    ref.instance.secondMessage = confirmationMessage2;
    ref.instance.sticky = false;
    ref.instance.cancelLabel = 'No';
    ref.instance.confirmLabel = 'Yes';

    ref.instance.closed$.subscribe(() => {
      this.closeAddDeviceModalComponent.hide();
    });

    ref.instance.confirmed$.subscribe(async () => {
      this.closeAddDeviceModalComponent.hide();
      this.gotoDevices();
    });
  }

  public gotoDevices(): void {
    this.router.navigate(['devices']);
  }

  preventDefault(event: Event): void {
    // Prevent default behavior (Prevent file from being opened)
    if (event) {
      event.preventDefault();
    }
  }

  drop(event: DragEvent): void {
    this.preventDefault(event);
    this.fileChange(event.dataTransfer.files[0]);
    this.removeDragData(event);
  }

  // Clean up. Remove drag data
  private removeDragData(event: DragEvent): void {
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to remove the drag data
      event.dataTransfer.items.clear();
    } else {
      // Use DataTransfer interface to remove the drag data
      event.dataTransfer.clearData();
    }
  }
}
