<sh-modal
  id="footer-modal"
  sticky
  modal-height="fit-content"
  [visible]="visible"
  [label]="
    (isEditMode
      ? 'devices.versions.axes.categories.editCategory'
      : 'devices.versions.axes.categories.addCategory'
    ) | translate
  "
>
  <sh-text
    [innerText]="'devices.versions.axes.categories.enterNewData' | translate"
  ></sh-text>

  <sh-input-text
    #nameTextbox
    mandatory
    [label]="'devices.versions.axes.categories.name' | translate"
    [value]="name"
    (value-changed)="name = $event.target.value"
  ></sh-input-text>

  <sh-input-text
    #keyTextbox
    mandatory
    [label]="'devices.versions.axes.key' | translate"
    [value]="key"
    (value-changed)="key = $event.target.value"
  ></sh-input-text>

  <sh-button
    slot="footer"
    size="m"
    color="secondary"
    [label]="'general.cancel' | translate"
    (click)="cancel()"
  ></sh-button>

  <sh-button
    slot="footer"
    size="m"
    [label]="'general.ok' | translate"
    [disabled]="!nameTextbox.value || !keyTextbox.value"
    (click)="close()"
  ></sh-button>
</sh-modal>
