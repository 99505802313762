<div>
  <sh-nav-bar slot="nav">
    <sh-tabs>
      <sh-tab-item
        [label]="
          'workflowguides.workflows.workflowVersions.overviews.tabBase'
            | translate
        "
        [active]="section === 'base'"
        (click)="section = 'base'"
      ></sh-tab-item>
      <sh-tab-item
        [label]="
          'workflowguides.workflows.workflowVersions.overviews.tabBlocks'
            | translate
        "
        [active]="section === 'blocks'"
        [disabled]="!id"
        (click)="section = 'blocks'"
      ></sh-tab-item>
    </sh-tabs>
  </sh-nav-bar>
  <div class="content">
    <div [style.display]="section === 'base' ? 'block' : 'none'">
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.workflows.workflowVersions.overviews.key'
              | translate
          "
        ></sh-text>
        <sh-input-text
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          mandatory
          [label]="
            'workflowguides.workflows.workflowVersions.overviews.key'
              | translate
          "
          [value]="key"
          (value-changed)="key = $event.target.value"
        ></sh-input-text>
      </div>
      <div class="content-row">
        <sh-text
          size="header-3"
          [innerHTML]="
            'workflowguides.workflows.workflowVersions.overviews.title'
              | translate
          "
        ></sh-text>
        <sh-input-text
          [disabled]="workflowGuidesService.isReleaseVersionActive()"
          mandatory
          [label]="
            'workflowguides.workflows.workflowVersions.overviews.title'
              | translate
          "
          [value]="titleTrans"
          (value-changed)="title = $event.target.value"
        ></sh-input-text>
      </div>
    </div>
    <div
      *ngIf="isEditMode"
      [style.display]="section === 'blocks' ? 'block' : 'none'"
    >
      <sh-table>
        <sh-table-row>
          <sh-table-head
            unsortable
            columns="22"
            [innerHTML]="
              'workflowguides.workflows.workflowVersions.overviews.blocks.type'
                | translate
            "
          ></sh-table-head>
          <sh-table-head
            unsortable
            icon
            columns="2"
            [innerHTML]=""
          ></sh-table-head>
        </sh-table-row>
        <sh-table-row *ngFor="let block of blocks">
          <sh-table-cell
            columns="22"
            [routerLink]="['blocks', block.id, getBlockType(block)]"
          >
            <p
              class="flow-text"
              [innerText]="getBlockTypeKey(block) | translate"
            ></p>
          </sh-table-cell>
          <sh-table-cell columns="2">
            <sh-icon *ngIf="!workflowGuidesService.isReleaseVersionActive()" button icon="delete" (click)="delete(block)"></sh-icon>
          </sh-table-cell>
        </sh-table-row>
      </sh-table>
      <div class="add-button-group">
        <sh-button
          *ngIf="!workflowGuidesService.isReleaseVersionActive()"
          class="add-button"
          size="m"
          routerLink="blocks/image"
          [label]="
            'workflowguides.workflows.workflowVersions.overviews.blocks.types.add-image'
              | translate
          "
        ></sh-button>
        <sh-button
          *ngIf="!workflowGuidesService.isReleaseVersionActive()"
          class="add-button"
          size="m"
          (click)="addAuthorBlock()"
          [label]="
            'workflowguides.workflows.workflowVersions.overviews.blocks.types.add-author'
              | translate
          "
        ></sh-button>
        <sh-button
          *ngIf="!workflowGuidesService.isReleaseVersionActive()"
          class="add-button"
          size="m"
          routerLink="blocks/text"
          [label]="
            'workflowguides.workflows.workflowVersions.overviews.blocks.types.add-text'
              | translate
          "
        ></sh-button>
      </div>
    </div>
  </div>

  <div class="buttons" *ngIf="!workflowGuidesService.isReleaseVersionActive()">
    <sh-button
      [disabled]="!key || !title"
      size="m"
      (click)="save()"
      [label]="'general.save' | translate"
    ></sh-button>
    <sh-button
      color="secondary"
      (click)="cancel()"
      size="m"
      [label]="'general.cancel' | translate"
    ></sh-button>
  </div>
</div>
