<sh-text
  size="header-2"
  [innerHTML]="
    (isEditMode
      ? 'devices.versions.axes.editAxis'
      : 'devices.versions.axes.addAxis'
    ) | translate
  "
></sh-text>

<div class="content">
  <div class="content-row">
    <sh-text [innerHTML]="'devices.versions.axes.name' | translate"></sh-text>
    <sh-input-text
      #inputName
      mandatory
      rows="1"
      [value]="name"
      [label]="'devices.versions.axes.name' | translate"
      (value-changed)="name = $event.target.value"
      autofocus
    ></sh-input-text>
  </div>

  <div class="content-row">
    <sh-text [innerHTML]="'devices.versions.axes.key' | translate"></sh-text>
    <sh-input-text
      #inputKey
      mandatory
      rows="1"
      [value]="key"
      [label]="'devices.versions.axes.key' | translate"
      (value-changed)="key = $event.target.value"
    ></sh-input-text>
  </div>

  <div class="content-row" *ngIf="!isEditMode">
    <sh-text [innerHTML]="'devices.versions.axes.type' | translate"></sh-text>
    <sh-dropdown
      #inputType
      mandatory
      rows="1"
      [value]="type | titlecase"
      [label]="'devices.versions.axes.type' | translate"
      (value-changed)="type = $event.target.value"
    >
      <sh-menu-item
        *ngFor="let t of axisTypeEnum | keyvalue"
        [label]="t.key"
      ></sh-menu-item>
    </sh-dropdown>
  </div>

  <div class="content-container" [class.hidden]="type !== 'rotation'">
    <div class="content-row data">
      <sh-text [innerHTML]="'devices.versions.axes.data' | translate"></sh-text>
      <sh-grid>
        <sh-input-number
          columns="12"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.rotation.start"
          (value-changed)="values.rotation.start = $event.target.getValue()"
          [label]="'devices.versions.axes.start' | translate"
        ></sh-input-number>

        <sh-switch columns="3">
          <sh-switch-item
            [active]="values.rotation.type === 0"
            (active-changed)="
              values.rotation.type = $event.target.active ? 0 : 1
            "
            [label]="'devices.versions.axes.linear' | translate"
          ></sh-switch-item>
          <sh-switch-item
            [active]="values.rotation.type === 1"
            (active-changed)="
              values.rotation.type = $event.target.active ? 1 : 0
            "
            [label]="'devices.versions.axes.looped' | translate"
          ></sh-switch-item>
        </sh-switch>

        <sh-input-number
          columns="3"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.rotation.maxSpeed"
          (value-changed)="values.rotation.maxSpeed = $event.target.getValue()"
          [label]="'devices.versions.axes.maxSpeed' | translate"
        ></sh-input-number>
        <sh-input-number
          columns="3"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.rotation.acceleration"
          (value-changed)="
            values.rotation.acceleration = $event.target.getValue()
          "
          [label]="'devices.versions.axes.acceleration' | translate"
        ></sh-input-number>
        <sh-input-number
          columns="3"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.rotation.deceleration"
          (value-changed)="
            values.rotation.deceleration = $event.target.getValue()
          "
          [label]="'devices.versions.axes.deceleration' | translate"
        ></sh-input-number>

        <sh-input-number
          columns="6"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.rotation.range"
          (value-changed)="values.rotation.range = $event.target.getValue()"
          [label]="'devices.versions.axes.rotation.range' | translate"
        ></sh-input-number>
        <sh-input-number
          columns="6"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.rotation.offset"
          (value-changed)="values.rotation.offset = $event.target.getValue()"
          [label]="'devices.versions.axes.rotation.offset' | translate"
        ></sh-input-number>

        <sh-input-number
          columns="4"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.rotation.axisX"
          (value-changed)="values.rotation.axisX = $event.target.getValue()"
          [label]="'devices.versions.axes.rotation.axisX' | translate"
        ></sh-input-number>
        <sh-input-number
          columns="4"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.rotation.axisY"
          (value-changed)="values.rotation.axisY = $event.target.getValue()"
          [label]="'devices.versions.axes.rotation.axisY' | translate"
        ></sh-input-number>
        <sh-input-number
          columns="4"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.rotation.axisZ"
          (value-changed)="values.rotation.axisZ = $event.target.getValue()"
          [label]="'devices.versions.axes.rotation.axisZ' | translate"
        ></sh-input-number>
      </sh-grid>
    </div>

    <div class="buttons">
      <sh-button
        color="secondary"
        (click)="cancel()"
        size="m"
        [label]="'general.cancel' | translate"
      ></sh-button>
      <sh-button
        [disabled]="!name || !type || !key"
        size="m"
        (click)="save()"
        [label]="'general.save' | translate"
      ></sh-button>
    </div>
  </div>

  <div class="content-container" [class.hidden]="type !== 'translation'">
    <div class="content-row data">
      <sh-text [innerHTML]="'devices.versions.axes.data' | translate"></sh-text>
      <sh-grid>
        <sh-input-number
          columns="12"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.translation.start"
          (value-changed)="values.translation.start = $event.target.getValue()"
          [label]="'devices.versions.axes.start' | translate"
        ></sh-input-number>

        <sh-switch columns="3">
          <sh-switch-item
            [active]="values.translation.type === 0"
            (active-changed)="
              values.translation.type = $event.target.active ? 0 : 1
            "
            [label]="'devices.versions.axes.linear' | translate"
          ></sh-switch-item>
          <sh-switch-item
            [active]="values.translation.type === 1"
            (active-changed)="
              values.translation.type = $event.target.active ? 1 : 0
            "
            [label]="'devices.versions.axes.looped' | translate"
          ></sh-switch-item>
        </sh-switch>

        <sh-input-number
          columns="3"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.translation.maxSpeed"
          (value-changed)="
            values.translation.maxSpeed = $event.target.getValue()
          "
          [label]="'devices.versions.axes.maxSpeed' | translate"
        ></sh-input-number>
        <sh-input-number
          columns="3"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.translation.acceleration"
          (value-changed)="
            values.translation.acceleration = $event.target.getValue()
          "
          [label]="'devices.versions.axes.acceleration' | translate"
        ></sh-input-number>
        <sh-input-number
          columns="3"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.translation.deceleration"
          (value-changed)="
            values.translation.deceleration = $event.target.getValue()
          "
          [label]="'devices.versions.axes.deceleration' | translate"
        ></sh-input-number>

        <sh-input-number
          columns="4"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.translation.startX"
          (value-changed)="values.translation.startX = $event.target.getValue()"
          [label]="'devices.versions.axes.translation.startX' | translate"
        ></sh-input-number>
        <sh-input-number
          columns="4"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.translation.startY"
          (value-changed)="values.translation.startY = $event.target.getValue()"
          [label]="'devices.versions.axes.translation.startY' | translate"
        ></sh-input-number>
        <sh-input-number
          columns="4"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.translation.startZ"
          (value-changed)="values.translation.startZ = $event.target.getValue()"
          [label]="'devices.versions.axes.translation.startZ' | translate"
        ></sh-input-number>

        <sh-input-number
          columns="4"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.translation.axisX"
          (value-changed)="values.translation.axisX = $event.target.getValue()"
          [label]="'devices.versions.axes.translation.axisX' | translate"
        ></sh-input-number>
        <sh-input-number
          columns="4"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.translation.axisY"
          (value-changed)="values.translation.axisY = $event.target.getValue()"
          [label]="'devices.versions.axes.translation.axisY' | translate"
        ></sh-input-number>
        <sh-input-number
          columns="4"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.translation.axisZ"
          (value-changed)="values.translation.axisZ = $event.target.getValue()"
          [label]="'devices.versions.axes.translation.axisZ' | translate"
        ></sh-input-number>

        <sh-input-number
          columns="12"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.translation.distance"
          (value-changed)="
            values.translation.distance = $event.target.getValue()
          "
          [label]="'devices.versions.axes.translation.distance' | translate"
        ></sh-input-number>
      </sh-grid>
    </div>

    <div class="buttons">
      <sh-button
        color="secondary"
        (click)="cancel()"
        size="m"
        [label]="'general.cancel' | translate"
      ></sh-button>
      <sh-button
        [disabled]="!name || !type || !key"
        size="m"
        (click)="save()"
        [label]="'general.save' | translate"
      ></sh-button>
    </div>
  </div>

  <div class="content-container" [class.hidden]="type !== 'virtual'">
    <div class="content-row data">
      <sh-text [innerHTML]="'devices.versions.axes.data' | translate"></sh-text>
      <sh-grid>
        <sh-input-number
          columns="12"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.virtual.start"
          (value-changed)="values.virtual.start = $event.target.getValue()"
          [label]="'devices.versions.axes.start' | translate"
        ></sh-input-number>

        <sh-switch columns="3">
          <sh-switch-item
            [active]="values.virtual.type === 0"
            (active-changed)="
              values.virtual.type = $event.target.active ? 0 : 1
            "
            [label]="'devices.versions.axes.linear' | translate"
          ></sh-switch-item>
          <sh-switch-item
            [active]="values.virtual.type === 1"
            (active-changed)="
              values.virtual.type = $event.target.active ? 1 : 0
            "
            [label]="'devices.versions.axes.looped' | translate"
          ></sh-switch-item>
        </sh-switch>

        <sh-input-number
          columns="3"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.virtual.maxSpeed"
          (value-changed)="values.virtual.maxSpeed = $event.target.getValue()"
          [label]="'devices.versions.axes.maxSpeed' | translate"
        ></sh-input-number>
        <sh-input-number
          columns="3"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.virtual.acceleration"
          (value-changed)="
            values.virtual.acceleration = $event.target.getValue()
          "
          [label]="'devices.versions.axes.acceleration' | translate"
        ></sh-input-number>
        <sh-input-number
          columns="3"
          mandatory
          no-clear
          value="0"
          step="0.1"
          [value]="values.virtual.deceleration"
          (value-changed)="
            values.virtual.deceleration = $event.target.getValue()
          "
          [label]="'devices.versions.axes.deceleration' | translate"
        ></sh-input-number>
      </sh-grid>
    </div>

    <div class="buttons">
      <sh-button
        color="secondary"
        (click)="cancel()"
        size="m"
        [label]="'general.cancel' | translate"
      ></sh-button>
      <sh-button
        [disabled]="!name || !type || !key"
        size="m"
        (click)="save()"
        [label]="'general.save' | translate"
      ></sh-button>
    </div>
  </div>
</div>
