import { Component } from '@angular/core';
import { ReportService } from './report.service';

@Component({
  selector: 'backend-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent {
  public constructor(public reportService: ReportService) {}
}
