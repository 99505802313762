<sh-text
  size="header-2"
  [innerHTML]="
    (isEditMode
      ? 'devices.versions.assets.editAsset'
      : 'devices.versions.assets.addAsset'
    ) | translate
  "
></sh-text>

<div class="content">
  <div class="content-row">
    <sh-text [innerHTML]="'devices.versions.assets.name' | translate"></sh-text>
    <sh-input-text
      #inputName
      mandatory
      rows="1"
      [value]="name"
      [label]="'devices.versions.assets.name' | translate"
      (value-changed)="name = $event.target.value"
      autofocus
    ></sh-input-text>
  </div>

  <div class="content-row">
    <sh-text
      [innerHTML]="'devices.versions.assets.platform' | translate"
    ></sh-text>
    <sh-dropdown
      #inputPlatform
      mandatory
      rows="1"
      [value]="platform"
      [label]="'devices.versions.assets.platform' | translate"
      (value-changed)="platform = $event.target.value"
    >
      <sh-menu-item *ngFor="let p of platforms" [label]="p"></sh-menu-item>
    </sh-dropdown>
  </div>

  <div class="content-row">
    <sh-text [innerHTML]="'devices.versions.assets.file' | translate"></sh-text>
    <sh-input-text
      #inputFileTextbox
      mandatory
      readonly
      [value]="fileBasename"
      rows="2"
      [label]="'devices.versions.assets.file' | translate"
    ></sh-input-text>
    <sh-button
      size="m"
      [label]="'devices.versions.assets.openFile' | translate"
      (click)="selectFile()"
    ></sh-button>
  </div>
</div>

<div class="buttons">
  <sh-button
    color="secondary"
    (click)="cancel()"
    size="m"
    [label]="'general.cancel' | translate"
  ></sh-button>
  <sh-button
    [disabled]="
      !inputName.value || !inputPlatform.value || !inputFileTextbox.value
    "
    size="m"
    (click)="save()"
    [label]="'general.save' | translate"
  ></sh-button>
</div>

<input
  #inputFile
  type="file"
  style="display: none"
  (change)="fileChange($event.target.files[0])"
/>
