<sh-modal
  *ngIf="open"
  [visible]="open"
  sticky="true"
  modal-width="85%"
  modal-height="90%"
>
  <sh-text slot="header">
    <sh-icon icon="settings" size="s"></sh-icon>
    <sh-text size="header-1"
      >{{ 'appusers.settings' | translate }}
      <sh-icon
        button
        icon="cancel"
        class="close-button"
        size="s"
        (click)="closeModal()"
      ></sh-icon>
    </sh-text>
  </sh-text>
  <sh-grid spacing="s">
    <div columns="2">
      <sh-menu-item
        [label]="'appusers.userDetails' | translate"
        [active]="currentTab === 'userinfo'"
        (click)="currentTab = 'userinfo'"
      ></sh-menu-item>
      <sh-menu-item
        [label]="'appusers.assignDevice' | translate"
        [active]="currentTab === 'devices'"
        (click)="currentTab = 'devices'"
      ></sh-menu-item>
      <sh-menu-item
        [label]="'appusers.assignWorkflow' | translate"
        [active]="currentTab === 'workflows'"
        (click)="currentTab = 'workflows'"
      >
      </sh-menu-item>
      <sh-menu-item
        [label]="'appusers.assignSite' | translate"
        [active]="currentTab === 'sites'"
        (click)="currentTab = 'sites'"
      ></sh-menu-item>
      <sh-menu-item
        [label]="'appusers.assignTraining' | translate"
        [active]="currentTab === 'technicianTraining'"
        (click)="currentTab = 'technicianTraining'"
      >
      </sh-menu-item>
      <sh-menu-item
        [label]="'appusers.assignBeta' | translate"
        [active]="currentTab === 'beta'"
        (click)="currentTab = 'beta'"
      ></sh-menu-item>

      <sh-menu-item
        [label]="'appusers.assignPackage' | translate"
        [active]="currentTab === 'package'"
        (click)="currentTab = 'package'"
      ></sh-menu-item>
    </div>

    <sh-card style="margin-top: 5px" columns="10">
      <div *ngIf="currentTab === 'userinfo'">
        <sh-text
          size="header-1"
          [innerText]="'appusers.userDetails' | translate"
        ></sh-text>
        <sh-text [innerText]="'appusers.addUserDetail' | translate"></sh-text>
        <sh-text
          size="body-2"
          [innerText]="'appusers.addRequiredUserDetail' | translate"
        ></sh-text>
        <sh-grid>
          <div columns="12" style="margin: 0px"></div>
          <div columns="12">
            <label for="emailText">{{ 'appusers.email' | translate }}</label>
            <sh-input-text
              id="emailText"
              readonly
              [value]="email"
              (value-changed)="email = $event.target.value"
              [label]="''"
              email
              #emailText
            ></sh-input-text>
          </div>
          <div columns="12">
            <label for="orgText"
              >{{ 'appusers.organization' | translate
              }}<b style="color: red">*</b></label
            >
            <sh-search
              id="orgText"
              #orgText
              [label]="organization"
              (click)="searchOrgData()"
              [value]="orgVal"
              (value-changed)="orgVal = $event.target.value"
              (clearsearch)="onSearchOrg()"
            ></sh-search>
            <sh-checkbox
              style="margin-top: 5px"
              [label]="'appusers.updateAllOrgs' | translate"
              (checked)="
                $event.target.active ? (updateAll = true) : (updateAll = false)
              "
              [active]="updateAll"
            >
            </sh-checkbox>
            <sh-text
              size="body-2"
              [innerText]="'appusers.UpdateUserDetail' | translate"
            ></sh-text>
          </div>
          <div columns="6">
            <label for="bLText"
              >{{ 'appusers.businessLine' | translate
              }}<b style="color: red">*</b></label
            >
            <sh-search
              id="bLText"
              [label]="businessLine"
              (click)="searchBLData()"
              [value]="businessLineVal"
              (value-changed)="businessLineVal = $event.target.value"
              (clearsearch)="onSearchBL()"
              #bLText
            ></sh-search>
          </div>
          <div columns="6">
            <label for="roleText"
              >{{ 'appusers.role' | translate
              }}<b style="color: red">*</b></label
            >
            <sh-search
              id="roleText"
              [label]="role"
              (click)="searchRoleData()"
              [value]="roleVal"
              (value-changed)="roleVal = $event.target.value"
              (clearsearch)="onSearchRole()"
              #roleText
            ></sh-search>
          </div>
          <div columns="6">
            <label for="countryText"
              >{{ 'appusers.country' | translate
              }}<b style="color: red">*</b></label
            >
            <sh-dropdown
              id="countryText"
              [role]="country"
              style="padding-left: 0px !important"
              editable
              rows="1"
              [value]="countryVal"
              [label]="''"
              (value-changed)="
                countryVal = $event.target.value; changeRegion(countryVal)
              "
              [error]="!isCountryAvailable(countryVal)"
              #countryText
            >
              <sh-menu-item
                *ngFor="let l of appUsersService.locations"
                [label]="l.country"
              ></sh-menu-item>
            </sh-dropdown>
          </div>
          <div columns="6">
            <label for="regionText"
              >{{ 'appusers.region' | translate
              }}<b style="color: red">*</b></label
            >
            <sh-input-text
              id="regionText"
              [label]="''"
              readonly
              [value]="regionVal"
              (value-changed)="regionVal = $event.target.value"
              #regionText
            ></sh-input-text>
          </div>
        </sh-grid>

        <sh-button
          slot="footer"
          [disabled]="isInvalid()"
          style="float: right"
          size="m"
          [label]="'general.save' | translate"
          (click)="okChecked()"
        ></sh-button>
      </div>

      <div *ngIf="currentTab === 'devices'">
        <sh-text
          size="header-1"
          [innerText]="'appusers.assignDevice' | translate"
        ></sh-text>
        <sh-text
          [innerText]="'appusers.assignReleasedDeviceDetail' | translate"
        ></sh-text>
        <sh-table *ngIf="$devices | async as devices">
          <sh-table-row slot="header" style="padding-bottom: 2.5rem">
            <sh-table-cell unsortable columns="5">
              <sh-text
                size="title-1"
                [innerText]="'appusers.devices' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="6">
              <sh-text
                size="title-1"
                [innerText]="'appusers.validity' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="6">
              <sh-text
                size="title-1"
                [innerText]="'appusers.startDate' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="6">
              <sh-text
                size="title-1"
                [innerText]="'appusers.endDate' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="3">
              <sh-text
                size="title-1"
                [innerText]="'appusers.assigned' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable icon></sh-table-cell>
          </sh-table-row>
          <sh-table-row
            #tableRow
            *ngFor="let device of filterDevices(devices, 2); let i = index"
            [expanded]="
              appUsersService.userHasDevice(user, device.name) &&
              appUsersService.hasPheno(device.name)
            "
          >
            <sh-table-cell columns="5">
              <sh-text
                [innerText]="
                  device.deviceDescription.name
                    | getLocaleText : globalLanguage?.key : true
                "
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell columns="6">
              <sh-dropdown
                mandatory
                [disabled]="!isEditable[i]"
                id="{{ i }}"
                editable
                [value]="duration[i]"
                [label]="'appusers.validityPh' | translate"
                (value-changed)="changeDuration($event?.target?.value, i)"
              >
                <sh-menu-item
                  *ngFor="let d of durationValues"
                  [label]="d"
                ></sh-menu-item>
              </sh-dropdown>
            </sh-table-cell>
            <sh-table-cell columns="6">
              <sh-datepicker
                [disabled]="!isEditable[i]"
                [editable]="isEditable[i]"
                format="YYYYMMDD"
                [value]="getFromDate(licenseStart[i])"
                (value-changed)="changeDate($event?.target?.value, i)"
                label="From"
                past-date-disable
              >
              </sh-datepicker>
            </sh-table-cell>
            <sh-table-cell columns="6">
              <sh-datepicker
                id="{{ 'date' + i }}"
                format="YYYYMMDD"
                [value]="getToDate(licenseStart[i], duration[i])"
                readonly
                label="To"
              ></sh-datepicker>
            </sh-table-cell>
            <sh-table-cell columns="3">
              <sh-checkbox
                [disabled]="!isEditable[i]"
                [label]="''"
                [active]="appUsersService.userHasDevice(user, device.name)"
                (checked)="isChecked[i] = $event.target.active"
              >
              </sh-checkbox>
            </sh-table-cell>
            <sh-table-row
              *ngIf="
                appUsersService.userHasDevice(user, device.name) &&
                appUsersService.hasPheno(device.name)
              "
              [expanded]="
                appUsersService.userHasDevice(user, device.name) &&
                appUsersService.hasPheno(device.name)
              "
            >
              <sh-table-cell>
                <sh-text
                  style="padding-top: 4px; padding-bottom: 4px"
                  [innerText]="'appusers.config' | translate"
                >
                  Configuration</sh-text
                >
              </sh-table-cell>
              <sh-table-row>
                <sh-table-cell columns="7">
                  <sh-text [innerText]="'appusers.table' | translate"></sh-text>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [disabled]="!isEditable[i]"
                    [label]="'appusers.siemens' | translate"
                    [active]="
                      appUsersService.userHasTableForDevice(
                        user,
                        device.name,
                        tableTypeEnum.Siemens
                      )
                    "
                    (checked)="siemensChecked[i] = $event.target.active"
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [disabled]="!isEditable[i]"
                    [label]="'appusers.magnus' | translate"
                    [active]="
                      appUsersService.userHasTableForDevice(
                        user,
                        device.name,
                        tableTypeEnum.Magnus
                      )
                    "
                    (checked)="magnusChecked[i] = $event.target.active"
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="9"> </sh-table-cell>
                <sh-table-cell unsortable icon> </sh-table-cell>
              </sh-table-row>
              <sh-table-row>
                <sh-table-cell columns="7">
                  <sh-text [innerText]="'appusers.pcm' | translate"></sh-text>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [disabled]="!isEditable[i]"
                    [label]="'appusers.pcm1' | translate"
                    [active]="
                      appUsersService.userHasPcmForDevice(
                        user,
                        device.name,
                        pcmEnum.PCM_1
                      )
                    "
                    (checked)="pcm1Checked[i] = $event.target.active"
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [disabled]="!isEditable[i]"
                    [label]="'appusers.pcm2' | translate"
                    [active]="
                      appUsersService.userHasPcmForDevice(
                        user,
                        device.name,
                        pcmEnum.PCM_2
                      )
                    "
                    (checked)="pcm2Checked[i] = $event.target.active"
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="9"> </sh-table-cell>
                <sh-table-cell unsortable icon> </sh-table-cell>
              </sh-table-row>
            </sh-table-row>
            <sh-table-cell icon>
              <sh-icon
                *ngIf="!isEditable[i]"
                button
                icon="edit"
                [disabled]="isPackageDevice(device.name)"
                (click)="
                  isChecked[i] = appUsersService.userHasDevice(
                    user,
                    device.name
                  );
                  isEditable[i] = true
                "
              >
              </sh-icon>
              <sh-icon
                *ngIf="isEditable[i]"
                button
                icon="save"
                id="submit"
                [disabled]="
                  isChecked[i] &&
                  (tempDuration[i] === undefined ||
                    tempDuration[i] === null ||
                    tempDuration[i] === 0)
                "
                (click)="
                  isEditable[i] = false;
                  select(
                    isChecked[i],
                    device.name,
                    tempStartDate[i],
                    tempDuration[i],
                    siemensChecked[i],
                    magnusChecked[i],
                    pcm1Checked[i],
                    pcm2Checked[i],
                    false
                  )
                "
              >
              </sh-icon>
              <sh-tooltip
                placement="bottom"
                target="submit"
                label="Please enter the validity duration to enable."
              >
              </sh-tooltip>
            </sh-table-cell>
          </sh-table-row>
        </sh-table>
      </div>

      <div *ngIf="currentTab === 'workflows'">
        <sh-text
          size="header-1"
          [innerText]="'appusers.assignWorkflow' | translate"
        ></sh-text>
        <sh-text
          [innerText]="'appusers.assignReleasedWorkflowDetail' | translate"
        ></sh-text>
        <sh-table *ngIf="$workflows | async as workflows">
          <sh-table-row slot="header" style="padding-bottom: 2.5rem">
            <sh-table-cell unsortable columns="5">
              <sh-text
                size="title-1"
                [innerText]="'appusers.workflows' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="6">
              <sh-text
                size="title-1"
                [innerText]="'appusers.validity' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="6">
              <sh-text
                size="title-1"
                [innerText]="'appusers.startDate' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="6">
              <sh-text
                size="title-1"
                [innerText]="'appusers.endDate' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable columns="3">
              <sh-text
                size="title-1"
                [innerText]="'appusers.assigned' | translate"
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell unsortable icon></sh-table-cell>
          </sh-table-row>
          <sh-table-row
            #tableRow
            *ngFor="
              let workflow of filterWorkflows(workflows, 2);
              let i = index
            "
            [expanded]="
              appUsersService.userHasWorkflow(user, workflow.key) &&
              appUsersService.hasPheno(workflow.key)
            "
          >
            <sh-table-cell columns="5">
              <sh-text
                [innerText]="
                  workflow.title | getLocaleText : globalLanguage?.key : true
                "
              ></sh-text>
            </sh-table-cell>
            <sh-table-cell columns="6">
              <sh-dropdown
                mandatory
                [disabled]="!isEditable[i]"
                id="{{ i }}"
                editable
                [value]="duration[i]"
                [label]="'appusers.validityPh' | translate"
                (value-changed)="changeDuration($event?.target?.value, i)"
              >
                <sh-menu-item
                  *ngFor="let d of durationValues"
                  [label]="d"
                ></sh-menu-item>
              </sh-dropdown>
            </sh-table-cell>
            <sh-table-cell columns="6">
              <sh-datepicker
                [disabled]="!isEditable[i]"
                [editable]="isEditable[i]"
                format="YYYYMMDD"
                [value]="getFromDate(licenseStart[i])"
                (value-changed)="changeDate($event?.target?.value, i)"
                label="From"
                past-date-disable
              >
              </sh-datepicker>
            </sh-table-cell>
            <sh-table-cell columns="6">
              <sh-datepicker
                id="{{ 'date' + i }}"
                format="YYYYMMDD"
                [value]="getToDate(licenseStart[i], duration[i])"
                readonly
                label="To"
              ></sh-datepicker>
            </sh-table-cell>
            <sh-table-cell columns="3">
              <sh-checkbox
                [disabled]="!isEditable[i]"
                [label]="''"
                [active]="appUsersService.userHasWorkflow(user, workflow.key)"
                (checked)="isChecked[i] = $event.target.active"
              >
              </sh-checkbox>
            </sh-table-cell>
            <sh-table-row
              *ngIf="
                appUsersService.userHasWorkflow(user, workflow.key) &&
                appUsersService.hasPheno(workflow.key)
              "
              [expanded]="
                appUsersService.userHasWorkflow(user, workflow.key) &&
                appUsersService.hasPheno(workflow.key)
              "
            >
              <sh-table-cell>
                <sh-text
                  style="padding-top: 4px; padding-bottom: 4px"
                  [innerText]="'appusers.config' | translate"
                >
                  Configuration</sh-text
                >
              </sh-table-cell>
              <sh-table-row>
                <sh-table-cell columns="7">
                  <sh-text [innerText]="'appusers.table' | translate"></sh-text>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [disabled]="!isEditable[i]"
                    [label]="'appusers.siemens' | translate"
                    [active]="
                      appUsersService.userHasTableForWorkflow(
                        user,
                        workflow.key,
                        tableTypeEnum.Siemens
                      )
                    "
                    (checked)="siemensChecked[i] = $event.target.active"
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [disabled]="!isEditable[i]"
                    [label]="'appusers.magnus' | translate"
                    [active]="
                      appUsersService.userHasTableForWorkflow(
                        user,
                        workflow.key,
                        tableTypeEnum.Magnus
                      )
                    "
                    (checked)="magnusChecked[i] = $event.target.active"
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="9"> </sh-table-cell>
                <sh-table-cell unsortable icon> </sh-table-cell>
              </sh-table-row>
              <sh-table-row>
                <sh-table-cell columns="7">
                  <sh-text [innerText]="'appusers.pcm' | translate"></sh-text>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [disabled]="!isEditable[i]"
                    [label]="'appusers.pcm1' | translate"
                    [active]="
                      appUsersService.userHasPcmForWorkflow(
                        user,
                        workflow.key,
                        pcmEnum.PCM_1
                      )
                    "
                    (checked)="pcm1Checked[i] = $event.target.active"
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="6">
                  <sh-checkbox
                    [disabled]="!isEditable[i]"
                    [label]="'appusers.pcm2' | translate"
                    [active]="
                      appUsersService.userHasPcmForWorkflow(
                        user,
                        workflow.key,
                        pcmEnum.PCM_2
                      )
                    "
                    (checked)="pcm2Checked[i] = $event.target.active"
                  >
                  </sh-checkbox>
                </sh-table-cell>
                <sh-table-cell columns="9"> </sh-table-cell>
                <sh-table-cell unsortable icon> </sh-table-cell>
              </sh-table-row>
            </sh-table-row>
            <sh-table-cell icon>
              <sh-icon
                *ngIf="!isEditable[i]"
                button
                [disabled]="isPackageWorkflow(workflow.key)"
                icon="edit"
                (click)="
                  isChecked[i] = appUsersService.userHasWorkflow(
                    user,
                    workflow.key
                  );
                  isEditable[i] = true
                "
              >
              </sh-icon>
              <sh-icon
                *ngIf="isEditable[i]"
                button
                icon="save"
                [disabled]="
                  isChecked[i] &&
                  (tempDuration[i] === undefined ||
                    tempDuration[i] === null ||
                    tempDuration[i] === 0)
                "
                (click)="
                  isEditable[i] = false;
                  selectWorkflow(
                    isChecked[i],
                    workflow,
                    tempStartDate[i],
                    tempDuration[i],
                    siemensChecked[i],
                    magnusChecked[i],
                    pcm1Checked[i],
                    pcm2Checked[i],
                    false
                  )
                "
              >
              </sh-icon>
            </sh-table-cell>
          </sh-table-row>
        </sh-table>
      </div>

      <div *ngIf="currentTab === 'sites'">
        <sh-text
          size="header-1"
          [innerText]="'appusers.assignedSites' | translate"
        ></sh-text>
        <sh-text
          [innerText]="'appusers.assignSiteDetail' | translate"
        ></sh-text>
        <sh-table *ngIf="filteredSites">
          <sh-table-row slot="header" style="padding-bottom: 2.5rem">
            <sh-table-cell style="width: 100%">
              <sh-text>
                <sh-search
                  style="margin-left: 5px"
                  #searchBox
                  whole-string
                  [label]="'general.search' | translate"
                  (search)="onSearch($event.target.value)"
                  (clearsearch)="onSearch()"
                ></sh-search>
              </sh-text>
            </sh-table-cell>
          </sh-table-row>
          <sh-table-row *ngFor="let site of sortSites(filteredSites)">
            <sh-table-cell>
              <sh-checkbox
                [label]="site.siteName"
                [active]="hasSite(site)"
                (checked)="selectSite($event, site)"
              >
              </sh-checkbox>
            </sh-table-cell>
          </sh-table-row>
        </sh-table>
      </div>

      <div *ngIf="currentTab === 'technicianTraining'">
        <sh-text
          size="header-1"
          [innerText]="'appusers.assignTraining' | translate"
        ></sh-text>
        <sh-text
          [innerText]="('appusers.technicianTraining' | translate) + ':'"
        ></sh-text>
        <sh-text
          size="body-2"
          [innerText]="
            ('appusers.technicianTrainingDetail' | translate) + email
          "
        ></sh-text>
        <sh-table>
          <sh-table-row>
            <sh-table-cell>
              <sh-checkbox
                [label]="'appusers.technicianTraining' | translate"
                [active]="appUsersService.isTechnicianTrainingUser(user)"
                (checked)="selectTechnicianTraining($event)"
              >
              </sh-checkbox>
            </sh-table-cell>
          </sh-table-row>
        </sh-table>
      </div>

      <div *ngIf="currentTab === 'beta'">
        <sh-text
          size="header-1"
          [innerText]="'appusers.assignBeta' | translate"
        ></sh-text>
        <sh-tabs>
          <sh-tab-item
            [label]="'appusers.devices' | translate"
            [active]="subTab === 'betaDevices'"
            (click)="subTab = 'betaDevices'"
          ></sh-tab-item>
          <sh-tab-item
            [label]="'appusers.workflows' | translate"
            [active]="subTab === 'betaWorkflows'"
            (click)="subTab = 'betaWorkflows'"
          >
          </sh-tab-item>
        </sh-tabs>
        <div *ngIf="subTab === 'betaDevices'">
          <sh-table *ngIf="$devices | async as devices">
            <sh-table-row slot="header" style="padding-bottom: 2.5rem">
              <sh-table-cell unsortable columns="5">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.devices' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="6">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.validity' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="6">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.startDate' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="6">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.endDate' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="3">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.assigned' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable icon></sh-table-cell>
            </sh-table-row>
            <sh-table-row
              #tableRow
              *ngFor="let device of filterDevices(devices, 0); let i = index"
              [expanded]="
                appUsersService.userHasDevice(user, device.name) &&
                appUsersService.hasPheno(device.name)
              "
            >
              <sh-table-cell columns="5">
                <sh-text
                  [innerText]="
                    device.deviceDescription.name
                      | getLocaleText : globalLanguage?.key : true
                  "
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell columns="6">
                <sh-dropdown
                  mandatory
                  [disabled]="!isEditable[i]"
                  id="{{ i }}"
                  editable
                  [value]="duration[i]"
                  [label]="'appusers.validityPh' | translate"
                  (value-changed)="changeDuration($event?.target?.value, i)"
                >
                  <sh-menu-item
                    *ngFor="let d of durationValues"
                    [label]="d"
                  ></sh-menu-item>
                </sh-dropdown>
              </sh-table-cell>
              <sh-table-cell columns="6">
                <sh-datepicker
                  [disabled]="!isEditable[i]"
                  [editable]="isEditable[i]"
                  format="YYYYMMDD"
                  [value]="getFromDate(licenseStart[i])"
                  (value-changed)="changeDate($event?.target?.value, i)"
                  label="From"
                  past-date-disable
                >
                </sh-datepicker>
              </sh-table-cell>
              <sh-table-cell columns="6">
                <sh-datepicker
                  id="{{ 'date' + i }}"
                  format="YYYYMMDD"
                  [value]="getToDate(licenseStart[i], duration[i])"
                  readonly
                  label="To"
                ></sh-datepicker>
              </sh-table-cell>
              <sh-table-cell columns="3">
                <sh-checkbox
                  [disabled]="!isEditable[i]"
                  [label]="''"
                  [active]="appUsersService.userHasBetaDevice(user, device.name)"
                  (checked)="isChecked[i] = $event.target.active"
                >
                </sh-checkbox>
              </sh-table-cell>
              <sh-table-row
                *ngIf="
                  appUsersService.userHasDevice(user, device.name) &&
                  appUsersService.hasPheno(device.name)
                "
                [expanded]="
                  appUsersService.userHasDevice(user, device.name) &&
                  appUsersService.hasPheno(device.name)
                "
              >
                <sh-table-cell>
                  <sh-text
                    style="padding-top: 4px; padding-bottom: 4px"
                    [innerText]="'appusers.config' | translate"
                  >
                    Configuration</sh-text
                  >
                </sh-table-cell>
                <sh-table-row>
                  <sh-table-cell columns="7">
                    <sh-text
                      [innerText]="'appusers.table' | translate"
                    ></sh-text>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [disabled]="!isEditable[i]"
                      [label]="'appusers.siemens' | translate"
                      [active]="
                        appUsersService.userHasTableForDevice(
                          user,
                          device.name,
                          tableTypeEnum.Siemens
                        )
                      "
                      (checked)="siemensChecked[i] = $event.target.active"
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [disabled]="!isEditable[i]"
                      [label]="'appusers.magnus' | translate"
                      [active]="
                        appUsersService.userHasTableForDevice(
                          user,
                          device.name,
                          tableTypeEnum.Magnus
                        )
                      "
                      (checked)="magnusChecked[i] = $event.target.active"
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="9"> </sh-table-cell>
                  <sh-table-cell unsortable icon> </sh-table-cell>
                </sh-table-row>
                <sh-table-row>
                  <sh-table-cell columns="7">
                    <sh-text [innerText]="'appusers.pcm' | translate"></sh-text>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [disabled]="!isEditable[i]"
                      [label]="'appusers.pcm1' | translate"
                      [active]="
                        appUsersService.userHasPcmForDevice(
                          user,
                          device.name,
                          pcmEnum.PCM_1
                        )
                      "
                      (checked)="pcm1Checked[i] = $event.target.active"
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [disabled]="!isEditable[i]"
                      [label]="'appusers.pcm2' | translate"
                      [active]="
                        appUsersService.userHasPcmForDevice(
                          user,
                          device.name,
                          pcmEnum.PCM_2
                        )
                      "
                      (checked)="pcm2Checked[i] = $event.target.active"
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="9"> </sh-table-cell>
                  <sh-table-cell unsortable icon> </sh-table-cell>
                </sh-table-row>
              </sh-table-row>
              <sh-table-cell icon>
                <sh-icon
                  *ngIf="!isEditable[i]"
                  button
                  icon="edit"
                  (click)="
                    isChecked[i] = appUsersService.userHasDevice(
                      user,
                      device.name
                    );
                    isEditable[i] = true
                  "
                >
                </sh-icon>
                <sh-icon
                  *ngIf="isEditable[i]"
                  button
                  icon="save"
                  [disabled]="
                    isChecked[i] &&
                    (tempDuration[i] === undefined ||
                      tempDuration[i] === null ||
                      tempDuration[i] === 0)
                  "
                  (click)="
                    isEditable[i] = false;
                    select(
                      isChecked[i],
                      device.name,
                      tempStartDate[i],
                      tempDuration[i],
                      siemensChecked[i],
                      magnusChecked[i],
                      pcm1Checked[i],
                      pcm2Checked[i],
                      true
                    )
                  "
                >
                </sh-icon>
              </sh-table-cell>
            </sh-table-row>
          </sh-table>
        </div>
        <div *ngIf="subTab === 'betaWorkflows'">
          <sh-table *ngIf="$workflows | async as workflows">
            <sh-table-row slot="header" style="padding-bottom: 2.5rem">
              <sh-table-cell unsortable columns="5">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.workflows' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="6">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.validity' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="6">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.startDate' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="6">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.endDate' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable columns="3">
                <sh-text
                  size="title-1"
                  [innerText]="'appusers.assigned' | translate"
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell unsortable icon></sh-table-cell>
            </sh-table-row>
            <sh-table-row
              #tableRow
              *ngFor="
                let workflow of filterWorkflows(workflows, 0);
                let i = index
              "
              [expanded]="
                appUsersService.userHasWorkflow(user, workflow.key) &&
                appUsersService.hasPheno(workflow.key)
              "
            >
              <sh-table-cell columns="5">
                <sh-text
                  [innerText]="
                    workflow.title | getLocaleText : globalLanguage?.key : true
                  "
                ></sh-text>
              </sh-table-cell>
              <sh-table-cell columns="6">
                <sh-dropdown
                  mandatory
                  [disabled]="!isEditable[i]"
                  id="{{ i }}"
                  editable
                  [value]="duration[i]"
                  [label]="'appusers.validityPh' | translate"
                  (value-changed)="changeDuration($event?.target?.value, i)"
                >
                  <sh-menu-item
                    *ngFor="let d of durationValues"
                    [label]="d"
                  ></sh-menu-item>
                </sh-dropdown>
              </sh-table-cell>
              <sh-table-cell columns="6">
                <sh-datepicker
                  [disabled]="!isEditable[i]"
                  [editable]="isEditable[i]"
                  format="YYYYMMDD"
                  [value]="getFromDate(licenseStart[i])"
                  (value-changed)="changeDate($event?.target?.value, i)"
                  label="From"
                  past-date-disable
                >
                </sh-datepicker>
              </sh-table-cell>
              <sh-table-cell columns="6">
                <sh-datepicker
                  id="{{ 'date' + i }}"
                  format="YYYYMMDD"
                  [value]="getToDate(licenseStart[i], duration[i])"
                  readonly
                  label="To"
                ></sh-datepicker>
              </sh-table-cell>
              <sh-table-cell columns="3">
                  <sh-checkbox
                  [disabled]="!isEditable[i]"
                  [label]="''"
                  [active]="appUsersService.userHasBetaWorkflow(user, workflow.key)"
                  (checked)="isChecked[i] = $event.target.active"
                >
                </sh-checkbox>
              </sh-table-cell>
              <sh-table-row
                *ngIf="
                  appUsersService.userHasWorkflow(user, workflow.key) &&
                  appUsersService.hasPheno(workflow.key)
                "
                [expanded]="
                  appUsersService.userHasWorkflow(user, workflow.key) &&
                  appUsersService.hasPheno(workflow.key)
                "
              >
                <sh-table-cell>
                  <sh-text
                    style="padding-top: 4px; padding-bottom: 4px"
                    [innerText]="'appusers.config' | translate"
                  >
                    Configuration</sh-text
                  >
                </sh-table-cell>
                <sh-table-row>
                  <sh-table-cell columns="7">
                    <sh-text
                      [innerText]="'appusers.table' | translate"
                    ></sh-text>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [disabled]="!isEditable[i]"
                      [label]="'appusers.siemens' | translate"
                      [active]="
                        appUsersService.userHasTableForWorkflow(
                          user,
                          workflow.key,
                          tableTypeEnum.Siemens
                        )
                      "
                      (checked)="siemensChecked[i] = $event.target.active"
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [disabled]="!isEditable[i]"
                      [label]="'appusers.magnus' | translate"
                      [active]="
                        appUsersService.userHasTableForWorkflow(
                          user,
                          workflow.key,
                          tableTypeEnum.Magnus
                        )
                      "
                      (checked)="magnusChecked[i] = $event.target.active"
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="9"> </sh-table-cell>
                  <sh-table-cell unsortable icon> </sh-table-cell>
                </sh-table-row>
                <sh-table-row>
                  <sh-table-cell columns="7">
                    <sh-text [innerText]="'appusers.pcm' | translate"></sh-text>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [disabled]="!isEditable[i]"
                      [label]="'appusers.pcm1' | translate"
                      [active]="
                        appUsersService.userHasPcmForWorkflow(
                          user,
                          workflow.key,
                          pcmEnum.PCM_1
                        )
                      "
                      (checked)="pcm1Checked[i] = $event.target.active"
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="6">
                    <sh-checkbox
                      [disabled]="!isEditable[i]"
                      [label]="'appusers.pcm2' | translate"
                      [active]="
                        appUsersService.userHasPcmForWorkflow(
                          user,
                          workflow.key,
                          pcmEnum.PCM_2
                        )
                      "
                      (checked)="pcm2Checked[i] = $event.target.active"
                    >
                    </sh-checkbox>
                  </sh-table-cell>
                  <sh-table-cell columns="9"> </sh-table-cell>
                  <sh-table-cell unsortable icon> </sh-table-cell>
                </sh-table-row>
              </sh-table-row>
              <sh-table-cell icon>
                <sh-icon
                  *ngIf="!isEditable[i]"
                  button
                  icon="edit"
                  (click)="
                    isChecked[i] = appUsersService.userHasWorkflow(
                      user,
                      workflow.key
                    );
                    isEditable[i] = true
                  "
                >
                </sh-icon>
                <sh-icon
                  *ngIf="isEditable[i]"
                  button
                  icon="save"
                  [disabled]="
                    isChecked[i] &&
                    (tempDuration[i] === undefined ||
                      tempDuration[i] === null ||
                      tempDuration[i] === 0)
                  "
                  (click)="
                    isEditable[i] = false;
                    selectWorkflow(
                      isChecked[i],
                      workflow,
                      tempStartDate[i],
                      tempDuration[i],
                      siemensChecked[i],
                      magnusChecked[i],
                      pcm1Checked[i],
                      pcm2Checked[i],
                      true
                    )
                  "
                >
                </sh-icon>
              </sh-table-cell>
            </sh-table-row>
          </sh-table>
        </div>
      </div>

      <!--      License Package Tab   -->
      <div *ngIf="currentTab === 'package'">
        <backend-license-package
          [user]="user"
          (packageUpdated)="packageUpdated($event)"
        ></backend-license-package>
      </div>
    </sh-card>
  </sh-grid>
</sh-modal>
