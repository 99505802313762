import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { ParamsService } from '@backend/webapp/services/params.service';
import { ConfirmationService } from '../confirmation/confirmation.service';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';
import { NotificationService } from '../notification/notification.service';
import { Constants } from '@backend/interfaces';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  public entriesSubject$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  public get entries$(): Observable<any[]> {
    return this.entriesSubject$.asObservable();
  }
  public get entries(): any[] {
    return this.entriesSubject$.value;
  }
  public languages: any[] = [Constants.DEFAULT_GLOBAL_LANGUAGE];
  private globalLang = Constants.DEFAULT_GLOBAL_LANGUAGE;
  private globalLanguageSubject$: BehaviorSubject<any> =
    new BehaviorSubject<any>(this.globalLang);
  public get globalLanguage$(): Observable<any> {
    return this.globalLanguageSubject$.asObservable();
  }
  public get globalLanguage(): any {
    return this.globalLanguageSubject$.value;
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly paramsService: ParamsService,
    private readonly confirmationService: ConfirmationService,
    private readonly spinner: SpinnerService,
    private readonly notificationService: NotificationService
  ) {
    this.getLanguages();
  }

  public changeGlobalLanguage(language) {
    localStorage.setItem('globalLanguage', JSON.stringify(language));
    this.globalLanguageSubject$.next(language);
  }

  public async getLanguages() {
    this.languages = await lastValueFrom(
      this.http.get<any[]>(`/api/globals/translations/languages`)
    );
    return this.languages;
  }

  public async createLanguage(key, name) {
    this.spinner.showSpinner();
    try {
      await lastValueFrom(
        this.http.put<any>(`/api/globals/translations/languages`, {
          languageKey: key,
          languageName: name,
        })
      );
      this.notificationService.confirmation(
        'general.success',
        'language.createSuccess',
        { langName: name }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'language.createError',
        { langName: name },
        error.error.message
      );
      return false;
    } finally {
      this.spinner.hideSpinner();
    }
  }

  public async deleteLanguage(langKey, langName) {
    if (
      await this.confirmationService.confirmDelete(
        'language.delete',
        'language.deleteLangConfirmation',
        { langName }
      )
    ) {
      this.spinner.showSpinner();
      try {
        await lastValueFrom(
          this.http.delete(`/api/globals/translations/languages/${langKey}`)
        );
        this.notificationService.confirmation(
          'general.success',
          'language.deleteSuccess',
          { langName }
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'language.deleteError',
          { langName },
          error.error.message
        );
        return false;
      } finally {
        this.spinner.hideSpinner();
      }
    }
  }

  public async getLocalizationentriesSubject() {
    const apiBaseUrl = this.getApiBaseUrl();
    return this.entriesSubject$.next(
      await lastValueFrom(this.http.get<any[]>(`${apiBaseUrl}`))
    );
  }

  public async getLocalizationentries() {
    const apiBaseUrl = this.getApiBaseUrl();
    return await lastValueFrom(this.http.get<any[]>(`${apiBaseUrl}`));
  }

  public async getLocalizationentriesForLanguage(langKey: string) {
    const apiBaseUrl = this.getApiBaseUrl();
    const languageEntries = await lastValueFrom(
      this.http.get<any[]>(`${apiBaseUrl}/languages/${langKey}`)
    );
    this.entriesSubject$.next(languageEntries);
    return languageEntries;
  }

  public async getLocalizationSubjectForLanguage(langKey: string) {
    const apiBaseUrl = this.getApiBaseUrl();
    return this.entriesSubject$.next(
      await lastValueFrom(
        this.http.get<any[]>(`${apiBaseUrl}/languages/${langKey}`)
      )
    );
  }

  public async getExport() {
    const apiBaseUrl = this.getApiBaseUrl();
    return await lastValueFrom(
      this.http.post<any>(`${apiBaseUrl}/export-csv`, {})
    );
  }
  public async saveEntriescreateChange(createChange: any) {
    const apiBaseUrl = this.getApiBaseUrl();
    return await lastValueFrom(
      this.http.put<any[]>(
        `${apiBaseUrl}/${createChange.key}/language/${createChange.language}`,
        { text: createChange.value }
      )
    );
  }
  public async saveEntriesmodifyChange(modifyChange: any) {
    const apiBaseUrl = this.getApiBaseUrl();
    return await lastValueFrom(
      this.http.put<any[]>(
        `${apiBaseUrl}/${modifyChange.key}/language/${modifyChange.language}`,
        { text: modifyChange.value }
      )
    );
  }

  public async saveEntriesDeleteChange(deleteChange: any) {
    const apiBaseUrl = this.getApiBaseUrl();
    return await lastValueFrom(
      this.http.delete<any[]>(`${apiBaseUrl}/${deleteChange.id}`)
    );
  }
  public async postImport(data: any) {
    const apiBaseUrl = this.getApiBaseUrl();
    return await lastValueFrom(
      this.http.post<any>(`${apiBaseUrl}/import-csv`, { data })
    );
  }

  public getApiBaseUrl(): string {
    if (this.paramsService.deviceId) {
      if (this.paramsService.deviceVersionId) {
        return `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/translations`;
      }
    } else {
      return '/api/globals/translations';
    }
  }
}
