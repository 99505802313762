<div>
  <div class="content">
    <div
      class="content-row"
      [style.display]="blockType === 'text' ? 'content' : 'none'"
    >
      <sh-text
        size="header-3"
        [innerHTML]="
          'workflowguides.workflows.workflowVersions.overviews.blocks.types.text'
            | translate
        "
      ></sh-text>
      <sh-input-text
        mandatory
        [disabled]="workflowGuidesService.isReleaseVersionActive()"
        textarea
        [label]="
          'workflowguides.workflows.workflowVersions.overviews.blocks.types.text'
            | translate
        "
        [value]="textTrans"
        (value-changed)="text = $event.target.value"
      ></sh-input-text>
    </div>

    <div [style.display]="blockType === 'image' ? 'content' : 'none'">
      <div class="content-row">
        <sh-text
          [innerHTML]="
            'workflowguides.workflows.workflowVersions.overviews.blocks.types.image'
              | translate
          "
        ></sh-text>
        <sh-input-text
          readonly
          mandatory
          [value]="imageBasename"
          [label]="
            'workflowguides.workflows.workflowVersions.overviews.blocks.types.image'
              | translate
          "
        ></sh-input-text>
        <sh-button
          size="m"
          [label]="'general.openFile' | translate"
          (click)="inputFile.click()"
        ></sh-button>
      </div>
      <div class="content-row">
        <sh-text size="header-3"></sh-text>
        <img *ngIf="image && !imageChanged" [src]="image | s3Image | async" />
        <img #previewImage *ngIf="imageChanged" />
      </div>
    </div>

    <div [style.display]="blockType === 'author' ? 'content' : 'none'">
      <sh-table>
        <sh-table-row>
          <sh-table-head
            unsortable
            columns="6"
            [innerHTML]="
              'workflowguides.workflows.workflowVersions.overviews.blocks.authors.name'
                | translate
            "
          ></sh-table-head>
          <sh-table-head
            unsortable
            columns="10"
            [innerHTML]="
              'workflowguides.workflows.workflowVersions.overviews.blocks.authors.description'
                | translate
            "
          ></sh-table-head>
          <sh-table-head
            unsortable
            columns="6"
            [innerHTML]="
              'workflowguides.workflows.workflowVersions.overviews.blocks.authors.portrait'
                | translate
            "
          ></sh-table-head>
          <sh-table-head unsortable columns="2"></sh-table-head>
        </sh-table-row>
        <sh-table-row *ngFor="let author of authors">
          <sh-table-cell columns="6" [routerLink]="author.id">
            <p>
              {{ author.name | getLocaleText : globalLanguage?.key : true }}
            </p>
          </sh-table-cell>
          <sh-table-cell columns="10" [routerLink]="author.id">
            <p *ngIf="author.description">
              {{
                author.description | getLocaleText : globalLanguage?.key : true
              }}
            </p>
          </sh-table-cell>
          <sh-table-cell columns="6" [routerLink]="author.id">
            <img
              *ngIf="author.portrait"
              [src]="author.portrait | s3Image | async"
              class="thumbnailImg"
            />
          </sh-table-cell>
          <sh-table-cell columns="2">
            <sh-icon
              button
              *ngIf="!workflowGuidesService.isReleaseVersionActive()"
              icon="delete"
              (click)="deleteAuthor(author)"
            ></sh-icon>
          </sh-table-cell>
        </sh-table-row>
      </sh-table>
      <sh-button
        class="add-author-button"
        *ngIf="!workflowGuidesService.isReleaseVersionActive()"
        size="m"
        (click)="addAuthor()"
        [label]="'general.add' | translate"
      ></sh-button>
    </div>

    <div class="buttons">
      <sh-button
        *ngIf="!workflowGuidesService.isReleaseVersionActive()"
        [disabled]="!canSave()"
        size="m"
        (click)="save()"
        [label]="'general.save' | translate"
      ></sh-button>
      <sh-button
        *ngIf="!workflowGuidesService.isReleaseVersionActive()"
        color="secondary"
        (click)="cancel()"
        size="m"
        [label]="'general.cancel' | translate"
      ></sh-button>
    </div>
  </div>
</div>

<input
  #inputFile
  type="file"
  accept=".jpg,.jpeg,.png"
  style="display: none"
  (change)="fileChange($event.target['files'][0])"
/>
