<sh-search
  size="body-2"
  class="search-box-hotspot"
  [label]="'general.search' | translate"
  [value]="searchHotspotString"
  (value-changed)="onSearchHotspotString($event.target.value)"
></sh-search>
<div style="padding: 10px">
  <sh-menu-item
    style="max-height: fit-content"
    [label]="'Information (' + informations.length + ')'"
    [expanded]="true"
    icon="url('/assets/icons/hotspot-info.svg')"
    icon-size="s"
  >
    <sh-icon
      *ngIf="!devicesService.isReleaseVersionActive() && addEditEnabled"
      slot="functions"
      button
      size="s"
      icon="plus"
      [routerLink]="['./informations/add']"
    ></sh-icon>
    <sh-menu-item
      *ngFor="let information of displayedInformations"
      [label]="information.title"
      (click)="onHotspotSelection(information)"
      [active]="computedHotspotId === information.computedId"
      ><sh-icon
        *ngIf="!devicesService.isReleaseVersionActive() && addEditEnabled"
        slot="functions"
        button
        size="s"
        icon="delete"
        (click)="deleteInformationDetail(information.id, information.title, $event)"
      ></sh-icon
      ><sh-icon
        *ngIf="!devicesService.isReleaseVersionActive() && addEditEnabled"
        slot="functions"
        button
        size="s"
        icon="edit"
        [routerLink]="['./informations', information.id, 'edit']"
      ></sh-icon
    ></sh-menu-item>
  </sh-menu-item>
  <sh-menu-item
    style="max-height: fit-content"
    [label]="'Switch (' + switchToScreens.length + ')'"
    [expanded]="false"
    icon="url('/assets/icons/hotspot-level-down.svg')"
    icon-size="s"
  >
    <sh-icon
      *ngIf="!devicesService.isReleaseVersionActive() && addEditEnabled"
      slot="functions"
      button
      size="s"
      icon="plus"
      [routerLink]="['./switchscreens/add']"
    ></sh-icon>
    <sh-menu-item
      *ngFor="let switchToScreen of displayedSwitchToScreens"
      [label]="switchToScreen.title"
      (click)="onHotspotSelection(switchToScreen)"
      [active]="computedHotspotId === switchToScreen.computedId"
      ><sh-icon
        *ngIf="!devicesService.isReleaseVersionActive() && addEditEnabled"
        slot="functions"
        button
        size="s"
        icon="delete"
        (click)="
          deleteSwitchScreenDetail(switchToScreen.id, switchToScreen.title, $event)
        "
      ></sh-icon
      ><sh-icon
        *ngIf="!devicesService.isReleaseVersionActive() && addEditEnabled"
        slot="functions"
        button
        size="s"
        icon="edit"
        [routerLink]="['./switchscreens', switchToScreen.id, 'edit']"
      ></sh-icon
    ></sh-menu-item>
  </sh-menu-item>
  <sh-menu-item
    style="max-height: fit-content"
    [label]="'Back (' + backToScreens.length + ')'"
    [expanded]="false"
    icon="url('/assets/icons/hotspot-level-up.svg')"
    icon-size="s"
  >
    <sh-icon
      *ngIf="!devicesService.isReleaseVersionActive() && addEditEnabled"
      slot="functions"
      button
      size="s"
      icon="plus"
      [routerLink]="['./backs/add']"
    ></sh-icon>
    <sh-menu-item
      *ngFor="let backToScreen of displayedBackToScreens"
      [label]="backToScreen.title"
      (click)="onHotspotSelection(backToScreen)"
      [active]="computedHotspotId === backToScreen.computedId"
      ><sh-icon
        *ngIf="!devicesService.isReleaseVersionActive() && addEditEnabled"
        slot="functions"
        button
        size="s"
        icon="delete"
        (click)="deleteBackDetail(backToScreen.id, backToScreen.title, $event)"
      ></sh-icon
      ><sh-icon
        *ngIf="!devicesService.isReleaseVersionActive() && addEditEnabled"
        slot="functions"
        button
        size="s"
        icon="edit"
        [routerLink]="['./backs', backToScreen.id, 'edit']"
      ></sh-icon
    ></sh-menu-item>
  </sh-menu-item>
</div>
