<sh-modal
  id="footer-modal"
  sticky
  modal-height="fit-content"
  [visible]="visible"
  [label]="'localization.addLang' | translate"
>
  <sh-text
    style="margin-bottom: 2px"
    [innerText]="'language.info' | translate"
  ></sh-text>
  <sh-text
    style="margin-top: 0px; margin-bottom: 16px"
    size="body-2"
    [innerText]="'localization.enterNewKey' | translate"
  ></sh-text>

  <sh-input-text
    #keyTextbox
    mandatory
    [label]="'localization.langKey' | translate"
    [value]="key"
    (value-changed)="key = $event.target.value"
  ></sh-input-text>
  <sh-input-text
    #valueTextbox
    mandatory
    [label]="'localization.langName' | translate"
    [value]="valueData"
    (value-changed)="valueData = $event.target.value"
  ></sh-input-text>
  <sh-button
    slot="footer"
    size="m"
    color="secondary"
    [label]="'general.cancel' | translate"
    (click)="cancel()"
  ></sh-button>

  <sh-button
    slot="footer"
    size="m"
    [label]="'general.ok' | translate"
    [disabled]="!keyTextbox.value"
    (click)="close()"
  ></sh-button>
</sh-modal>
