import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { ScreensService } from '../../screens.service';
import { ParamsService } from '../../../../../../../services/params.service';
import { HotspotsService } from '../../hotspots.service';
import { DetailsService } from '../../details.service';
import { ImgConvertorComponent } from '@backend/webapp/img-convertor/img-convertor.component';

@Component({
  selector: 'backend-edit-screen',
  templateUrl: './edit-screen.component.html',
  styleUrls: ['./edit-screen.component.scss'],
})
export class EditScreenComponent implements OnInit {
  @ViewChild('inputFile')
  public inputFile: ElementRef<HTMLInputElement>;

  @ViewChild('previewImage')
  public previewImage: ElementRef<HTMLImageElement>;

  public isEditMode = false;

  public file: string;
  public fileBasename: string;
  public fileChanged = false;

  public key: string = null;
  private imgConvertor: ImgConvertorComponent;

  public constructor(
    private router: Router,
    private paramsService: ParamsService,
    private hotspotsService: HotspotsService,
    private screensService: ScreensService,
    private detailsService: DetailsService,
    private renderer: Renderer2
  ) {
    this.imgConvertor = new ImgConvertorComponent();
  }

  public async ngOnInit(): Promise<void> {
    await this.screensService.updateScreen();
    this.updateFields();
  }

  public fileChange(file: File): void {
    if (file) {
      this.file = file.name;
      this.fileBasename = file.name.replace(/.*[\/\\]/, '');
      this.fileChanged = true;

      const reader = new FileReader();
      reader.onloadend = (e) => {
        let image = new Image();
        this.renderer.setAttribute(
          this.previewImage.nativeElement,
          'src',
          e.target.result.toString()
        );
        image.src = e.target.result.toString();
        image.onload = () => {
          this.imgConvertor.drawImageOnCanvas(image, this.fileBasename);
        };
      };
      reader.readAsDataURL(file);
    } else {
      this.file = '';
      this.fileBasename = '';
    }
  }

  private async updateFields(): Promise<void> {
    const screen = await this.screensService.getScreen();
    if (screen) {
      this.key = screen.key;
      this.file = screen.image;
      if (this.file) {
        this.fileBasename = this.file.replace(/.*[\/\\]/, '');
      } else {
        this.fileBasename = '';
      }
      this.isEditMode = true;
    } else {
      this.resetFields();
      this.isEditMode = false;
    }
  }

  private resetFields(): void {
    this.key = null;
    this.file = '';
    this.fileBasename = '';
    this.fileChanged = false;
  }

  public selectFile(): void {
    this.inputFile.nativeElement.click();
  }

  public async save(): Promise<void> {
    const file = this.fileChanged ? this.imgConvertor.getImageFile() : null;
    let result: boolean;
    if (!this.isEditMode) {
      result = await this.screensService.createScreen(this.key, file);
    } else {
      result = await this.screensService.modifyScreen(
        this.paramsService.screenId,
        this.key,
        file
      );
    }

    await this.hotspotsService.updateHotspots();
    await this.hotspotsService.updateHotspot();
    await this.screensService.updateScreens();
    await this.screensService.updateScreen();
    await this.detailsService.updateDetails();
    await this.detailsService.updateDetail();

    if (result) {
      this.gotoHotspots();
    }
  }

  public cancel(): void {
    this.screensService.lastModifiedScreenId = this.paramsService.screenId;
    this.gotoHotspots();
  }

  public gotoHotspots(): void {
    this.router.navigateByUrl(
      '/devices/' +
        this.paramsService.deviceId +
        '/versions/' +
        this.paramsService.deviceVersionId +
        '/hotspots/' +
        this.paramsService.hotspotId +
        '/screens'
    );
  }
}
