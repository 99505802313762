<sh-card style="height: calc(100% - 20px)">
  <sh-text
    size="header-1"
    slot="header"
    [innerText]="'auth.userGuide' | translate"
  ></sh-text>
  <sh-grid
    *ngIf="userGuide"
    style="height: 100%; margin-top: 1rem !important"
    spacing="s"
  >
    <sh-card
      style="border-top: 1px solid rgba(var(--ui-1), var(--opacity-6))"
      columns="3"
    >
      <sh-text slot="header" size="title-1">Contents</sh-text>
      <sh-menu-item
        *ngFor="let section of userGuide"
        [label]="section.title"
        (click)="selected = section"
        [active]="selected === section"
      ></sh-menu-item>
    </sh-card>
    <sh-card
      style="border-top: 1px solid rgba(var(--ui-1), var(--opacity-6))"
      columns="9"
    >
      <ng-container>
        <sh-text slot="header" size="title-1">{{ selected?.title }}</sh-text>
        <sh-text size="title-2">{{ selected?.subhead }}</sh-text>
        <sh-text size="body">{{ selected?.contents }}</sh-text>
        <div style="display: flex; justify-content: center; margin-top: 20px">
          <img [src]="selected?.image" alt="" />
        </div>
      </ng-container>
    </sh-card>
  </sh-grid>
</sh-card>
