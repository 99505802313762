<sh-card>
  <sh-text
    size="header-1">
  {{ 'header.tabs.adminusers' | translate }}
  {{ getallAdminUsersCount() }}
</sh-text>
  <sh-table (scroll-end)="onScrollEnd()">
    <sh-table-row slot="header">
      <sh-table-head
        unsortable
        columns="6"
        [innerText]="'adminusers.siemensId' | translate"
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="12"
        [innerText]="'adminusers.name' | translate"
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="6"
        [innerText]="'adminusers.role' | translate"
      ></sh-table-head>
      <sh-table-head unsortable icon columns="1"></sh-table-head>
    </sh-table-row>
   
      <sh-table-row *ngFor="let user of displayedAdminUsers">
        <sh-table-cell columns="6" [innerText]="user.name"></sh-table-cell>
        <sh-table-cell
          columns="12"
          [innerText]="user.firstName + ' ' + user.lastName"
        ></sh-table-cell>
        <sh-table-cell
          columns="6"
          *ngIf="user.role === 0"
          [innerText]="'No Access'"
        ></sh-table-cell>
        <sh-table-cell
          columns="6"
          *ngIf="user.role === 1"
          [innerText]="'Content Admin'"
        ></sh-table-cell>
        <sh-table-cell
          columns="6"
          *ngIf="user.role === 2"
          [innerText]="'Super Admin'"
        ></sh-table-cell>
        <sh-table-cell icon columns="1">
          <sh-icon
            button
            icon="more"
            [id]="'adminusers-menu-' + user.id"
          ></sh-icon>
        </sh-table-cell>
        <sh-popover
          #popover
          [target]="'adminusers-menu-' + user.id"
          position="left-up"
        >
          <sh-menu-item
            icon="edit"
            [label]="'general.edit' | translate"
            (click)="edit(user.id)"
          ></sh-menu-item>
          <sh-menu-item
            icon="delete"
            [label]="'general.delete' | translate"
            (click)="delete(user.id)"
          ></sh-menu-item>
        </sh-popover>
      </sh-table-row>

    <sh-table-row
        *ngIf="adminUserService.adminUsers != null && displayedAdminUsers.length == 0"
      >
        <sh-table-cell columns="11"></sh-table-cell>
        <sh-table-cell
          columns="13"
          [innerText]="'adminusers.empty' | translate"
        ></sh-table-cell>
      </sh-table-row>
  </sh-table>
  <sh-button
    slot="footer"
    size="m"
    [label]="'general.add' | translate"
    (click)="onAddClick()"
  ></sh-button>
</sh-card>

<backend-add-admin-user #addAdminUser></backend-add-admin-user>
