import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PhaseVersionService } from '../../phases/phase-versions/phase-version.service';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { ParamsService } from '../../../services/params.service';
import { ConfirmationService } from '@backend/webapp/shared/confirmation/confirmation.service';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';
import { NotificationService } from '@backend/webapp/shared/notification/notification.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ReportService } from '@backend/webapp/shared/report/report.service';
import { ReportType } from '@backend/webapp/shared/report/report-type.enum';
import { WorkflowGuidesService } from '../../workflowguides.service';
import { Workflow } from '@backend/api/WorkflowGuides/Workflow/workflow.model';

const apiRoute = (workflowId) =>
  `/api/workflowguides/workflows/${workflowId}/workflowversions/`;

@Injectable({
  providedIn: 'root',
})
export class WorkflowVersionService {
  private workflowVersionsSubject: BehaviorSubject<any[]> = new BehaviorSubject<
    any[]
  >([]);

  public get workflowVersions(): Observable<any[]> {
    return this.workflowVersionsSubject.asObservable();
  }

  public constructor(
    private readonly http: HttpClient,
    private readonly paramsService: ParamsService,
    private confirmationService: ConfirmationService,
    private spinner: SpinnerService,
    private notificationService: NotificationService,
    private reportService: ReportService,
    private linksService: WorkflowGuidesService,
    private phaseVersionService: PhaseVersionService
  ) {}

  public async getWorkflowVersions() {
    this.spinner.showSpinner();
    this.workflowVersionsSubject.next([]);
    await this.loadWorkflowVersions();
    this.spinner.hideSpinner();
    return this.workflowVersionsSubject.asObservable();
  }

  public async createWorkflowVersion(workflowVersion: any) {
    let query = {},
      showReport = false;
    if (
      workflowVersion.copyFrom !== null &&
      workflowVersion.copyFrom > 0
    ) {
      query = { copyVersion: workflowVersion.copyFrom };
      showReport = true;
    }

    let newworkflowId = workflowVersion.newWorkflowId ? workflowVersion.newWorkflowId : this.paramsService.workflowId;
    let result = null;
    if (showReport && result === null) {
      this.reportService.progress(null, null, null, ReportType.Progress);
    }
    try {
      result = await lastValueFrom(
        this.http.post<any>(
          apiRoute(newworkflowId),
          {
            status: workflowVersion.status,
            deviceVersion: workflowVersion.deviceVersion,
            arSupport : workflowVersion.ar
          },
          { params: query }
        )
      );
    } catch (error) {
      this.reportService.close();
      this.notificationService.error(
        'general.error',
        'Error in creating workflow version!',
        error.error.message
      );
    }
    this.reportService.close();
    if (showReport && result && result.report) {
      this.reportService.confirm(
        'Workflow Version: ' + workflowVersion.name + ' Copied!',
        result.report,
        result.errors,
        ReportType.Success
      );
    }
    return result;
  }

  public async deleteWorkflowVersion(id: number): Promise<boolean> {
    this.paramsService.isLoading = true;
    try {
        await lastValueFrom(
          this.http.delete(
            '/api/workflowguides/workflows/' +
              this.paramsService.workflowId +
              '/workflowversions/' +
              id
          )
        );
        this.notificationService.confirmation('general.success', 'workflowguides.workflows.workflowVersions.deleteSuccess', null);
        return true;
      } catch (e) {
        this.notificationService.error(
          'general.error',
          'workflowguides.workflows.workflowVersions.deleteError'
        );
        return false;
      } finally {
        this.paramsService.isLoading = false;
    }
  }

  public async editWorkflowVersion(workflowVersion: any): Promise<any> {
    this.spinner.showSpinner();
    try {
      const result = await lastValueFrom(
        this.http.put(apiRoute(this.paramsService.workflowId) + workflowVersion.id,
          {
            deviceVersion: workflowVersion.deviceVersion,
            status: workflowVersion.status,
            arSupport: workflowVersion.arSupport
          })
      );
      this.notificationService.confirmation(
        'general.success',
        'workflowguides.workflows.workflowVersions.modifySuccess'
      );
      return result;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'workflowguides.workflows.workflowVersions..modifyError',
        error.error.message
      );
      return false;
    } finally {
      await this.loadWorkflowVersions();
      this.spinner.hideSpinner();
    }
  }

  public async getWorkflowVersion(
    id: number = this.paramsService.workflowVersionId
  ) {
    if (this.paramsService.workflowId === null || id === null) {
      return;
    }
    this.paramsService.isLoading = true;
    const result = await lastValueFrom(
      this.http.get(
        '/api/workflowguides/workflows/' +
          this.paramsService.workflowId +
          '/workflowversions/' +
          id
      )
    );
    this.paramsService.isLoading = false;
    return result;
  }

  public async loadWorkflowVersions() {
    this.spinner.showSpinner();
    if (this.paramsService.workflowId === null) {
      return;
    }
    this.paramsService.isLoading = true;
    this.workflowVersionsSubject.next(
      await lastValueFrom(
        this.http.get<any[]>(
          '/api/workflowguides/workflows/' +
            this.paramsService.workflowId +
            '/workflowversions'
        )
      )
    );
    this.paramsService.isLoading = false;
    await this.linksService.loadWfLinks();
    this.spinner.hideSpinner();
  }

  public async getAllDeviceVersionsIds() {
    this.spinner.showSpinner();
    this.paramsService.isLoading = true;
    const associatedDeviceVersionIds = await lastValueFrom(
      this.http.get<any[]>(
        '/api/workflowguides/workflows/' +
          this.paramsService.workflowId +
          '/workflowversions/allDeviceVersionIds'
      )
    );
    this.paramsService.isLoading = false;
    this.spinner.hideSpinner();
    return associatedDeviceVersionIds;
  }
  async removePhaseVersionToCurrent(id: number): Promise<any> {
    this.spinner.showSpinner();
    this.paramsService.isLoading = true;
    const result = await lastValueFrom(
      this.http.put<any>(
        '/api/workflowguides/workflows/' +
          this.paramsService.workflowId +
          '/workflowversions/' +
          this.paramsService.workflowVersionId +
          '/removePhase/',
        { phaseId: id }
      )
    );
    this.paramsService.isLoading = false;
    if(result){
      await this.phaseVersionService.deletePhaseVersion(id);
    }
    await this.linksService.loadWfLinks();
    this.spinner.hideSpinner();
    return result;
  }

  async addPhaseVersionToCurrent(id: number): Promise<void> {
    this.paramsService.isLoading = true;
    const result = lastValueFrom(
      this.http.put<void>(
        '/api/workflowguides/workflows/' +
          this.paramsService.workflowId +
          '/workflowversions/' +
          this.paramsService.workflowVersionId +
          '/addPhase/',
        { phaseId: id }
      )
    );
    this.paramsService.isLoading = false;
    return result;
  }

  public async orderPhaseActivities(
    phasesActivities: any[],
    workkflowVersionId: number,
    previousIndex: number,
    currentIndex: number
  ): Promise<any> {
    if (
      await this.confirmationService.confirm(
        'workflowguides.phases.phaseWrappers.rearrangePhaseActivities',
        'workflowguides.phases.phaseWrappers.rearrangePhaseActivitiesConfirmation'
      )
    ) {
      this.spinner.showSpinner();
      try {
        moveItemInArray(phasesActivities, previousIndex, currentIndex);
        await lastValueFrom(
          this.http.put(
            '/api/workflowguides/phases/order/phasesActivities',
            {
              phasesActivities,
              workkflowVersionId,
            }
          )
        );
        this.notificationService.confirmation(
          'general.success',
          'workflowguides.phases.phaseWrappers.rearrangeSuccess'
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'workflowguides.phases.phaseWrappers.rearrangeError',
          error.error.message
        );
        return false;
      } finally {
        this.spinner.hideSpinner();
      }
    }
  }

  public async addWorkflowToPackageUsers(workflowId: number): Promise<void>{
    try {
      this.spinner.showSpinner();
      const workflowDetails = await lastValueFrom(
        this.http.get<Workflow>(`/api/workflowguides/workflows/${workflowId}`)
      );
      if (workflowDetails) {
        const packageId = await lastValueFrom(
          this.http.get<number>(`/api/package/packageIdByBusinessLine/${workflowDetails.businessLine}`)
        );
        if (packageId) {
          await lastValueFrom(
            this.http.post(`/api/package/addWorkflowToPackageUsers/${packageId}`, {})
          );
        }
      }
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.addWorkflowToPackageUsersError',
        null,
        error.error.message
      );
    } finally {
      this.spinner.hideSpinner();
    }
  }

  public async removeWorkflowFromPackageUsers(workflow: Workflow): Promise<void>{
    try {
      this.spinner.showSpinner();
      //Remove workflow from pacakge users need only for released workflows.
      if (!workflow.workflowReleasedVersion) {
        return;
      }
      const packageId = await lastValueFrom(
        this.http.get<number>(`/api/package/packageIdByBusinessLine/${workflow.businessLine}`)
      );
      if (packageId) {
        await lastValueFrom(
          this.http.post(`/api/package/removeWorkflowFromPackageUsers/${packageId}`, { workflow })
        );
      }    
    }catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.removeWorkflowToPackageUsersError',
        null,
        error.error.message
      );
    } finally {
      this.spinner.hideSpinner();
    }
  }
}
