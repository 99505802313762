import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router } from '@angular/router';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { LanguageService } from '@backend/webapp/services/language.service';
import { Constants } from '@backend/interfaces';
import { Subscription } from 'rxjs';
import { ParamsService } from '@backend/webapp/services/params.service';
import { HotspotsService } from '../../hotspots.service';
import { DetailsService } from '../../details.service';
import { ScreensService } from '../../screens.service';
import { DetailType } from '../../detail-type.enum';
import { DevicesService } from '@backend/webapp/devices/devices.service';

@Component({
  selector: 'backend-hotspots-right-pane',
  templateUrl: './hotspots-right-pane.component.html',
  styleUrls: ['./hotspots-right-pane.component.scss'],
})
export class HotspotsRightPaneComponent implements OnInit, OnChanges {
  @Input() selectedScreen: any;
  @Input() addEditEnabled: boolean = false;
  @Input() computedHotspotId: string;

  @Output() hotspotDeleted = new EventEmitter<boolean>();
  @Output() hotspotSelected = new EventEmitter<any>();

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;

  languageSubscription: Subscription;
  searchHotspotString = '';
  informations: any[] = [];
  displayedInformations: any[] = [];
  backToScreens: any[] = [];
  displayedBackToScreens: any[] = [];
  switchToScreens: any[] = [];
  displayedSwitchToScreens: any[] = [];

  public constructor(
    public router: Router,
    public localization: LocalizationService,
    public languageService: LanguageService,
    public paramsService: ParamsService,
    public hotspotsService: HotspotsService,
    public detailsService: DetailsService,
    public screensService: ScreensService,
    public devicesService: DevicesService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
        }
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedScreen && changes.selectedScreen.currentValue) {
      this.selectedScreen = changes.selectedScreen.currentValue;
      this.searchHotspotString = '';
      this.onScreenSelectionChange();
    }
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  public onHotspotSelection(selectedHotspot) {
    this.computedHotspotId = selectedHotspot.computedId;
    this.hotspotSelected.emit(selectedHotspot);
  }

  public onSearchHotspotString(searchedString: string): void {
    this.searchHotspotString = searchedString;
    this.searchHotspots();
  }

  public async deleteInformationDetail(detailId, detail, event): Promise<void> {
    event.stopPropagation();
    await this.detailsService.deleteInformationDetail(
      this.paramsService.hotspotId,
      this.paramsService.screenId,
      detailId,
      detail
    );
    await this.updateAll();
    this.hotspotDeleted.emit(true);
  }

  public async deleteSwitchScreenDetail(detailId, detail, event): Promise<void> {
    event.stopPropagation();
    await this.detailsService.deleteSwitchScreenDetail(
      this.paramsService.hotspotId,
      this.paramsService.screenId,
      detailId,
      detail
    );
    await this.updateAll();
    this.hotspotDeleted.emit(true);
  }

  public async deleteBackDetail(detailId, detail, event): Promise<void> {
    event.stopPropagation();
    await this.detailsService.deleteBackDetail(
      this.paramsService.hotspotId,
      this.paramsService.screenId,
      detailId,
      detail
    );
    await this.updateAll();
    this.hotspotDeleted.emit(true);
  }

  private async updateAll(): Promise<void> {
    await this.hotspotsService.updateHotspots();
    await this.hotspotsService.updateHotspot();
    await this.screensService.updateScreens();
    await this.screensService.updateScreen();
    await this.detailsService.updateDetails();
    await this.detailsService.updateDetail();
  }

  private getTranslation(translations: any[]): string {
    const translation = this.languageService.getDefaultTranslationOrByKey(
      translations,
      this.globalLanguage
        ? this.globalLanguage.key
        : Constants.DEFAULT_GLOBAL_LANGUAGE.key
    );

    return translation;
  }

  onScreenSelectionChange(): void {
    this.resetScreenHotspots();
    if (this.selectedScreen?.informations?.length) {
      this.selectedScreen.informations.forEach((information) => {
        this.informations.push({
          hotspot: information,
          detailType: DetailType.Information,
          title: this.getTranslation(information.title),
          id: information.id,
          computedId: `${DetailType.Information}-${information.id}`,
        });
      });
      this.displayedInformations = this.informations;
    }

    if (this.selectedScreen?.backToScreens?.length) {
      this.selectedScreen.backToScreens.forEach((backToScreen) => {
        this.backToScreens.push({
          hotspot: backToScreen,
          detailType: DetailType.Back,
          title: backToScreen.backToScreenId,
          id: backToScreen.id,
          computedId: `${DetailType.Back}-${backToScreen.id}`,
        });
      });
      this.displayedBackToScreens = this.backToScreens;
    }

    if (this.selectedScreen?.switchToScreens?.length) {
      this.selectedScreen.switchToScreens.forEach((switchToScreen) => {
        this.switchToScreens.push({
          hotspot: switchToScreen,
          detailType: DetailType.SwitchScreen,
          title: this.getTranslation(switchToScreen.title),
          id: switchToScreen.id,
          computedId: `${DetailType.SwitchScreen}-${switchToScreen.id}`,
        });
      });
      this.displayedSwitchToScreens = this.switchToScreens;
    }
  }

  private resetScreenHotspots(): void {
    this.informations = [];
    this.displayedInformations = [];
    this.backToScreens = [];
    this.displayedBackToScreens = [];
    this.switchToScreens = [];
    this.displayedSwitchToScreens = [];
  }

  private searchHotspots(): void {
    if (!this.searchHotspotString) {
      this.displayedBackToScreens = this.backToScreens;
      this.displayedInformations = this.informations;
      this.displayedSwitchToScreens = this.switchToScreens;
    } else {
      const searchText = this.searchHotspotString.toLocaleLowerCase();
      this.displayedBackToScreens = this.backToScreens.filter((backToScreen) =>
        backToScreen.title.toString().toLowerCase().includes(searchText)
      );
      this.displayedInformations = this.informations.filter((information) =>
        information.title.toLocaleLowerCase().includes(searchText)
      );
      this.displayedSwitchToScreens = this.switchToScreens.filter(
        (switchToScreen) =>
          switchToScreen.title.toLocaleLowerCase().includes(searchText)
      );
    }
  }
}
