import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { SpinnerService } from '../../../../../spinner/spinner.service';
import { NotificationService } from '../../../../../shared/notification/notification.service';
import { LanguageService } from '../../../../../services/language.service';
import { ConfirmationService } from '../../../../../shared/confirmation/confirmation.service';
import { ParamsService } from '../../../../../../app/services/params.service';
import { areObjectsIdentical } from '../../../../../tools';
import { Constants } from '@backend/interfaces';
import { ImageOptimizationService } from '@backend/webapp/services/imageoptimization.service';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  private featuresSubject$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  public get features$(): Observable<any[]> {
    return this.featuresSubject$.asObservable();
  }
  public get features(): any[] {
    return this.featuresSubject$.value;
  }

  private featureSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public get feature$(): Observable<any> {
    return this.featureSubject$.asObservable();
  }
  public get feature(): any {
    return this.featureSubject$.value;
  }
  public allFeatures: Array<Array<any>> = [
    [
      {
        value: "System Introduction",
        languageKey: "en",
        languageName: "English"
      },
      {
        value: "系统介绍",
        languageKey: "cn",
        languageName: "Chinese"
      }
    ],
    [
      {
          value: "Simulation",
          languageKey: "en",
          languageName: "English"
      },
      {
          value: "模拟",
          languageKey: "cn",
          languageName: "Chinese"
      }
    ]
  ];

  public localeTextPipe = new GetLocaleTextPipe(this.localization);

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  public constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private paramsService: ParamsService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private languageService: LanguageService,
    private imageOptimizationService: ImageOptimizationService,
    public localization: LocalizationService
  ) {}

  public async updateFeatures(spinner: boolean = false): Promise<boolean> {
    if (spinner) {
      this.spinnerService.showSpinner();
    }
    this.paramsService.updateParams();
    if (
      this.paramsService.deviceId !== null &&
      this.paramsService.deviceVersionId !== null
    ) {
      try {
        const values = await lastValueFrom(
          this.http.get<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/features`
          )
        );
        if (!areObjectsIdentical(this.features, values)) {
          this.featuresSubject$.next(values);
        }
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.features.getError',
          null,
          error.error.message
        );
        return false;
      } finally {
        this.checkIfAllFeaturesAdded();
        this.spinnerService.hideSpinner();
      }
    }

    this.featuresSubject$.next(null);
    this.spinnerService.hideSpinner();
    return false;
  }

  public async updateFeature(): Promise<boolean> {
    this.paramsService.updateParams();
    const featureId = this.paramsService.featureId;
    if (featureId !== null) {
      const feature = await this.getFeature(featureId);
      if (feature && this.feature !== feature) {
        this.featureSubject$.next(feature);
        return true;
      }
    }

    this.featureSubject$.next(null);
    return false;
  }

  public async getFeature(
    featureId: number = this.paramsService.featureId
  ): Promise<any> {
    if (!this.features) {
      const updateSuccess = await this.updateFeatures();
      if (!updateSuccess) {
        return null;
      }
    }

    return this.features.find((a) => a.id === featureId);
  }

  public async createFeature(
    featureName: string,
    featureDescription: string,
    featureAr: boolean,
    image: File
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      const response = await lastValueFrom(
        this.http.post(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/features`,
          {
            deviceId: this.paramsService.deviceId,
            deviceVersionId: this.paramsService.deviceVersionId,
          }
        )
      );
      image = await this.imageOptimizationService.resizeImage(image, 326, 246);
      const featureId = response['id'];
      const formData = new FormData();
      formData.append('ar', featureAr ? 'true' : 'false');
      formData.append('image', image, image.name);
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/features/${featureId}`,
          formData
        )
      );
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/features/${featureId}/name/${this.languageService.guiLanguageCode}`,
          { content: featureName }
        )
      );
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/features/${featureId}/description/${this.languageService.guiLanguageCode}`,
          { content: featureDescription }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.features.createSuccess',
        { featureName }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.features.createError',
        { featureName },
        error.error.message
      );
      return false;
    } finally {
      await this.updateFeatures();
      this.spinnerService.hideSpinner();
    }
  }

  public async modifyFeature(
    featureId: number,
    featureName: string,
    featureDescription: string,
    featureAr: boolean,
    image?: File
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      const formData = new FormData();
      formData.append('ar', featureAr ? 'true' : 'false');
      if (image) {
        image = await this.imageOptimizationService.resizeImage(image, 326, 246);
        formData.append('image', image, image.name);
      }
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/features/${featureId}`,
          formData
        )
      );
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/features/${featureId}/name/${this.languageService.guiLanguageCode}`,
          { content: featureName }
        )
      );
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/features/${featureId}/description/${this.languageService.guiLanguageCode}`,
          { content: featureDescription }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.features.modifySuccess',
        { featureName }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.features.modifyError',
        { featureName },
        error.error.message
      );
      return false;
    } finally {
      await this.updateFeatures();
      this.spinnerService.hideSpinner();
    }
  }

  public async deleteFeature(featureId: number): Promise<boolean> {
    const feature = await this.getFeature(featureId);
    const featureName = this.languageService.getTranslateValue(feature.name);
    if (
      await this.confirmationService.confirmDelete(
        'devices.versions.features.deleteFeature',
        'devices.versions.features.deleteFeatureConfirmation',
        { featureName }
      )
    ) {
      this.spinnerService.showSpinner();
      try {
        await lastValueFrom(
          this.http.delete<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/features/${featureId}`
          )
        );
        this.notificationService.confirmation(
          'general.success',
          'devices.versions.features.deleteSuccess',
          { featureName }
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.features.deleteError',
          { featureName },
          error.error.message
        );
        return false;
      } finally {
        await this.updateFeatures();
        this.spinnerService.hideSpinner();
      }
    }
  }

  public checkIfAllFeaturesAdded(): boolean {
    if (this.features) {
      let allFeaturesAddedForDevice = true;
      let listedFeaturesForDevice = [];
      this.features.forEach((feature) => {
        listedFeaturesForDevice.push(
            this.getTranslatedData(feature.name)
        );
      });

      this.allFeatures.forEach((featureTranslations) => {
        allFeaturesAddedForDevice = allFeaturesAddedForDevice && (listedFeaturesForDevice.includes(featureTranslations[0].value) || listedFeaturesForDevice.includes(featureTranslations[1].value));
      });

      return allFeaturesAddedForDevice;
    }
  }

  public getTranslatedData(data: any) {
    let result = this.localeTextPipe.transform(data, this.globalLanguage?.key, true);
    return result;
  }

  public getCurrentFeatureByName(featurename: string): string {
    let featureTranslations = null;
    this.allFeatures.forEach((translationitem) => {
      translationitem.forEach((item) => {
        if(item.value === featurename) {
          featureTranslations = translationitem;
        }
      });
    });

    return this.getTranslatedData(featureTranslations);
  }
}
