<sh-card>
  <sh-text
    size="header-1"
    [innerText]="'header.tabs.siteplanner' | translate"
  ></sh-text>
  <sh-grid style="margin-top: 20px; max-height: 54px">
    <div columns="7"></div>
    <sh-search
      columns="4"
      style="margin-left: 5px"
      #searchBox
      whole-string
      [label]="'general.search' | translate"
      (search)="onSearch($event.target.value)"
      (clearsearch)="onSearch()"
    ></sh-search>
    <sh-button
      columns="1"
      size="m"
      [label]="'general.search' | translate"
      (click)="onSearch(searchBox.value)"
    ></sh-button>
  </sh-grid>

  <sh-table>
    <sh-table-row slot="header">
      <sh-table-head
        unsortable
        columns="4"
        [innerHTML]="'sites.thumbnail' | translate"
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="7"
        [innerHTML]="'sites.name' | translate"
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="12"
        [innerHTML]="'sites.description' | translate"
      ></sh-table-head>
    </sh-table-row>

    <sh-table-row *ngFor="let site of sites">
      <sh-table-cell columns="4">
        <img
          [src]="
            'data:image/jpeg;base64,' +
            getImageURL(site?.uploadedImageURL | valImage | async)
          "
          alt="../../assets/images/no_img.png"
          onerror='this.src="../../assets/images/no_img.png"'
        />
      </sh-table-cell>

      <sh-table-cell columns="7">
        <p class="flow-text text-center" [innerText]="site?.siteName"></p>
      </sh-table-cell>

      <sh-table-cell columns="12">
        <p class="flow-text" [innerText]="site?.siteDescription"></p>
      </sh-table-cell>
    </sh-table-row>

    <sh-table-row *ngIf="sites?.length === 0">
      <sh-table-cell columns="4"></sh-table-cell>
      <sh-table-cell
        columns="19"
        [innerText]="'sites.empty' | translate"
      ></sh-table-cell>
    </sh-table-row>
  </sh-table>

  <sh-pagination
    [pages]="totalPages"
    [attr.current-page]="currPage"
    (clicked-page-move)="paginate($event?.target?.currentPage, pageLen)"
    (next-page-move)="paginate($event?.target?.currentPage, pageLen)"
    (previous-page-move)="paginate($event?.target?.currentPage, pageLen)"
  >
  </sh-pagination>
</sh-card>
