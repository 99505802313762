import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Block } from '../../../../../../../../api/src/app/WorkflowGuides/Workflow/WorkflowVersion/Overview/block.model';
import { OverviewsService } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/overviews.service';
import { ParamsService } from '@backend/webapp/services/params.service';
import { Overview } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/overview';
import { Router } from '@angular/router';
import { LanguageService } from '@backend/webapp/services/language.service';
import { PopoverService } from '@backend/webapp/services/popover.service';
import { BlocksService } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/blocks/blocks.service';
import { Constants } from '@backend/interfaces';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { pairwise } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';

@Component({
  selector: 'backend-edit-overview',
  templateUrl: './edit-overview.component.html',
  styleUrls: ['./edit-overview.component.scss'],
})
export class EditOverviewComponent implements OnInit, OnDestroy {
  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  public isEditMode: boolean;
  public section: string = 'blocks';

  public id: number;
  public key: string;
  public title: string;
  public blocks: any[];

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  titleTrans: any[];

  paramSubscription: Subscription;
  languageSubscription: Subscription;

  constructor(
    public readonly popoverService: PopoverService,
    private readonly overviewsService: OverviewsService,
    private readonly blocksService: BlocksService,
    private readonly router: Router,
    private readonly languageService: LanguageService,
    private readonly paramsService: ParamsService,
    public readonly localization: LocalizationService,
    public readonly workflowGuidesService: WorkflowGuidesService
  ) {
    this.section = 'blocks';
  }

  ngOnInit(): void {
    this.id = null;
    this.key = null;
    this.title = null;
    this.blocks = [];
    this.section = 'blocks';
    if (!this.paramsService.overviewId) {
      this.section = 'base';
      return;
    }

    this.isEditMode = true;
    this.updateOverview();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          this.updateOverview();
        }
      }
    );
    this.paramSubscription = this.paramsService.overviewId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          this.updateOverview();
        }
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  updateOverview() {
    this.overviewsService
      .getOverview(this.paramsService.overviewId)
      .then((overview) => {
        if (overview) {
          this.id = overview.id;
          this.key = overview.key;
          this.blocks = overview.blocks;
          this.title = this.languageService.getTranslationByKey(
            overview.title,
            this.globalLanguage.key
          );
          this.titleTrans = new GetLocaleTextPipe(this.localization).transform(
            overview.title,
            this.globalLanguage.key
          );
        }
      });
  }

  getBlockTypeKey(block: Block): string {
    const type = this.getBlockType(block);
    return (
      'workflowguides.workflows.workflowVersions.overviews.blocks.types.' + type
    );
  }

  getBlockType(block: Block): string {
    if (block.image) return 'image';
    if (block.text && block.text.length > 0) return 'text';
    return 'author';
  }

  async save(): Promise<void> {
    let overview: Overview;
    if (!this.isEditMode) {
      overview = await this.overviewsService.create(this.key);
    } else {
      overview = await this.overviewsService.edit(this.id, this.key);
    }

    if (this.title)
      await this.overviewsService.editTile(overview.id, this.title);

    this.goBack();
  }

  cancel(): void {
    this.goBack();
  }

  private goBack() {
    this.router
      .navigate([
        'workflowguides',
        'workflows',
        this.paramsService.workflowId,
        'versions',
        this.paramsService.workflowVersionId,
      ])
      .then();
  }

  async delete(block: Block) {
    await this.blocksService.delete(block.id);
    this.updateOverview();
  }

  public async addAuthorBlock() {
    const block = await this.blocksService.create();
    // workflows/:workflowId/versions/:workflowVersionId/overviews/:overviewId/blocks/:blockId/:blockType
    await this.router.navigate([
      'workflowguides',
      'workflows',
      this.paramsService.workflowId,
      'versions',
      this.paramsService.workflowVersionId,
      'overviews',
      this.paramsService.overviewId,
      'blocks',
      block.id,
      'author',
      'edit',
    ]);
  }
}
