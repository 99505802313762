<sh-text
  size="header-2"
  [innerHTML]="
    (isEditMode
      ? 'devices.versions.hotspots.editHotspot'
      : 'devices.versions.hotspots.addHotspot'
    ) | translate
  "
></sh-text>

<div class="content">
  <div class="content-row">
    <sh-text
      size="header-3"
      [innerHTML]="'devices.versions.hotspots.name' | translate"
    ></sh-text>
    <sh-input-text
      #inputName
      mandatory
      [label]="'devices.versions.hotspots.name' | translate"
      [value]="nameTrans"
      (value-changed)="name = $event.target.value"
    ></sh-input-text>
  </div>
  <div class="content-row">
    <sh-text
      size="header-3"
      [innerHTML]="'devices.versions.hotspots.description' | translate"
    ></sh-text>
    <sh-input-text
      #inputDesc
      mandatory
      textarea
      rows="6"
      [label]="'devices.versions.hotspots.description' | translate"
      [value]="descriptionTrans"
      (value-changed)="description = $event.target.value"
    ></sh-input-text>
  </div>
  <div class="content-row">
    <sh-text
      size="header-3"
      [innerHTML]="'devices.versions.hotspots.key' | translate"
    ></sh-text>
    <sh-input-text
      #inputKey
      mandatory
      [label]="'devices.versions.hotspots.key' | translate"
      [value]="key"
      (value-changed)="key = $event.target.value"
    ></sh-input-text>
  </div>
  <div class="content-row">
    <sh-text
      [innerHTML]="'devices.versions.hotspots.file' | translate"
    ></sh-text>
    <sh-input-text
      #inputFileTextbox
      mandatory
      readonly
      [value]="fileBasename"
      [label]="'devices.versions.hotspots.file' | translate"
    ></sh-input-text>
    <sh-button
      size="m"
      [label]="'devices.versions.hotspots.openFile' | translate"
      (click)="selectFile()"
    ></sh-button>
  </div>
  <div class="content-row">
    <sh-text size="header-3"></sh-text>
    <img *ngIf="file && !fileChanged" [src]="file | s3Image | async" />
    <img #previewImage *ngIf="fileChanged" />
  </div>
</div>

<div class="buttons">
  <sh-button
    color="secondary"
    (click)="cancel()"
    size="m"
    [label]="'general.cancel' | translate"
  ></sh-button>
  <sh-button
    [disabled]="
      !inputName.value ||
      !inputDesc.value ||
      !inputKey.value ||
      !inputFileTextbox.value
    "
    size="m"
    (click)="save()"
    [label]="'general.save' | translate"
  ></sh-button>
</div>

<input
  #inputFile
  accept=".jpg,.jpeg,.png"
  type="file"
  style="display: none"
  (change)="fileChange($event.target.files[0])"
/>
