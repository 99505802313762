import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParamsService } from '@backend/webapp/services/params.service';
import { AssetsService } from '@backend/webapp/workflowguides/phases/phase-versions/key-activities/steps/assets/assets.service';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';
import { Subscription } from 'rxjs';
import { pairwise } from 'rxjs/operators';

@Component({
  selector: 'backend-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent implements OnInit, OnDestroy {
  public assets: any[];

  paramSubscription: Subscription;

  constructor(
    private readonly assetService: AssetsService,
    private readonly paramsService: ParamsService,
    public readonly workflowGuidesService: WorkflowGuidesService,
    public router: Router
  ) {}

  public async ngOnInit(): Promise<void> {
    this.assets = await this.assetService.getAll();
    this.paramSubscription = this.paramsService.stepId$
      .pipe(pairwise())
      .subscribe(async ([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          this.assets = await this.assetService.getAll();
        }
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
  }

  async deleteAsset(id: any) {
    await this.assetService.deleteAsset(id);
    this.assets = await this.assetService.getAll();
  }

  getType(asset: any): string {
    if (asset.image) return 'image';
    if (asset.instructions) return 'instructions';
    if (asset.video) return 'video';
    if (asset.simulation) return 'simulation';
    throw new Error('Type not implemented!');
  }
}
