import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from '../../../../../../services/language.service';
import { ParamsService } from '../../../../../../services/params.service';
import { ScreensService } from '../screens.service';
import { DetailsService } from '../details.service';
import { DetailType } from '../detail-type.enum';
import { HotspotsService } from '../hotspots.service';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { Constants } from '@backend/interfaces';
import { Subscription } from 'rxjs';
import { TargetScreenModalComponent } from './modals/target-screen-modal/target-screen-modal.component';

@Component({
  selector: 'backend-edit-details',
  templateUrl: './edit-details.component.html',
  styleUrls: ['./edit-details.component.scss'],
})
export class EditDetailsComponent implements OnInit, OnDestroy {
  public isEditMode = false;

  @ViewChild('targetScreenModal')
  public targetScreenModalComponent: TargetScreenModalComponent;

  public type: DetailType = null;
  public title = '';
  public text = '';
  public x: number = 0;
  public y: number = 1;
  public targetName = '';

  public get isInformationType(): boolean {
    return this.type === DetailType.Information;
  }

  public get isSwitchScreenType(): boolean {
    return this.type === DetailType.SwitchScreen;
  }

  public get isBackType(): boolean {
    return this.type === DetailType.Back;
  }

  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;
  titleTrans: any[];
  textTrans: any[];

  languageSubscription: Subscription;

  public constructor(
    public languageService: LanguageService,
    public screensService: ScreensService,
    public hotspotsService: HotspotsService,
    public screenService: ScreensService,
    public detailsService: DetailsService,
    private router: Router,
    private paramsService: ParamsService,
    private cd: ChangeDetectorRef,
    public localization: LocalizationService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.paramsService.updateParams();
    await this.detailsService.updateDetails();
    await this.detailsService.updateDetail();
    await this.updateFields();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      async (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          await this.updateFields();
        }
      }
    );
  }

  ngOnDestroy() {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  public onScreenSelected(screenKey: string): void {
    this.targetName = screenKey;
  }

  private async updateFields(): Promise<void> {
    const detail = await this.detailsService.getDetail();
    if (detail) {
      this.isEditMode = true;
      this.title = this.languageService.getTranslationByKey(
        detail.title,
        this.globalLanguage.key
      );
      this.titleTrans = new GetLocaleTextPipe(this.localization).transform(
        detail.title,
        this.globalLanguage.key
      );
      this.text = this.languageService.getTranslationByKey(
        detail.text,
        this.globalLanguage.key
      );
      this.textTrans = new GetLocaleTextPipe(this.localization).transform(
        detail.text,
        this.globalLanguage.key
      );
      this.type = this.paramsService.detailType as DetailType;
      this.x = detail.x;
      this.y = detail.y;
      const targetId = detail.switchToScreenId
        ? detail.switchToScreenId
        : detail.backToScreenId;
      const target = await this.screensService.getScreen(targetId);
      if (target) {
        this.targetName = target.key;
      } else {
        this.targetName = '';
      }
    } else {
      this.resetFields();
      this.isEditMode = false;
    }
  }

  private resetFields(): void {
    this.title = '';
    this.text = '';
    this.type = this.paramsService.detailType as DetailType;
    this.x = 0;
    this.y = 1;
    this.targetName = '';
    this.cd.detectChanges();
  }

  public async save(): Promise<boolean> {
    let result: boolean;
    const type = this.paramsService.detailType as DetailType;
    const targetId = this.screensService.screens.find(
      (s) => s.key === this.targetName
    );
    if (type !== DetailType.Information && targetId === undefined) {
      return false;
    }

    switch (type) {
      case DetailType.Information:
        if (!this.isEditMode) {
          result = await this.detailsService.createInformationDetail(
            this.x,
            this.y,
            this.title,
            this.text
          );
        } else {
          result = await this.detailsService.modifyInformationDetail(
            this.paramsService.detailId,
            this.x,
            this.y,
            this.title,
            this.text
          );
        }
        break;
      case DetailType.SwitchScreen:
        if (!this.isEditMode) {
          result = await this.detailsService.createSwitchScreenDetail(
            this.x,
            this.y,
            targetId,
            this.title,
            this.text
          );
        } else {
          result = await this.detailsService.modifySwitchScreenDetail(
            this.paramsService.detailId,
            this.x,
            this.y,
            targetId,
            this.title,
            this.text
          );
        }
        break;
      case DetailType.Back:
        if (!this.isEditMode) {
          result = await this.detailsService.createBackDetail(
            this.x,
            this.y,
            targetId
          );
        } else {
          result = await this.detailsService.modifyBackDetail(
            this.paramsService.detailId,
            this.x,
            this.y,
            targetId
          );
        }
        break;
    }

    await this.hotspotsService.updateHotspots();
    await this.hotspotsService.updateHotspot();
    await this.screensService.updateScreens();
    await this.screensService.updateScreen();
    await this.detailsService.updateDetails();
    await this.detailsService.updateDetail();

    if (result) {
      await this.gotoHotspots();
    }
  }

  public cancel(): void {
    this.hotspotsService.editedHotspot = this.paramsService.hotspotId;
    this.screensService.lastModifiedScreenId = this.paramsService.screenId;
    this.gotoHotspots();
  }

  public gotoHotspots(): void {
    this.hotspotsService.editedHotspot = this.paramsService.hotspotId;
    this.router.navigate([
      'devices',
      this.paramsService.deviceId,
      'versions',
      this.paramsService.deviceVersionId,
      'hotspots',
      this.paramsService.hotspotId,
      'screens',
      this.paramsService.screenId,
      'details',
    ]);
  }

  openTargetScreenModal(screens: any[]) {
    this.targetScreenModalComponent.open = true;
    this.targetScreenModalComponent.screens = screens;
    this.targetScreenModalComponent.displayedScreens = screens;
  }
}
