import { Component, OnInit } from '@angular/core';
import { DevicesService } from '../../../../devices.service';
import { DeviceVersionsService } from '../../../device-versions.service';

@Component({
  selector: 'backend-device-localization',
  templateUrl: './device-localization.component.html',
  styleUrls: ['./device-localization.component.scss'],
})
export class DeviceLocalizationComponent implements OnInit {
  public constructor(
    public devicesService: DevicesService,
    public deviceVersionsService: DeviceVersionsService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.deviceVersionsService.updateDeviceVersion();
  }
}
