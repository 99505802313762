import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';

import { PhaseService } from './phase.service';
import { LanguageService } from '../../services/language.service';
import { PopoverService } from '../../services/popover.service';
import { WorkflowGuidesService } from '../workflowguides.service';

@Component({
  selector: 'backend-phases',
  templateUrl: './phases.component.html',
  styleUrls: ['./phases.component.scss'],
})
export class PhasesComponent {
  public phaseWrappers = this.phaseService.phasen;

  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  public constructor(
    public readonly languageService: LanguageService,
    public readonly popoverService: PopoverService,
    public readonly phaseService: PhaseService,
    public readonly router: Router,
    public readonly linksService: WorkflowGuidesService
  ) {}

  public deletePhaseWrapper(id: number, key) {
    this.phaseService.deletePhaseWrapper(id, key);
  }
}
