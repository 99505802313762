export abstract class Constants {
  static readonly ACTIONS = ['All', 'GET', 'POST', 'PUT', 'DELETE'];
  static readonly PERIOD = ['All', 'Today', 'Current Year', 'Custom'];
  static readonly DEFAULT_GLOBAL_LANGUAGE = {
    key: 'en',
    name: 'English',
  };
  static readonly DATE_RANGE_LABEL = 'From - To (MM/DD/YYYY)';

  static readonly USER_GUIDE = [
    {
      title: 'Overview',
      subhead: 'An Augmented Reality Experience for Equipment Onboarding',
      contents:
        'Expert Guidance is an Augmented Reality experience for equipment onboarding and clinical workflow education developed to decrease staff onboarding time and effort to learn both equipment and complex workflow procedures involved in using the modality, and to also improve performance and quality of care.\nOptimize clinical operations and build a learning health system by training your staff in complex procedures and workflows to increase workforce productivity and retention.',
      image: '../../assets/images/overview.png',
    },
    {
      title: 'Contact Us Tab',
      subhead: '',
      contents:
        'The Contact Us page displays Siemens Healthineers Customer Care information. This page is displayed for all user profiles.',
      image: '../../assets/images/contact.png',
    },
    {
      title: 'About Tab',
      subhead: '',
      contents:
        'The About page displays the product version and build information.',
      image: '../../assets/images/about.png',
    },
  ];

  static readonly SOON_TO_EXPIRE_DURATION = 15;
}
