import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { ParamsService } from '../../services/params.service';
import { LanguageService } from './../../services/language.service';
import { NotificationService } from './../../shared/notification/notification.service';
import {BusinessLine, DeviceAndWorkflowStatus, Status, LicenseCategory} from '@backend/interfaces';
import { WorkflowGuidesService } from '../workflowguides.service';
import { WorkflowVersionService } from './workflow-versions/workflow-version.service';
import { Workflow } from '@backend/api/WorkflowGuides/Workflow/workflow.model';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';
import { Router } from '@angular/router';

const apiRoute: string = 'api/workflowguides/workflows/';

@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  private workflowsSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );

  private workflowsSubjectWithVersions: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    []
  );

  public get workflows$(): any[] {
    return this.workflowsSubject.value;
  }

  public get workflows(): Observable<any[]> {
    return this.workflowsSubject.asObservable();
  }

  public get workflowswithVersions(): Observable<any[]> {
    return this.workflowsSubjectWithVersions.asObservable();
  }


  public constructor(
    private readonly http: HttpClient,
    private readonly paramsService: ParamsService,
    private readonly languageService: LanguageService,
    private readonly notificationService: NotificationService,
    public readonly linksService: WorkflowGuidesService,
    public readonly workflowVersionService: WorkflowVersionService,
    private readonly spinner: SpinnerService,
    private router: Router
  ) {}

  public async createWorkflow(
    key: string,
    title: string,
    description: string,
    thumbnail: File,
    status: number,
    businessLine: BusinessLine,
    thumbnailName : string,
    workflowIdToBeCopied :number
  ): Promise<boolean> {
    const formData = new FormData();
    formData.append('key', key);
    this.paramsService.isLoading = true;
    if (thumbnail) formData.append('thumbnail', thumbnail, thumbnail.name);
    formData.append('status', status.toString());
    formData.append('businessLine', businessLine);
    formData.append('deviceThumbnail', thumbnailName);
    formData.append('workflowIdToBeCopied', workflowIdToBeCopied.toString());
    let result;
    try {
      this.spinner.showSpinner();
      result = await lastValueFrom(this.http.post<any>(apiRoute, formData));
      this.spinner.hideSpinner();
      if (result) {
        this.spinner.showSpinner();
        let copyFromDraftOrRelease = -1;
        let copyFromVersion = null;
        let deviceVersion = -1;
        let ar = false;
        if (workflowIdToBeCopied > 0) {
          let workFlowWithVersion = this.getWorkflowById(workflowIdToBeCopied);
          copyFromVersion = workFlowWithVersion.workflowReleasedVersion
          ? workFlowWithVersion.workflowReleasedVersion
          : workFlowWithVersion.workflowDraftVersion;
          copyFromDraftOrRelease = copyFromVersion.id;
          deviceVersion = copyFromVersion.deviceVersion.id
        }
        this.spinner.hideSpinner();
        await this.workflowVersionService.createWorkflowVersion({
          copyFrom: copyFromDraftOrRelease,
          status: Status.DRAFT,
          deviceVersion: deviceVersion,
          newWorkflowId: result.id, 
          name : key,
          ar : ar
        });
      }
    } catch (e) {
      this.spinner.hideSpinner();
      this.notificationService.error(
        'general.error',
        'workflowguides.workflows.createError',
        null,
        e.message + '\n' + JSON.stringify(e.error)
      );
      return;
    }

    if (title != null) {
      await lastValueFrom(
        this.http.put(
          `${apiRoute}${result.id}/title/${this.languageService.guiLanguageCode}`,
          { title }
        )
      );
    }

    if (description != null) {
      await lastValueFrom(
        this.http.put(
          `${apiRoute}${result.id}/description/${this.languageService.guiLanguageCode}`,
          { description }
        )
      );
    }

    await this.loadWorkflows();
    this.paramsService.isLoading = false;
    return true;
  }

  public async deleteWorkflow(workflow: Workflow) {
      this.paramsService.isLoading = true;
      try {
        await lastValueFrom(
          this.http.delete(
            '/api/workflowguides/workflows/' + workflow.id + '/workflowversions/'
          )
        );
        await lastValueFrom(
          this.http.delete('/api/workflowguides/workflows/' + workflow.id)
        );
        this.notificationService.confirmation(
          'general.success',
          'workflowguides.workflows.deleteSuccess'
        );
        await this.workflowVersionService.removeWorkflowFromPackageUsers(workflow); 
        return true;
      } catch (e) {
        this.notificationService.error(
          'general.error',
          'workflowguides.workflows.deleteError'
        );
        return false;
      } finally {
        this.paramsService.isLoading = false;
    }
  }

  public async editWorkflow(
    id: number,
    title: string,
    description: string,
    thumbnail: File,
    status: number,
    businessLine: BusinessLine
  ) {
    this.spinner.showSpinner();
    try {
      const formData = new FormData();
      if (thumbnail) formData.append('thumbnail', thumbnail, thumbnail.name);
      formData.append('status', status.toString());
      formData.append('businessLine', businessLine);
      let workflowDetails: Workflow = await this.getWorkflow(id);
      
      this.paramsService.isLoading = true;
      await lastValueFrom(this.http.put(apiRoute + id, formData));
      await lastValueFrom(
        this.http.put(
          `${apiRoute}${id}/title/${this.languageService.guiLanguageCode}`,
          {
            title,
          }
        )
      );
      await lastValueFrom(
        this.http.put(
          `${apiRoute}${id}/description/${this.languageService.guiLanguageCode}`,
          { description }
        )
      );
      if (workflowDetails.businessLine != businessLine && workflowDetails.workflowReleasedVersion) {
        await this.workflowVersionService.removeWorkflowFromPackageUsers(workflowDetails);
        await this.workflowVersionService.addWorkflowToPackageUsers(id);       
      }
      this.paramsService.isLoading = false;

      this.notificationService.confirmation(
        'general.success',
        'workflowguides.workflows.modifySuccess'
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'workflowguides.workflows.modifyError'
      );
      return false;
    } finally {
      this.spinner.hideSpinner();
    }
  }

  public async getWorkflow(id: number = this.paramsService.workflowId): Promise<Workflow> {
    if (id) {
      this.paramsService.isLoading = true;
      const result = await lastValueFrom(
        this.http.get<Workflow>('/api/workflowguides/workflows/' + id)
      );
      this.paramsService.isLoading = false;
      return result;
    }
    return null;
  }

  public getWorkflowByKey(key: string) {
    return this.workflowsSubject.value.find((w) => w.key === key);
  }

  public getWorkflowById(id: number) {
    return this.workflowsSubjectWithVersions.value.find((w) => w.id === id);
  }

  public async loadWorkflows() {
    this.spinner.showSpinner();
    this.paramsService.isLoading = true;
    const workflows = await lastValueFrom(this.http.get<any[]>(apiRoute));
    this.paramsService.isLoading = false;
    this.workflowsSubjectWithVersions.next(workflows);
    this.workflowsSubject.next(workflows);
    await this.linksService.loadWfLinks(workflows);
    this.spinner.hideSpinner();
    return true;
  }

  public async getWorkflows() {
    this.paramsService.isLoading = true;
    const workflows = await lastValueFrom(
      this.http.get<any[]>(apiRoute + '?noVersions=true')
    );
    this.paramsService.isLoading = false;
    this.workflowsSubject.next(workflows);
    await this.linksService.loadWfLinks();
    return true;
  }

  public getStatusEnumKeys(): Array<string> {
    const keys = Object.keys(DeviceAndWorkflowStatus);
    const slice = keys.slice(keys.length / 2, keys.length);
    for (let i = 0; i < slice.length; i++) {
      slice[i] = slice[i].replace('BETA', 'Beta').replace('RELEASE', 'Release');
    }
    return slice;
  }

  public async checkForReleaseVersionDependency(workflow: Workflow): Promise<boolean> {   
    const isWorkflowAssignToUser = await lastValueFrom(
      this.http.get<boolean>(
        `/api/license/checkForUserAssignment`,
        {
          params: {
            category: LicenseCategory.WORKFLOW_LICENSE,
            item: workflow.key,
            isDraftAssigned: false
          }
        }
      )
    );
    return isWorkflowAssignToUser;
  }

  public async checkForDraftVersionDependency(workflow: Workflow): Promise<boolean> { 
    const isWorkflowAssignToUser = await lastValueFrom(
      this.http.get<boolean>(
        `/api/license/checkForUserAssignment`,
        {
          params: {
            category: LicenseCategory.WORKFLOW_LICENSE,
            item: workflow.key,
            isDraftAssigned: true
          }
        }
      )
    );
    return isWorkflowAssignToUser;
  }

  public async getAddressableGroupNames(): Promise<string[]> {
    const addressableGroupNames = await lastValueFrom(
      this.http.get<any[]>('/api/v1/public/uploadAssets/addressableGroupNames')
    );
    return addressableGroupNames;
  }

  public getCurrentWorkFlow(): any {
    let allWorkflows: any[] = this.workflowsSubjectWithVersions.getValue();
    let workflow = allWorkflows.find((workflow) => workflow.id === this.paramsService.workflowId);
    return workflow;
  }

  public async createWorkflowDraftVersion(): Promise<void> {
    let selectedWorkflow = this.linksService.getCurrentWorkFlow();
    if (selectedWorkflow.workflowDraftVersion) {
      this.router.navigate([
        'workflowguides/workflows',
        selectedWorkflow.id,
        'versions',
        selectedWorkflow.workflowDraftVersion.id
      ]);
    } else {
      await this.workflowVersionService.createWorkflowVersion({
        arSupport: selectedWorkflow.arSupport,
        status: Status.DRAFT,
        deviceVersion: selectedWorkflow.workflowReleasedVersion.deviceVersion.id,
        copyFrom: this.paramsService.workflowVersionId,
        name: selectedWorkflow.key
      });
      await this.loadWorkflows();
      selectedWorkflow = this.linksService.getCurrentWorkFlow();
      this.router.navigate([
        'workflowguides/workflows',
        this.paramsService.workflowId,
        'versions',
        selectedWorkflow.workflowDraftVersion.id
      ]);
    }
  }
}
