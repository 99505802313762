<sh-text
  size="header-2"
  [innerHTML]="
    (isEditMode
      ? 'devices.versions.hotspots.screens.details.editHotspot'
      : 'devices.versions.hotspots.screens.details.addHotspot'
    ) | translate
  "
></sh-text>

<div class="content">
  <div class="content-row" [class.hidden]="isBackType">
    <sh-text
      size="header-3"
      [innerHTML]="
        'devices.versions.hotspots.screens.details.title' | translate
      "
    ></sh-text>
    <sh-input-text
      #inputName
      mandatory
      [label]="'devices.versions.hotspots.screens.details.title' | translate"
      [value]="titleTrans"
      (value-changed)="title = $event.target.value"
    ></sh-input-text>
  </div>
  <div class="content-row" [class.hidden]="isBackType">
    <sh-text
      size="header-3"
      [innerHTML]="'devices.versions.hotspots.screens.details.text' | translate"
    ></sh-text>
    <sh-input-text
      #inputDesc
      mandatory
      textarea
      rows="6"
      [label]="'devices.versions.hotspots.screens.details.text' | translate"
      [value]="textTrans"
      (value-changed)="text = $event.target.value"
    ></sh-input-text>
  </div>
  <div class="content-row" [class.hidden]="isInformationType">
    <sh-text
      size="header-3"
      [innerHTML]="
        'devices.versions.hotspots.screens.details.target' | translate
      "
    ></sh-text>
    <sh-dropdown
      #inputTarget
      mandatory
      [label]="'devices.versions.hotspots.screens.details.target' | translate"
      [value]="targetName"
      (value-changed)="targetName = $event.target.value"
    >
      <sh-menu-item
        *ngFor="let screen of screensService.screens$ | async"
        [label]="screen.key"
        [value]="screen.key"
      >
        <img
          style="width: 75px"
          slot="functions"
          [src]="screen.image | s3Image | async"
        />
      </sh-menu-item>
    </sh-dropdown>
    <div
      class="large-thumbnails"
      (click)="openTargetScreenModal(screensService.screens)"
    >
      Large Thumbnails
    </div>
  </div>
</div>

<div class="buttons">
  <div style="flex: 1"></div>
  <sh-button
    color="secondary"
    (click)="cancel()"
    size="s"
    [label]="'general.close' | translate"
  ></sh-button>
  <sh-button
    [disabled]="
      (!isBackType && (!inputName.value || !inputDesc.value)) ||
      (!isInformationType && !inputTarget.value)
    "
    size="s"
    (click)="save()"
    [label]="'general.save' | translate"
  ></sh-button>
</div>

<backend-target-screen-modal
  #targetScreenModal
  (screenSelected)="onScreenSelected($event)"
></backend-target-screen-modal>
