import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { ThemeService } from '../services/theme.service';
import { Observable } from 'rxjs';
import { IAdminUser } from '@backend/interfaces';
import { environment } from '../../environments/environment';
import { LocalizationService } from '../shared/localization/localization.service';
import { CreateLanguageComponent } from '../shared/localization/modals/create-language/create-language.component';
import { ConfirmationService } from '../shared/confirmation/confirmation.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'backend-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  $auth: Observable<boolean>;
  $profile: Observable<any>;
  $role: Observable<IAdminUser>;
  productName = environment.product_name;
  productVersion: string;
  frontEndVersion: string;
  isActive: boolean[] = [false, false, false, false, false];
  languages = this.languageService.languages;
  isVisible = true;
  noToggle = false;

  @ViewChild('createLanguageModal')
  public createLanguageModal: CreateLanguageComponent;

  public constructor(
    public authService: AuthService,
    public themeService: ThemeService,
    public router: Router,
    public languageService: LocalizationService,
    public confirmationService: ConfirmationService,
    public http: HttpClient
  ) {
    this.languageService.getLanguages();
  }

  public onThemeToggleClick(event: MouseEvent): void {
    event.stopPropagation();
    this.themeService.toggle();
  }

  async ngOnInit() {
    this.isVisible = true;
    this.$auth = this.authService.loggedIn$;
    this.$profile = this.authService.userProfile$;
    this.$role = this.authService.$role;
    this.authService.applicationVersionDetails$.subscribe((details) => {
      this.productVersion = details.product_version;
      this.frontEndVersion = details.front_end_version;
    });
    this.languages = await this.languageService.getLanguages();
    this.resetActive();
  }

  public async ngAfterViewInit(): Promise<void> {
    this.createLanguageModal.closed.subscribe(async (key) => {
      if (key) {
        await this.languageService.createLanguage(
          key,
          this.createLanguageModal.valueData
        );
        this.languages = await this.languageService.getLanguages();
      }
    });
  }

  resetActive() {
    this.isActive = [false, false, false, false, false];
  }

  changeGlobalLanguage(language) {
    this.confirmationService
      .confirm('language.changeLang', 'language.changeLangConfirmation', {
        langName: language.name,
      })
      .then((onfulfilled) => {
        if (onfulfilled) {
          this.languageService.changeGlobalLanguage(language);
          this.isVisible = true;
        }
      });
  }

  createLanguage() {
    this.createLanguageModal.open();
  }

  deleteLanguage(language) {
    this.languageService
      .deleteLanguage(language.key, language.name)
      .then((onfulfilled) => {
        if (onfulfilled) {
          this.languages = null;
          this.languageService.getLanguages().then((resolved) => {
            if (resolved) {
              this.languages = this.languageService.languages;
            }
          });
        }
      });
  }
}
