import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { BusinessLine, DeviceAndWorkflowStatus } from '@backend/interfaces';
import { SpinnerService } from '../spinner/spinner.service';
import { LanguageService } from '../services/language.service';
import { NotificationService } from '../shared/notification/notification.service';
import { ConfirmationService } from '../shared/confirmation/confirmation.service';
import { ParamsService } from '../services/params.service';
import { areObjectsIdentical } from '../tools';
import { Device } from '@backend/api/Device/device.model';
import { Router } from '@angular/router';
import { DeviceVersionsService } from './device-versions/device-versions.service';
import { ImageOptimizationService } from '../services/imageoptimization.service';

@Injectable({
  providedIn: 'root',
})
export class DevicesService {
  private devicesSubject$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  public get devices$(): Observable<any[]> {
    return this.devicesSubject$.asObservable();
  }
  public get devices(): any[] {
    return this.devicesSubject$.value;
  }

  private deviceSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public get device$(): Observable<any> {
    return this.deviceSubject$.asObservable();
  }
  public get device(): any {
    return this.deviceSubject$.value;
  }

  public businessLines;

  public activeDevice: Device;
  public constructor(
    public router: Router,
    private http: HttpClient,
    private spinner: SpinnerService,
    private paramsService: ParamsService,
    private languageService: LanguageService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    public deviceVersionService: DeviceVersionsService,
    private imageOptimizationService: ImageOptimizationService
  ) {}

  public async getAddressableGroupNames(): Promise<string[]> {
    const addressableGroupNames = await lastValueFrom(
      this.http.get<any[]>('/api/v1/public/uploadAssets/addressableGroupNames')
    );
    return addressableGroupNames;
  }

  public async updateDevices(spinner: boolean = false): Promise<boolean> {
    if (spinner) {
      this.spinner.showSpinner();
    }
    try {
      let devices = await lastValueFrom(this.http.get<any[]>('/api/devices'));
      for (let device of devices) {
        let allDeviceVersion: Array<any> = device.deviceVersions;
        allDeviceVersion = allDeviceVersion.reverse();
        this.assignDraftAndReleaseVersion(device, allDeviceVersion);
      }

      if (!areObjectsIdentical(this.devices, devices)) {
        this.devicesSubject$.next(devices);
      }
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.getError',
        null,
        error.error.message
      );
      return false;
    } finally {
      this.spinner.hideSpinner();
    }
  }

  public assignDraftAndReleaseVersion(
    device: Device,
    allDeviceVersions: any[]
  ): void {
    for (let deviceVersion of allDeviceVersions) {
      if (device.deviceDraftVersion && device.deviceReleasedVersion) {
        break;
      }
      if (!device.deviceDraftVersion && deviceVersion.status === 0) {
        device.deviceDraftVersion = deviceVersion;
      } else if (!device.deviceReleasedVersion && deviceVersion.status === 2) {
        device.deviceReleasedVersion = deviceVersion;
      }
    }
  }
  public async updateDevice(): Promise<boolean> {
    this.paramsService.updateParams();
    const deviceId = this.paramsService.deviceId;
    if (deviceId !== null) {
      const device = await this.getDevice(deviceId);
      if (device && this.device !== device) {
        this.deviceSubject$.next(device);
        return true;
      }
    }

    this.deviceSubject$.next(null);
    return false;
  }

  public async getDevice(
    deviceId: number = this.paramsService.deviceId
  ): Promise<any> {
    if (!this.devices) {
      const updateSuccess = await this.updateDevices();
      if (!updateSuccess) {
        return null;
      }
    }

    return this.devices.find((a) => a.id === deviceId);
  }

  public async createDevice(
    deviceNameInternal: string,
    deviceName: string,
    deviceDescription: string,
    status: number,
    businessLine: BusinessLine,
    addressableGroupName: string,
    thumbnail: string,
    image: File
  ): Promise<Device> {
    this.spinner.showSpinner();
    try {
      const device: any = await lastValueFrom(
        this.http.post('/api/devices', {
          name: deviceNameInternal,
          status: status,
          businessLine: businessLine,
          addressableGroupName: addressableGroupName,
        })
      );
      if (thumbnail) {
        await lastValueFrom(
          this.http.post(`/api/devices/${device.id}/devicedescriptions/copy`, {
            deviceId: device.id,
            deviceThumbnail: thumbnail,
          })
        );
      } else {
        const formData = new FormData();
        image = await this.imageOptimizationService.resizeImage(
          image,
          326,
          246
        );
        formData.append('image', image, image.name);
        await lastValueFrom(
          this.http.post(
            `/api/devices/${device.id}/devicedescriptions`,
            formData
          )
        );
      }
      await lastValueFrom(
        this.http.put(
          `/api/devices/${device.id}/devicedescriptions/name/${this.languageService.guiLanguageCode}`,
          { content: deviceName }
        )
      );
      await lastValueFrom(
        this.http.put(
          `/api/devices/${device.id}/devicedescriptions/description/${this.languageService.guiLanguageCode}`,
          { content: deviceDescription }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.createSuccess',
        { deviceName }
      );
      return device;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.createError',
        { deviceName },
        error.error.message
      );
    } finally {
      await this.updateDevices();
      this.spinner.hideSpinner();
    }
  }

  public async modifyDevice(
    deviceId: number,
    deviceNameInternal: string,
    deviceName: string,
    deviceDescription: string,
    status: number,
    businessLine: BusinessLine,
    addressableGroupName: string,
    image?: File
  ): Promise<boolean> {
    this.spinner.showSpinner();
    try {
      let deviceDetails: Device = await this.getDevice(deviceId);
      await lastValueFrom(
        this.http.put(`/api/devices/${deviceId}`, {
          name: deviceNameInternal,
          status: status,
          businessLine: businessLine,
          addressableGroupName: addressableGroupName,
        })
      );

      if (deviceDetails.businessLine != businessLine && deviceDetails.deviceReleasedVersion) {
        await this.deviceVersionService.removeDeviceFromPackageUsers(deviceDetails);
        await this.deviceVersionService.addDeviceToPackageUsers(deviceId);       
      }

      if (image) {
        image = await this.imageOptimizationService.resizeImage(
          image,
          326,
          246
        );
        const formData = new FormData();
        formData.append('image', image, image.name);
        await lastValueFrom(
          this.http.put(`/api/devices/${deviceId}/devicedescriptions`, formData)
        );
      }
      await lastValueFrom(
        this.http.put(
          `/api/devices/${deviceId}/devicedescriptions/name/${this.languageService.guiLanguageCode}`,
          { content: deviceName }
        )
      );
      await lastValueFrom(
        this.http.put(
          `/api/devices/${deviceId}/devicedescriptions/description/${this.languageService.guiLanguageCode}`,
          { content: deviceDescription }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.modifySuccess',
        { deviceName }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.modifyError',
        { deviceName },
        error.error.message
      );
      return false;
    } finally {
      await this.updateDevices();
      this.spinner.hideSpinner();
    }
  }

  public async deleteDevice(
    deviceId: number,
    forceDelete?: boolean
  ): Promise<boolean> {
    const device: Device = await this.getDevice(deviceId);
    const deviceName = this.languageService.getTranslateValue(
      device.deviceDescription.name
    );
    if (
      forceDelete ||
      (await this.confirmationService.confirmDelete(
        'devices.deleteDevice',
        'devices.deleteDeviceConfirmation',
        { deviceName }
      ))
    ) {
      this.spinner.showSpinner();
      try {
        await lastValueFrom(
          this.http.delete<any[]>(`/api/devices/${deviceId}`)
        );
        this.notificationService.confirmation(
          'general.success',
          'devices.deleteSuccess',
          { deviceName }
        );
        await this.deviceVersionService.removeDeviceFromPackageUsers(device);       
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.deleteError',
          { deviceName },
          error.error.message
        );
        return false;
      } finally {
        await this.updateDevices();
        this.router.navigate(['devices']);
        this.spinner.hideSpinner();
      }
    }
  }

  public getStatusEnumKeys(): Array<string> {
    const keys = Object.keys(DeviceAndWorkflowStatus);
    const slice = keys.slice(keys.length / 2, keys.length);
    for (let i = 0; i < slice.length; i++) {
      slice[i] = slice[i]
        .replace('DRAFT', 'Draft')
        .replace('RELEASE', 'Release');
    }
    return slice;
  }

  public isReleaseVersionActive(): boolean {
    return (
      this.router.url.indexOf(
        this.activeDevice?.deviceReleasedVersion?.id.toString()
      ) !== -1
    );
  }
}
