<sh-table>
  <sh-table-row>
    <sh-table-head
      unsortable
      columns="10"
      [innerHTML]="
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.target'
          | translate
      "
    ></sh-table-head>
    <sh-table-head
      unsortable
      columns="8"
      [innerHTML]="
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.type'
          | translate
      "
    ></sh-table-head>
    <sh-table-head
      unsortable
      columns="4"
      [innerHTML]="
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.countHotspots'
          | translate
      "
    ></sh-table-head>
    <sh-table-head unsortable icon columns="2"></sh-table-head>
  </sh-table-row>

  <ng-container *ngIf="assets">
    <sh-table-row *ngFor="let asset of assets">
      <sh-table-cell
        columns="10"
        [routerLink]="['assets', getType(asset), asset.id]"
      >
        <p class="flow-text" [innerText]="asset.target"></p>
      </sh-table-cell>
      <sh-table-cell
        columns="8"
        [routerLink]="['assets', getType(asset), asset.id]"
      >
        <p
          class="flow-text"
          [innerText]="
            'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.types.' +
              getType(asset) | translate
          "
        ></p>
      </sh-table-cell>
      <sh-table-cell
        columns="4"
        [routerLink]="['assets', getType(asset), asset.id]"
      >
        <p class="flow-text" [innerText]="asset.hotspots?.length"></p>
      </sh-table-cell>
      <sh-table-cell icon columns="2" (click)="deleteAsset(asset.id)">
        <sh-icon button *ngIf="!workflowGuidesService.isReleaseVersionActive()" icon="delete"></sh-icon>
      </sh-table-cell>
    </sh-table-row>
  </ng-container>
</sh-table>

<div class="assets-footer"  *ngIf="!workflowGuidesService.isReleaseVersionActive()">
  <sh-text size="header-3">{{
    'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.addButtons'
      | translate
  }}</sh-text>
  <div class="add-button-group">
    <sh-button 
      size="m"
      [label]="
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.types.image'
          | translate
      "
      [routerLink]="['assets', 'image']"
    ></sh-button>
    <sh-button
      size="m"
      [label]="
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.types.video'
          | translate
      "
      [routerLink]="['assets', 'video']"
    ></sh-button>
    <sh-button
      size="m"
      [label]="
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.types.simulation'
          | translate
      "
      [routerLink]="['assets', 'simulation']"
    ></sh-button>
    <sh-button
      size="m"
      [label]="
        'workflowguides.phases.phaseWrappers.phaseVersions.keyActivities.steps.assets.types.instructions'
          | translate
      "
      [routerLink]="['assets', 'instructions']"
    ></sh-button>
  </div>
</div>
