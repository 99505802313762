import {
  Component,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { PhaseVersionService } from '../phase-versions/phase-version.service';
import { Router } from '@angular/router';
import { LanguageService } from '../../../services/language.service';
import { WorkflowGuidesService } from '../../workflowguides.service';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { SpinnerService } from '@backend/webapp/spinner/spinner.service';
import { ImgConvertorComponent } from '@backend/webapp/img-convertor/img-convertor.component';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';
import { Constants } from '@backend/interfaces';
import { ParamsService } from '../../../services/params.service';

@Component({
  selector: 'backend-edit-phase-wrapper',
  templateUrl: './edit-phase-wrapper.component.html',
  styleUrls: ['./edit-phase-wrapper.component.scss'],
})
export class EditPhaseWrapperComponent implements OnInit {
  @ViewChild('previewImage')
  public previewImage: ElementRef<HTMLImageElement>;

  @ViewChild('inputFile')
  public inputFile: ElementRef<HTMLInputElement>;

  public isEditMode = false;

  public id: number;
  public key: string;
  public name: string;
  private versionDescription: string;
  private copyFrom: number;
  private ar: boolean;
  public isPhaseNameValid = true;
  public editPhase: any;
  public phaseList: any[];
  public copyExistingPhase:boolean = false;
  public workflowListClicked: boolean = false;
  public workflowList: any[];
  public thumbnail: string;
  public thumbnailBasename: string;
  public thumbnailChanged = false;
  descriptionTrans: any[];
  selectedPhase : string;
  public description: string;
  public imgConvertor: ImgConvertorComponent;
  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;

  public constructor(
    private readonly router: Router,
    private readonly renderer: Renderer2,
    private readonly phaseVersionService: PhaseVersionService,
    public readonly languageService: LanguageService,
    public readonly linksService: WorkflowGuidesService,
    public readonly localization: LocalizationService,
    public readonly spinnerService: SpinnerService,
    private readonly paramsService: ParamsService
  ) {
    this.imgConvertor = new ImgConvertorComponent();
  }

  public async ngOnInit(): Promise<void> {
    this.phaseList = [];
    this.spinnerService.showSpinner();
    this.linksService.wflinks.subscribe({
      next: (value) => {
        this.workflowList = value;
      },
    });
    this.resetFields();
    this.updateFields();
    this.spinnerService.hideSpinner();
  }

  private async updateFields(): Promise<void> {
    if (this.paramsService.phaseVersionId) {
      const currentPhaseList = this.GetSPecificWorkflowVersionPhase(
        this.paramsService.workflowId,
        this.paramsService.workflowVersionId
      );
      this.editPhase = currentPhaseList.find(
        (x) => x.id == this.paramsService.phaseVersionId
      );
      this.UpdateFields(this.editPhase);
      this.isEditMode = true;
    } else {
      this.resetFields();
      this.isEditMode = false;
    }
  }

  private UpdateFields(phase: any) {
    this.name = new GetLocaleTextPipe(this.localization).transform(
      phase.name,
      this.globalLanguage.key
    );
    this.selectedPhase = this.name;
    this.description = new GetLocaleTextPipe(this.localization).transform(
      phase.description,
      this.globalLanguage.key
    );
    this.thumbnail = phase.thumbnail;
    if (this.thumbnail) {
      this.thumbnailBasename = this.thumbnail.replace(/.*[\\/]/, '');
    } else {
      this.thumbnailBasename = '';
    }
    this.copyFrom = phase.id;
  }

  private resetFields(): void {
    this.id = null;
    this.key = '';
    this.name = '';
    this.ar = false;
    this.copyFrom = -1;
    this.versionDescription = '';
    this.editPhase = null;
    this.workflowListClicked = false;
  }

  public async save() {
    let result: boolean;
    let file;
    if (this.thumbnailChanged) {
      file = this.imgConvertor.getImageFile();
    } else {
      file = null;
    }
    if (!this.isEditMode) {
      result = await this.phaseVersionService.createPhaseVersion(
        {
          copyFrom: this.copyExistingPhase? this.copyFrom : '-1',
          workflowId: this.paramsService.workflowVersionId,
          name: this.name,
          description: this.description,
        },
        file
      );
    } else {
      result = await this.phaseVersionService.editPhaseVersion(
        this.paramsService.phaseVersionId,
        this.name,
        this.description,
        file,
        this.globalLanguage.key
      );
    }

    if (result) {
      this.gotoWorkFlows();
      this.linksService.loadPhaseLinks();
      this.linksService.loadWfLinks();
    }
  }

  public async copyPhaseWrapperName(phase: any) {
    this.UpdateFields(phase);
  }

  public async cancel() {
    this.gotoWorkFlows();
  }

  public gotoWorkFlows(): void {
    const url = `workflowguides/workflows/${this.paramsService.workflowId}/versions/${this.paramsService.workflowVersionId}`;
    this.router.navigate([url]);
  }

  public async checkForValidPhaseName(phaseName: string): Promise<void> {
    if (phaseName) {
      this.name = phaseName.trim();
      if (this.isEditMode && this.editPhase) {
      const editPhaseName = new GetLocaleTextPipe(this.localization).transform(
        this.editPhase.name,
        this.globalLanguage.key
      );
        if (this.name.toLowerCase() === editPhaseName.trim().toLowerCase()) {
          this.isPhaseNameValid = true;
          return;
        }
      }

      const currentPhaseList = this.GetSPecificWorkflowVersionPhase(
        this.paramsService.workflowId,
        this.paramsService.workflowVersionId
      );
      if (!currentPhaseList) {
        return;
      }

      for (let phase of currentPhaseList) {
        const existingPhaseName = new GetLocaleTextPipe(
          this.localization
        ).transform(phase.name, this.globalLanguage.key);

        if (
          existingPhaseName.trim()?.toLowerCase() === this.name?.toLowerCase()
        ) {
          this.isPhaseNameValid = false;
          break;
        } else {
          this.isPhaseNameValid = true;
        }
      }
    }
  }

  public GetPhasesForSelectedWorkFlow(id: number) {
    this.workflowListClicked = true;
    const workflow = this.workflowList.find((x) => x.id === id);
    this.phaseList =
      workflow.workflowReleasedVersion?.phasen ??
      workflow.workflowDraftVersion?.phasen ??
      [];
      if(this.copyExistingPhase)
      {
        this.copyPhaseWrapperName(this.phaseList[0]);
      }
  }

  private GetSPecificWorkflowVersionPhase(
    workflowId: number,
    workflowVersionId: number
  ): any[] {
    const workflow = this.workflowList.find((x) => x.id === workflowId);
    let phasen;
    if (workflow.workflowReleasedVersion?.id == workflowVersionId) {
      phasen = workflow.workflowReleasedVersion.phasen;
    } else if (workflow.workflowDraftVersion?.id  == workflowVersionId) {
      phasen = workflow.workflowDraftVersion.phasen;
    } else {
      phasen = [];
    }
    return phasen;
  }

  selectFile() {
    this.inputFile.nativeElement.click();
  }

  public fileChange(file: File): void {
    if (file) {
      this.thumbnail = file.name;
      this.thumbnailBasename = file.name.replace(/.*[\\/]/, '');
      this.thumbnailChanged = true;
      const reader = new FileReader();
      reader.onloadend = (e) => {
        let image = new Image();
        this.renderer.setAttribute(
          this.previewImage.nativeElement,
          'src',
          e.target.result as string
        );
        image.src = e.target.result as string;
        image.onload = () => {
          this.imgConvertor.drawImageOnCanvas(image, this.thumbnailBasename);
        };
      };
      reader.readAsDataURL(file);
    } else {
      this.thumbnail = '';
      this.thumbnailBasename = '';
    }
  }
}
