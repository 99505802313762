import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '@backend/interfaces';
import { LocalizationService } from '../shared/localization/localization.service';
import { getAllValuesByLanguageKey, getValueByLanguageKey, getDefaultTranslationOrByKey } from '../tools';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public guiLanguageCode = Constants.DEFAULT_GLOBAL_LANGUAGE.key;
  public guiLanguageName = Constants.DEFAULT_GLOBAL_LANGUAGE.name;

  public constructor(
    private translateService: TranslateService,
    private localization: LocalizationService
  ) {
    this.localization.globalLanguage$.subscribe(async (data) => {
      if (data) {
        this.guiLanguageCode = data.key;
        this.guiLanguageName = data.name;
      }
    });
    this.translateService.setDefaultLang(this.guiLanguageCode);
    this.translateService.use(this.guiLanguageCode);
    document.documentElement.setAttribute('lang', this.guiLanguageCode);
  }

  public getTranslateValue(translations: any[]): string {
    this.localization.globalLanguage$.subscribe(async (data) => {
      if (data) {
        this.guiLanguageCode = data.key;
        this.guiLanguageName = data.name;
      }
    });
    return getValueByLanguageKey(translations, this.guiLanguageCode);
  }

  public getOneTranslateValue(translations: any[]) {
    this.localization.globalLanguage$.subscribe(async (data) => {
      if (data) {
        this.guiLanguageCode = data.key;
        this.guiLanguageName = data.name;
      }
    });
    return getAllValuesByLanguageKey(translations, this.guiLanguageCode);
  }

  public getTranslationByKey(translations: any[], langKey: string): string {
    return getValueByLanguageKey(translations, langKey);
  }

  public getAllTranslationsByLanguageKey(translations: any[], langKey: string) {
    return getAllValuesByLanguageKey(translations, langKey);
  }

  public getDefaultTranslationOrByKey(translations: any[], langKey: string): string {
    return getDefaultTranslationOrByKey(translations, langKey);
  }
}
