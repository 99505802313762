<sh-table>
  <sh-table-row>
    <sh-table-head
      unsortable
      columns="5"
      [innerHTML]="'devices.versions.hotspots.name' | translate"
    ></sh-table-head>
    <sh-table-head
      unsortable
      columns="5"
      [innerHTML]="'devices.versions.hotspots.description' | translate"
    >
    </sh-table-head>
    <sh-table-head
      unsortable
      columns="5"
      [innerHTML]="'devices.versions.hotspots.key' | translate"
    ></sh-table-head>
    <sh-table-head
      unsortable
      columns="8"
      [innerHTML]="'devices.versions.hotspots.file' | translate"
    ></sh-table-head>
    <sh-table-head unsortable icon columns="1"></sh-table-head>
  </sh-table-row>

  <sh-table-row #tableRow *ngIf="hotspotsService.hotspots?.length === 0">
    <sh-table-cell columns="3"></sh-table-cell>
    <sh-table-cell
      columns="21"
      [innerText]="'devices.versions.hotspots.empty' | translate"
    ></sh-table-cell>
  </sh-table-row>

  <sh-table-row
    #tableRow
    *ngFor="let hotspot of hotspotsService.hotspots"
    id="{{ hotspot?.id }}"
    (expand)="selectedHotspotId = hotspot?.id"
    [expanded]="selectedHotspotId === hotspot?.id"
    (contextmenu)="
      popoverService.openPopover(
        popovers,
        'hotspot-menu-' + hotspot?.id,
        $event
      )
    "
  >
    <sh-table-cell
      columns="5"
      [innerText]="hotspot?.title | getLocaleText : globalLanguage?.key : true"
    ></sh-table-cell>

    <sh-table-cell
      columns="5"
      [innerText]="
        hotspot?.description | getLocaleText : globalLanguage?.key : true
      "
    ></sh-table-cell>

    <sh-table-cell columns="5" [innerText]="hotspot?.key"></sh-table-cell>

    <sh-table-cell columns="8">
      <img
        class="hotspots-image"
        *ngIf="hotspot?.thumbnail"
        [src]="hotspot?.thumbnail | s3Image | async"
      />
    </sh-table-cell>

    <sh-table-cell icon columns="1">
      <sh-icon
        button
        icon="more"
        [id]="'hotspot-menu-' + hotspot?.id"
      ></sh-icon>
    </sh-table-cell>

    <sh-popover
      #popover
      [target]="'hotspot-menu-' + hotspot?.id"
      position="left-up"
    >
      <sh-menu-item
        icon="plus"
        [label]="'devices.versions.hotspots.addHotspot' | translate"
        [routerLink]="['add']"
      >
      </sh-menu-item>
      <sh-menu-item
        icon="edit"
        [label]="'devices.versions.hotspots.editHotspot' | translate"
        [routerLink]="[hotspot?.id, 'edit']"
      ></sh-menu-item>
      <sh-menu-item
        icon="delete"
        [label]="'devices.versions.hotspots.deleteHotspot' | translate"
        (click)="deleteHotspot(hotspot?.id)"
      ></sh-menu-item>
      <sh-divider></sh-divider>
      <sh-menu-item
        icon="plus"
        [label]="'devices.versions.hotspots.screens.addScreen' | translate"
        [routerLink]="[hotspot?.id, 'screens', 'add']"
      ></sh-menu-item>
    </sh-popover>

    <sh-table-row>
      <span style="width: 100%" *ngIf="selectedHotspotId === hotspot?.id">
        <sh-table-row
          #tableRow
          *ngFor="let screen of hotspot?.screens"
          id="{{ screen?.id }}"
          (expand)="selectedScreenId = screen?.id"
          [expanded]="selectedScreenId === screen?.id"
          (contextmenu)="
            popoverService.openPopover(
              popovers,
              'hotspot-menu-' + hotspot?.id + '-' + screen?.id,
              $event
            );
            selectedScreenId = screen?.id
          "
        >
          <sh-table-cell columns="15" [innerText]="screen?.key"></sh-table-cell>

          <sh-table-cell columns="8">
            <div class="hotspot-image-container" #container>
              <img
                *ngIf="screen?.image"
                [src]="screen?.image | s3Image | async"
                (dragover)="onDragOver($event)"
                (load)="imageLoaded(container)"
              />

              <div
                *ngFor="let detail of screen?.informations"
                class="hotspot information"
                draggable="true"
                [style.left]="detail?.x * 100 + '%'"
                [style.bottom]="detail?.y * 100 + '%'"
                [title]="
                  detail?.title | getLocaleText : globalLanguage?.key : true
                "
                (dragstart)="onDragStart($event)"
                (dragover)="onDragOver($event)"
                (dragend)="
                  onDragEnd(
                    $event,
                    'informations',
                    hotspot?.id,
                    screen?.id,
                    detail?.id
                  )
                "
                (click)="
                  popoverService.openPopover(
                    popovers,
                    'hotspot-menu-' +
                      hotspot?.id +
                      '-' +
                      screen?.id +
                      '-i-' +
                      detail?.id +
                      '-i',
                    $event
                  )
                "
                (contextmenu)="
                  popoverService.openPopover(
                    popovers,
                    'hotspot-menu-' +
                      hotspot?.id +
                      '-' +
                      screen?.id +
                      '-i-' +
                      detail?.id +
                      '-i',
                    $event
                  )
                "
                [id]="
                  'hotspot-menu-' +
                  hotspot?.id +
                  '-' +
                  screen?.id +
                  '-i-' +
                  detail?.id +
                  '-i'
                "
              ></div>
              <div
                *ngFor="let detail of screen?.switchToScreens"
                class="hotspot switchscreen"
                draggable="true"
                [style.left]="detail?.x * 100 + '%'"
                [style.bottom]="detail?.y * 100 + '%'"
                [title]="
                  detail?.title | getLocaleText : globalLanguage?.key : true
                "
                (dragstart)="onDragStart($event)"
                (dragover)="onDragOver($event)"
                (dragend)="
                  onDragEnd(
                    $event,
                    'switchscreens',
                    hotspot?.id,
                    screen?.id,
                    detail?.id,
                    detail?.switchToScreenId
                  )
                "
                (click)="
                  popoverService.openPopover(
                    popovers,
                    'hotspot-menu-' +
                      hotspot?.id +
                      '-' +
                      screen?.id +
                      '-s-' +
                      detail?.id +
                      '-s',
                    $event
                  )
                "
                (contextmenu)="
                  popoverService.openPopover(
                    popovers,
                    'hotspot-menu-' +
                      hotspot?.id +
                      '-' +
                      screen?.id +
                      '-s-' +
                      detail?.id +
                      '-s',
                    $event
                  )
                "
                [id]="
                  'hotspot-menu-' +
                  hotspot?.id +
                  '-' +
                  screen?.id +
                  '-s-' +
                  detail?.id +
                  '-s'
                "
              ></div>
              <div
                *ngFor="let detail of screen?.backToScreens"
                class="hotspot back"
                draggable="true"
                [style.left]="detail?.x * 100 + '%'"
                [style.bottom]="detail?.y * 100 + '%'"
                (dragstart)="onDragStart($event)"
                (dragover)="onDragOver($event)"
                (dragend)="
                  onDragEnd(
                    $event,
                    'backs',
                    hotspot?.id,
                    screen?.id,
                    detail?.id,
                    detail?.backToScreenId
                  )
                "
                (click)="
                  popoverService.openPopover(
                    popovers,
                    'hotspot-menu-' +
                      hotspot?.id +
                      '-' +
                      screen?.id +
                      '-b-' +
                      detail?.id +
                      '-b',
                    $event
                  )
                "
                (contextmenu)="
                  popoverService.openPopover(
                    popovers,
                    'hotspot-menu-' +
                      hotspot?.id +
                      '-' +
                      screen?.id +
                      '-b-' +
                      detail?.id +
                      '-b',
                    $event
                  )
                "
                [id]="
                  'hotspot-menu-' +
                  hotspot?.id +
                  '-' +
                  screen?.id +
                  '-b-' +
                  detail?.id +
                  '-b'
                "
              ></div>
            </div>
          </sh-table-cell>

          <sh-table-cell icon columns="1">
            <sh-icon
              button
              icon="more"
              [id]="'hotspot-menu-' + hotspot?.id + '-' + screen?.id"
            ></sh-icon>
          </sh-table-cell>

          <sh-popover
            #popover
            [target]="'hotspot-menu-' + hotspot?.id + '-' + screen?.id"
            position="left-up"
          >
            <sh-menu-item
              icon="edit"
              [label]="
                'devices.versions.hotspots.screens.editScreen' | translate
              "
              [routerLink]="[hotspot?.id, 'screens', screen?.id, 'edit']"
            ></sh-menu-item>
            <sh-menu-item
              icon="delete"
              [label]="
                'devices.versions.hotspots.screens.deleteScreen' | translate
              "
              (click)="deleteScreen(hotspot?.id, screen?.id, screen?.key)"
            ></sh-menu-item>
          </sh-popover>

          <sh-table-row>
            <span style="width: 100%" *ngIf="selectedScreenId === screen?.id">
              <sh-table-row
                #tableRow
                [expanded]="
                  hotspotsService.editedHotspot?.detailType === 'informations'
                "
                (contextmenu)="
                  popoverService.openPopover(
                    popovers,
                    'hotspot-menu-' + hotspot?.id + '-i-' + screen?.id,
                    $event
                  )
                "
              >
                <sh-table-cell columns="23">
                  <span>
                    <sh-icon icon="information"></sh-icon
                    >{{
                      'devices.versions.hotspots.screens.information'
                        | translate
                    }}
                  </span>
                </sh-table-cell>

                <sh-table-cell icon columns="1">
                  <sh-icon
                    button
                    icon="more"
                    [id]="'hotspot-menu-' + hotspot?.id + '-i-' + screen?.id"
                  ></sh-icon>
                </sh-table-cell>

                <sh-popover
                  #popover
                  [target]="'hotspot-menu-' + hotspot?.id + '-i-' + screen?.id"
                  position="left-up"
                >
                  <sh-menu-item
                    icon="plus"
                    [label]="
                      'devices.versions.hotspots.screens.details.informations.add'
                        | translate
                    "
                    [routerLink]="[
                      hotspot?.id,
                      'screens',
                      screen?.id,
                      'details',
                      'informations',
                      'add'
                    ]"
                  >
                  </sh-menu-item>
                </sh-popover>

                <sh-table-row
                  #tableRow
                  *ngFor="let detail of screen?.informations"
                  [active]="
                    hotspotsService.editedHotspot?.detailId === detail?.id
                  "
                  [attr.data-id]="
                    hotspot?.id + '-' + screen?.id + '-i-' + detail?.id
                  "
                  (contextmenu)="
                    popoverService.openPopover(
                      popovers,
                      'hotspot-menu-' +
                        hotspot?.id +
                        '-' +
                        screen?.id +
                        '-i-' +
                        detail?.id,
                      $event
                    )
                  "
                >
                  <sh-table-cell
                    columns="23"
                    [innerText]="
                      detail?.title | getLocaleText : globalLanguage?.key : true
                    "
                  ></sh-table-cell>

                  <sh-table-cell icon columns="1">
                    <sh-icon
                      button
                      icon="more"
                      [id]="
                        'hotspot-menu-' +
                        hotspot?.id +
                        '-' +
                        screen?.id +
                        '-i-' +
                        detail?.id
                      "
                    >
                    </sh-icon>
                  </sh-table-cell>

                  <sh-popover
                    #popover
                    [target]="
                      'hotspot-menu-' +
                      hotspot?.id +
                      '-' +
                      screen?.id +
                      '-i-' +
                      detail?.id
                    "
                    position="left-up"
                  >
                    <sh-menu-item
                      icon="edit"
                      [label]="
                        'devices.versions.hotspots.screens.details.informations.edit'
                          | translate
                      "
                      [routerLink]="[
                        hotspot?.id,
                        'screens',
                        screen?.id,
                        'details',
                        'informations',
                        detail?.id,
                        'edit'
                      ]"
                    >
                    </sh-menu-item>
                    <sh-menu-item
                      icon="delete"
                      [label]="
                        'devices.versions.hotspots.screens.details.informations.delete'
                          | translate
                      "
                      (click)="
                        deleteInformationDetail(
                          hotspot?.id,
                          screen?.id,
                          detail?.id,
                          detail?.title
                        )
                      "
                    ></sh-menu-item>
                  </sh-popover>

                  <sh-popover
                    #popover
                    [target]="
                      'hotspot-menu-' +
                      hotspot?.id +
                      '-' +
                      screen?.id +
                      '-i-' +
                      detail?.id +
                      '-i'
                    "
                    position="left-up"
                  >
                    <sh-menu-item
                      icon="edit"
                      [label]="
                        'devices.versions.hotspots.screens.details.informations.edit'
                          | translate
                      "
                      [routerLink]="[
                        hotspot?.id,
                        'screens',
                        screen?.id,
                        'details',
                        'informations',
                        detail?.id,
                        'edit'
                      ]"
                    >
                    </sh-menu-item>
                    <sh-menu-item
                      icon="delete"
                      [label]="
                        'devices.versions.hotspots.screens.details.informations.delete'
                          | translate
                      "
                      (click)="
                        deleteInformationDetail(
                          hotspot?.id,
                          screen?.id,
                          detail?.id,
                          detail?.title
                        )
                      "
                    ></sh-menu-item>
                  </sh-popover>
                </sh-table-row>
              </sh-table-row>

              <sh-table-row
                #tableRow
                [expanded]="
                  hotspotsService.editedHotspot?.detailType === 'switchscreens'
                "
                (contextmenu)="
                  popoverService.openPopover(
                    popovers,
                    'hotspot-menu-' + hotspot?.id + '-s-' + screen?.id,
                    $event
                  )
                "
              >
                <sh-table-cell columns="23">
                  <span>
                    <sh-icon icon="rotate-2d"></sh-icon
                    >{{
                      'devices.versions.hotspots.screens.switchScreen'
                        | translate
                    }}
                  </span>
                </sh-table-cell>

                <sh-table-cell icon columns="1">
                  <sh-icon
                    button
                    icon="more"
                    [id]="'hotspot-menu-' + hotspot?.id + '-s-' + screen?.id"
                  ></sh-icon>
                </sh-table-cell>

                <sh-popover
                  #popover
                  [target]="'hotspot-menu-' + hotspot?.id + '-s-' + screen?.id"
                  position="left-up"
                >
                  <sh-menu-item
                    icon="plus"
                    [label]="
                      'devices.versions.hotspots.screens.details.switchScreens.add'
                        | translate
                    "
                    [routerLink]="[
                      hotspot?.id,
                      'screens',
                      screen?.id,
                      'details',
                      'switchscreens',
                      'add'
                    ]"
                  >
                  </sh-menu-item>
                </sh-popover>

                <sh-table-row
                  #tableRow
                  *ngFor="let detail of screen?.switchToScreens"
                  [active]="
                    hotspotsService.editedHotspot?.detailId === detail?.id
                  "
                  [attr.data-id]="
                    hotspot?.id + '-' + screen?.id + '-s-' + detail?.id
                  "
                  (contextmenu)="
                    popoverService.openPopover(
                      popovers,
                      'hotspot-menu-' +
                        hotspot?.id +
                        '-' +
                        screen?.id +
                        '-s-' +
                        detail?.id,
                      $event
                    )
                  "
                >
                  <sh-table-cell
                    columns="23"
                    [innerText]="
                      detail?.title | getLocaleText : globalLanguage?.key : true
                    "
                  ></sh-table-cell>

                  <sh-table-cell icon columns="1">
                    <sh-icon
                      button
                      icon="more"
                      [id]="
                        'hotspot-menu-' +
                        hotspot?.id +
                        '-' +
                        screen?.id +
                        '-s-' +
                        detail?.id
                      "
                    >
                    </sh-icon>
                  </sh-table-cell>

                  <sh-popover
                    #popover
                    [target]="
                      'hotspot-menu-' +
                      hotspot?.id +
                      '-' +
                      screen?.id +
                      '-s-' +
                      detail?.id
                    "
                    position="left-up"
                  >
                    <sh-menu-item
                      icon="edit"
                      [label]="
                        'devices.versions.hotspots.screens.details.switchScreens.edit'
                          | translate
                      "
                      [routerLink]="[
                        hotspot?.id,
                        'screens',
                        screen?.id,
                        'details',
                        'switchscreens',
                        detail?.id,
                        'edit'
                      ]"
                    >
                    </sh-menu-item>
                    <sh-menu-item
                      icon="delete"
                      [label]="
                        'devices.versions.hotspots.screens.details.switchScreens.delete'
                          | translate
                      "
                      (click)="
                        deleteSwitchScreenDetail(
                          hotspot?.id,
                          screen?.id,
                          detail?.id,
                          detail?.title
                        )
                      "
                    ></sh-menu-item>
                  </sh-popover>

                  <sh-popover
                    #popover
                    [target]="
                      'hotspot-menu-' +
                      hotspot?.id +
                      '-' +
                      screen?.id +
                      '-s-' +
                      detail?.id +
                      '-s'
                    "
                    position="left-up"
                  >
                    <sh-menu-item
                      icon="edit"
                      [label]="
                        'devices.versions.hotspots.screens.details.switchScreens.edit'
                          | translate
                      "
                      [routerLink]="[
                        hotspot?.id,
                        'screens',
                        screen?.id,
                        'details',
                        'switchscreens',
                        detail?.id,
                        'edit'
                      ]"
                    >
                    </sh-menu-item>
                    <sh-menu-item
                      icon="delete"
                      [label]="
                        'devices.versions.hotspots.screens.details.switchScreens.delete'
                          | translate
                      "
                      (click)="
                        deleteSwitchScreenDetail(
                          hotspot?.id,
                          screen?.id,
                          detail?.id,
                          detail?.title
                        )
                      "
                    ></sh-menu-item>
                  </sh-popover>
                </sh-table-row>
              </sh-table-row>

              <sh-table-row
                #tableRow
                [expanded]="
                  hotspotsService.editedHotspot?.detailType === 'backs'
                "
                (contextmenu)="
                  popoverService.openPopover(
                    popovers,
                    'hotspot-menu-' + hotspot?.id + '-b-' + screen?.id,
                    $event
                  )
                "
              >
                <sh-table-cell columns="23">
                  <span>
                    <sh-icon icon="undo"></sh-icon
                    >{{ 'devices.versions.hotspots.screens.back' | translate }}
                  </span>
                </sh-table-cell>

                <sh-table-cell icon columns="1">
                  <sh-icon
                    button
                    icon="more"
                    [id]="'hotspot-menu-' + hotspot?.id + '-b-' + screen?.id"
                  ></sh-icon>
                </sh-table-cell>

                <sh-popover
                  #popover
                  [target]="'hotspot-menu-' + hotspot?.id + '-b-' + screen?.id"
                  position="left-up"
                >
                  <sh-menu-item
                    icon="plus"
                    [label]="
                      'devices.versions.hotspots.screens.details.backs.add'
                        | translate
                    "
                    [routerLink]="[
                      hotspot?.id,
                      'screens',
                      screen?.id,
                      'details',
                      'backs',
                      'add'
                    ]"
                  ></sh-menu-item>
                </sh-popover>

                <sh-table-row
                  #tableRow
                  *ngFor="let detail of screen?.backToScreens"
                  [active]="
                    hotspotsService.editedHotspot?.detailId === detail?.id
                  "
                  [attr.data-id]="
                    hotspot?.id + '-' + screen?.id + '-b-' + detail?.id
                  "
                  (contextmenu)="
                    popoverService.openPopover(
                      popovers,
                      'hotspot-menu-' +
                        hotspot?.id +
                        '-' +
                        screen?.id +
                        '-b-' +
                        detail?.id,
                      $event
                    )
                  "
                >
                  <sh-table-cell
                    columns="23"
                    [innerText]="detail?.backToScreenId"
                  ></sh-table-cell>

                  <sh-table-cell icon columns="1">
                    <sh-icon
                      button
                      icon="more"
                      [id]="
                        'hotspot-menu-' +
                        hotspot?.id +
                        '-' +
                        screen?.id +
                        '-b-' +
                        detail?.id
                      "
                    >
                    </sh-icon>
                  </sh-table-cell>

                  <sh-popover
                    #popover
                    [target]="
                      'hotspot-menu-' +
                      hotspot?.id +
                      '-' +
                      screen?.id +
                      '-b-' +
                      detail?.id
                    "
                    position="left-up"
                  >
                    <sh-menu-item
                      icon="edit"
                      [label]="
                        'devices.versions.hotspots.screens.details.backs.edit'
                          | translate
                      "
                      [routerLink]="[
                        hotspot?.id,
                        'screens',
                        screen?.id,
                        'details',
                        'backs',
                        detail?.id,
                        'edit'
                      ]"
                    >
                    </sh-menu-item>
                    <sh-menu-item
                      icon="delete"
                      [label]="
                        'devices.versions.hotspots.screens.details.backs.delete'
                          | translate
                      "
                      (click)="
                        deleteBackDetail(
                          hotspot?.id,
                          screen?.id,
                          detail?.id,
                          detail?.title
                        )
                      "
                    ></sh-menu-item>
                  </sh-popover>

                  <sh-popover
                    #popover
                    [target]="
                      'hotspot-menu-' +
                      hotspot?.id +
                      '-' +
                      screen?.id +
                      '-b-' +
                      detail?.id +
                      '-b'
                    "
                    position="left-up"
                  >
                    <sh-menu-item
                      icon="edit"
                      [label]="
                        'devices.versions.hotspots.screens.details.backs.edit'
                          | translate
                      "
                      [routerLink]="[
                        hotspot?.id,
                        'screens',
                        screen?.id,
                        'details',
                        'backs',
                        detail?.id,
                        'edit'
                      ]"
                    >
                    </sh-menu-item>
                    <sh-menu-item
                      icon="delete"
                      [label]="
                        'devices.versions.hotspots.screens.details.backs.delete'
                          | translate
                      "
                      (click)="
                        deleteBackDetail(
                          hotspot?.id,
                          screen?.id,
                          detail?.id,
                          detail?.title
                        )
                      "
                    ></sh-menu-item>
                  </sh-popover>
                </sh-table-row>
              </sh-table-row>
            </span>
          </sh-table-row>
        </sh-table-row>
      </span>
    </sh-table-row>

    <sh-table-row #tableRow *ngIf="hotspot?.screens?.length === 0">
      <sh-table-cell columns="3"></sh-table-cell>
      <sh-table-cell
        columns="21"
        [innerText]="'devices.versions.hotspots.screens.empty' | translate"
      ></sh-table-cell>
    </sh-table-row>
  </sh-table-row>
</sh-table>

<div class="buttons">
  <sh-button
    size="m"
    [label]="'general.add' | translate"
    [routerLink]="'add'"
  ></sh-button>
</div>

<backend-edit-hotspot-details
  #editHotspotDetailsModal
></backend-edit-hotspot-details>
