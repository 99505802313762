import { Component } from '@angular/core';
import { ConfirmationService } from './confirmation.service';

@Component({
  selector: 'backend-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  public constructor(public confirmationService: ConfirmationService) {}
}
