<sh-card>
  <sh-text
    size="header-1"
    slot="header"
    [innerText]="'header.tabs.auditTrail' | translate"
  ></sh-text>
  <sh-grid style="margin-top: 20px; max-height: 54px">
    <sh-dropdown
      columns="3"
      [value]="userEmailId"
      [label]="
        isEmail(userEmailId)
          ? ('audit.columns.userName' | translate)
          : getName(userEmailId)
      "
      (value-changed)="userEmailId = $event.target.value"
      [disabled]="($role | async).role !== 2"
    >
      <sh-menu-item
        *ngFor="let user of users"
        [label]="getName(user.userName)"
        [value]="user.userName"
      >
      </sh-menu-item>
    </sh-dropdown>
    <sh-dropdown
      columns="2"
      [value]="action"
      [label]="'audit.actionType' | translate"
      (value-changed)="action = $event.target.value"
    >
      <sh-menu-item *ngFor="let a of actions" [label]="a"></sh-menu-item>
    </sh-dropdown>
    <sh-dropdown
      columns="2"
      [value]="period"
      [label]="'audit.range' | translate"
      (value-changed)="period = $event.target.value; resetDate()"
      class="child-tab audit-tab"
    >
      <sh-menu-item *ngFor="let p of choices" [label]="p"></sh-menu-item>
    </sh-dropdown>
    <sh-datepicker
      id="datePicker"
      columns="3"
      [label]="'audit.customRange' | translate"
      range
      [value]="dateRangeLabel"
      (value-changed)="changeDate($event)"
      future-date-disable
      [disabled]="period !== choices[3]"
    ></sh-datepicker>
    <sh-button
      [disabled]="isInvalid()"
      style="margin-top: 12px"
      columns="1"
      [label]="'audit.apply' | translate"
      size="m"
      (click)="applyFilter()"
    ></sh-button>
    <div columns="1">
      <sh-menu-item
        icon="export"
        [label]="'audit.export' | translate"
        (click)="export()"
        [toggle]="noToggle"
      >
      </sh-menu-item>
    </div>
  </sh-grid>
  <sh-text style="margin-top: 20px" color="secondary"
    >{{ 'audit.rows' | translate }} ({{ totalRecords }})</sh-text
  >
  <sh-table>
    <sh-table-row slot="header">
      <sh-table-head columns="3" unsortable>{{
        'audit.columns.date' | translate
      }}</sh-table-head>
      <sh-table-head columns="4" unsortable>{{
        'audit.columns.userName' | translate
      }}</sh-table-head>
      <sh-table-head columns="4" unsortable>{{
        'audit.columns.message' | translate
      }}</sh-table-head>
      <sh-table-head columns="2" unsortable>{{
        'audit.columns.action' | translate
      }}</sh-table-head>
      <sh-table-head columns="11" unsortable>{{
        'audit.columns.details' | translate
      }}</sh-table-head>
    </sh-table-row>
    <ng-container *ngIf="$auditDetails | async as details">
      <sh-table-row *ngFor="let detail of auditDetails">
        <sh-table-cell columns="3">{{ detail.time }}</sh-table-cell>
        <sh-table-cell columns="4">{{ detail.userName }}</sh-table-cell>
        <sh-table-cell columns="4">{{ detail.summary }}</sh-table-cell>
        <sh-table-cell columns="2">{{ detail.action }}</sh-table-cell>
        <sh-table-cell columns="11">{{ detail.details }}</sh-table-cell>
      </sh-table-row>
    </ng-container>
    <ng-container *ngIf="!auditDetails || !auditDetails.length">
      <sh-table-row>
        <sh-table-cell columns="20">{{
          'audit.empty' | translate
        }}</sh-table-cell>
      </sh-table-row>
    </ng-container>
  </sh-table>

  <sh-pagination
    [pages]="totalPages"
    [attr.current-page]="currPage"
    (clicked-page-move)="paginate($event?.target?.currentPage, pageLen)"
    (next-page-move)="paginate($event?.target?.currentPage, pageLen)"
    (previous-page-move)="paginate($event?.target?.currentPage, pageLen)"
  >
  </sh-pagination>
</sh-card>
