import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FeaturesComponent } from './content/features/features.component';
import { AssetsComponent } from './content/assets/assets.component';
import { AxesComponent } from './content/axes/axes.component';
import { HotspotsComponent } from './content/hotspots/hotspots.component';
import { DeviceLocalizationComponent } from './content/device-localization/device-localization.component';
import { DetailsComponent } from './details.component';
import { AuthGuard } from '../../../auth/auth.guard';
import { EditFeatureComponent } from './content/features/edit-feature/edit-feature.component';
import { EditScreenComponent } from './content/hotspots/hotspot-screens/edit-screen/edit-screen.component';
import { EditAssetsComponent } from './content/assets/edit-assets/edit-assets.component';
import { EditAxisComponent } from './content/axes/edit-axis/edit-axis.component';
import { EditHotspotComponent } from './content/hotspots/edit-hotspot/edit-hotspot.component';
import { EditDetailsComponent } from './content/hotspots/edit-details/edit-details.component';
import { HotspotsCollectionComponent } from './content/hotspots/hotspots-collection/hotspots-collection/hotspots-collection.component';
import { HotspotScreensComponent } from './content/hotspots/hotspot-screens/hotspot-screens.component';
import { ScreenHotspotsComponent } from './content/hotspots/hotspot-screens/screen-hotspots/screen-hotspots.component';

const detailsRoutes: Routes = [
  {
    path: '',
    component: DetailsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'assets',
        component: AssetsComponent,
      },
      {
        path: 'assets/add',
        component: EditAssetsComponent,
      },
      {
        path: 'assets/:assetId/edit',
        component: EditAssetsComponent,
      },
      {
        path: 'assets/:assetId',
        redirectTo: 'assets/:assetId/edit',
      },
      {
        path: 'axes',
        component: AxesComponent,
      },
      {
        path: 'axes/:axisCategoryId/add',
        component: EditAxisComponent,
      },
      {
        path: 'axes/:axisCategoryId/:axisType/:axisId/edit',
        component: EditAxisComponent,
      },
      {
        path: 'features',
        component: FeaturesComponent,
      },
      {
        path: 'features/add',
        component: EditFeatureComponent,
      },
      {
        path: 'features/:featureId/edit',
        component: EditFeatureComponent,
      },
      {
        path: 'features/:featureId',
        redirectTo: 'features/:featureId/edit',
      },
      {
        path: 'hotspots',
        component: HotspotsCollectionComponent,
      },
      {
        path: 'hotspots/add',
        component: EditHotspotComponent,
      },
      {
        path: 'hotspots/:hotspotId',
        component: HotspotsComponent,
      },
      {
        path: 'hotspots/:hotspotId/edit',
        component: EditHotspotComponent,
      },
      {
        path: 'hotspots/:hotspotId/screens',
        component: HotspotScreensComponent,
      },
      {
        path: 'hotspots/:hotspotId/screens/add',
        component: EditScreenComponent,
      },
      {
        path: 'hotspots/:hotspotId/screens/:screenId',
        redirectTo: 'hotspots/:hotspotId/screens/:screenId/edit',
      },
      {
        path: 'hotspots/:hotspotId/screens/:screenId/edit',
        component: EditScreenComponent,
      },
      {
        path: 'hotspots/:hotspotId/screens/:screenId/details',
        component: ScreenHotspotsComponent,
      },
      {
        path: 'hotspots/:hotspotId/screens/:screenId/details/:detailType/add',
        component: EditDetailsComponent,
      },
      {
        path: 'hotspots/:hotspotId/screens/:screenId/details/:detailType/:detailId',
        redirectTo:
          'hotspots/:hotspotId/screens/:screenId/details/:detailType/:detailId/edit',
      },
      {
        path: 'hotspots/:hotspotId/screens/:screenId/details/:detailType/:detailId/edit',
        component: EditDetailsComponent,
      },
      {
        path: 'localization',
        component: DeviceLocalizationComponent,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'features',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(detailsRoutes)],
  exports: [],
})
export class DetailsRoutingModule {}
