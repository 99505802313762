import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { SpinnerService } from '../../../../../spinner/spinner.service';
import { NotificationService } from '../../../../../shared/notification/notification.service';
import { LanguageService } from '../../../../../services/language.service';
import { ConfirmationService } from '../../../../../shared/confirmation/confirmation.service';
import { ParamsService } from '../../../../../services/params.service';
import { areObjectsIdentical } from '../../../../../tools';
import { ImageOptimizationService } from '@backend/webapp/services/imageoptimization.service';

@Injectable({
  providedIn: 'root',
})
export class HotspotsService {
  private hotspotsSubject$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  public get hotspots$(): Observable<any[]> {
    return this.hotspotsSubject$.asObservable();
  }
  public get hotspots(): any[] {
    return this.hotspotsSubject$.value;
  }

  private hotspotSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  public get hotspot$(): Observable<any> {
    return this.hotspotSubject$.asObservable();
  }
  public get hotspot(): any {
    return this.hotspotSubject$.value;
  }

  public editedHotspot: any = undefined;

  public constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private languageService: LanguageService,
    private paramsService: ParamsService,
    private imageOptimizationService: ImageOptimizationService
  ) {}

  public async updateHotspots(spinner: boolean = false): Promise<boolean> {
    if (spinner) {
      this.spinnerService.showSpinner();
    }
    this.paramsService.updateParams();
    if (
      this.paramsService.deviceId !== null &&
      this.paramsService.deviceVersionId !== null
    ) {
      try {
        const values = await lastValueFrom(
          this.http.get<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots`
          )
        );
        if (!areObjectsIdentical(this.hotspots, values)) {
          this.hotspotsSubject$.next(values);
        }
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.hotspots.getError',
          null,
          error.error.message
        );
        return false;
      } finally {
        this.spinnerService.hideSpinner();
      }
    }

    this.hotspotsSubject$.next(null);
    this.spinnerService.hideSpinner();
    return false;
  }

  public async updateHotspot(): Promise<boolean> {
    this.paramsService.updateParams();
    const hotspotId = this.paramsService.hotspotId;
    if (hotspotId !== null) {
      const hotspot = await this.getHotspot(hotspotId);
      if (hotspot && this.hotspot !== hotspot) {
        this.hotspotSubject$.next(hotspot);
        this.editedHotspot = this.hotspot.id;
        return true;
      }
    }

    this.hotspotSubject$.next(null);
    return false;
  }

  public async getHotspot(
    hotspotId: number = this.paramsService.hotspotId
  ): Promise<any> {
    this.spinnerService.showSpinner();
    try {
      if (!this.hotspots) {
        const updateSuccess = await this.updateHotspots();
        if (!updateSuccess) {
          return null;
        }
      }

      return this.hotspots.find((a) => a.id === hotspotId);
    } catch {
    } finally {
      this.spinnerService.hideSpinner();
    }
  }

  public async createHotspot(
    hotspotName: string,
    hotspotDescription: string,
    hotspotKey: string,
    image: File
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      const formData = new FormData();
      formData.append(
        'deviceVersionId',
        this.paramsService.deviceVersionId.toString()
      );
      formData.append('key', hotspotKey);
      image = await this.imageOptimizationService.resizeImage(image, 326, 246);
      formData.append('thumbnail', image, image.name);
      const hotspot = await lastValueFrom(
        this.http.post(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots`,
          formData
        )
      );
      const hotspotId = hotspot['id'];
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${hotspotId}/title/${this.languageService.guiLanguageCode}`,
          { title: hotspotName }
        )
      );
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${hotspotId}/description/${this.languageService.guiLanguageCode}`,
          { description: hotspotDescription }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.hotspots.createSuccess',
        { hotspotName }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.hotspots.createError',
        { hotspotName },
        error.error.message
      );
      return false;
    } finally {
      await this.updateHotspots();
      this.spinnerService.hideSpinner();
    }
  }

  public async modifyHotspot(
    hotspotId: number,
    hotspotName: string,
    hotspotDescription: string,
    hotspotKey: string,
    image?: File
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      const formData = new FormData();
      if (image) {
        image = await this.imageOptimizationService.resizeImage(image, 326, 246);
        formData.append('thumbnail', image, image.name);
      }
      formData.append('key', hotspotKey);
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${hotspotId}`,
          formData
        )
      );
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${hotspotId}/title/${this.languageService.guiLanguageCode}`,
          { title: hotspotName }
        )
      );
      await lastValueFrom(
        this.http.put(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${hotspotId}/description/${this.languageService.guiLanguageCode}`,
          { description: hotspotDescription }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.hotspots.modifySuccess',
        { hotspotName }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.hotspots.modifyError',
        { hotspotName },
        error.error.message
      );
      return false;
    } finally {
      await this.updateHotspots();
      this.spinnerService.hideSpinner();
    }
  }

  public async deleteHotspot(hotspotId: number): Promise<boolean> {
    const hotspot = await this.getHotspot(hotspotId);
    const name = this.languageService.getTranslateValue(hotspot.title);
    if (
      await this.confirmationService.confirmDelete(
        'devices.versions.hotspots.deleteHotspot',
        'devices.versions.hotspots.deleteHotspotConfirmation',
        { hotspotName: name }
      )
    ) {
      this.spinnerService.showSpinner();
      try {
        await lastValueFrom(
          this.http.delete<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/hotspots/${hotspotId}`
          )
        );
        this.notificationService.confirmation(
          'general.success',
          'devices.versions.hotspots.deleteSuccess',
          { hotspotName: name }
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.hotspots.deleteError',
          { hotspotName: name },
          error.error.message
        );
        return false;
      } finally {
        await this.updateHotspots();
        this.spinnerService.hideSpinner();
      }
    }
  }
}
