import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';
import { TokenInterceptor } from '../auth/auth.interceptor';
import { WorkflowGuidesComponent } from './workflowguides.component';
import { EditWorkflowComponent } from './workflows/edit-workflow/edit-workflow.component';
import { WorkflowVersionsComponent } from './workflows/workflow-versions/workflow-versions.component';
import { EditWorkflowVersionComponent } from './workflows/workflow-versions/edit-workflow-version/edit-workflow-version.component';
import { PhasesComponent } from './phases/phases.component';
import { WorkflowGuidesRoutingModule } from './workflowguides-routing.module';
import { WorkflowsComponent } from './workflows/workflows.component';
import { EditPhaseWrapperComponent } from './phases/edit-phase-wrapper/edit-phase-wrapper.component';
import { PhaseVersionsComponent } from './phases/phase-versions/phase-versions.component';
import { EditPhaseVersionComponent } from './phases/phase-versions/edit-phase-version/edit-phase-version.component';
import { KeyActivitiesComponent } from './phases/phase-versions/key-activities/key-activities.component';
import { EditKeyActivityComponent } from './phases/phase-versions/key-activities/edit-key-activity/edit-key-activity.component';
import { StepsComponent } from './phases/phase-versions/key-activities/steps/steps.component';
import { EditStepComponent } from './phases/phase-versions/key-activities/steps/edit-step/edit-step.component';
import { OverviewsComponent } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/overviews.component';
import { EditOverviewComponent } from '@backend/webapp/workflowguides/workflows/workflow-versions/overviews/edit-overview/edit-overview.component';
import {
  FullscreenOverlayContainer,
  OverlayContainer,
  OverlayModule,
} from '@angular/cdk/overlay';
import { EditBlockComponent } from './workflows/workflow-versions/overviews/blocks/edit-block/edit-block.component';
import { EditAuthorComponent } from './workflows/workflow-versions/overviews/blocks/edit-author/edit-author.component';
import { AssetsComponent } from './phases/phase-versions/key-activities/steps/assets/assets.component';
import { EditAssetComponent } from './phases/phase-versions/key-activities/steps/assets/edit-asset/edit-asset.component';
import { HotspotsComponent } from './phases/phase-versions/key-activities/steps/assets/hotspots/hotspots.component';
import { EditHotspotComponent } from './phases/phase-versions/key-activities/steps/assets/hotspots/edit-hotspot/edit-hotspot.component';
import { EditAxisComponent } from './phases/phase-versions/key-activities/steps/edit-step/edit-axis/edit-axis.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    WorkflowGuidesComponent,
    WorkflowsComponent,
    EditWorkflowComponent,
    WorkflowVersionsComponent,
    EditWorkflowVersionComponent,
    PhasesComponent,
    EditPhaseWrapperComponent,
    PhaseVersionsComponent,
    EditPhaseVersionComponent,
    KeyActivitiesComponent,
    EditKeyActivityComponent,
    StepsComponent,
    EditStepComponent,
    OverviewsComponent,
    EditOverviewComponent,
    EditBlockComponent,
    EditAuthorComponent,
    AssetsComponent,
    EditAssetComponent,
    HotspotsComponent,
    EditHotspotComponent,
    EditAxisComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    OverlayModule,
    RouterModule,
    WorkflowGuidesRoutingModule,
    DragDropModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: OverlayContainer,
      useClass: FullscreenOverlayContainer,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WorkflowGuidesModule {}
