<sh-page [theme]="themeService.theme" no-padding>
  <sh-pane slot="left" behavior='collapse' size='expanded' style="font: var(--body-2);height: 100%;" label="Filters"
    resizable="false">
    <sh-text class="clear" (click)="clearFilters()">Clear Filters</sh-text>
    <sh-accordion [label]="'appusers.userType' | translate" expanded>
      <sh-radio-group>
        <sh-radio-button *ngFor="let filterVal of domainFilters" [label]="filterVal" [value]="filterVal"
          [active]="filterOptions.userType === filterVal"
          (click)="filterOptions.userType = $event.target.value;changeFilter();">
        </sh-radio-button>
      </sh-radio-group>
    </sh-accordion>
    <sh-accordion [label]="'appusers.organization' | translate" expanded>
      <sh-text class="filterSection">
        <sh-checkbox *ngFor="let org of appUserService.organizations" [label]="org" (checked)="$event.target.active ? filterOptions.organizations.push(org) :
                  filterOptions.organizations.splice(filterOptions.organizations.indexOf(org), 1); changeFilter();"
          [active]="filterOptions.organizations.includes(org)">
        </sh-checkbox>
      </sh-text>
    </sh-accordion>
    <sh-accordion [label]="'appusers.businessLine' | translate" expanded>
      <sh-text class="filterSection">
        <sh-checkbox *ngFor="let bL of appUserService.businessLines" [label]="bL" (checked)="$event.target.active ? filterOptions.businessLines.push(bL) :
        filterOptions.businessLines.splice(filterOptions.businessLines.indexOf(bL), 1); changeFilter();"
          [active]="filterOptions.businessLines.includes(bL)">
        </sh-checkbox>
      </sh-text>
    </sh-accordion>
    <sh-accordion [label]="'appusers.role' | translate" expanded>
      <sh-text class="filterSection">
        <sh-checkbox *ngFor="let role of appUserService.roles" [label]="role" (checked)="$event.target.active ? filterOptions.roles.push(role) :
        filterOptions.roles.splice(filterOptions.roles.indexOf(role), 1); changeFilter();"
          [active]="filterOptions.roles.includes(role)">
        </sh-checkbox>
      </sh-text>
    </sh-accordion>
    <sh-accordion [label]="('appusers.region' | translate) + ' & ' + ('appusers.country' | translate)" expanded>
      <sh-search [label]="'general.searchByCountry' | translate" (search)="searchByCountry($event.target.value)"
        (clearsearch)="searchByCountry()" id="countrySearch" #countrySearch></sh-search>
      <sh-wrapper direction="row">
        <label style="font: var(--body-1); color: var(--text-primary);margin-right: 0.8em;">{{'general.searchByRegion' |
          translate}}:</label>
        <label style="width: 0.8em;" class="letter" *ngFor="let i of getOrderedLetters()"
          (click)="filterRegions(i)">{{i}}</label>
        <label class="letter" style="text-decoration: none;"
          (click)="filterRegions('');filterOptions.countries = []; changeFilter();">All</label>
      </sh-wrapper>
      <sh-text class="filterSection" style="max-height: calc(var(--sh-text-body2-line-height)*16) !important;">
        <sh-menu-item *ngFor="let loc of filteredRegions" [label]="loc.region" expanded>
          <sh-checkbox class="clearfix" *ngFor="let country of loc.countries" [label]="country"
            (checked)="$event.target.active ? filterOptions.countries.push(country) :
                            filterOptions.countries.splice(filterOptions.countries.indexOf(country), 1); changeFilter();" [active]="filterOptions.countries.includes(country)">
          </sh-checkbox>
        </sh-menu-item>
      </sh-text>
    </sh-accordion>
  </sh-pane>

  <sh-card>
    <sh-grid style="max-height: 54px;">
      <sh-search columns="10" id="searchBox" #searchBox whole-string [label]="'general.search' | translate"
        (search)="onSearch($event.target.value)" (clearsearch)="onSearch()"></sh-search>
      <sh-menu-item columns="1" icon="export" [label]="'appusers.export' | translate" data-toggle="tooltip"
        id="download" (click)="downloadCSV()" [toggle]="noToggle"></sh-menu-item>
      <sh-button columns="1" style="margin-top: 12px" data-toggle="tooltip" id="add" size="m"
        [label]="'general.add' | translate" (click)="onAddClick()">
      </sh-button>
    </sh-grid>
    <sh-grid style="max-height: 72px;">
      <sh-text columns="10" style="margin-top: 36px;" size="body-1">{{ 'appusers.availableUsers' |
        translate }} ({{ filteredUsers?.length }})
      </sh-text>
      <sh-text columns="1" class="sortWrapper" size="body-1">
        Sort:
        <sh-icon color="primary" button id="sortOrder" icon="sort-descending" (click)="toggleSortOrder()"
          data-toggle="tooltip"></sh-icon>
      </sh-text>
      <sh-switch columns="1" class="switchWrapper" stretch>
        <sh-switch-item icon='layout-3x3' (click)="showListView = false; scrollToActiveRow(activeRow);" active
          data-toggle="tooltip" id="tileView"></sh-switch-item>
        <sh-switch-item icon='menu' (click)="getCompleteDataForListView(); scrollToActiveRow(activeRow);" data-toggle="tooltip"
          id="listView"></sh-switch-item>
      </sh-switch>
    </sh-grid>


    <!-- Table View -->
    <ng-container *ngIf="showListView">
      <sh-table id="scrollAnchor" style="margin-top: 10px; overflow-y: auto;" (scroll-end)="onScrollEnd()">
        <sh-table-row slot="header">
          <sh-table-cell unsortable columns="4">
            <sh-text size="title-1" [innerText]="'appusers.name' | translate"></sh-text>
          </sh-table-cell>
          <sh-table-cell unsortable columns="3">
            <sh-text size="title-1" [innerText]="'appusers.organization' | translate"></sh-text>
          </sh-table-cell>
          <sh-table-cell unsortable columns="3">
            <sh-text size="title-1" [innerText]="'appusers.internalExternal' | translate"></sh-text>
          </sh-table-cell>
          <sh-table-cell unsortable columns="4">
            <sh-text size="title-1" [innerText]="'appusers.devices' | translate"></sh-text>
          </sh-table-cell>
          <sh-table-cell unsortable columns="4">
            <sh-text size="title-1" [innerText]="'appusers.workflows' | translate"></sh-text>
          </sh-table-cell>
          <sh-table-cell unsortable columns="4">
            <sh-text size="title-1" [innerText]="'appusers.assignedSites' | translate"></sh-text>
          </sh-table-cell>
          <sh-table-cell unsortable columns="3">
            <sh-text size="title-1" [innerText]="'appusers.technicianTraining' | translate"></sh-text>
          </sh-table-cell>
          <sh-table-cell unsortable columns="2">
            <sh-text size="title-1" [innerText]="'appusers.agb' | translate"></sh-text>
          </sh-table-cell>
          <sh-table-cell unsortable columns="3">
            <sh-text size="title-1" [innerText]="'appusers.created' | translate"></sh-text>
          </sh-table-cell>
          <sh-table-cell unsortable columns="3">
            <sh-text size="title-1" [innerText]="'appusers.firstLogin' | translate"></sh-text>
          </sh-table-cell>
          <sh-table-cell unsortable columns="3">
            <sh-text size="title-1" [innerText]="'appusers.lastLogin' | translate"></sh-text>
          </sh-table-cell>
          <sh-table-cell unsortable icon></sh-table-cell>
        </sh-table-row>
        <ng-container *ngIf="dispalyedAppUsers">
          <sh-table-row [active]="activeRow === user.email" *ngFor="let user of dispalyedAppUsers"
            id="{{user.email}}" (contextmenu)="popoverService.openPopover(
                popovers,
                'appusers-menu-' + user.userId,
                $event
              )">
            <sh-table-cell columns="4" [innerText]="user.email"></sh-table-cell>
            <sh-table-cell columns="3" [innerText]="user.organization"></sh-table-cell>
            <sh-table-cell columns="3"
              [innerText]="user.email.toLowerCase().endsWith(siemensHealthineersDomain) ? 'Internal' : 'External'"></sh-table-cell>
            <sh-table-cell columns="4" [innerText]="user.devices?.join(', ')"></sh-table-cell>
            <sh-table-cell columns="4" [innerText]="user.workflows?.join(', ')"></sh-table-cell>
            <sh-table-cell columns="4"><span>{{user.sites}}</span><span *ngIf="user.enabled && user.sites?.length > 0"
                (click)="showFullData(user)"
                style="color:rgb(207, 75, 0, 0.9);text-decoration:underline;cursor:pointer">...more</span></sh-table-cell>
            <sh-table-cell columns="3">
              <sh-checkbox [label]="''" [active]="user.technicianTraining" disabled></sh-checkbox>
            </sh-table-cell>
            <sh-table-cell columns="2" [innerText]="user.consent ? '✓' : ''"></sh-table-cell>
            <sh-table-cell columns="3" [innerText]="user.created"></sh-table-cell>
            <sh-table-cell columns="3" [innerText]="user.firstLogin"></sh-table-cell>
            <sh-table-cell columns="3" [innerText]="user.lastLogin"></sh-table-cell>
            <sh-table-cell icon>
              <sh-icon button icon="more" [id]="'appusers-menu-' + user.userId"></sh-icon>
            </sh-table-cell>
            <sh-popover #popover [target]="'appusers-menu-' + user?.userId" position="right-up">
              <sh-menu-item icon-size="s" icon="settings" [label]="'appusers.settings' | translate"
                (click)="appuserSettings(user)">
              </sh-menu-item>
              <sh-menu-item icon-size="s" icon="reset" [label]="'appusers.resetPassword' | translate"
                (click)="resetUserPassword(user.userId, user.email)">
              </sh-menu-item>
              <sh-menu-item icon-size="s" icon="favorite" [label]="'appusers.feedback' | translate"
                (click)="setFeedBack(user.userId)">
              </sh-menu-item>
              <sh-menu-item icon-size="s" text-size="body-2" icon="delete" [label]="'general.delete' | translate"
                (click)="deleteUser(user.userId, user.email)">
              </sh-menu-item>
            </sh-popover>
          </sh-table-row>
        </ng-container>
      </sh-table>
    </ng-container>

    <!-- Tile View -->
    <sh-table *ngIf="!showListView" style="margin-top: 10px; overflow-y: auto;" (scroll-end)="onScrollEnd()">
      <sh-grid id="scrollAnchor">
        <sh-card class="userCard" [ngClass]="activeRow === user?.email ? 'activeUser' : 'inactiveUser'"
          id="{{ user?.email }}" *ngFor="let user of dispalyedAppUsers; let i = index" columns="3"
          (contextmenu)="
                popoverService.openPopover(
                  popovers,
                  'appusers-menu-' + user.userId,
                  $event
                )
              ">
          <sh-grid>
            <sh-text columns="11">
              <sh-user-identifier embedded size="body-2" icon-size="s" [name]="getSubstring(user.email, true)"
                [info]="getSubstring(user.email, false)">
              </sh-user-identifier>
            </sh-text>
            <sh-button id="{{ 'appusers-menu-' + user?.userId }}" style="margin-top: 0px; padding-top: 0px"
              color="tertiary" icon-size="s" columns="1" icon="more" (click)="
                    popoverService.openPopover(
                      popovers,
                      'appusers-menu-' + user.userId,
                      $event
                    )
                  ">
            </sh-button>
            <sh-popover #popover [target]="'appusers-menu-' + user?.userId" position="right-up">
              <sh-menu-item icon-size="s" icon="settings" [label]="'appusers.settings' | translate"
                (click)="appuserSettings(user)">
              </sh-menu-item>
              <sh-menu-item icon-size="s" icon="reset" [label]="'appusers.resetPassword' | translate"
                (click)="resetUserPassword(user.userId, user.email)">
              </sh-menu-item>
              <sh-menu-item icon-size="s" icon="favorite" [label]="'appusers.feedback' | translate"
                (click)="setFeedBack(user.userId)">
              </sh-menu-item>
              <sh-menu-item icon-size="s" text-size="body-2" icon="delete" [label]="'general.delete' | translate"
                (click)="deleteUser(user.userId, user.email)">
              </sh-menu-item>
            </sh-popover>
          </sh-grid>
          <sh-grid>
            <sh-text class="compact" size="body-2" columns="4"><strong>{{ 'appusers.organization' | translate }}:
              </strong></sh-text>
            <sh-text class="compact" size="body-2" columns="8">{{
              user.organization || '-'
              }}</sh-text>
            <sh-text class="compact" size="body-2" columns="4"><strong>{{ 'appusers.businessLine' | translate }}:
              </strong></sh-text>
            <sh-text class="compact" size="body-2" columns="8">{{
              user.businessLine || '-'
              }}</sh-text>
            <sh-text class="compact" size="body-2" columns="4"><strong>{{ 'appusers.role' | translate }}:
              </strong></sh-text>
            <sh-text class="compact" size="body-2" columns="8">{{
              user.role || '-'
              }}</sh-text>
            <sh-text class="compact" size="body-2" columns="4"><strong>{{ 'appusers.country' | translate }}:
              </strong></sh-text>
            <sh-text class="compact" size="body-2" columns="8">{{
              user.country || '-'
              }}</sh-text>
            <sh-text class="compact" size="body-2" columns="4"><strong>{{ 'appusers.region' | translate }}:
              </strong></sh-text>
            <sh-text class="compact" size="body-2" columns="8">{{
              user.region || '-'
              }}</sh-text>
            <ng-container *ngIf="isExpanded[i]">
              <sh-text class="compact" size="body-2" columns="4"><strong>{{ 'appusers.firstLogin' | translate }}:
                </strong></sh-text>
              <sh-text class="compact" size="body-2" columns="8">{{
                user.firstLogin || '-'
                }}</sh-text>
              <sh-text class="compact" size="body-2" columns="4"><strong>{{ 'appusers.lastLogin' | translate }}:
                </strong></sh-text>
              <sh-text class="compact" size="body-2" columns="8">{{
                user.lastLogin || '-'
                }}</sh-text>
              <sh-text class="compact" size="body-2" columns="4"><strong>{{ 'appusers.created' | translate }}:
                </strong></sh-text>
              <sh-text class="compact" size="body-2" columns="8">{{
                user.created
                }}</sh-text>
              <sh-text class="compact" size="body-2" columns="4"><strong>{{ 'appusers.agb' | translate }}:
                </strong></sh-text>
              <sh-text class="compact" size="body-2" columns="8">{{
                user.consent ? '✓' : '-'
                }}</sh-text>
            </ng-container>
            <sh-text *ngIf="!isExpanded[i]" columns="3" size="body-2" class="link"
              (click)="changeHeight(user?.email, true); isExpanded[i] = true">
              {{ 'appusers.viewMore' | translate }}
              <sh-icon class="caret" size="s" button icon="arrow-line-down-s"></sh-icon>
            </sh-text>
            <sh-text *ngIf="isExpanded[i]" columns="3" size="body-2" class="link"
              (click)="changeHeight(user?.email, false); isExpanded[i] = false">
              {{ 'appusers.viewLess' | translate }}
              <sh-icon class="caret" size="s" button icon="arrow-line-up-s"></sh-icon>
            </sh-text>
            <sh-text columns="8"></sh-text>
          </sh-grid>
          <div style="margin-top: 0px">
            <sh-text color="secondary" size="body-2">{{
              user.email.toLowerCase().endsWith(HEALTHINEERS_DOMAIN)
              ? INTERNAL_USERS
              : EXTERNAL_USERS
              }}</sh-text>
          </div>
        </sh-card>
      </sh-grid>
    </sh-table>

    <backend-appuser-settings #userSettings (closeEmitted)="highlightActiveUser($event)" (updateUserInfo)="updateUserInfo($event)"></backend-appuser-settings>
    <backend-user-assigned-sites #userassignsites></backend-user-assigned-sites>
    <backend-add-user #adduser (highlightUser)="highlightNewUser($event)"></backend-add-user>
    <backend-edit-organisation #editOrganisation></backend-edit-organisation>
    <sh-tooltip target="download" [label]="'appusers.exportInfo' | translate"></sh-tooltip>
    <sh-tooltip target="add" [label]="'adminusers.addUser' | translate"></sh-tooltip>
    <sh-tooltip target="listView" [label]="'appusers.listView' | translate"></sh-tooltip>
    <sh-tooltip target="tileView" [label]="'appusers.tileView' | translate"></sh-tooltip>
    <sh-tooltip target="settings" [label]="'appusers.settings' | translate"></sh-tooltip>
    <sh-tooltip target="sortOrder" [label]="isAscending ? 'Descending Order': 'Ascending Order'"></sh-tooltip>
    <sh-spinner *ngIf="show | async" overlay size="m"></sh-spinner>
  </sh-card>
</sh-page>