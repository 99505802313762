import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'backend-target-screen-modal',
  templateUrl: './target-screen-modal.component.html',
  styleUrls: ['./target-screen-modal.component.scss'],
})
export class TargetScreenModalComponent {
  public open = false;
  public screens: any[];
  public displayedScreens: any[] = [];

  @Output() screenSelected = new EventEmitter<string>();

  searchScreenString = '';

  public closeModal(): void {
    this.open = false;
  }

  public onScreenSelection(screenKey: string): void {
    this.screenSelected.emit(screenKey);
    this.open = false;
  }

  public onSearchScreenString(searchedString: string): void {
    this.searchScreenString = searchedString;
    this.searchScreensByKey();
  }

  public searchScreensByKey(): void {
    if (!this.searchScreenString) {
      this.displayedScreens = this.screens;
    } else {
      const searchText = this.searchScreenString.toLocaleLowerCase();
      this.displayedScreens = this.screens.filter((screen) =>
        screen.key.toLocaleLowerCase().includes(searchText)
      );
    }
  }
}
