<div>
  <sh-nav-bar slot="nav" style="position: relative">
  <sh-tabs>
    <sh-tab-item
      [label]="
        'workflowguides.phases.phaseWrappers.phaseVersions.tabKeyActivities'
          | translate
      "
      [active]="section === 'keyActivities'"
      [disabled]="!id"
      (click)="section = 'keyActivities'"
    ></sh-tab-item>
  </sh-tabs>
    <sh-button
      style="position: absolute; right: 0; margin: 14px"
      label="Edit"
      *ngIf="linksService.isReleaseVersionActive()"
      (click)="phaseService.checkForSamePhaseInDraft()"
    ></sh-button>
  </sh-nav-bar>
  <div
    class="content"
    [style.display]="section === 'keyActivities' ? 'flex' : 'none'"
  >
    <backend-key-activities></backend-key-activities>
  </div>

  <div class="buttons" *ngIf="!linksService.isReleaseVersionActive()">
    <sh-button
      color="secondary"
      (click)="cancel()"
      size="m"
      [label]="'general.close' | translate"
    ></sh-button>
  </div>
</div>

<input
  #inputFile
  type="file"
  accept=".jpg,.jpeg,.png"
  style="display: none"
  (change)="fileChange($event.target['files'][0])"
/>
