import { Constants } from "@backend/interfaces";

export function getValueByLanguageKey(
  translations: any[],
  langKey: string
): string {
  langKey = langKey.toLowerCase();
  if (Array.isArray(translations) && translations.length > 0) {
    const currentEntry = translations.find(
      (entry: any) => entry.languageKey.toLowerCase() === langKey
    );
    if (currentEntry) {
      return currentEntry.value;
    }
  } else {
    return '';
  }
}

export function getDefaultTranslationOrByKey(
  translations: any[],
  langKey: string
): string {
  langKey = langKey.toLowerCase();
  if (Array.isArray(translations) && translations.length > 0) {
    const currentEntry = translations.find(
      (entry: any) => entry.languageKey.toLowerCase() === langKey
    );
    if (currentEntry) {
      return currentEntry.value;
    } else {
      if (
        translations.find(
          (x) => x.languageKey === Constants.DEFAULT_GLOBAL_LANGUAGE.key
        )
      ) {
        return translations.find(
          (x) => x.languageKey === Constants.DEFAULT_GLOBAL_LANGUAGE.key
        ).value;
      }
    }

  }

  return '';
}

export function getAllValuesByLanguageKey(
  translations: any[],
  langKey: string
): string[] {
  langKey = langKey.toLowerCase();
  if (Array.isArray(translations) && translations.length > 0) {
    const currentEntries = translations.filter(
      (entry: any) => entry.languageKey.toLowerCase() === langKey
    );
    if (currentEntries) {
      return currentEntries.map((val) => val.value);
    }
  } else {
    return [''];
  }
}

export function areObjectsIdentical(a: any, b: any): boolean {
  return JSON.stringify(a) === JSON.stringify(b);
}
