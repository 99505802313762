import { Component, Input, EventEmitter } from '@angular/core';
import { SpinnerService } from '../../../../../../../spinner/spinner.service';
import { AxesService } from '../../axes.service';

@Component({
  selector: 'backend-edit-axis-category',
  templateUrl: './edit-axis-category.component.html',
  styleUrls: ['./edit-axis-category.component.scss'],
})
export class EditAxisCategoryComponent {
  private _visible = false;
  @Input()
  public set visible(value: boolean) {
    this._visible = value;
  }
  public get visible(): boolean {
    return this._visible;
  }

  public isEditMode = false;

  private id = null;
  public name = '';
  public key = '';

  public constructor(
    private axesService: AxesService,
    private spinnerService: SpinnerService
  ) {}

  public reset(): void {
    this.id = null;
    this.name = '';
  }

  public async open(axisCategoryId?: number): Promise<void> {
    this.reset();

    if (axisCategoryId) {
      const axisCategory = await this.axesService.getAxisCategory(
        axisCategoryId
      );
      this.id = axisCategoryId;
      this.name = axisCategory.name;
      this.key = axisCategory.key;
      this.isEditMode = true;
    } else {
      this.isEditMode = false;
    }

    this.visible = true;
  }

  public async close(): Promise<void> {
    this.spinnerService.showSpinner();
    if (!this.isEditMode) {
      await this.axesService.createAxisCategory(this.name, this.key);
    } else {
      await this.axesService.modifyAxisCategory(this.id, this.name, this.key);
    }

    this.visible = false;
    this.spinnerService.hideSpinner();
  }

  public cancel(): void {
    this.visible = false;
  }
}
