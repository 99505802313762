<sh-card>
  <sh-text size="header-1" [innerText]="'pages.title' | translate"></sh-text>
  <sh-table>
    <sh-table-row slot="header">
      <sh-table-head
        unsortable
        columns="7"
        [innerText]="'pages.nameInternal' | translate"
      ></sh-table-head>
      <sh-table-head
        unsortable
        columns="16"
        [innerText]="'pages.name' | translate"
      ></sh-table-head>
      <sh-table-head unsortable icon columns="1"></sh-table-head>
    </sh-table-row>
    <ng-container *ngIf="$pages | async as pages">
      <sh-table-row
        *ngFor="let page of pages"
        (contextmenu)="
          popoverService.openPopover(popovers, 'menu_' + page.id, $event)
        "
      >
        <sh-table-cell columns="7" [innerText]="page.name"></sh-table-cell>
        <sh-table-cell
          columns="16"
          [innerText]="page.title | getLocaleText : globalLanguage?.key : true"
        ></sh-table-cell>
        <sh-table-cell icon columns="1">
          <sh-icon button icon="more" [id]="'menu_' + page.id"></sh-icon>
          <sh-popover #popover [target]="'menu_' + page.id" position="left-up">
            <sh-menu-item
              [routerLink]="['/', 'pages', page.id]"
              icon="edit"
              [label]="'pages.editPage' | translate"
            ></sh-menu-item>
            <sh-divider></sh-divider>
            <sh-menu-item
              (click)="edit(page)"
              icon="edit"
              [label]="'pages.editName' | translate"
            ></sh-menu-item>
            <sh-menu-item
              (click)="delete(page.id)"
              icon="delete"
              [label]="'general.delete' | translate"
            ></sh-menu-item>
          </sh-popover>
        </sh-table-cell>
      </sh-table-row>
    </ng-container>
  </sh-table>
  <sh-button
    slot="footer"
    size="m"
    [label]="'general.add' | translate"
    (click)="addNewPage()"
  ></sh-button>
</sh-card>

<backend-add-page #addPage></backend-add-page>
