import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { SpinnerService } from '../../../../../spinner/spinner.service';
import { NotificationService } from '../../../../../shared/notification/notification.service';
import { AxisType } from './axis-type.enum';
import { ConfirmationService } from '../../../../../shared/confirmation/confirmation.service';
import { ParamsService } from '../../../../../services/params.service';
import { areObjectsIdentical } from '../../../../../tools';

@Injectable({
  providedIn: 'root',
})
export class AxesService {
  private axesSubject$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(
    null
  );
  public get axes$() {
    return this.axesSubject$.asObservable();
  }
  public get axes() {
    return this.axesSubject$.value;
  }

  private axisSubject$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public get axis$() {
    return this.axisSubject$.asObservable();
  }
  public get axis() {
    return this.axisSubject$.value;
  }

  public constructor(
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private paramsService: ParamsService
  ) {}

  public async updateAxes(spinner: boolean = false): Promise<boolean> {
    if (spinner) {
      this.spinnerService.showSpinner();
    }
    this.paramsService.updateParams();
    if (
      this.paramsService.deviceId !== null &&
      this.paramsService.deviceVersionId !== null
    ) {
      try {
        const values = await lastValueFrom(
          this.http.get<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/axiscategories`
          )
        );
        if (!areObjectsIdentical(this.axes, values)) {
          this.axesSubject$.next(values);
        }
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.axes.getError',
          null,
          error.error.message
        );
        return false;
      } finally {
        this.spinnerService.hideSpinner();
      }
    }

    this.axesSubject$.next(null);
    this.spinnerService.hideSpinner();
    return false;
  }

  public async updateAxis(): Promise<boolean> {
    this.paramsService.updateParams();
    const axisId = this.paramsService.axisId;
    const axisType = this.paramsService.axisType;
    if (axisId !== null) {
      const axis = await this.getAxis(axisType as AxisType, axisId);
      if (axis && this.axis !== axis) {
        this.axisSubject$.next(axis);
        return true;
      }
    }

    this.axisSubject$.next(null);
    return false;
  }

  public async getAxisCategory(
    axisCategoryId: number = this.paramsService.axisCategoryId
  ): Promise<any> {
    if (!this.axes) {
      const updateSuccess = await this.updateAxes();
      if (!updateSuccess) {
        return null;
      }
    }

    return this.axes.find((a) => a.id === axisCategoryId);
  }

  public async getAxis(
    axisType: AxisType = this.paramsService.axisType as AxisType,
    axisId: number = this.paramsService.axisId
  ): Promise<any> {
    if (!this.axes) {
      await this.updateAxes();
    }

    for (const category of this.axes) {
      let axes;
      switch (axisType) {
        case AxisType.Rotation:
          axes = category.rotationAxes;
          break;
        case AxisType.Translation:
          axes = category.translationAxes;
          break;
        case AxisType.Virtual:
          axes = category.virtualAxes;
          break;
        default:
          return null;
      }

      for (const axis of axes) {
        if (axis.id === axisId) {
          return axis;
        }
      }
    }

    return null;
  }

  public async createAxisCategory(name: string, key: string): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.post<any[]>(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/axiscategories`,
          { name, key }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.axes.categories.createSuccess',
        { axisCategoryName: name }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.axes.categories.createError',
        { axisCategoryName: name },
        error.error.message
      );
      return false;
    } finally {
      await this.updateAxes();
      this.spinnerService.hideSpinner();
    }
  }

  public async modifyAxisCategory(
    axisCategoryId: number,
    name: string,
    key: string
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.put<any[]>(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/axiscategories/${axisCategoryId}`,
          { name, key }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.axes.categories.modifySuccess',
        { axisCategoryName: name }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.axes.categories.modifyError',
        { axisCategoryName: name },
        error.error.message
      );
      return false;
    } finally {
      await this.updateAxes();
      this.spinnerService.hideSpinner();
    }
  }

  public async deleteAxisCategory(axisCategoryId: number): Promise<boolean> {
    const category = await this.getAxisCategory(axisCategoryId);
    if (
      await this.confirmationService.confirmDelete(
        'devices.versions.axes.categories.deleteCategory',
        'devices.versions.axes.categories.deleteCategoryConfirmation',
        { axisCategoryName: category.name }
      )
    ) {
      this.spinnerService.showSpinner();
      try {
        await lastValueFrom(
          this.http.delete<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/axiscategories/${axisCategoryId}`
          )
        );
        this.notificationService.confirmation(
          'general.success',
          'devices.versions.axes.categories.deleteSuccess',
          { axisCategoryName: category.name }
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.axes.categories.deleteError',
          { axisCategoryName: category.name },
          error.error.message
        );
        return false;
      } finally {
        await this.updateAxes();
        this.spinnerService.hideSpinner();
      }
    }
  }

  public async createRotationAxis(
    axisCategoryId: number,
    name: string,
    key: string,
    startValue: number,
    type: number,
    maxSpeed: number,
    acceleration: number,
    deceleration: number,
    range: number,
    offset: number,
    axisX: number,
    axisY: number,
    axisZ: number
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.post<any[]>(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/axiscategories/${axisCategoryId}/rotations`,
          {
            name,
            key,
            type,
            startValue,
            maxSpeed,
            acceleration,
            deceleration,
            range,
            offset,
            axisX,
            axisY,
            axisZ,
          }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.axes.createSuccess',
        { axisName: name }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.axes.createError',
        { axisName: name },
        error.error.message
      );
      return false;
    } finally {
      await this.updateAxes();
      this.spinnerService.hideSpinner();
    }
  }

  public async createTranslationAxis(
    axisCategoryId: number,
    name: string,
    key: string,
    startValue: number,
    type: number,
    maxSpeed: number,
    acceleration: number,
    deceleration: number,
    startX: number,
    startY: number,
    startZ: number,
    axisX: number,
    axisY: number,
    axisZ: number,
    distance: number
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.post<any[]>(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/axiscategories/${axisCategoryId}/translations`,
          {
            name,
            key,
            type,
            startValue,
            maxSpeed,
            acceleration,
            deceleration,
            offsetX: startX,
            offsetY: startY,
            offsetZ: startZ,
            axisX,
            axisY,
            axisZ,
            range: distance,
          }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.axes.createSuccess',
        { axisName: name }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.axes.createError',
        { axisName: name },
        error.error.message
      );
      return false;
    } finally {
      await this.updateAxes();
      this.spinnerService.hideSpinner();
    }
  }

  public async createVirtualAxis(
    axisCategoryId: number,
    name: string,
    key: string,
    startValue: number,
    type: number,
    maxSpeed: number,
    acceleration: number,
    deceleration: number
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.post<any[]>(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/axiscategories/${axisCategoryId}/virtuals`,
          { name, key, type, startValue, maxSpeed, acceleration, deceleration }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.axes.createSuccess',
        { axisName: name }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.axes.createError',
        { axisName: name },
        error.error.message
      );
      return false;
    } finally {
      await this.updateAxes();
      this.spinnerService.hideSpinner();
    }
  }

  public async modifyRotationAxis(
    axisCategoryId: number,
    axisId: number,
    name: string,
    key: string,
    startValue: number,
    type: number,
    maxSpeed: number,
    acceleration: number,
    deceleration: number,
    range: number,
    offset: number,
    axisX: number,
    axisY: number,
    axisZ: number
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.put<any[]>(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/axiscategories/${axisCategoryId}/rotations/${axisId}`,
          {
            name,
            key,
            type,
            startValue,
            maxSpeed,
            acceleration,
            deceleration,
            range,
            offset,
            axisX,
            axisY,
            axisZ,
          }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.axes.modifySuccess',
        { axisName: name }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.axes.modifyError',
        { axisName: name },
        error.error.message
      );
      return false;
    } finally {
      await this.updateAxes();
      this.spinnerService.hideSpinner();
    }
  }

  public async modifyTranslationAxis(
    axisCategoryId: number,
    axisId: number,
    name: string,
    key: string,
    startValue: number,
    type: number,
    maxSpeed: number,
    acceleration: number,
    deceleration: number,
    startX: number,
    startY: number,
    startZ: number,
    axisX: number,
    axisY: number,
    axisZ: number,
    distance: number
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.put<any[]>(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/axiscategories/${axisCategoryId}/translations/${axisId}`,
          {
            name,
            key,
            type,
            startValue,
            maxSpeed,
            acceleration,
            deceleration,
            offsetX: startX,
            offsetY: startY,
            offsetZ: startZ,
            axisX,
            axisY,
            axisZ,
            range: distance,
          }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.axes.modifySuccess',
        { axisName: name }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.axes.modifyError',
        { axisName: name },
        error.error.message
      );
      return false;
    } finally {
      await this.updateAxes();
      this.spinnerService.hideSpinner();
    }
  }

  public async modifyVirtualAxis(
    axisCategoryId: number,
    axisId: number,
    name: string,
    key: string,
    startValue: number,
    type: number,
    maxSpeed: number,
    acceleration: number,
    deceleration: number
  ): Promise<boolean> {
    this.spinnerService.showSpinner();
    try {
      await lastValueFrom(
        this.http.put<any[]>(
          `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/axiscategories/${axisCategoryId}/virtuals/${axisId}`,
          { name, type, key, startValue, maxSpeed, acceleration, deceleration }
        )
      );
      this.notificationService.confirmation(
        'general.success',
        'devices.versions.axes.modifySuccess',
        { axisName: name }
      );
      return true;
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'devices.versions.axes.modifyError',
        { axisName: name },
        error.error.message
      );
      return false;
    } finally {
      await this.updateAxes();
      this.spinnerService.hideSpinner();
    }
  }

  public async deleteRotationAxis(
    axisCategoryId: number,
    axisId: number
  ): Promise<boolean> {
    const axis = await this.getAxis(AxisType.Rotation, axisId);
    const axisName = axis.name;
    if (
      await this.confirmationService.confirmDelete(
        'devices.versions.axes.deleteAxis',
        'devices.versions.axes.deleteAxisConfirmation',
        { axisName }
      )
    ) {
      this.spinnerService.showSpinner();
      try {
        await lastValueFrom(
          this.http.delete<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/axiscategories/${axisCategoryId}/rotations/${axisId}`
          )
        );
        this.notificationService.confirmation(
          'general.success',
          'devices.versions.axes.deleteSuccess',
          { axisName }
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.axes.deleteError',
          { axisName },
          error.error.message
        );
        return false;
      } finally {
        await this.updateAxes();
        this.spinnerService.hideSpinner();
      }
    }
  }

  public async deleteTranslationAxis(
    axisCategoryId: number,
    axisId: number
  ): Promise<boolean> {
    const axis = await this.getAxis(AxisType.Translation, axisId);
    const axisName = axis.name;
    if (
      await this.confirmationService.confirmDelete(
        'devices.versions.axes.deleteAxis',
        'devices.versions.axes.deleteAxisConfirmation',
        { axisName }
      )
    ) {
      this.spinnerService.showSpinner();
      try {
        await lastValueFrom(
          this.http.delete<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/axiscategories/${axisCategoryId}/translations/${axisId}`
          )
        );
        this.notificationService.confirmation(
          'general.success',
          'devices.versions.axes.deleteSuccess',
          { axisName }
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.axes.deleteError',
          { axisName },
          error.error.message
        );
        return false;
      } finally {
        await this.updateAxes();
        this.spinnerService.hideSpinner();
      }
    }
  }

  public async deleteVirtualAxis(
    axisCategoryId: number,
    axisId: number
  ): Promise<boolean> {
    const axis = await this.getAxis(AxisType.Virtual, axisId);
    const axisName = axis.name;
    if (
      await this.confirmationService.confirmDelete(
        'devices.versions.axes.deleteAxis',
        'devices.versions.axes.deleteAxisConfirmation',
        { axisName }
      )
    ) {
      this.spinnerService.showSpinner();
      try {
        await lastValueFrom(
          this.http.delete<any[]>(
            `/api/devices/${this.paramsService.deviceId}/versions/${this.paramsService.deviceVersionId}/axiscategories/${axisCategoryId}/virtuals/${axisId}`
          )
        );
        this.notificationService.confirmation(
          'general.success',
          'devices.versions.axes.deleteSuccess',
          { axisName }
        );
        return true;
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'devices.versions.axes.deleteError',
          { axisName },
          error.error.message
        );
        return false;
      } finally {
        await this.updateAxes();
        this.spinnerService.hideSpinner();
      }
    }
  }
}
