import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Pipe({
  name: 's3Image',
})
export class S3ImagePipe implements PipeTransform {
  public constructor(private http: HttpClient) {}

  public async transform(key: string): Promise<any> {
    if (!key) return '';
    return await lastValueFrom(
      this.http.get('/api/tools/cloudFrontUrl/', {
        params: { key },
        responseType: 'text',
      })
    );
  }
}
