import {
  Component,
  ViewChildren,
  QueryList,
  ElementRef,
  OnInit,
} from '@angular/core';
import { AssetsService } from './assets.service';
import { PopoverService } from '../../../../../services/popover.service';

@Component({
  selector: 'backend-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent implements OnInit {
  @ViewChildren('popover')
  public popovers: QueryList<ElementRef<HTMLElement>>;

  public platforms = this.assetsService.getPlatformEnumKeys();

  public constructor(
    public popoverService: PopoverService,
    public assetsService: AssetsService
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.assetsService.updateAssets(true);
  }

  public getBasename(path: string): string {
    return path.replace(/.*[\/\\]/, '');
  }
}
