import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeyActivityService } from './key-activity.service';
import { LanguageService } from '../../../../services/language.service';
import { PopoverService } from '../../../../services/popover.service';
import { ParamsService } from '@backend/webapp/services/params.service';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { WorkflowGuidesService } from '@backend/webapp/workflowguides/workflowguides.service';
import { Constants } from '@backend/interfaces';
import { LocalizationService } from '@backend/webapp/shared/localization/localization.service';
import { pairwise } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { GetLocaleTextPipe } from '@backend/webapp/get-locale-text.pipe';

@Component({
  selector: 'backend-key-activities',
  templateUrl: './key-activities.component.html',
  styleUrls: ['./key-activities.component.scss'],
})
export class KeyActivitiesComponent implements OnInit, OnDestroy {
  public keyActivities: any[];
  public searchString: string = '';
  public keyActivitiesWithoutFilter: any[] = [];
  public globalLanguage = Constants.DEFAULT_GLOBAL_LANGUAGE;

  paramSubscription: Subscription;
  languageSubscription: Subscription;

  public constructor(
    public readonly languageService: LanguageService,
    public readonly popoverService: PopoverService,
    public readonly keyActivityService: KeyActivityService,
    public readonly paramsService: ParamsService,
    public readonly router: Router,
    public readonly linksService: WorkflowGuidesService,
    public readonly localization: LocalizationService
  ) {}

  public async deleteKeyActivity(id: number, title) {
    await this.keyActivityService.deleteKeyActivity(id, title);
    this.keyActivitiesWithoutFilter =
      await this.keyActivityService.getCurrentKeyActivities();
    this.resetSearch();
  }

  private resetSearch() {
    this.searchString = '';
    this.keyActivities = this.keyActivitiesWithoutFilter;
  }

  public async ngOnInit(): Promise<void> {
    this.keyActivitiesWithoutFilter =
      await this.keyActivityService.getCurrentKeyActivities();
    this.languageSubscription = this.localization.globalLanguage$.subscribe(
      (data) => {
        if (this.router.isActive) {
          this.globalLanguage = data;
          this.keyActivityService
            .getCurrentKeyActivities()
            .then((activities) => {
              this.keyActivitiesWithoutFilter = activities;
              this.resetSearch();
            });
        }
      }
    );

    this.paramSubscription = this.paramsService.phaseVersionId$
      .pipe(pairwise())
      .subscribe(([previous, current]) => {
        if (previous != null && previous !== current && this.router.isActive) {
          this.keyActivityService
            .getCurrentKeyActivities()
            .then((activities) => {
              this.keyActivitiesWithoutFilter = activities;
              this.resetSearch();
            });
        }
      });
  }

  ngOnDestroy() {
    if (this.paramSubscription) {
      this.paramSubscription.unsubscribe();
    }
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }
  }

  addKeyActivity() {
    // 'phases/:phaseWrapperId/versions/:phaseVersionId/keyactivities/add',
    if (this.router.url.indexOf('/workflows') !== -1) {
      this.router
        .navigate([
          'workflowguides',
          'workflows',
          this.paramsService.workflowId,
          'versions',
          this.paramsService.workflowVersionId,
          'phases',
          this.paramsService.phaseVersionId,
          'keyactivities',
          'add',
        ])
        .then();
    } else {
      this.router
        .navigate([
          'workflowguides',
          'phases',
          this.paramsService.phaseWrapperId,
          'versions',
          this.paramsService.phaseVersionId,
          'keyactivities',
          'add',
        ])
        .then();
    }
  }

  async drop(event: CdkDragDrop<string[]>): Promise<void> {
    await this.keyActivityService.orderKeyActivities(
      this.keyActivitiesWithoutFilter,
      event.previousIndex,
      event.currentIndex
    );
    this.resetSearch();
  }

  private filterKeyActivities() {
    this.keyActivities = [];
    this.keyActivitiesWithoutFilter.forEach((keyActivity) => {
      const title = new GetLocaleTextPipe(this.localization).transform(
        keyActivity.title,
        this.globalLanguage.key
      );
      if (
        title
          .toLocaleLowerCase()
          .includes(this.searchString.toLocaleLowerCase())
      ) {
        this.keyActivities.push(keyActivity);
      }
    });
  }

  public onSearchString(searchedString: string): void {
    this.searchString = searchedString;
    this.filterKeyActivities();
  }
}
