import { Component } from '@angular/core';

import { FeedbackService } from './feedback.service';
import { LanguageService } from '../services/language.service';
import { PopoverService } from '../services/popover.service';
import { DeviceVersionsService } from '../devices/device-versions/device-versions.service';

@Component({
  selector: 'backend-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent {
  public constructor(
    public readonly languageService: LanguageService,
    public readonly popoverService: PopoverService,
    public readonly feedbackService: FeedbackService,
    public readonly deviceVersionsService: DeviceVersionsService
  ) {}
}
