import { Component, OnInit } from '@angular/core';
import { AppUsersService } from '../../appuser.service';

@Component({
  selector: 'backend-user-assigned-sites',
  templateUrl: './user-assigned-sites.component.html',
  styleUrls: ['./user-assigned-sites.component.scss'],
})
export class UserAssignedSitesComponent implements OnInit {
  public open = false;
  public sitesList = [];
  public sites = [];

  public constructor(public appUsersService: AppUsersService) {}

  public ngOnInit(): void {}
}
