import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from '../../../spinner/spinner.service';
import { NotificationService } from '../../../shared/notification/notification.service';
import { ConfirmationService } from '../../../shared/confirmation/confirmation.service';
import { AppUsersService } from '../../appuser.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EditUserInfoService {
  public constructor(
    private http: HttpClient,
    private spinner: SpinnerService,
    private notificationService: NotificationService,
    private confirmationService: ConfirmationService,
    private appUserService: AppUsersService
  ) {}

  public async updateEntries(
    organization: string,
    searchedOrganization: string,
    email: string
  ): Promise<boolean> {
    if (
      await this.confirmationService.confirm(
        'appusers.updateAll',
        'appusers.updateEntries'
      )
    ) {
      this.spinner.showSpinner();
      try {
        await lastValueFrom(
          this.http.put('/api/appusers/updateAllOrganization', {
            organization,
            searchedOrganization,
            email,
          })
        );
        this.notificationService.confirmation(
          'general.success',
          'appusers.userOrganizationSuccess'
        );
      } catch (error) {
        this.notificationService.error(
          'general.error',
          'appusers.userOrganizationError',
          null,
          error.error.message
        );
      }
      await this.appUserService.loadUsers();
      this.spinner.hideSpinner();
      return true;
    } else {
      return false;
    }
  }

  public async updateUserInfo(
    organization: string,
    businessLine: string,
    role: string,
    country: string,
    region: string,
    email: string
  ): Promise<void> {
    this.spinner.showSpinner();
    try {
      await lastValueFrom(
        this.http.put('/api/appusers/updateUserInfo', {
          organization,
          businessLine,
          role,
          country,
          region,
          email,
        })
      );
      this.notificationService.confirmation(
        'general.success',
        'appusers.userInfoSuccess'
      );
    } catch (error) {
      this.notificationService.error(
        'general.error',
        'appusers.userInfoError',
        null,
        error.error.message
      );
    }
    await this.appUserService.loadUsers();
    this.spinner.hideSpinner();
  }
}
