import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';

import { IAppDashboard } from '@backend/interfaces';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
  providedIn: 'root',
})
export class AppdashboardService {
  private users: BehaviorSubject<IAppDashboard[]> = new BehaviorSubject<
    IAppDashboard[]
  >([]);
  public get $users(): Observable<IAppDashboard[]> {
    return this.users.asObservable();
  }

  public constructor(
    private http: HttpClient,
    private spinner: SpinnerService
  ) {
    this.loadAppUsers();
  }

  public async loadAppUsers(): Promise<void> {
    this.spinner.showSpinner();
    this.users.next(
      await lastValueFrom(this.http.get<IAppDashboard[]>('/api/appusers'))
    );
    this.spinner.hideSpinner();
  }
}
