import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { GlobalLocalizationComponent } from './global-localization/global-localization.component';
import { DevicesComponent } from './devices/devices.component';
import { EditDeviceComponent } from './devices/edit-device/edit-device.component';
import { AppuserComponent } from './appuser/appuser.component';
import { EditorComponent } from './pages/editor/editor.component';
import { PagesComponent } from './pages/pages.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { CallbackComponent } from './auth/callback/callback.component';
import { AdminusersComponent } from './adminusers/adminusers.component';
import { SuperAuthGuard } from './auth/superAuth.guard';
import { LoginComponent } from './auth/login/login.component';
import { AppdashboardComponent } from './appdashboard/appdashboard.component';
import { SiteplannerComponent } from './siteplanner/siteplanner.component';
import { UserGuideComponent } from './user-guide/user-guide.component';
import { AuditComponent } from './audit/audit.component';

const routes: Routes = [
  {
    path: 'callback',
    component: CallbackComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'localization',
    component: GlobalLocalizationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pages',
    component: PagesComponent,
    canActivate: [SuperAuthGuard],
    canActivateChild: [SuperAuthGuard],
  },
  {
    path: 'pages/:pageId',
    component: EditorComponent,
    canActivate: [SuperAuthGuard],
    canActivateChild: [SuperAuthGuard],
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'devices',
    component: DevicesComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: ':deviceId/versions/:deviceVersionId',
        loadChildren: () =>
          import('./devices/device-versions/details/details.module').then(
            (m) => m.DetailsModule
          ),
      },
    ],
  },
  {
    path: 'devices/add',
    component: EditDeviceComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'devices/edit/:deviceId',
    component: EditDeviceComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'appusers',
    component: AppuserComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'workflowguides',
    loadChildren: () =>
      import('./workflowguides/workflowguides.module').then(
        (m) => m.WorkflowGuidesModule
      ),
  },
  {
    path: 'adminusers',
    component: AdminusersComponent,
    canActivate: [SuperAuthGuard],
    canActivateChild: [SuperAuthGuard],
  },
  {
    path: 'appdashboard',
    component: AppdashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'siteplanner',
    component: SiteplannerComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
  },
  {
    path: 'userguide',
    component: UserGuideComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'audit',
    component: AuditComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'devices',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
